<template>
    <v-container fluid>
        <v-card elevation="6" tile>
            <v-card-title class="pt-2 pb-2">
                <v-icon color="secondary" left>mdi-chart-bar-stacked</v-icon>
                Dienstaktivität für {{ days }} Tag:e
            </v-card-title>
            <v-divider/>
            <v-card-text>
                <custom-date-picker
                    :text-field-props="{solo: true, dense: true, 'prependInnerIcon': 'mdi-calendar-start-outline', hint: 'Zeitraum (Start)', persistentHint: true}"
                    v-model="startRange"/>
                <custom-date-picker
                    :text-field-props="{solo: true, dense: true, 'prependInnerIcon': 'mdi-calendar-end-outline', hint: 'Zeitraum (Ende)', persistentHint: true}"
                    v-model="endRange"/>

                <div v-if="!userId">
                    <v-select solo dense prepend-inner-icon="mdi-calendar-check-outline" hint="Tagesansicht"
                              persistent-hint :items="activityDateFilterOptions"
                              v-model="activityDateFilter"></v-select>
                </div>

                <v-alert type="info" v-if="userId" elevation="5">
                    Die durchschnittliche Arbeitszeit der letzten {{ days }} Tage ist: <b>{{ averageTime }} Minuten</b>.
                </v-alert>
            </v-card-text>
            <v-divider/>
            <v-data-table dense sort-desc :sort-by="userId ? 'date' : 'duration'" :headers="activityTableContentHeader"
                          :items="activityTableContentFiltered">
            </v-data-table>
            <v-divider/>
            <v-card-text>
                <canvas id="chart" height="100"></canvas>
            </v-card-text>
        </v-card>
    </v-container>
</template>


<script>
import moment           from "moment";
import Chart            from "chart.js/auto";
import chartTrendline   from "chartjs-plugin-trendline";
import CustomDatePicker from "@/components/CustomDatePicker.vue";

export default {
    components: {CustomDatePicker},
    data:       () => ({
        loading:                   false,
        activities:                [],
        activityTableContent:      [],
        activityDateFilter:        moment().format("DD.MM.YYYY"),
        activityDateFilterOptions: [],

        startRange: moment().subtract(14, 'days').format("YYYY-MM-DD"),
        endRange:   moment().format("YYYY-MM-DD"),

        activityTableContentHeader: [
            {text: 'Datum', value: 'date'},
            {text: 'Officer', value: 'officerName'},
            {text: 'Dienstzeit (in Minuten)', value: 'duration'},
            {text: 'Dienstzeit', value: 'durationHuman'},
        ],

        userId: null,

        chartInstance: null,
    }),

    computed: {
        activityTableContentFiltered() {
            let filtered = this.activityTableContent;

            if (this.userId) { // return all rows
                return filtered;
            }

            return filtered.filter(row => row.date === this.activityDateFilter);
        },

        days() {
            let days = parseInt(moment(this.startRange).diff(this.endRange, 'd'));

            return Math.abs(days);
        },

        averageTime() {
            if (this.userId) {
                let durationTotal = 0;
                for (let obj of this.activityTableContentFiltered) {
                    durationTotal += obj['duration'];
                }
                return Math.ceil(durationTotal / this.days);
            }
            return null;
        },
    },

    methods: {
        renderChart() {
            if (this.chartInstance) {
                this.chartInstance.destroy();
            }

            const ctx = document.getElementById('chart').getContext('2d');

            let data              = {};
            let dataByUserAndDate = {}

            for (let activity of this.activities) {
                let clockedInAt = moment(activity.clockedIn).startOf('day');

                let index = clockedInAt.format('DD.MM.YYYY')

                data[index] = data[index] || 0;
                data[index] += activity.duration

                let officerName                       = activity.user.displayName;
                dataByUserAndDate[index]              = dataByUserAndDate[index] || {}
                dataByUserAndDate[index][officerName] = dataByUserAndDate[index][officerName] || 0
                dataByUserAndDate[index][officerName] += activity.duration;
            }

            console.log(dataByUserAndDate);

            this.activityDateFilterOptions = [];

            let tableData = [];
            for (let dateObj in dataByUserAndDate) {
                for (let officerName in dataByUserAndDate[dateObj]) {
                    let totalMinutes = dataByUserAndDate[dateObj][officerName];
                    let hours        = Math.floor(totalMinutes / 60);
                    let minutes      = totalMinutes % 60;
                    if (minutes < 10) minutes = '0' + minutes;
                    if (hours < 10) hours = '0' + hours;

                    tableData.push({
                        date:          dateObj,
                        officerName:   officerName,
                        duration:      totalMinutes,
                        durationHuman: `${hours}h ${minutes}m`
                    })
                }
                this.activityDateFilterOptions.push(dateObj);
            }

            console.log('DateFilterOptions', this.activityDateFilterOptions);

            this.activityDateFilterOptions.sort((a, b) => moment(a, 'DD.MM.YYYY').unix() - moment(b, 'DD.MM.YYYY').unix()).reverse();

            this.activityDateFilter = this.activityDateFilterOptions[0];

            this.activityTableContent = tableData;

            let labels = [];
            let values = [];

            for (let dataKey of Object.keys(data).sort()) {
                labels.push(dataKey);
                values.push(data[dataKey]);
            }

            Chart.register(chartTrendline);
            this.chartInstance = new Chart(ctx, {
                type:    'bar',
                data:    {
                    labels:   labels,
                    datasets: [
                        {
                            label:           'Dienstzeit in Minuten',
                            data:            values,
                            backgroundColor: [
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)'
                            ],
                            borderColor:     [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)'
                            ],
                            borderWidth:     2,

                            trendlineLinear: {
                                style:      "rgba(255,105,180, .8)",
                                lineStyle:  "dotted|solid",
                                width:      2,
                                projection: false,
                            },
                        }
                    ]
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    },
                }
            });
        },

        fetchData() {
            if (this.userId) {
                this.apiGetEmployeeActivitiesInRangeOfFor(this.startRange, this.endRange, this.userId).then(r => {
                    this.activities = r.data;

                    this.renderChart();
                });
            } else {
                this.apiGetAllEmployeeActivitiesInRangeOf(this.startRange, this.endRange).then(r => {
                    this.activities = r.data;

                    this.renderChart();
                });
            }
        }
    },

    watch: {
        startRange: {
            handler: function () {
                this.fetchData()
            }
        },
        endRange:   {
            handler: function () {
                this.fetchData()
            }
        },
    },

    mounted() {
        this.userId = this.$route.params.id;
        this.fetchData();
    }
}
</script>
<template>
  <v-avatar :size="size" color="primary">
    <img :src="apiAttachmentUrl(userObj.avatar) + (cache ? '' : '?cache-reset=' + new Date())" alt="John"
         v-if="userObj.avatar">
    <b style="color: white;" v-else>{{ identificationNumber }}</b>
  </v-avatar>
</template>

<script>
export default {
  props: {
    size: [Number],
    userObj: [Object],
    cache: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  computed: {
    identificationNumber() {
      let regex = /(\d+)/gm
      let m = regex.exec(this.userObj.displayName);

      let id = 'P'
      m.forEach((match) => {
        console.log(match);
        id = match
      })

      return id;
    }
  }
}
</script>
<template>
    <v-container fluid fill-height fill-width>
        <v-card height="100%" fill-height fill-width width="100%" class="pb-12">
            <v-card-title class="pb-2 pt-2">
                <v-icon left color="primary">mdi-map-marker-path</v-icon>
                Karte

                <v-spacer/>

                <v-btn-toggle class="pr-3">
                    <v-btn small color="primary" active-class="error" v-model="editMode">
                        Edit Mode
                    </v-btn>
                </v-btn-toggle>

                <v-btn color="warning" small @click="openEditor()">
                    <v-icon class="pr-2">mdi-plus</v-icon>
                    Create Marker
                </v-btn>
            </v-card-title>
            <v-divider/>

            <div style="z-index: 0;" id="map"></div>
        </v-card>

        <v-dialog v-model="dialogMarkerEditor.active" width="400" persistent>
            <v-card>
                <v-card-title class="pt-2 pb-2 pl-3">
                    <v-icon left>mdi-map-marker-circle</v-icon>
                    Marker Editor
                </v-card-title>
                <v-divider/>
                <v-card-text class="pt-4 pb-2" v-if="dialogMarkerEditor.marker">
                    <v-combobox :items="availableCategories" v-model="dialogMarkerEditor.marker.category"
                                hint="Kategorie"
                                persistent-hint solo
                                dense outlined/>


                    <v-text-field v-model="dialogMarkerEditor.marker.x" hint="Koordinaten (X)" persistent-hint solo
                                  dense outlined/>

                    <v-text-field v-model="dialogMarkerEditor.marker.y" hint="Koordinaten (Y)" persistent-hint solo
                                  dense outlined/>

                    <v-text-field v-model="dialogMarkerEditor.marker.sprite" outlined
                                  persistent-hint solo dense hint="Sprite"/>

                    <v-textarea v-model="dialogMarkerEditor.marker.label" outlined
                                persistent-hint solo dense hint="Beschreibung"/>

                    <v-checkbox v-if="userHasSecurityLevel('HIGH_CRIMINAL_OFFICER') && user.accountType === 'POLICE'"
                                label="Kriminal Polizei (Sicherer Kreis)"
                                dense
                                :background-color="dialogMarkerEditor.marker.restrictionSecurityLevel ? 'error' : ''"
                                v-model="dialogMarkerEditor.marker.restrictionSecurityLevel"
                                value="HIGH_CRIMINAL_OFFICER"/>

                    <v-checkbox v-if="user.trustLevel >= 50 && user.accountType === 'POLICE'" label="Kriminal Polizei"
                                dense
                                v-model="dialogMarkerEditor.marker.restrictionTrustLevel" :value="50"/>

                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-btn color="primary" small @click="dialogMarkerSave" :loading="dialogMarkerEditor.saving">
                        <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                    <v-btn color="secondary" small @click="dialogMarkerClone" v-if="dialogMarkerEditor.marker?.id"
                           :loading="dialogMarkerEditor.saving">
                        <v-icon>mdi-circle-multiple-outline</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-btn color="error" outlined small @click="dialogMarkerDelete"
                           v-if="dialogMarkerEditor.marker && dialogMarkerEditor.marker.id && (user.admin || user.orgAdmin)">
                        Löschen
                    </v-btn>
                    <v-btn color="primary" outlined small @click="dialogMarkerEditor.active = false">
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog width="500" v-model="trafficControlDialog">
            <v-card>
                <v-card-title class="pa-3">
                    <v-icon left color="red darken-3">mdi-shield-outline</v-icon>
                    Traffic Control Unit
                </v-card-title>
                <v-divider/>
                <v-card-text class="mt-6">
                    <v-select :items="trafficControlSettings.types" v-model="trafficControlUnit.unitType"
                              item-text="label"
                              item-value="key"
                              solo outlined dense hint="Art des Leitsystem" persistent-hint
                              :disabled="trafficControlEditMode"/>

                    <v-text-field type="number" v-model="trafficControlUnit.iid" solo outlined dense
                                  hint="ID der Control Unit"
                                  persistent-hint v-if="!trafficControlEditMode"/>
                    <v-text-field v-model="trafficControlUnit.label" solo outlined dense hint="Anzeigename der Einheit"
                                  persistent-hint clearable
                                  :disabled="trafficControlEditMode && !user.admin && !user.orgAdmin"/>
                    <v-text-field v-model="trafficControlUnit.category" solo outlined dense hint="Kategorie der Einheit"
                                  persistent-hint clearable
                                  :disabled="trafficControlEditMode && !user.admin && !user.orgAdmin"/>

                    <v-text-field v-model="trafficControlUnit.positionX" type="number" solo outlined dense
                                  hint="Position X"
                                  persistent-hint clearable v-if="user.admin"/>
                    <v-text-field v-model="trafficControlUnit.positionY" type="number" solo outlined dense
                                  hint="Position Y"
                                  persistent-hint clearable v-if="user.admin"/>

                    <br/>

                    <!-- Lane 1 -->
                    <v-row>
                        <v-col class="pb-0 pt-0">
                            <v-select :items="trafficControlSettings.speedStates"
                                      v-model="trafficControlUnit.lane1Speed"
                                      item-text="label"
                                      item-value="key" solo outlined dense>
                                <template v-slot:selection="{ item }">
                                    <img height="32px" :src='apiImageUrl(item.image)' alt="speed image"/>
                                    <span class="pl-3">{{ item.label }}</span>
                                </template>
                                <template v-slot:item="{ item }">
                                    <img height="32px" :src='apiImageUrl(item.image)' alt="speed image"/>
                                    <span class="pl-3">{{ item.label }}</span>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col class="pb-0 pt-0">
                            <v-select :items="trafficControlSettings.additionalStates"
                                      v-model="trafficControlUnit.lane1Additional"
                                      item-text="label"
                                      item-value="key" solo outlined dense>
                                <template v-slot:selection="{ item }">
                                    <img height="32px" :src='apiImageUrl(item.image)' alt="speed image"/>
                                    <span class="pl-3">{{ item.label }}</span>
                                </template>
                                <template v-slot:item="{ item }">
                                    <img height="32px" :src='apiImageUrl(item.image)' alt="speed image"/>
                                    <span class="pl-3">{{ item.label }}</span>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>

                    <!-- Lane 2 -->
                    <v-row v-if="trafficControlSettings.laneCounts[trafficControlUnit.unitType] >= 2">
                        <v-col class="pb-0 pt-0">
                            <v-select :items="trafficControlSettings.speedStates"
                                      v-model="trafficControlUnit.lane2Speed"
                                      item-text="label"
                                      item-value="key" solo outlined dense>
                                <template v-slot:selection="{ item }">
                                    <img height="32px" :src='apiImageUrl(item.image)' alt="speed image"/>
                                    <span class="pl-3">{{ item.label }}</span>
                                </template>
                                <template v-slot:item="{ item }">
                                    <img height="32px" :src='apiImageUrl(item.image)' alt="speed image"/>
                                    <span class="pl-3">{{ item.label }}</span>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col class="pb-0 pt-0">
                            <v-select :items="trafficControlSettings.additionalStates"
                                      v-model="trafficControlUnit.lane2Additional"
                                      item-text="label"
                                      item-value="key" solo outlined dense>
                                <template v-slot:selection="{ item }">
                                    <img height="32px" :src='apiImageUrl(item.image)' alt="speed image"/>
                                    <span class="pl-3">{{ item.label }}</span>
                                </template>
                                <template v-slot:item="{ item }">
                                    <img height="32px" :src='apiImageUrl(item.image)' alt="speed image"/>
                                    <span class="pl-3">{{ item.label }}</span>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>

                    <!-- Lane 3 -->
                    <v-row v-if="trafficControlSettings.laneCounts[trafficControlUnit.unitType] >= 3"
                           class="pa-0">
                        <v-col class="pb-0 pt-0">
                            <v-select :items="trafficControlSettings.speedStates"
                                      v-model="trafficControlUnit.lane3Speed"
                                      item-text="label"
                                      item-value="key" solo outlined dense>
                                <template v-slot:selection="{ item }">
                                    <img height="32px" :src='apiImageUrl(item.image)' alt="speed image"/>
                                    <span class="pl-3">{{ item.label }}</span>
                                </template>
                                <template v-slot:item="{ item }">
                                    <img height="32px" :src='apiImageUrl(item.image)' alt="speed image"/>
                                    <span class="pl-3">{{ item.label }}</span>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col class="pb-0 pt-0">
                            <v-select :items="trafficControlSettings.additionalStates"
                                      v-model="trafficControlUnit.lane3Additional"
                                      item-text="label"
                                      item-value="key" solo outlined dense>
                                <template v-slot:selection="{ item }">
                                    <img height="32px" :src='apiImageUrl(item.image)' alt="speed image"/>
                                    <span class="pl-3">{{ item.label }}</span>
                                </template>
                                <template v-slot:item="{ item }">
                                    <img height="32px" :src='apiImageUrl(item.image)' alt="speed image"/>
                                    <span class="pl-3">{{ item.label }}</span>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>

                    <!-- Lane 4 -->
                    <v-row v-if="trafficControlSettings.laneCounts[trafficControlUnit.unitType] >= 4">
                        <v-col class="pb-0 pt-0">
                            <v-select :items="trafficControlSettings.speedStates"
                                      v-model="trafficControlUnit.lane4Speed"
                                      item-text="label"
                                      item-value="key" solo outlined dense>
                                <template v-slot:selection="{ item }">
                                    <img height="32px" :src='apiImageUrl(item.image)' alt="speed image"/>
                                    <span class="pl-3">{{ item.label }}</span>
                                </template>
                                <template v-slot:item="{ item }">
                                    <img height="32px" :src='apiImageUrl(item.image)' alt="speed image"/>
                                    <span class="pl-3">{{ item.label }}</span>
                                </template>
                            </v-select>
                        </v-col>
                        <v-col class="pb-0 pt-0">
                            <v-select :items="trafficControlSettings.additionalStates"
                                      v-model="trafficControlUnit.lane4Additional"
                                      item-text="label"
                                      item-value="key" solo outlined dense>
                                <template v-slot:selection="{ item }">
                                    <img height="32px" :src='apiImageUrl(item.image)' alt="speed image"/>
                                    <span class="pl-3">{{ item.label }}</span>
                                </template>
                                <template v-slot:item="{ item }">
                                    <img height="32px" :src='apiImageUrl(item.image)' alt="speed image"/>
                                    <span class="pl-3">{{ item.label }}</span>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-btn small color="primary" @click="createTrafficControlUnit()">
                        <v-icon left>mdi-content-save</v-icon>
                        Speichern
                    </v-btn>
                    <v-btn small color="warning" @click="trafficControlDialog = false">
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<style>
.image-border {
    border-style: solid !important;
    border: #353535;
}

.speed_image {
    width: 48px;
    height: 48px;
}

#map {
    width: 100%;
    height: 100%;
}
</style>

<script>
import L                          from 'leaflet';
import Swal                       from 'sweetalert2';
import {TRAFFIC_CONTROL_SETTINGS} from "@/plugins/config";

const center_x = 117.3;
const center_y = 172.8;
const scale_x  = 0.02072;
const scale_y  = 0.0205;

const CUSTOM_CRS = L.extend({}, L.CRS.Simple, {
    projection: L.Projection.LonLat,

    scale: function (zoom) {
        return Math.pow(2, zoom);
    },

    zoom: function (sc) {
        return Math.log(sc) / 0.6931471805599453;
    },

    distance: function (pos1, pos2) {
        let x_difference = pos2.lng - pos1.lng;
        let y_difference = pos2.lat - pos1.lat;
        return Math.sqrt(x_difference * x_difference + y_difference * y_difference);
    },

    transformation: new L.Transformation(scale_x, center_x, -scale_y, center_y),
    infinite:       true
});

export default {
    data:    () => ({
        blips:   {},
        map:     null,
        markers: [],
        groups:  {},

        editMode: false,

        markerIdx: 1,

        // traffic control
        trafficControlUnit:     {},
        trafficControlDialog:   false,
        trafficControlSettings: TRAFFIC_CONTROL_SETTINGS,
        trafficControlEditMode: false,

        // default MapMarker editor
        dialogMarkerEditor: {
            active: false,
            saving: false,
            marker: null,
        },

        availableCategories:    [],
        availableSecurityFlags: [],

        websocketDispatchSubscriber: null,

        draggedMarkers: [],
    }),
    methods: {
        modifyTrafficControlUnit(unit) {
            this.trafficControlUnit     = unit;
            this.trafficControlEditMode = true;
            this.trafficControlDialog   = true;
        },
        createTrafficControlUnit() {
            if (this.trafficControlUnit.id) {
                this.apiCreateTrafficControl(this.trafficControlUnit).then(() => {
                    this.fetchMarkers();
                })
            } else {
                this.apiCreateTrafficControl(this.trafficControlUnit).then(() => {
                    this.fetchMarkers();
                });
            }

            this.trafficControlDialog   = false;
            this.trafficControlEditMode = false;
        },


        // marker editor
        openEditor(marker) {
            this.dialogMarkerEditor.active = true;

            if (marker) {
                this.dialogMarkerEditor.marker = JSON.parse(JSON.stringify(marker));
            } else {
                this.dialogMarkerEditor.marker = {
                    id:                       undefined,
                    x:                        0,
                    y:                        0,
                    sprite:                   'blips/blip1',
                    category:                 'Andere',
                    restrictionTrustLevel:    null,
                    restrictionSecurityLevel: null,
                }
            }
        },
        dialogMarkerClone() {
            let marker = JSON.parse(JSON.stringify(this.dialogMarkerEditor.marker));

            marker.id = null;
            marker.x += 25.0;

            this.apiCreateMapMarker(marker).then(() => {
                this.fetchDynamicMarkers();

                this.dialogMarkerEditor.saving = false;
                this.dialogMarkerEditor.active = false;
            })
        },
        dialogMarkerSave() {
            this.dialogMarkerEditor.saving = true;

            if (this.dialogMarkerEditor.marker.id !== undefined) {
                if (this.dialogMarkerEditor.marker.x === undefined || this.dialogMarkerEditor.marker.x === null) {
                    this.dialogMarkerEditor.marker.x = 0; // ensure a number in x
                }
                if (this.dialogMarkerEditor.marker.y === undefined || this.dialogMarkerEditor.marker.y === null) {
                    this.dialogMarkerEditor.marker.y = 0; // ensure a number in y
                }

                this.apiUpdateMapMarker(this.dialogMarkerEditor.marker.id, this.dialogMarkerEditor.marker).then(() => {
                    this.fetchDynamicMarkers();

                    this.dialogMarkerEditor.saving = false;
                    this.dialogMarkerEditor.active = false;
                })
            } else {
                this.apiCreateMapMarker(this.dialogMarkerEditor.marker).then(() => {
                    this.fetchDynamicMarkers();

                    this.dialogMarkerEditor.saving = false;
                    this.dialogMarkerEditor.active = false;
                })
            }
        },
        dialogMarkerDelete() {
            Swal.fire({
                title:             'Aktion erforderlich',
                html:              'Möchtest du diesen Marker wirklich löschen?',
                showConfirmButton: true,
                showCancelButton:  true
            }).then(r => {
                if (r.isConfirmed) {
                    this.apiDeleteMapMarker(this.dialogMarkerEditor.marker.id).then(() => {
                        this.leafletRemoveMarker(this.dialogMarkerEditor.marker.id);
                        this.fetchDynamicMarkers();
                        this.dialogMarkerEditor.active = false;
                        this.dialogMarkerEditor.marker = null;
                    })
                } else {
                    this.dialogMarkerEditor.active = false;
                    this.dialogMarkerEditor.marker = null;
                }
            })
        },

        // debug methods


        // lib
        leafletRemoveMarker(idx) {
            if (!this.markers[idx]) return;
            let marker = this.markers[idx];

            Object.keys(this.groups).forEach(key => {
                let g = this.groups[key];
                g.removeLayer(marker);
            })

            this.markers[idx] = null;
        },
        leafletGetLayer(name, enabled) {
            if (!this.groups[name]) {
                this.groups[name] = L.layerGroup();
                if (enabled) this.groups[name].addTo(this.map);
                this.map.layerControl.addOverlay(this.groups[name], name)
            }

            return this.groups[name];
        },
        leafletGetMarker(idx, coords, icon, options) {
            if (this.markers[idx]) {
                return this.markers[idx];
            }

            let markerOpts = Object.assign({}, options, {
                icon: icon
            });

            this.markers[idx] = L.marker(coords, markerOpts);

            return this.markers[idx];
        },
        leafletGetIcon(image) {
            if (this.blips[image]) return this.blips[image];

            let icon = null;

            if (image.startsWith('http')) {
                icon = L.icon({iconUrl: image, iconSize: [24, 24]})
            } else {
                icon = L.icon({iconUrl: this.apiImageUrl(image), iconSize: [24, 24]})
            }

            this.blips[image] = icon;

            return icon;
        },

        fetchMarkers() {
            if (!this.isCurrentAccountPolice) return;

            this.apiGetAllTrafficControls().then(r => {
                let trafficControl = this.leafletGetLayer('🏎️ Traffic Control', false);
                let markerIcon     = this.leafletGetIcon('blips/blip1');

                r.data.forEach(tcu => {
                    let marker = this.leafletGetMarker(tcu.id, [tcu.positionY, tcu.positionX], markerIcon);

                    marker.on('click', () => {
                        this.modifyTrafficControlUnit(tcu);
                    })

                    trafficControl.addLayer(marker);
                })
            });
        },
        fetchDynamicMarkers() {
            this.apiGetAllMapMarkers().then(r => {
                this.availableCategories = [];

                r.data.forEach(mm => {
                    // remove marker first
                    this.leafletRemoveMarker(mm.id);

                    if (!mm.x) mm.x = 0;
                    if (!mm.y) mm.y = 0;

                    let markerIcon = this.leafletGetIcon(mm.sprite);
                    let marker     = this.leafletGetMarker(mm.id, [mm.y, mm.x], markerIcon, {draggable: this.editMode});

                    if (!this.availableCategories.includes(mm.category) && mm.category) {
                        this.availableCategories.push(mm.category);
                    }

                    if (mm.label && !this.editMode) {
                        marker.bindPopup(mm.label.replaceAll('\n', '<br/>'));
                    } else {
                        marker.on('click', event => {
                            let marker   = event.target;
                            let position = marker.getLatLng();

                            mm.y = position.lat;
                            mm.x = position.lng;

                            this.openEditor(mm);
                        })
                    }

                    marker.on('drag', event => {
                        let marker   = event.target;
                        let position = marker.getLatLng();

                        mm.y = position.lat;
                        mm.x = position.lng;

                        let minifiedObject = {
                            id: mm.id,
                            x:  mm.x,
                            y:  mm.y,
                        }

                        this.$root.websocketClient.publish({
                            destination: '/app/traffic-control/interactive-move',
                            body:        JSON.stringify(minifiedObject)
                        });

                        if (!this.draggedMarkers.includes(mm.id)) this.draggedMarkers.push(mm.id);
                    })

                    marker.on('dragend', event => {
                        let marker   = event.target;
                        let position = marker.getLatLng();

                        mm.y = position.lat;
                        mm.x = position.lng;

                        this.apiUpdateMapMarker(mm.id, mm);

                        setTimeout(() => this.draggedMarkers.splice(this.draggedMarkers.indexOf(mm.id), 1), 500);
                    });

                    let category = this.leafletGetLayer(mm.category || 'Sonstige', true);

                    category.addLayer(marker);
                })
            })
        },
        fetchSecurityLevels() {
            this.apiGetAllSecurityLevels().then(r => {
                this.availableSecurityFlags = r.data;
            })
        }
    },
    async mounted() {
        await this.waitForWebsocket(); // wait for socket

        const AtlasStyle = L.tileLayer(this.apiImageUrl('styleAtlas/{z}/{x}/{y}', 'jpg'), {
            minZoom:         0,
            maxZoom:         5,
            noWrap:          true,
            continuousWorld: false,
            attribution:     'Online map GTA V',
            id:              'atlasStyle map',
        });

        const GridStyle = L.tileLayer(this.apiImageUrl('styleGrid/{z}/{x}/{y}', 'png'), {
            minZoom:         0,
            maxZoom:         5,
            noWrap:          true,
            continuousWorld: false,
            attribution:     'Online map GTA V',
            id:              'gridStyle map',
        });

        const SatelliteStyle = L.tileLayer(this.apiImageUrl('styleSatellite/{z}/{x}/{y}', 'jpg'), {
            minZoom:         0,
            maxZoom:         5,
            noWrap:          true,
            continuousWorld: false,
            attribution:     'Online map GTA V',
            id:              'satelliteStyle map',
        });

        const PostalStyle = L.tileLayer(this.apiImageUrl('stylePostal/{z}/{x}/{y}', 'png'), {
            minZoom:         0,
            maxZoom:         5,
            noWrap:          true,
            continuousWorld: false,
            attribution:     'Online map GTA V',
            id:              'postalStyle map',
        });

        const DarkStyle = L.tileLayer(this.apiImageUrl('styleDarkMode/{z}/{x}/{y}', 'png'), {
            minZoom:         0,
            maxZoom:         6,
            noWrap:          true,
            continuousWorld: false,
            attribution:     'Online map GTA V',
            id:              'darkMode map',
        });

        let defaultMapStyle = PostalStyle;
        if (this.$store.state.darkMode) {
            defaultMapStyle = DarkStyle
        }

        this.map = L.map('map', {
            crs:           CUSTOM_CRS,
            minZoom:       3,
            maxZoom:       5,
            Zoom:          5,
            maxNativeZoom: 5,
            preferCanvas:  true,
            layers:        [defaultMapStyle],
            center:        [0, 0],
            zoom:          3,
        });

        this.map.layerControl = L.control.layers({
            'Atlas':     AtlasStyle,
            'Satellite': SatelliteStyle,
            'Grid':      GridStyle,
            'Zip Code':  PostalStyle,
            'Dark Mode': DarkStyle,
        }).addTo(this.map);

        this.fetchSecurityLevels();
        this.fetchMarkers();
        this.fetchDynamicMarkers();


        this.websocketDispatchSubscriber = (await this.waitForWebsocket()).subscribe('/topic/traffic-control/interactive-move', msg => {
            let markerUpdate = JSON.parse(msg.body);

            if (this.draggedMarkers.includes(markerUpdate.id)) return;

            let marker = this.leafletGetMarker(markerUpdate.id)
            marker.setLatLng([markerUpdate.y, markerUpdate.x]);
        })
    },
    beforeDestroy() {
        this.websocketDispatchSubscriber?.unsubscribe();
    },
    watch: {
        editMode() {
            this.fetchDynamicMarkers();
        }
    }
}
</script>
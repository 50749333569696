<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-card elevation="4" tile>
                    <v-card-title class="pa-2 pl-4">
                        <v-icon left color="info">mdi-chair-rolling</v-icon>
                        Gewerbebuch
                        <v-spacer/>
                        <v-btn x-small color="primary" to="/company/new">
                            <v-icon small class="mr-1">mdi-content-save</v-icon>
                            Neues Gewerbe erstellen
                        </v-btn>
                    </v-card-title>
                    <v-divider/>
                    <company-list-view-companies/>
                    <v-divider/>
                    <v-card-actions>
                        <v-row class="pa-2">
                            <v-col sm="2" cols="12">
                                <v-icon left color="error">mdi-store-alert-outline</v-icon>
                                10 oder mehr Strikes
                            </v-col>
                            <v-col sm="2" cols="12">
                                <v-icon left color="grey">mdi-account-alert-outline</v-icon>
                                Kein Besitzer
                            </v-col>
                            <v-col sm="2" cols="12">
                                <v-icon left color="warning">mdi-phone-alert-outline</v-icon>
                                Keine Telefonnummer
                            </v-col>
                            <v-col sm="2" cols="12">
                                <v-icon left color="info">mdi-tsunami</v-icon>
                                Beurlaubt
                            </v-col>
                            <v-col sm="2" cols="12">
                                <v-icon left color="warning">mdi-credit-card-clock-outline</v-icon>
                                Zahlung bevorstehend
                            </v-col>
                            <v-col sm="2" cols="12">
                                <v-icon left color="error">mdi-currency-eur-off</v-icon>
                                Im Zahlungsverzug
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card elevation="4" tile>
                    <v-card-title class="pa-2 pl-4">
                        <v-icon left color="info">mdi-file-cabinet</v-icon>
                        Letzten Einträge
                    </v-card-title>
                    <v-divider/>
                    <company-list-view-record-entries/>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import CompanyListViewCompanies     from "@/views/gov/CompanyListViewCompanies.vue";
import CompanyListViewRecordEntries from "@/views/gov/CompanyListViewRecordEntries.vue";

export default {
    components: {CompanyListViewRecordEntries, CompanyListViewCompanies},
    data:       () => ({}),
    computed:   {},
    methods:    {},
    mounted() {
    }
}
</script>
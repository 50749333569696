<template>
    <v-app>
        <v-app-bar dense color="primary" app :clipped-left="isAuthenticated" dark fixed elevation="3">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

            <v-toolbar-title>
                <v-icon left v-if="userAccountType === 'POLICE'">mdi-police-badge-outline</v-icon>
                <v-icon left v-if="userAccountType === 'ZOLL'">mdi-police-badge-outline</v-icon>
                <v-icon left v-if="userAccountType === 'FEDERAL_INTELLIGENCE_SERVICE'">mdi-police-badge-outline</v-icon>
                <v-icon left v-if="userAccountType === 'MEDIC'">mdi-ambulance</v-icon>
                <v-icon left v-if="userAccountType === 'GOVERNMENT'">mdi-island</v-icon>
                <v-icon left v-if="userAccountType === 'ADMIN'">mdi-chair-rolling</v-icon>
                <v-icon left v-if="userAccountType === 'VETERINARY'">mdi-dog</v-icon>
                <v-icon left v-if="userAccountType === 'none'">mdi-home-group</v-icon>
                {{ $FRONTEND_URL }}
            </v-toolbar-title>

            <v-spacer v-if="developmentMode"/>
            <v-btn small color="info" v-if="developmentMode" elevation="12" tile>
                Entwicklermodus
            </v-btn>
        </v-app-bar>

        <v-navigation-drawer app clipped color="primary darken-1" v-model="drawer"
                             v-if="isAuthenticated && $route.name !== 'OrgApplication'"
                             dark>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="text-h6">
                        {{ user.displayName }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="pt-3">
                        {{ user.rank }} ({{ user.trustLevel }})
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider/>
            <v-list dense nav>
                <v-list-item v-for="item in navigationItems" :key="item.title" link :to="item.to" :href="item.href"
                             exact>
                    <v-list-item-icon>
                        <v-badge dot color="red" v-if="item.isNewVariable && compareLocalValueToTrue(item.isNewVariable)">
                            <v-icon :color="item.color" :class="item.color === 'error' ? 'wiggle' : ''">
                                {{ item.icon }}
                            </v-icon>
                        </v-badge>
                        <v-icon v-else :color="item.color" :class="item.color === 'error' ? 'wiggle' : ''">
                            {{ item.icon }}
                        </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-main>
            <v-container fluid v-if="notifications.length > 0 && isAuthenticated" class="pb-0">
                <v-row class="pb-0">
                    <v-col cols="12" class="pb-0">
                        <v-alert v-for="notification in notifications" :key="notification.id" dense
                                 :icon="notification.icon"
                                 :type="notification.color" :prominent="notification.prominent"
                                 :border="notification.border"
                                 elevation="2">
                            <div v-html="mdToHtml(notification.message)" class="alert"></div>
                        </v-alert>
                    </v-col>
                </v-row>
            </v-container>
            <v-container fluid v-if="newYear" class="pb-0">
                <v-row class="pb-0">
                    <v-col cols="12" class="pb-0">
                        <img alt="Happy new Year 2024" :src="require('@/assets/newyear2024_2.png')" width="100%"/>
                    </v-col>
                </v-row>
            </v-container>
            <div v-if="requirePlayerSync" class="d-inline">
                <sync-player-identifier-view/>
            </div>
            <div v-else-if="$route.name === 'OrgApplication'">
                <org-application/>
            </div>
            <div v-else-if="!['Login', 'Logout'].includes($route.name) && isAuthenticated && passwordComplexity < 0.75">
                <v-container fluid class="pb-0">
                    <v-row class="pb-0">
                        <v-col cols="12" class="pb-0">
                            <v-alert dense type="error" border="left" elevation="2">
                                <b>Achtung! Dein Passwort ist nicht stark genug! Du musst es sofort ändern. Dein Passwort muss mindestens 8 Zeichen lang sein, ein Großbuchstabe, ein Kleinbuchstabe,
                                    eine Zahl und ein Sonderzeichen enthalten.</b>
                                <b>{{ passwordComplexity }}</b>
                            </v-alert>
                        </v-col>
                    </v-row>
                </v-container>
                <Settings/>
            </div>
            <div v-else class="d-inline">
                <router-view/>
            </div>
        </v-main>

        <v-footer color="primary darken-2" app dark>
            Made with
            <v-icon left right color="red">mdi-heart</v-icon>
            by Altera-Holding: IT Services GmbH (Build {{ $VERSION }})
        </v-footer>
    </v-app>
</template>

<!--suppress CssUnusedSymbol -->
<style>
@import url('https://fonts.googleapis.com/css2?family=Nova+Mono&family=Nova+Mono&family=Rubik&family=Nunito&family=Montserrat&display=swap');

* {
    font-family: "Montserrat", "Nunito", sans-serif;
}

.scrollbar-overflow-hidden::-webkit-scrollbar {
    display: none;
}

.scrollbar-overflow-hidden {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.scrollbox {
    height: 80vh !important;
    max-height: 80vh !important;
}

.text-box {
    font-family: "Nunito", "Roboto", sans-serif !important;
    line-height: 27px;
}

.swal2-container {
    font-family: "Nunito", "Roboto", sans-serif !important;
}

.v-card__title > p {
    display: inline !important;
    margin: 0 !important;
}

.text-box > p {
    font-size: 19px;
    display: block;
    margin: 0;
    padding: 0;
}

.theme--light .text-box > h1 h2 h3 h4 h5 h6 {
    color: rgb(81, 81, 81) !important;
}

.theme--light .text-box > p {
    color: rgb(71, 71, 71) !important;
}

.theme--dark .text-box > h1 h2 h3 h4 h5 h6 {
    color: rgb(255, 255, 255) !important;
}

.theme--dark .text-box > p {
    color: rgb(205, 205, 205) !important;
}

.alert > p {
    margin: 0 !important;
    padding: 0 !important;
}

@keyframes wiggle {
    0% {
        transform: rotate(0deg);
    }
    10% {
        transform: rotate(10deg);
    }
    30% {
        transform: rotate(-10deg);
    }
    50% {
        transform: rotate(10deg);
    }
    70% {
        transform: rotate(-10deg);
    }
    95% {
        transform: rotate(0deg);
    }
}

/*noinspection CssUnknownProperty*/
.wiggle {
    -webkit-animation-name: wiggle;
    -ms-animation-name: wiggle;
    -ms-animation-duration: 2000ms;
    -webkit-animation-duration: 2000ms;
    -webkit-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
}

.wiggle:hover {
    animation: none;
}

.swal2-popup {
    background-color: #353535 !important;
    color: grey !important;
}

#map {
    background: black;
}
</style>

<script>
import markdown                 from "markdown-it";
import SyncPlayerIdentifierView from "@/components/SyncPlayerIdentifierView.vue";
import OrgApplication           from "@/views/public/OrgApplication.vue";
import * as SockJS              from 'sockjs-client';
import {Client}                 from '@stomp/stompjs/esm6';
import {UserFlagEnum}           from "./config";
import moment                   from "moment";
import Settings                 from "@/views/shared/Settings.vue";

class AppFeature {
    to;
    title;
    icon;
    preview;
    userFlag;
    isNewVariable;

    constructor(route, label, icon, preview, userFlag, isNewVariable) {
        this.to            = route;
        this.title         = label;
        this.icon          = icon;
        this.preview       = preview || false;
        this.userFlag      = userFlag;
        this.isNewVariable = isNewVariable || false;
    }
}

const FEATURES = {
    // SHARED
    PatrolUnit:       new AppFeature('/patrol-units', 'Streifenmanager', 'mdi-car-emergency'),
    MissionReports:   new AppFeature('/reports', 'Einsatzberichte', 'mdi-file-document-multiple-outline'),
    Appointments:     new AppFeature('/appointments', 'Terminkalender', 'mdi-calendar-multiselect'),
    InternalInfos:    new AppFeature('/intern-infos', 'Interne Informationen', 'mdi-folder-information-outline'),
    ControlCenter:    new AppFeature('/control-center', 'Leitstelle', 'mdi-map-legend'),
    Complaints:       new AppFeature('/complaints', 'Mitarbeiterfeedback', 'mdi-alert-rhombus-outline'),
    Employees:        new AppFeature('/employees', 'Mitarbeiter', 'mdi-account-group-outline'),
    DataListOverview: new AppFeature('/data-lists', 'Interne Listen', 'mdi-format-list-checkbox', true),
    Letters:          new AppFeature('/letters', 'Briefkasten', 'mdi-email-variant', true, undefined, 'unreadMessages'),

    // POLICE
    CriminalRecords:     new AppFeature('/criminal-records', 'Aktenregister', 'mdi-card-account-details-outline'),
    CriminalFileRecords: new AppFeature('/criminal-files', 'Kriminalakten', 'mdi-archive-star-outline', undefined, UserFlagEnum.CRIMINAL_POLICE),
    PoliceMedicOverview: new AppFeature('/dashboard-medic', '👨‍⚕️ Übersicht', 'mdi-hospital', undefined, UserFlagEnum.MEDIC_POLICE),
    PoliceMedicFiles:    new AppFeature('/medical-records', '👨‍⚕️ Patientenakten', 'mdi-hospital', undefined, UserFlagEnum.MEDIC_POLICE),
    FastRecords:         new AppFeature('/fast-records', 'Schnelle Datenerfassung (SDE)', 'mdi-clock-fast'),
    BlitzerTool:         new AppFeature('/traffic-speed-tool', 'Blitzer-Tool', 'mdi-camera-wireless-outline'),
    Map:                 new AppFeature('/traffic-control', 'Karte', 'mdi-map-marker-distance'),
    CrimeAnalyse:        new AppFeature('/crime-heatmap', 'Einsatzstatistiken', 'mdi-map-marker', true),

    // MEDIC
    MedicFiles:           new AppFeature('/medical-records', 'Patientenakten', 'mdi-hospital'),
    MedicAnalyse:         new AppFeature('/medic-heatmap', 'Einsatzstatistiken', 'mdi-map-marker', true),
    CriminalRecordsMedic: new AppFeature('/criminal-records', 'Aktenregister', 'mdi-card-account-details-outline', undefined, UserFlagEnum.MEDIC_POLICE),
    BlitzerToolMedic:     new AppFeature('/traffic-speed-tool', 'Blitzer-Tool', 'mdi-camera-wireless-outline', undefined, UserFlagEnum.MEDIC_POLICE),

    // GOVERNMENT
    CommercialOffice: new AppFeature('/companies', 'Gewerbeamt', 'mdi-chair-rolling', true, 'COMMERCIAL_OFFICE'),
}

const ACCOUNT_TYPE_FEATURES = {
    'POLICE':     [
        FEATURES.PatrolUnit,
        FEATURES.ControlCenter,
        FEATURES.CriminalRecords,
        FEATURES.CriminalFileRecords,
        FEATURES.MissionReports,
        FEATURES.PoliceMedicOverview,
        FEATURES.PoliceMedicFiles,
        FEATURES.Appointments,
        FEATURES.InternalInfos,
        FEATURES.FastRecords,
        FEATURES.BlitzerTool,
        FEATURES.Map,
        FEATURES.CommercialOffice,
        FEATURES.CrimeAnalyse,
        FEATURES.DataListOverview,
        FEATURES.Complaints,
        FEATURES.Employees,
        FEATURES.Letters,
    ],
    'ZOLL':       [
        FEATURES.PatrolUnit,
        FEATURES.ControlCenter,
        FEATURES.CriminalRecords,
        FEATURES.CriminalFileRecords,
        FEATURES.MissionReports,
        FEATURES.PoliceMedicOverview,
        FEATURES.PoliceMedicFiles,
        FEATURES.Appointments,
        FEATURES.InternalInfos,
        FEATURES.FastRecords,
        FEATURES.BlitzerTool,
        FEATURES.Map,
        FEATURES.CrimeAnalyse,
        FEATURES.CommercialOffice,
        FEATURES.Complaints,
        FEATURES.Employees,
    ],
    'MEDIC':      [
        FEATURES.PatrolUnit,
        FEATURES.ControlCenter,
        FEATURES.MedicFiles,
        FEATURES.CriminalRecordsMedic,
        FEATURES.BlitzerToolMedic,
        FEATURES.MissionReports,
        FEATURES.Appointments,
        FEATURES.InternalInfos,
        FEATURES.Map,
        FEATURES.MedicAnalyse,
        FEATURES.DataListOverview,
        FEATURES.Complaints,
        FEATURES.Employees,
        FEATURES.Letters,
    ],
    'VETERINARY': [
        FEATURES.ControlCenter,
        FEATURES.Appointments,
        FEATURES.InternalInfos,
        FEATURES.Map,
        FEATURES.Complaints,
        FEATURES.Employees,
    ],
    'GOVERNMENT': [
        FEATURES.PatrolUnit,
        FEATURES.CriminalRecords,
        FEATURES.ControlCenter,
        FEATURES.MissionReports,
        FEATURES.Appointments,
        FEATURES.InternalInfos,
        FEATURES.BlitzerTool,
        FEATURES.Map,
        FEATURES.CommercialOffice,
        FEATURES.Complaints,
        FEATURES.Employees,
    ],
    'MECHANIC':   [
        FEATURES.ControlCenter,
        FEATURES.Appointments,
        FEATURES.InternalInfos,
        FEATURES.Map,
        FEATURES.Complaints,
        FEATURES.Employees,
    ]
}

export default {
    name:       'App',
    computed:   {
        newYear() {
            let now = moment();
            if (now.dayOfYear() === 365 || now.dayOfYear() === 1) {
                return now.year() + 1;
            }
            return false;
        },
        developmentMode() {
            return process.env.NODE_ENV === 'development';
        },
        navigationItems() {
            let items = [
                {icon: 'mdi-laptop', title: 'Übersicht', to: '/dashboard-' + this.user.accountType.toLowerCase()}
            ];

            let features = ACCOUNT_TYPE_FEATURES[this.userAccountType] || [];

            for (let feature of features) {
                items.push(feature);
            }

            if (this.user?.accountType === 'ADMIN') {
                items.push({
                    icon:  'mdi-hanger',
                    title: 'Kleidungstool',
                    to:    '/admin/tools/clothing',
                })

                items.push({
                    icon:  'mdi-wardrobe-outline',
                    title: 'Job Kleidung',
                    to:    '/admin/tools/job-clothes',
                })

                items.push({
                    icon:  'mdi-bag-personal-outline',
                    title: 'Inventory Viewer',
                    to:    '/admin/tools/inv-viewer',
                })
            }

            if (this.user.admin) {
                items.push({icon: 'mdi-post-outline', title: '(Admin) Audit Log', to: '/admin/audit-log'})
            }

            items.push({icon: 'mdi-cog-outline', title: 'Einstellungen', to: '/settings'});
            items.push({icon: 'mdi-logout', title: 'Abmelden', to: '/logout'})

            return items
                .filter(n => (n.preview && this.previewFeatures) || n.preview === undefined || n.preview === false)
                .filter(n => (n.userFlag && this.userHasFlag(n.userFlag)) || n.userFlag === undefined || this.user?.orgAdmin);
        },
        passwordComplexity() {
            return this.$store.state.passwordComplexity;
        }
    },
    data:       () => ({
        notifications: [],
        timer:         null,

        fetchIntervalInst: null,

        unreadMessages: 0,

        drawer: true,
    }),
    components: {Settings, OrgApplication, SyncPlayerIdentifierView},
    methods:    {
        compareLocalValueToTrue(varName) {
            return !!this[varName];
        },
        mdToHtml(markdownText) {
            if (markdownText == null || markdownText.trim().length === 0) {
                return '<i>Keine Informationen angegeben.</i>'
            }

            return markdown({breaks: true, typographer: true, xhtmlOut: true})
                .render(markdownText);
        },
        fetchNotifications() {
            return this.apiGetGlobalNotifications().then(response => {
                this.notifications = response.data;
            })
        },

        fetchIntervalFunc() {
            if (this.isAuthenticated) {
                this.fetchNotifications();

                this.apiGetUnreadMessageCount().then(r => {
                    this.unreadMessages = r.data;
                })

                this.fetchIntervalInst = setTimeout(() => {
                    this.fetchIntervalFunc();
                }, 7500)
            } else {
                this.fetchIntervalInst = setTimeout(() => {
                    this.fetchIntervalFunc();
                }, 2000)
            }
        }
    },
    mounted() {
        this.updateVuetifyTheme();
        this.fetchIntervalFunc();

        const fragment                 = new URLSearchParams(window.location.hash.slice(1).substring(1));
        const [accessToken, tokenType] = [fragment.get('access_token'), fragment.get('token_type')];

        if (accessToken && tokenType) {
            fetch('https://discord.com/api/users/@me', {headers: {authorization: `${tokenType} ${accessToken}`}})
                .then(result => result.json())
                .then(response => {
                    const {username, discriminator, id} = response;

                    console.log(response);

                    this.$store.commit('update', {
                        discordName: username + '#' + discriminator,
                        discordId:   id,
                    });

                    if (window.location.pathname.substring(0, 13) === '/application/') {
                        let accountType      = window.location.pathname.split("/");
                        accountType          = accountType[accountType.length - 1];
                        window.location.href = window.location.protocol + '//' + window.location.host + '/#/application/' + accountType;
                    }
                })
        }

        this.timer = setInterval(() => {
            if (this.$store.state.timeBasedDarkMode) {
                if (this.isTimeBasedDarkModeAvailable()) {
                    this.updateVuetifyTheme();
                }
            }
        }, 1000);


        this.websocketUrl().then(url => {
            this.$root.websocket = new SockJS(url);

            this.$root.websocketClient = new Client({
                webSocketFactory:  () => this.$root.websocket,
                debug:             msg => console.log(msg),
                reconnectDelay:    5000,
                connectionTimeout: 2500,
            })

            this.$root.websocketClient.onConnect = () => {
                let url = this.$root.websocket._transport.url;
                url     = url.replace(
                    /.*\/websocket\//, "");
                url     = url.replace("/websocket", "");
                url     = url.replace(/^[0-9]+\//, "");

                this.$store.commit('update', {websocketSessionId: url});
            }

            this.$root.websocketClient.activate();
        })
    },
    beforeDestroy() {
        console.log('Destroying App ... ')
        clearInterval(this.timer);
        clearTimeout(this.fetchIntervalInst);
    }
}
</script>

<template>
    <v-container fluid>
        <v-card elevation="4" outlined>
            <v-card-title class="pb-2 pt-2 pl-3">
                <v-icon left color="error">mdi-alert-rhombus-outline</v-icon>
                Mitarbeiterfeedback
            </v-card-title>
            <v-divider/>
            <v-card-text>
                <v-select solo dense outlined multiple v-model="filterStatus" :items="possibleStatusList" item-value="key"
                          hint="Status Filter" persistent-hint>
                    <template v-slot:selection="{item}">
                        <v-btn x-small v-if="item.key === 'OPEN'" color="success" class="mr-2">Offen</v-btn>
                        <v-btn x-small v-else-if="item.key === 'CLOSED'" color="warning" class="mr-2">Geschlossen</v-btn>
                    </template>
                    <template v-slot:item="{item}">
                        <v-btn x-small v-if="item.key === 'OPEN'" color="success">Offen</v-btn>
                        <v-btn x-small v-else-if="item.key === 'CLOSED'" color="warning">Geschlossen</v-btn>
                    </template>
                </v-select>

                <v-autocomplete solo dense outlined multiple v-model="filterTargetUser" item-text="displayName"
                                :items="employeeList" item-value="id"
                                hint="Benutzer Filter" persistent-hint>
                    <template v-slot:selection="{item}">
                        <v-btn x-small color="blue-grey" dark class="mr-2">{{ item.displayName }}</v-btn>
                    </template>
                    <template v-slot:item="{item}">
                        {{ item.displayName }}
                    </template>
                </v-autocomplete>
            </v-card-text>
            <v-divider/>
            <v-data-table :items="complaints" :headers="reportTableHeader" sort-by="createdAt" sort-desc>

                <template v-slot:item.createdAt="{ item }">
                    {{ item.createdAt | datetime }}
                </template>
                <template v-slot:item.sourceUser.displayName="{ item }">
          <span
              v-if="user.admin || user.trustLevel >= 9999 || user.id === item.sourceUser.id || user.accountType === 'MEDIC'">
            <v-icon color="primary darken-3" left>mdi-incognito</v-icon>{{ item.sourceUser.displayName }}
          </span>
                    <span v-else>Anonym</span>
                </template>
                <template v-slot:item.rating="{ item }">
                    <v-btn x-small v-if="item.rating >= 10" color="error">Critical</v-btn>
                    <v-btn x-small v-else-if="item.rating >= 7" color="warning">Major</v-btn>
                    <v-btn x-small v-else-if="item.rating >= 5" color="info darken-3">Important</v-btn>
                    <v-btn x-small v-else-if="item.rating >= 3" color="blue-grey" dark>Low</v-btn>
                    <v-btn x-small v-else color="black" dark>Trivial</v-btn>
                </template>
                <template v-slot:item.status="{ item }">
                    <v-icon left color="yellow darken-1" v-if="item.type === 'RECOMMENDATION'">mdi-star-shooting-outline</v-icon>
                    <v-icon left color="error" v-else-if="item.type === 'COMPLAINT'">mdi-alert-rhombus-outline</v-icon>

                    <v-btn x-small v-if="item.status === 'OPEN'" color="success">Offen</v-btn>
                    <v-btn x-small v-else-if="item.status === 'CLOSED'" color="warning">Geschlossen</v-btn>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn icon color="primary" @click="openComplaint(item)">
                        <v-icon>mdi-eye-outline</v-icon>
                    </v-btn>
                    <v-btn icon color="success" @click="closeComplaint(item)"
                           v-if="(user.orgAdmin || user.id === item.sourceUser.id || user.flags.includes('COMPLAINT_MANAGER')) && item.status === 'OPEN'">
                        <v-icon>mdi-check-decagram-outline</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
            <v-divider/>
            <v-card-actions>
                <v-btn small color="error darken-2" @click="openCreateComplaint()">
                    <v-icon left>mdi-alert-plus-outline</v-icon>
                    Anlegen
                </v-btn>
                <v-spacer/>
                <v-btn small color="success" @click="fetchComplaints">
                    <v-icon left>mdi-refresh</v-icon>
                    Neu laden
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-dialog v-model="createComplaintDialog" width="700">
            <v-card dark>
                <v-card-title class="pb-2 pt-2 pl-3">
                    <v-icon left color="error">mdi-alert-plus-outline</v-icon>
                    Beschwerde oder Empfehlung erstellen
                </v-card-title>
                <v-divider/>
                <v-card-text class="pt-5">
                    <v-select :items="[{text: 'Beschwerde', value: 'COMPLAINT'}, {text: 'Empfehlung', value: 'RECOMMENDATION'}]"
                              item-text="text" item-value="value" v-model="createComplaint.type"
                              hint="Beschwerde oder Empfehlung?" persistent-hint
                              prepend-inner-icon="mdi-lightbulb-question-outline" solo dense outlined/>

                    <v-textarea v-model="createComplaint.content" solo dense outlined prepend-inner-icon="mdi-text"
                                placeholder="Anliegen, Beschwerde oder Empfehlung"/>

                    <v-autocomplete :items="employeeList" v-model="createComplaint.targetUser" return-object item-value="id"
                                    item-text="displayName" solo dense outlined prepend-inner-icon="mdi-account-circle-outline"
                                    placeholder="Beamte"/>

                    <v-select :items="[[0, 'Trivial'], [3, 'Low'], [5, 'Important'], [7, 'Major'], [10, 'Critical']]"
                              item-value="0" item-text="1" solo dense outlined prepend-inner-icon="mdi-progress-alert"
                              v-model="createComplaint.rating">
                        <template v-slot:selection="{ item }">
                            <v-btn x-small v-if="item[0] >= 10" color="error">Critical</v-btn>
                            <v-btn x-small v-else-if="item[0] >= 7" color="warning">Major</v-btn>
                            <v-btn x-small v-else-if="item[0] >= 5" color="info darken-3">Important</v-btn>
                            <v-btn x-small v-else-if="item[0] >= 3" color="blue-grey" dark>Low</v-btn>
                            <v-btn x-small v-else color="black" dark>Trivial</v-btn>
                        </template>
                        <template v-slot:item="{ item }">
                            <v-btn x-small v-if="item[0] >= 10" color="error">Critical</v-btn>
                            <v-btn x-small v-else-if="item[0] >= 7" color="warning">Major</v-btn>
                            <v-btn x-small v-else-if="item[0] >= 5" color="info darken-3">Important</v-btn>
                            <v-btn x-small v-else-if="item[0] >= 3" color="blue-grey" dark>Low</v-btn>
                            <v-btn x-small v-else color="black" dark>Trivial</v-btn>
                        </template>
                    </v-select>
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-btn small color="primary" @click="createComplaintPost()">
                        Erstellen
                    </v-btn>
                    <v-spacer/>
                    <v-btn small color="error darken-2" @click="createComplaintDialog = false;">
                        <v-icon left>mdi-close-octagon-outline</v-icon>
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </v-container>
</template>

<script>
import Swal from 'sweetalert2';

export default {
    data:     () => ({
        reports:           [],
        reportTableHeader: [
            {text: 'Erstelldatum', value: 'createdAt'},
            {text: 'Status', value: 'status'},
            {text: 'Ersteller', value: 'sourceUser.displayName'},
            {text: 'Beschwerde gegen / Empfehlung für', value: 'targetUser.displayName'},
            {text: 'Wichtigkeit', value: 'rating'},
            {text: 'Aktionen', value: 'actions'},
        ],

        possibleStatusList: [
            {key: 'OPEN', label: 'Offen', color: 'success'},
            {key: 'CLOSED', label: 'Geschlossen', color: 'warning'},
        ],

        employeeList: [],

        filterStatus:     ['OPEN'],
        filterTargetUser: [],

        createComplaintDialog: false,
        createComplaint:       {
            type: 'COMPLAINT'
        }
    }),
    computed: {
        complaints() {
            let complaints = [];

            for (let complaint of this.reports) {
                let statusFilter = this.filterStatus.length === 0 || this.filterStatus.includes(complaint.status);
                let userFilter   = this.filterTargetUser.length === 0 || this.filterTargetUser.includes(complaint.targetUser.id);

                if (statusFilter && userFilter) {
                    complaints.push(complaint);
                }
            }

            return complaints;
        }
    },
    methods:  {
        openCreateComplaint() {
            this.createComplaint       = {
                type:       'COMPLAINT',
                status:     'OPEN',
                rating:     0,
                targetUser: null,
                content:    '',
            }
            this.createComplaintDialog = true;
        },
        createComplaintPost() {
            if (this.createComplaint.content === null || this.createComplaint.content.length <= 5) {
                Swal.fire('Du musst auch etwas zu deiner Beschwerde oder Empfehlung schreiben!');
                return;
            }

            this.apiCreateComplaint(this.createComplaint).then(() => {
                this.fetchComplaints();
                this.createComplaintDialog = false;
            })
        },
        fetchComplaints() {
            this.apiGetAllComplaints().then(r => {
                this.reports = r.data;
            })
        },
        fetchEmployeeList() {
            this.apiGetMissionReportEmployees().then(r => {
                this.employeeList = r.data;
            })
        },
        openComplaint(complaint) {
            this.$router.push('/complaint/' + complaint.id)
        },
        closeComplaint(complaint) {
            complaint.status = 'CLOSED';
            this.apiUpdateComplaint(complaint.id, {
                status: complaint.status
            }).then(() => {
                this.fetchComplaints();
            })
        },
    },
    mounted() {
        this.fetchComplaints();
        this.fetchEmployeeList();
    }
}
</script>
<!--suppress JSUnresolvedVariable -->
<template>
    <v-container fluid>
        <!-- Info Card -->
        <v-row v-if="record">
            <v-col>
                <div v-if="record.status === 'DEAD'">
                    <v-alert type="warning" elevation="5" border="left">
                        <b>Die Person ist <i>Verstorben</i>.</b>
                    </v-alert>
                </div>
                <div v-if="record.status === 'BURIED'">
                    <v-alert icon="mdi-grave-stone" dark color="blue-grey darken-2" elevation="5" border="left">
                        <b>Der Leichnam wurde der Bundesregierung zur Bestattung übergeben.</b>
                    </v-alert>
                </div>
                <v-card elevation="4" outlined>
                    <v-card-title>
                        <v-icon left color="error">mdi-hospital</v-icon>
                        Patient - {{ record.name }}
                        <v-spacer/>
                        <v-btn icon small @click="showRecord = !showRecord">
                            <v-icon v-if="showRecord">mdi-chevron-down</v-icon>
                            <v-icon v-else>mdi-chevron-left</v-icon>
                        </v-btn>
                    </v-card-title>
                    <div v-show="showRecord">
                        <div v-if="record.note">
                            <v-card-text class="pt-0 pb-0">
                                <v-alert type="error" elevation="5">
                                    <b>Achtung! Für diese Person wurde ein Warnhinweis hinterlegt:<br/></b>
                                    {{ record.note }}
                                </v-alert>
                            </v-card-text>
                            <v-divider/>
                        </div>
                        <!-- Info Card -->
                        <v-card-text>
                            <v-row>
                                <v-col class="pt-4 pb-1">
                                    <v-text-field v-model="record.name" label="Name des Bürgers" dense solo outlined
                                                  single-line counter
                                                  prepend-icon="mdi-account"/>
                                </v-col>
                                <v-col class="pt-4 pb-1">
                                    <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                                            :return-value.sync="record.birthday"
                                            transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field dense solo outlined v-model="record.birthday"
                                                          label="Geburtsdatum"
                                                          prepend-icon="mdi-calendar"
                                                          readonly
                                                          v-bind="attrs" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="record.birthday" no-title scrollable>
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="menu = false">
                                                Cancel
                                            </v-btn>
                                            <v-btn text color="primary" @click="$refs.menu.save(record.birthday)">
                                                OK
                                            </v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col class="pt-4 pb-1">
                                    <v-select dense
                                              :items="[{value: 'MALE', text: 'Männlich'}, {value: 'FEMALE', text: 'Weiblich'}]"
                                              item-text="text" prepend-icon="mdi-gender-male-female"
                                              solo outlined
                                              item-value="value" v-model="record.gender"/>
                                </v-col>
                                <v-col class="pt-4 pb-1">
                                    <v-select dense
                                              :items="[{value: 'ALIVE', text: 'Lebend'}, {value: 'DEAD', text: 'Verstorben'}, {value: 'MISSING', text: 'Vermisst'}, {value: 'BURIED', text: 'An Regierung übergeben / begraben'}]"
                                              item-text="text" prepend-icon="mdi-delta"
                                              solo outlined
                                              item-value="value" v-model="record.status"/>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-text-field dense solo outlined v-model="record.bloodType"
                                                  placeholder="Blutgruppe"
                                                  prepend-icon="mdi-blood-bag"/>
                                </v-col>
                                <v-col>
                                    <v-text-field dense solo outlined v-model="record.knownAllergies"
                                                  placeholder="Bekannte Allergien"
                                                  prepend-icon="mdi-emoticon-sick-outline"/>
                                </v-col>
                                <v-col>
                                    <v-text-field dense solo outlined v-model="record.knownDiseases"
                                                  placeholder="Bekannte Krankheiten"
                                                  prepend-icon="mdi-emoticon-sick-outline"/>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3" class="pt-0 pb-1">
                                    <v-text-field v-model="record.address" label="Anschrift (wenn bekannt)" dense solo
                                                  outlined
                                                  prepend-icon="mdi-home-group"/>
                                </v-col>
                                <v-col class="pt-0 pb-1">
                                    <v-text-field v-model="record.note" label="Warnhinweis"
                                                  dense solo outlined prepend-icon="mdi-head-alert-outline"/>
                                </v-col>
                                <v-col class="pt-0 pb-1">
                                    <v-text-field v-model="record.organization" label="Organisation" dense solo outlined
                                                  prepend-icon="mdi-account-multiple"/>
                                </v-col>
                                <v-col class="pt-0 pb-1">
                                    <v-text-field v-model="record.phoneNumber" label="Telefonnummer" dense solo outlined
                                                  prepend-icon="mdi-cellphone-basic"/>
                                </v-col>
                                <v-col class="pt-0 pb-1">
                                    <v-checkbox dense v-model="record.privateInsurance" label="Privatversichert?"
                                                ripple/>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-divider/>
                        <v-card-actions>
                            <v-btn small
                                   :color="updatingRecordFailed ? 'error' : updatingRecordSuccess ? 'success': 'primary'"
                                   @click="saveRecord" :loading="updatingRecord">
                                <v-icon left>mdi-content-save-outline</v-icon>
                                Speichern
                            </v-btn>
                        </v-card-actions>
                    </div>
                </v-card>
            </v-col>
        </v-row>

        <!-- Entry Cards -->
        <v-row v-if="record">
            <v-col>
                <v-card elevation="4" outlined :loading="recordEntriesLoading" loader-height="5">
                    <v-card-title class="pb-2 pt-2 pr-2">
                        <v-icon left>mdi-card-account-details-outline</v-icon>
                        {{ record.name }} - Einträge

                        <v-spacer/>

                        <div v-if="!['BURIED', 'DEAD'].includes(record.status)">
                            <v-btn class="mr-3" small color="error" @click="startCreateNotOpEntry()"
                                   v-if="user.trustLevel >= 5">
                                Not-OP - P00
                            </v-btn>

                            <v-btn class="mr-3" small color="info" @click="createMedicalPsychologicalExaminationEntry()"
                                   v-if="user.trustLevel >= 11">
                                MPU durchgeführt
                            </v-btn>

                            <v-btn small color="primary" @click="createRecordEntryDialog = true">
                                <v-icon left small>mdi-plus</v-icon>
                                Neuen Eintrag anlegen
                            </v-btn>
                        </div>
                        <div v-else>
                            <v-btn disabled color="primary" small>
                                Neuen Eintrag erstellen
                            </v-btn>
                        </div>
                    </v-card-title>

                    <v-divider/>
                    <v-card-text class="pb-0">
                        <v-layout class="d-flex justify-center" v-if="recordEntries.length === 0 && recordEntriesLoading" style="height: 64px;">
                            <v-progress-circular indeterminate color="primary" size="32"/>
                        </v-layout>

                        <v-row v-for="entry in recordEntries" :key="entry.id">
                            <v-col class="pa-0">
                                <medical-record-entry v-on:updated="fetchRecordEntries" :record="record"
                                                      :entry="entry" v-on:edit="editRecordEntry"/>
                            </v-col>
                        </v-row>
                        <v-row v-if="recordEntries.length === 0 && !recordEntriesLoading">
                            <v-col>
                                <v-alert type="info" dense outlined>
                                    Keine Einträge vorhanden.
                                </v-alert>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-divider/>
                    <v-card-actions v-if="!['BURIED', 'DEAD'].includes(record.status)">
                        <v-btn small color="primary" @click="createRecordEntryDialog = true">
                            <v-icon left small>mdi-plus</v-icon>
                            Neuen Eintrag anlegen
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <!-- Loader -->
        <v-card v-if="!record">
            <v-skeleton-loader type="card">
            </v-skeleton-loader>
        </v-card>

        <!-- Edit Record Entry Dialog -->
        <v-dialog v-model="modifyRecordEntryDialog" :width="900" persistent>
            <v-card outlined v-if="modifyRecordEntry"
                    :color="isDarkModeEnabled() ? 'primary darken-3' : 'primary lighten-2'" :dark="isDarkModeEnabled()">
                <v-card-title class="pl-3 pb-2 pt-2">
                    <v-icon left>mdi-plus</v-icon>
                    Eintrag vom {{ modifyRecordEntry.createdAt | datetime }} bearbeiten.
                </v-card-title>
                <v-divider/>
                <v-card-text class="pa-3">
                    <v-alert v-if="modifyRecordEntryAdminNotice" dense type="warning" elevation="7">
                        Dieser Eintrag ist älter als 2 Stunden oder wurde nicht von dir erstellt.
                        Deine Berechtigungen erlauben es dir dennoch, diesen Eintrag zu bearbeiten.
                    </v-alert>
                    <!-- Entry Type -->
                    <v-select solo outlined dense :items="MEDICAL_RECORD_ENTRY_TYPES"
                              item-value="type" item-text="text"
                              v-model="modifyRecordEntry.entryType" item-color="primary lighten-3"
                              label="Art" prepend-icon="mdi-clipboard-text"/>

                    <!-- Content -->
                    <v-textarea solo outlined dense v-model="modifyRecordEntry.content"
                                label="Unfallhergang / Beschreibung"
                                color="primary lighten-3" rows="14"
                                prepend-icon="mdi-form-textbox" hint="Dieses Feld unterstützt Markdown"
                                persistent-hint>
                    </v-textarea>

                    <!-- Zip Code -->
                    <v-text-field solo outlined dense prepend-icon="mdi-earth"
                                  clearable type="number"
                                  v-model="modifyRecordEntry.zipCode" placeholder="Unfallort (Postleitzahl)"/>
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-btn color="success" small @click="postModifiedEntry" :loading="modifyRecordEntryUpdating">
                        <v-icon left>mdi-database-arrow-up</v-icon>
                        Aktualisieren
                    </v-btn>
                    <v-spacer/>
                    <v-btn color="error" small @click="abortModifyRecordEntry">
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Create Record Entry Dialog -->
        <v-dialog v-model="createRecordEntryDialog" :width="$store.state.previewFeatures ? 1200 : 600" persistent>
            <v-card outlined>
                <v-card-title class="pl-3 pb-2 pt-2">
                    <v-row>
                        <v-col>
                            <v-icon left>mdi-plus</v-icon>
                            Eintrag anlegen
                        </v-col>
                        <v-col v-if="$store.state.previewFeatures">
                            <v-icon left>mdi-content-paste</v-icon>
                            Akteneintrag
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-divider/>
                <v-card-text class="pa-3">
                    <v-row>
                        <v-col cols="12" md="6" xl="6">
                            <!-- Entry Type -->
                            <v-select solo outlined dense :items="MEDICAL_RECORD_ENTRY_TYPES"
                                      item-value="type" item-text="text"
                                      v-model="createEntry.entryType" item-color="primary lighten-3"
                                      label="Art" prepend-icon="mdi-clipboard-text"/>

                            <div v-if="$store.state.previewFeatures">
                                <v-text-field solo outlined dense prepend-icon="mdi-earth"
                                              clearable type="number"
                                              v-model="createEntry.zipCode" placeholder="Unfallort (Postleitzahl)"/>

                                <v-combobox multiple :items="MEDICAL_FINDING_TYPES" return-object solo
                                            outlined dense prepend-icon="mdi-bone-off" placeholder="Befunde"
                                            v-model="createEntryTemplate.findings">
                                    <template v-slot:item="{item}">
                                        [{{ item.index }}] {{ item.text }}
                                    </template>
                                </v-combobox>

                                <v-combobox multiple :items="MEDICAL_MEDICATION_TYPES" dense solo outlined
                                            prepend-icon="mdi-pill-multiple" item-text="label" placeholder="Medikamente"
                                            v-model="createEntryTemplate.medication">
                                    <template v-slot:item="{ item }">
                                        <img height="24px"
                                             :src='"https://fivem.r3ktm8.de/items/" + item.item + ".png"'/>
                                        <span class="pl-3">{{ item.label }}</span>
                                    </template>
                                </v-combobox>
                            </div>

                            <!-- Content -->
                            <v-textarea solo outlined dense v-model="createEntry.content"
                                        label="Unfallhergang / Beschreibung"
                                        color="primary lighten-3"
                                        prepend-icon="mdi-form-textbox" hint="Dieses Feld unterstützt Markdown"
                                        persistent-hint>
                            </v-textarea>

                            <v-autocomplete v-model="treatmentSelected" multiple :items="treatmentHelpData" item-text="injury"
                                            return-object solo dense outlined prepend-icon="mdi-emoticon-sick-outline"
                                            placeholder="Art der Verletzung" clearable/>

                            <v-autocomplete v-model="treatmentSelected" multiple :items="treatmentHelpData" item-text="symptoms"
                                            return-object solo dense outlined prepend-icon="mdi-emoticon-sick-outline"
                                            placeholder="Symptome" clearable/>

                            <!-- Treatment -->
                            <v-textarea solo outlined dense v-model="createEntryTemplate.treatment" label="Behandlung"
                                        color="primary lighten-3"
                                        prepend-icon="mdi-form-textbox" hint="Dieses Feld unterstützt Markdown"
                                        persistent-hint
                                        v-if="$store.state.previewFeatures">
                            </v-textarea>

                            <v-text-field v-on:paste="doPasteEvent" solo dense outlined
                                          clearable clear-icon="mdi-delete"
                                          prepend-icon="mdi-archive"
                                          color="white" hint="Patienteninformation" persistent-hint
                                          placeholder="Sonstige Informationen wie Atteste und Rezepte im Bildformat"/>

                        </v-col>
                        <v-col cols="12" md="6" xl="6" v-if="$store.state.previewFeatures" v-html="mdToHtml(createEntryContent)">

                        </v-col>
                    </v-row>
                </v-card-text>
                <div v-if="createEntryImages.length > 0">
                    <v-divider/>
                    <v-card-title class="pt-2 pb-2 pl-3">
                        <v-icon left>mdi-camera-wireless-outline</v-icon>
                        Sonstige Informationen wie Atteste und Rezepte
                    </v-card-title>
                    <v-divider/>
                    <v-card-text>
                        <v-row>
                            <v-col cols="4" v-for="(ev, k) in createEntryImages" :key="k">
                                <div v-if="ev.type === 'BASE64_IMAGE'">
                                    <v-img @click="deleteEvidence(ev, k)" :height="256 / 16 * 9" :width="256" :src="'data:image/png;base64,' + ev.image"></v-img>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>

                </div>
                <v-divider/>
                <v-card-actions>
                    <v-btn color="success" small @click="postEntry" :loading="createEntryPosting">
                        <v-icon left>mdi-content-save</v-icon>
                        Anlegen
                    </v-btn>
                    <v-spacer/>
                    <v-btn color="error" small @click="abortCreation">
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="createNotOpEntry" width="700">
            <v-card light>
                <v-card-title class="pt-1 pb-1 pl-1 pr-1">
                    <v-icon left>mdi-water-remove</v-icon>
                    Eintrag erstellen: Not-OP
                    <v-spacer/>
                    <v-btn icon @click="abortNotOpEntry">
                        <v-icon>mdi-close-outline</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider/>
                <v-card-text class="pt-5 pb-0">
                    <!-- Comment -->
                    <v-text-field solo outlined dense prepend-icon="mdi-text-short" clearable
                                  v-model="createNotOpEntryComment" placeholder="Unfallhergang (falls notwendig)"/>
                    <!-- Zip Code -->
                    <v-text-field solo outlined dense prepend-icon="mdi-earth"
                                  clearable type="number"
                                  v-model="createNotOpEntryZipCode" placeholder="Unfallort (Postleitzahl)"/>
                    <!-- Befund -->
                    <v-combobox :items="MEDICAL_FINDING_TYPES" return-object solo clearable
                                outlined dense prepend-icon="mdi-bone-off" placeholder="Befunde"
                                v-model="createNotOpEntryFinding">
                        <template v-slot:item="{item}">
                            [{{ item.index }}] {{ item.text }}
                        </template>
                        <template v-slot:selection="{item}">
                            [{{ item.index }}] {{ item.text }}
                        </template>
                    </v-combobox>
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-btn small color="primary" @click="postNotOpEntry('VORORT')" :disabled="!createNotOpEntryFinding">
                        Vorort übergeben
                    </v-btn>
                    <v-btn small color="secondary" @click="postNotOpEntry('DRK')" :disabled="!createNotOpEntryFinding">
                        Im Krankenhaus
                    </v-btn>
                    <v-spacer/>
                    <v-btn icon @click="abortNotOpEntry">
                        <v-icon>mdi-close-outline</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="createMpuEntry" width="700">
            <v-card light>
                <v-card-title class="pt-1 pb-1 pl-1 pr-1">
                    <v-icon left>mdi-head-cog-outline</v-icon>
                    MPU erfassen
                    <v-spacer/>
                    <v-btn icon @click="abortMpuEntry">
                        <v-icon>mdi-close-outline</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider/>
                <v-card-text class="pt-5 pb-0">
                    <!-- Comment -->
                    <v-textarea solo outlined dense prepend-icon="mdi-text-short" clearable rows="15"
                                v-model="createMpuEntryProtocol" placeholder="Protokoll"/>

                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-btn small color="primary" @click="postMpuEntry(true)" :disabled="!createMpuEntryProtocol">
                        Bestanden
                    </v-btn>
                    <v-btn small color="error" @click="postMpuEntry(false)" :disabled="!createMpuEntryProtocol">
                        Nicht bestanden
                    </v-btn>
                    <v-spacer/>
                    <v-btn icon @click="abortMpuEntry">
                        <v-icon>mdi-close-outline</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import markdown                                               from "markdown-it";
import MedicalRecordEntry                                     from "../../components/MedicalRecordEntry";
import {MEDICAL_MEDICATION_TYPES, MEDICAL_RECORD_ENTRY_TYPES} from "@/plugins/config";
import Papa                                                   from 'papaparse';
import data                                                   from 'raw-loader!../../assets/behandlungshilfe.csv';

export default {
    components: {MedicalRecordEntry},
    data:       () => ({

        record:        null,
        recordEntries: [],

        recordEntriesLoading: false,

        showRecord: true,

        modifyRecordEntryAdminNotice: false,
        modifyRecordEntryUpdating:    false,
        modifyRecordEntry:            null,
        modifyRecordEntryDialog:      false,

        updatingRecord:        false,
        updatingRecordFailed:  false,
        updatingRecordSuccess: false,

        createNotOpEntry:        false,
        createNotOpEntryZipCode: null,
        createNotOpEntryComment: null,
        createNotOpEntryFinding: null,

        createMpuEntry:         false,
        createMpuEntryProtocol: null,

        // CREATE DIALOG
        createRecordEntryDialog: false,
        createEntry:             {
            entryType: 'FINDING',
            content:   '',
            zipCode:   null,
        },
        createEntryImages:       [],
        createEntryPosting:      false,
        createEntryTemplate:     {
            medication: [],
            findings:   [],
            treatment:  '',
        },
        createEntryFindings:     [],

        // MENU STATE
        menu: false,

        // IMPORTS | RESOURCE DATA
        MEDICAL_FINDING_TYPES:      [],
        MEDICAL_RECORD_ENTRY_TYPES: MEDICAL_RECORD_ENTRY_TYPES,
        MEDICAL_MEDICATION_TYPES:   MEDICAL_MEDICATION_TYPES,

        treatmentHelpData: null,
        treatmentSelected: [],
    }),
    computed:   {
        createEntryContent() {
            let text = '';

            if (this.createEntry.content) {
                text += '**Unfallhergang**:\n' + this.createEntry.content + '\n\n';
            }

            if (this.createEntryTemplate.findings.length > 0) {
                text += '**Befunde / Diagnosen**:\n';
                for (let finding of this.createEntryTemplate.findings) {
                    text += '- ' + finding.index + ' ' + finding.text + '\n';
                }
                text += '\n';
            }

            if (this.createEntryTemplate.medication.length > 0) {
                text += '**Medikation (während der Behandlung)**:\n';
                for (let med of this.createEntryTemplate.medication) {
                    text += '- ' + med.label + '\n';
                }
                text += '\n';
            }

            if (this.createEntryTemplate.treatment) {
                text += '**Durchgeführte Behandlung**:\n' + this.createEntryTemplate.treatment + '\n';
            }

            if (this.createEntryImages.length > 0) {
                text += '\n**Sonstige Informationen wie Atteste und Rezepte**:\n[BEWEISE]\n';
            }

            return text;
        }
    },
    methods:    {
        doPasteEvent(data) {
            let item = data.clipboardData.items[0];

            if (item.kind === 'file') {
                let file = item.getAsFile();

                this.toBase64(file).then(r => {
                    this.createEntryImages.push({
                        title: 'Patienteninformation',
                        type:  'BASE64_IMAGE',
                        image: r,
                    });
                });
            }
        },
        deleteEvidence(evidence, index) {
            this.createEntryImages.splice(index, 1);
        },
        editRecordEntry(entry, record, isYoungEnough) {
            console.log(isYoungEnough);
            this.modifyRecordEntry            = entry;
            this.modifyRecordEntryDialog      = true;
            this.modifyRecordEntryAdminNotice = !(isYoungEnough && entry.createdBy.id === this.user.id);
        },
        abortModifyRecordEntry() {
            this.modifyRecordEntry       = null;
            this.modifyRecordEntryDialog = false;
        },
        postModifiedEntry() {
            this.modifyRecordEntryUpdating = true;

            this.apiUpdateMedicalRecordEntry(this.record.id, this.modifyRecordEntry.id, {
                content:   this.modifyRecordEntry.content,
                entryType: this.modifyRecordEntry.entryType,
                zipCode:   this.modifyRecordEntry.zipCode,
            }).finally(() => {
                this.fetchRecordEntries().finally(() => {
                    this.modifyRecordEntryUpdating = false;
                    this.abortModifyRecordEntry();
                });
            })
        },
        mdToHtml(markdownText) {
            if (markdownText == null || markdownText.trim().length === 0) {
                return '<i>Keine Informationen angegeben.</i>'
            }

            return markdown({breaks: true}).render(markdownText);
        },
        abortMpuEntry() {
            this.createMpuEntry = false;
        },
        postMpuEntry(approved) {
            if (approved) {
                this.apiCreateMedicalRecordEntry(this.$route.params.id, {
                    entryType: 'MPE',
                    content:   'Medizinische psychologische Untersuchung wurde durchgeführt.\nErgebnis: **BESTANDEN**\n**Protokoll der Untersuchung**:\n' + (this.createMpuEntryProtocol || 'Kein Protokoll'),
                }).finally(() => {
                    this.abortMpuEntry();
                    this.fetchRecordEntries();
                })
            } else {
                this.apiCreateMedicalRecordEntry(this.$route.params.id, {
                    entryType: 'MPE',
                    content:   'Medizinische psychologische Untersuchung wurde durchgeführt.\nErgebnis: **NICHT BESTANDEN**\n**Protokoll der Untersuchung**:\n' + (this.createMpuEntryProtocol || 'Kein Protokoll'),
                }).finally(() => {
                    this.abortMpuEntry();
                    this.fetchRecordEntries();
                })
            }
        },
        startCreateNotOpEntry() {
            for (let key in this.MEDICAL_FINDING_TYPES) {
                let obj = this.MEDICAL_FINDING_TYPES[key];
                if (obj.index === 'P00') {
                    this.createNotOpEntryFinding = obj;
                }
            }

            this.createNotOpEntry = true;
        },
        abortNotOpEntry() {
            this.createNotOpEntry        = false;
            this.createNotOpEntryZipCode = null;
            this.createNotOpEntryComment = null;
        },
        postNotOpEntry(entryType) {
            let messageEntryTypes = {
                DRK:    '**Befunde / Diagnosen**:\n- {FINDING}\n\n**Durchgeführte Behandlung**:\nWurde aufgrund zu starker Kopfverletzungen ins Krankenhaus überführt und an das Not-OP Team übergeben.\n',
                VORORT: '**Befunde / Diagnosen**:\n- {FINDING}\n\n**Durchgeführte Behandlung**:\nWurde aufgrund zu starker Kopfverletzungen noch vor Ort an das Not-OP Team übergeben.\n',
            };

            let msg = messageEntryTypes[entryType];

            msg = msg.replace("{FINDING}", this.createNotOpEntryFinding.index + ' ' + this.createNotOpEntryFinding.text);

            if (this.createNotOpEntryComment) {
                msg = '**Unfallhergang**:\n{COMMENT}\n\n' + msg;
                msg = msg.replace("{COMMENT}", this.createNotOpEntryComment);
            }

            console.log(this.createNotOpEntryFinding);
            console.log(msg);

            this.apiCreateMedicalRecordEntry(this.$route.params.id, {
                entryType: 'OP',
                zipCode:   this.createNotOpEntryZipCode,
                content:   msg,
            }).finally(() => {
                this.abortNotOpEntry();
                this.fetchRecordEntries();
            })
        },
        createMedicalPsychologicalExaminationEntry() {
            this.createMpuEntryProtocol = null;
            this.createMpuEntry         = true;
        },
        fetchRecord() {
            this.apiGetMedicalRecord(this.$route.params.id).then(r => {
                this.record = r.data;

                if (this.record.status == null) {
                    this.record.status = 'ALIVE';
                }
            }).catch(() => {
                this.$router.push('/medical-records');
            })
        },
        fetchRecordEntries() {
            this.recordEntriesLoading = true;
            return this.apiGetMedicalRecordEntries(this.$route.params.id).then(r => {
                this.recordEntries        = r.data.reverse();
                this.recordEntriesLoading = false;
            })
        },
        fetchPossibleMedicalFindings() {
            this.apiGetAllMedicalFindings().then(r => {
                this.MEDICAL_FINDING_TYPES = r.data;
            })
        },
        saveRecord() {
            this.updatingRecord = true;

            this.apiUpdateMedicalRecord(this.$route.params.id, this.record).then(() => {
                this.updatingRecordSuccess = true;

                this.fetchRecordEntries();
            }).catch(() => {
                this.updatingRecordFailed = true;
            }).finally(() => {
                this.updatingRecord = false;
            });

            setTimeout(() => {
                this.updatingRecordSuccess = false;
                this.updatingRecordFailed  = false;
            }, 2000);
        },
        async postEntry() {
            this.createEntryPosting = true;

            if (this.$store.state.previewFeatures && this.createEntryContent) {
                console.log('Using generated content');
                this.createEntry.content = this.createEntryContent
            }

            if (this.createEntryImages.length > 0) {
                console.log('Preparing ' + this.createEntryImages.length + ' pictures');
                if (this.createEntry.content.includes('[BEWEISE]')) {
                    let evidenceUrlList = [];
                    for (let ev of this.createEntryImages) {
                        if (ev.type === 'BASE64_IMAGE') {
                            let response = await this.apiUploadEvidence(ev.image, 'evidence.png', null, true);
                            evidenceUrlList.push(`- [${ev.title}](${this.apiAttachmentUrl({id: response.data.id})})`);
                        }
                    }

                    console.log('Adjusting createEntry.content to contain the pictures');

                    this.createEntry.content = this.createEntry.content.replace('[BEWEISE]', `${evidenceUrlList.join('\n')}`);
                    this.createEntryImages   = [];
                }
            }

            console.log('Creating/Posting the entry ...');

            await this.apiCreateMedicalRecordEntry(this.$route.params.id, this.createEntry);
            await this.fetchRecordEntries();

            this.abortCreation();
            this.createEntryPosting = false;
        },
        abortCreation() {
            this.createRecordEntryDialog = false;
            this.createEntry.content     = '';
            this.createEntry.type        = 'FINDING';
            this.createEntry.zipCode     = null;

            this.createEntryTemplate.treatment        = '';
            this.createEntryTemplate.accidentLocation = '';
            this.createEntryTemplate.medication       = [];
            this.createEntryTemplate.findings         = [];
        },

        fetchTreatmentHelp() {
            let csv    = Papa.parse(data);
            let result = [];

            for (let data of csv.data) {
                result.push({
                    injury:   data[0],
                    symptoms: data[2],

                    step1: data[3], // D - H (4 - 8)
                    step2: data[4], // D - H (4 - 8)
                    step3: data[5], // D - H (4 - 8)
                    step4: data[6], // D - H (4 - 8)
                    step5: data[7], // D - H (4 - 8)

                    requiresFollowUp: data[8].includes('JA')
                });
            }

            console.log(result);
            return result;
        }
    },
    watch:      {
        treatmentSelected: function (newVal) {
            console.log(newVal);

            if (!newVal) return;

            let injuries = [];
            let symptoms = [];

            let step1s = [];
            let step2s = [];
            let step3s = [];
            let step4s = [];
            let step5s = [];

            for (let obj of newVal) {
                injuries.push("- " + obj.injury);
                symptoms.push("- " + obj.symptoms);

                if (obj.step1) step1s.push(obj.step1)
                if (obj.step2) step2s.push(obj.step2)
                if (obj.step3) step3s.push(obj.step3)
                if (obj.step4) step4s.push(obj.step4)
                if (obj.step5) step5s.push(obj.step5)
            }

            this.createEntryTemplate.treatment = '_Das folgende Verletzungsmuster ist beim Patienten aufzufinden_:\n';
            this.createEntryTemplate.treatment += injuries.join('\n') + '\n';

            this.createEntryTemplate.treatment += '\n_Folgende Symptome sind beim Patienten aufgetreten_:\n';
            this.createEntryTemplate.treatment += symptoms.join('\n') + '\n';

            this.createEntryTemplate.treatment += '\n**Diese Behandlungsschritte wurden durchgeführt**:\n';

            let steps = step1s.concat(step2s, step3s, step4s, step5s)

            for (let i = 0; i < steps.length; i++) {

                this.createEntryTemplate.treatment += (i + 1) + '. ' + steps[i] + '\n'
            }
        }
    },
    mounted() {
        this.fetchRecord();
        this.fetchRecordEntries();
        this.fetchPossibleMedicalFindings();

        this.treatmentHelpData = this.fetchTreatmentHelp();
    }
}
</script>
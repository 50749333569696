//
// Application Configuration (to prevent repeating myself xD)
//

import {ORGANIZATIONS} from "@/config";

export const COMPANY_TYPES = {
    RESTAURANT:       'Restaurant',
    CLUB:             'Club / Bar',
    CAR_WORKSHOP:     'Werkstatt',
    SHOP_247:         '24/7',
    PRODUCTION:       'Produktion & Vertrieb',
    OTHER:            'Andere',
    SERVICE_PROVIDER: 'Dienstleister',
}

export const COMPANY_RECORD_ENTRY_TYPES = {
    RECORD_CHANGE: 'Aktenänderung',
    CRIME:         'Straftat',
    NOTE:          'Eintrag',
    PAYMENT:       'Zahlung',
}

export const MEDICAL_RECORD_ENTRY_TYPES = [
    {type: 'FINDING', text: '📑 Medizinischer Befund'},
    {type: 'MALNUTRITION', text: '🍽️ Mangelernährung'},
    {type: 'NOTE', text: '📋 Notiz'},
    {type: 'OP', text: '💉 Operation'},
    {type: 'ACCIDENT', text: '🤕 Unfall'},
    {type: 'REPORT', text: '⚠️ Meldung'},
    {type: 'FOLLOW_UP', text: '🗓️ Nachsorge Untersuchung'},
    {type: 'TRANSPORT', text: '🚑 Patienten Transport'},
];

export const MEDICAL_MEDICATION_TYPES = [
    {item: 'med_pills_ibuprofen600', label: 'Ibuprofen 600'},
    {item: 'med_pills_ibuprofen800', label: 'Ibuprofen 800'},
    {item: 'med_pills_ibuprofen1000', label: 'Ibuprofen 1000'},
    {item: 'med_pills_novalgin', label: 'Novalgin (50mg)'},
    {item: 'med_pills_pantoprazol', label: 'Pantoprazol'},
    {item: 'med_pills_imodium_akut', label: 'Imodium Akut'},
    {item: 'med_pills_paracetamol', label: 'Paracetamol'},
    {item: 'med_pills_tramadol', label: 'Tramadol'},
    {item: 'med_bepanthen', label: 'Bepanthen'},
    {item: 'med_voltaren', label: 'Voltaren Schmerzgel'},
    {item: 'med_fentanyl', label: 'Fentanyl'},
    {item: 'med_infusions', label: 'Infusion'},
    {item: 'med_morphium', label: 'Morphium'},
    {item: 'med_pills_amlodipin', label: 'Amlodipin'},
    {item: 'med_pills_antibiotics', label: 'Antibiotika'},
    {item: 'med_pills_aspirin', label: 'Aspirin Complex'},
]

export const POLICE_BAIL_TIME_MAXIMUM = 30;

export const CRIMINAL_RECORD_ENTRY_TYPES = [
    // EXEKUTIVE
    {
        type:         'TICKET',
        text:         'Ordnungswidrigkeit',
        accountTypes: [ORGANIZATIONS.POL, ORGANIZATIONS.BFJ, ORGANIZATIONS.BND, ORGANIZATIONS.ZOLL]
    },
    {
        type:         'CRIMINAL_RECORD',
        text:         'Straftat',
        accountTypes: [ORGANIZATIONS.POL, ORGANIZATIONS.BFJ, ORGANIZATIONS.BND, ORGANIZATIONS.ZOLL]
    },
    {
        type:         'JAIL',
        text:         'Gefängnisbesuch',
        accountTypes: [ORGANIZATIONS.POL, ORGANIZATIONS.BFJ, ORGANIZATIONS.BND, ORGANIZATIONS.ZOLL]
    },
    {
        type:         'NOTE',
        text:         'Anmerkung / Notiz',
        accountTypes: [ORGANIZATIONS.POL, ORGANIZATIONS.REG, ORGANIZATIONS.BFJ, ORGANIZATIONS.BND, ORGANIZATIONS.ZOLL]
    },
    {
        type:         'INVESTIGATION',
        text:         'Ermittlung',
        accountTypes: [ORGANIZATIONS.POL, ORGANIZATIONS.BND, ORGANIZATIONS.ZOLL],
        requireFlags: ['CRIMINAL_POLICE']
    },
    // REGIERUNG & ORDNUNGSAMT
    {
        type:         'GOVERNMENT_REGULATION_VIOLATION',
        text:         'Verstoß gesetzlicher Regulierungen',
        accountTypes: [ORGANIZATIONS.POL, ORGANIZATIONS.REG, ORGANIZATIONS.ZOLL],
        requireFlags: ['TAX_OFFICER']
    },
    {
        type:         'TICKET',
        text:         'Ordnungswidrigkeit',
        accountTypes: [ORGANIZATIONS.REG],
        requireFlags: ['ORDER_POLICE']
    },
    {
        type:         'CRIMINAL_RECORD',
        text:         'Straftat',
        accountTypes: [ORGANIZATIONS.REG],
        requireFlags: ['ORDER_POLICE']
    },
    {
        type:         'JAIL',
        text:         'Gefängnisbesuch',
        accountTypes: [ORGANIZATIONS.REG],
        requireFlags: ['ORDER_POLICE']
    },
];

export const TRAFFIC_CONTROL_SETTINGS = {
    laneCounts: {
        ONE_LANE:      1,
        TWO_LANE:      2,
        TWO_LANE_2WAY: 2,

        THREE_LANE:           3,
        THREE_LANE_2WAY:      3,
        THREE_LANE_2WAY_FLIP: 3,

        FOUR_LANE:      4,
        FOUR_LANE_2WAY: 4,
        FOUR_LANE_3WAY: 4,
    },

    types:            [
        {key: 'ONE_LANE', label: '1 Spur Leitsystem'},

        {key: 'TWO_LANE', label: '2 Spuren Leitsystem'},
        {key: 'TWO_LANE_2WAY', label: '1+1 Spuren Leitsystem'},

        {key: 'THREE_LANE', label: '3 Spuren Leitsystem'},
        {key: 'THREE_LANE_2WAY', label: '2+1 Spuren Leitsystem'},

        {key: 'FOUR_LANE', label: '4 Spuren Leitsystem'},
        {key: 'FOUR_LANE_2WAY', label: '2+2 Spuren Leitsystem'},
        {key: 'FOUR_LANE_3WAY', label: '3+1 Spuren Leitsystem'},
    ],
    speedStates:      [
        {key: 'LIMIT_60', image: 'speed_60', label: 'Limit 60 Km/h'},
        {key: 'LIMIT_80', image: 'speed_80', label: 'Limit 80 Km/h'},
        {key: 'LIMIT_100', image: 'speed_100', label: 'Limit 100 Km/h'},
        {key: 'LIMIT_120', image: 'speed_120', label: 'Limit 120 Km/h'},
        {key: 'LIMIT_160', image: 'speed_160', label: 'Limit 160 Km/h'},
        {key: 'LIMIT_200', image: 'speed_200', label: 'Limit 200 Km/h'},
        {key: 'LIMIT_UNLIMITED', image: 'speed_unlimited', label: 'Kein Limit'},
        {key: 'EMPTY', image: 'empty', label: 'Leer'},
    ],
    additionalStates: [
        {key: 'DIRECTION_RIGHT', image: 'additional_dir_right', label: 'Spurwechsel Rechts'},
        {key: 'DIRECTION_LEFT', image: 'additional_dir_left', label: 'Spurwechsel Links'},
        {key: 'DIRECTION_CLOSED', image: 'additional_dir_closed', label: 'Spursperre'},
        {key: 'WARNING', image: 'additional_warning', label: 'Warnung'},
        {key: 'CONSTRUCTION', image: 'additional_construction', label: 'Baustelle'},

        {key: 'OVERTAKE_CAR', image: 'additional_overtake_all', label: 'Überholverbot'},
        {key: 'OVERTAKE_CAR_ALLOW', image: 'additional_overtake_all_allow', label: 'Überholverbot aufh.'},
        {key: 'OVERTAKE_TRUCK', image: 'additional_overtake_truck', label: 'Überholverbot LKW'},
        {key: 'OVERTAKE_TRUCK_ALLOW', image: 'additional_overtake_truck_allow', label: 'Überholverbot LKW aufh.'},

        {key: 'TRAFFIC_JAM', image: 'additional_traffic_jam', label: 'Stau'},
        {key: 'EMPTY', image: 'empty', label: 'Leer'},
    ],
}
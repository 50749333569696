<template>
  <v-container fluid>
    <v-card :loading="fetching" elevation="4" outlined>
      <v-card-title class="pb-2 pt-2">
        <v-icon left>mdi-clock-fast</v-icon>
        Schnelle Datenerfassung
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-row>
          <v-col cols="4" v-for="(record, k) in records" :key="k">
            <v-card outlined>
              <v-toolbar elevation="0" dense color="primary" dark>
                <span v-if="record.mission">{{ record.mission }}</span>
                <i v-else>Kein Einsatz</i>
                <v-spacer/>
                <v-btn icon small @click="deleteRecord(record)">
                  <v-icon small color="error">mdi-delete</v-icon>
                </v-btn>
                <v-btn icon small @click="modifyRecord(record)">
                  <v-icon small color="orange">mdi-pencil</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-simple-table>
                  <tr v-if="record.criminal">
                    <th style="text-align: left">Name</th>
                    <td>
                      <router-link :to="'/criminal-record/' + record.criminal.id">{{
                          record.criminal.name
                        }}
                      </router-link>
                      <v-icon color="blue-grey" small>mdi-gender-{{ record.criminal.gender.toLowerCase() }}</v-icon>
                    </td>
                  </tr>
                  <tr v-if="record.criminal">
                    <th style="text-align: left">Geburtsdatum</th>
                    <td>{{ record.criminal.birthday | date }}</td>
                  </tr>
                  <tr v-if="!record.criminal">
                    <th style="text-align: left">Name</th>
                    <td><i>Niemand</i></td>
                  </tr>
                  <tr>
                    <td colspan="2">&nbsp;</td>
                  </tr>
                  <tr v-if="record.createdBy">
                    <th style="text-align: left">Sichergestellt von</th>
                    <td>{{ record.createdBy.displayName }}</td>
                  </tr>
                  <tr v-if="record.createdAt">
                    <th style="text-align: left">Sichergestellt am</th>
                    <td>{{ record.createdAt | datetime }}</td>
                  </tr>
                </v-simple-table>

                <br/>

                <v-row>
                  <v-col>
                    <h3>Konfisziert</h3>
                    <br/>
                    <pre v-if="record.confiscated">{{ record.confiscated }}</pre>
                    <i v-else>Nichts</i>
                  </v-col>
                  <v-col>
                    <h3>Notiz</h3>
                    <br/>
                    <pre v-if="record.note">{{ record.note }}</pre>
                    <i v-else>Keine</i>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn x-small fab color="primary" @click="entryDialog = true">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- Create Entry Dialog -->
    <v-dialog v-model="entryDialog" width="700" persistent>
      <v-card outlined color="primary" dark>
        <v-card-title class="pa-3">
          <v-icon left>mdi-plus</v-icon>
          Daten erfassen
        </v-card-title>
        <v-divider/>
        <v-card-text class="pa-3">
          <!-- Mission -->
          <v-combobox :items="missions" v-model="entry.mission" solo background-color="primary" outlined dense
                      prepend-inner-icon="mdi-alarm-light" color="white" persistent-hint hint="Einsatz"
                      clearable shaped>
          </v-combobox>

          <!-- Criminal Record -->
          <v-autocomplete :items="criminalRecords" return-object :item-text="formatCriminalName"
                          v-model="entry.criminal"
                          label="Stammdatensatz" solo outlined background-color="primary" clearable color="white"
                          prepend-inner-icon="mdi-account" dense></v-autocomplete>

          <v-divider class="pb-3" v-if="entry.criminal === null"/>

          <v-row class="pb-1 pt-2" v-if="entry.criminal === null">
            <v-col>
              <v-text-field v-model="entry.newRecord.name" label="Name des Bürgers" dense single-line
                            outlined background-color="primary"
                            prepend-inner-icon="mdi-account" color="white"/>
            </v-col>
            <v-col>
              <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                      :return-value.sync="entry.newRecord.birthday"
                      transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field dense v-model="entry.newRecord.birthday" label="Geburtsdatum"
                                prepend-inner-icon="mdi-calendar" readonly
                                outlined background-color="primary" color="white"
                                v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="entry.newRecord.birthday" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn outlined color="blue-grey" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn outlined color="success" @click="$refs.menu.save(entry.newRecord.birthday)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-select dense outlined background-color="primary" v-model="entry.newRecord.gender" color="white"
                        :items="genders" item-value="type"
                        item-text="icon">
                <template slot="selection" slot-scope="data">
                  <v-icon>{{ data.item.icon }}</v-icon>
                </template>
                <template slot="item" slot-scope="data">
                  <v-icon>{{ data.item.icon }}</v-icon>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-divider class="pb-5 pt-0" v-if="entry.criminal === null"/>

          <!-- Content -->
          <v-textarea solo background-color="primary" outlined dense v-model="entry.confiscated"
                      color="primary lighten-3"
                      prepend-inner-icon="mdi-bag-personal-outline" hint="Konfiszierte Gegenstände" persistent-hint>
          </v-textarea>

          <!-- Notiz -->
          <v-textarea solo background-color="primary" outlined dense v-model="entry.note"
                      color="primary lighten-3"
                      prepend-inner-icon="mdi-information-outline" hint="Notiz" persistent-hint>
          </v-textarea>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn small color="success" @click="createRecord" v-if="entryId">
            <v-icon left>mdi-plus-circle-outline</v-icon>
            Update
          </v-btn>
          <v-btn small color="success" @click="createRecord" v-else>
            <v-icon left>mdi-plus-circle-outline</v-icon>
            Hinzufügen
          </v-btn>
          <v-spacer/>
          <v-btn small color="blue-grey" @click="closeDialog">
            Abbrechen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from "moment";

export default {
  data:     () => ({
    fetching: false,

    genders: [{type: 'MALE', icon: 'mdi-gender-male'}, {type: 'FEMALE', icon: 'mdi-gender-female'}],

    records: [],

    criminalRecords: [],
    vehicleRecords:  [],

    entryDialog: false,

    entryId: null,

    entry: {
      criminal:    null,
      confiscated: '',
      note:        '',
      mission:     '',

      newRecord: {
        name:     '',
        birthday: '',
        gender:   'MALE',
      }
    },

    // refs
    menu: null,
  }),
  filters:  {
    datetime(data) {
      return moment(data).format("DD.MM.YYYY HH:mm");
    },
    date(data) {
      return moment(data).format("DD.MM.YYYY");
    }
  },
  computed: {
    missions() {
      let missions = [];

      this.records.forEach(i => {
        if (i.mission) {
          missions.push(i.mission);
        }
      })

      return missions;
    }
  },
  methods:  {
    deleteRecord(record) {
      this.apiDeleteQuickRecord(record.id).then(() => {
        this.fetchQuickRecords();
      });
    },

    modifyRecord(record) {
      this.entry.criminal    = record.criminal;
      this.entry.mission     = record.mission;
      this.entry.confiscated = record.confiscated;
      this.entry.note        = record.note;

      this.entryDialog = true;

      this.entryId = record.id;
    },

    fetchQuickRecords() {
      this.fetching = true;
      return this.apiGetQuickRecords().then(response => {
        this.records = response.data;
      }).finally(() => {
        this.fetching = false;
      })
    },

    closeDialog() {
      this.entryDialog = false;
      this.entryId     = null;

      this.entry.criminal    = null;
      this.entry.confiscated = '';
      this.entry.note        = '';
      this.entry.mission     = '';

      this.entry.newRecord.name     = '';
      this.entry.newRecord.birthday = '';
      this.entry.newRecord.gender   = 'MALE';
    },

    fetchIntervalFunc() {
      this.fetchQuickRecords().finally(() => {
        setTimeout(() => {
          if (this.$route.name === 'FastRecords')
            this.fetchIntervalFunc();

          console.log('Added new Request to Queue ...')
        }, 2000)
      });
    },

    formatCriminalName(item) {
      return item.name + ' ' + item.birthday;
    },

    fetchMasterDataRecords() {
      this.apiGetCriminalRecords().then(response => {
        this.criminalRecords = response.data;
      });

      /*this.apiGetVehicleRecords().then(response => {
        this.vehicleRecords = response.data;
      });*/
    },

    createRecord() {
      if (this.entryId) {
        this.apiUpdateQuickRecord(this.entryId, this.entry).then(() => {
          this.fetchQuickRecords();
          this.closeDialog();
        });

        return;
      }

      if (this.entry.note.trim().length === 0 && this.entry.confiscated.trim().length === 0) {
        return;
      }

      if (this.entry.newRecord.name && this.entry.newRecord.birthday && !this.entry.criminal) {
        console.log('CREATING');

        this.apiCreateCriminalRecord(this.entry.newRecord).then(r => {
          this.criminalRecords.push(r.data);
          this.entry.criminal = r.data;

          this.createRecord();
        })
      } else {
        this.apiCreateQuickRecord(this.entry).then(() => {
          this.fetchQuickRecords();
          this.closeDialog();
        })
      }
    }
  },
  mounted() {
    this.fetchIntervalFunc();
    this.fetchMasterDataRecords();
  }
}
</script>
<template>
    <v-container fluid>
        <v-card elevation="4" outlined v-if="editor">
            <v-card-title class="pb-2 pt-2 pl-3" v-if="loaded">
                <v-icon left color="error">mdi-list-status</v-icon>
                Arbeitsvorrat :: {{ workListItem.title }}
            </v-card-title>
            <v-card-title class="pb-2 pt-2 pl-3" v-else>
                <v-icon left color="error">mdi-list-status</v-icon>
                Arbeitsvorrat
            </v-card-title>
            <v-divider/>
            <v-card-text class="pb-0">
                <v-alert type="warning" dense elevation="2" border="left" outlined>
                    <b>Feature befindet sich aktuell noch in der Entwicklung, kann aber genutzt werden!</b>
                </v-alert>
            </v-card-text>
            <v-divider/>
            <v-row v-if="loaded">
                <v-col cols="12">
                    <v-card-title class="pa-0 pl-4">
                        <v-icon class="pr-2">mdi-information</v-icon>
                        Informationen
                    </v-card-title>
                    <v-divider/>
                    <v-card-text>

                    </v-card-text>
                </v-col>
            </v-row>
            <v-divider/>
            <v-row v-if="loaded">
                <v-col v-show="editMode" :class="editMode ? 'pr-0' : ''">
                    <v-card-title class="pa-0 pl-4">
                        <v-icon class="pr-2">mdi-pen</v-icon>
                        Editor
                    </v-card-title>
                    <v-divider/>
                    <v-card-text>
                        <v-toolbar dense class="pa-0 ma-0" elevation="0" outlined height="35px">
                            <v-btn small icon :color="editor.isActive('bold') ? 'black' : ''"
                                   @click="editor.chain().focus().toggleBold().run()">
                                <v-icon small>mdi-format-bold</v-icon>
                            </v-btn>
                            <v-btn small icon :color="editor.isActive('italic') ? 'black' : ''"
                                   @click="editor.chain().focus().toggleItalic().run()">
                                <v-icon small>mdi-format-italic</v-icon>
                            </v-btn>
                            <v-btn small icon :color="editor.isActive('heading', {level: 1}) ? 'black' : ''"
                                   @click="editor.chain().focus().toggleHeading({level: 1}).run()">
                                <v-icon small>mdi-format-header-1</v-icon>
                            </v-btn>
                            <v-btn small icon :color="editor.isActive('heading', {level: 2}) ? 'black' : ''"
                                   @click="editor.chain().focus().toggleHeading({level: 2}).run()">
                                <v-icon small>mdi-format-header-2</v-icon>
                            </v-btn>
                            <v-btn small icon :color="editor.isActive('heading', {level: 3}) ? 'black' : ''"
                                   @click="editor.chain().focus().toggleHeading({level: 3}).run()">
                                <v-icon small>mdi-format-header-3</v-icon>
                            </v-btn>
                            <v-btn small icon :color="editor.isActive({ textAlign: 'left' }) ? 'black' : ''"
                                   @click="editor.chain().focus().setTextAlign('left').run()">
                                <v-icon small>mdi-format-horizontal-align-left</v-icon>
                            </v-btn>
                            <v-btn small icon :color="editor.isActive({ textAlign: 'center' }) ? 'black' : ''"
                                   @click="editor.chain().focus().setTextAlign('center').run()">
                                <v-icon small>mdi-format-horizontal-align-center</v-icon>
                            </v-btn>
                            <v-btn small icon :color="editor.isActive('taskList') ? 'black' : ''"
                                   @click="editor.chain().focus().toggleTaskList().run()">
                                <v-icon small>mdi-format-list-checks</v-icon>
                            </v-btn>
                            <v-btn small icon :color="editor.isActive('table') ? 'black' : ''"
                                   @click="editor.chain().focus().insertTable().run()">
                                <v-icon small>mdi-table-large-plus</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-toolbar dense class="pa-0 ma-0" elevation="0" outlined height="35px"
                                   v-if="editor.isActive('table')">
                            <v-btn small icon @click="editor.chain().focus().addColumnBefore().run()" color="primary">
                                <v-icon small>mdi-table-column-plus-before</v-icon>
                            </v-btn>
                            <v-btn small icon @click="editor.chain().focus().addColumnAfter().run()" color="primary">
                                <v-icon small>mdi-table-column-plus-after</v-icon>
                            </v-btn>
                            <v-btn small icon @click="editor.chain().focus().deleteColumn().run()" color="primary">
                                <v-icon small>mdi-table-column-remove</v-icon>
                            </v-btn>
                            <v-btn small icon @click="editor.chain().focus().addRowBefore().run()" color="primary">
                                <v-icon small>mdi-table-row-plus-before</v-icon>
                            </v-btn>
                            <v-btn small icon @click="editor.chain().focus().addRowAfter().run()" color="primary">
                                <v-icon small>mdi-table-row-plus-after</v-icon>
                            </v-btn>
                            <v-btn small icon @click="editor.chain().focus().deleteRow().run()" color="primary">
                                <v-icon small>mdi-table-row-remove</v-icon>
                            </v-btn>
                            <v-btn small icon @click="editor.chain().focus().toggleHeaderRow().run()" color="primary">
                                <v-icon small>mdi-table-border</v-icon>
                            </v-btn>
                            <v-btn small icon @click="editor.chain().focus().toggleHeaderColumn().fixTables().run()"
                                   color="primary">
                                <v-icon small class="mdi-rotate-270">mdi-table-border</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-toolbar dense class="pa-0 ma-0" elevation="0" outlined height="35px" v-else>
                        </v-toolbar>
                        <v-card elevation="2">
                            <editor-content class="editor" :editor="editor"/>
                        </v-card>
                    </v-card-text>
                </v-col>
                <v-col :class="editMode ? 'pl-0' : ''">
                    <v-card-title class="pa-0 pl-4">
                        <v-icon class="pr-2">mdi-eye</v-icon>
                        Inhalt
                    </v-card-title>
                    <v-divider/>
                    <v-card-text>
                        <div class="ProseMirror" v-html="workListItemModified"/>
                    </v-card-text>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col>
                    <v-progress-linear indeterminate height="30">
                        Lade Daten ...
                    </v-progress-linear>
                </v-col>
            </v-row>
            <v-divider/>
            <v-card-actions>
                <v-btn small :color="editMode ? 'error darken-3' : 'primary'" @click="editMode = !editMode"
                       :disabled="workListItemModified !== workListItem.description">
                    <v-icon left>mdi-pen</v-icon>
                    Editiermodus
                </v-btn>
                <v-btn small color="success" v-if="editMode"
                       :disabled="workListItemModified === workListItem.description"
                       @click="saveCurrentState">
                    <v-icon left>mdi-content-save-outline</v-icon>
                    Speichern
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<style>
[contenteditable] {
    outline: 0 solid transparent;
}

.ProseMirror ul[data-type="taskList"] {
    list-style: none;
    padding: 0;
    margin: 0 !important;
}

.ProseMirror ul[data-type="taskList"] > li {
    display: flex;
}

.ProseMirror li > label {
    flex: 0 0 auto;
    margin-right: 0.5rem;
    user-select: none;
}

.ProseMirror li > div {
    flex: 1 1 auto;
}

.ProseMirror {
    margin: 0.75em;
    border: unset !important;
}

.editor {
    border: 2px dotted black;
    border-radius: 4px;
}

.ProseMirror p {
    height: 20px;
}

/* Placeholder (at the top) */
.ProseMirror p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: #adb5bd;
    pointer-events: none;
}

/* Give a remote user a caret */
.collaboration-cursor__caret {
    position: relative;
    margin-left: -1px;
    margin-right: -1px;
    border-left: 1px solid #0D0D0D;
    border-right: 1px solid #0D0D0D;
    word-break: normal;
    pointer-events: none;
    display: flow;
}

/* Render the username above the caret */
.collaboration-cursor__label {
    position: absolute;
    top: -1.4em;
    left: -1px;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    user-select: none;
    color: #0D0D0D;
    padding: 0.1rem 0.3rem;
    border-radius: 3px 3px 3px 0;
    white-space: nowrap;
}

.ProseMirror table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;
}

.ProseMirror table td, .ProseMirror table th {
    min-width: 1em;
    border: 2px solid #ced4da;
    padding: 3px 5px;
    vertical-align: top;
    box-sizing: border-box;
    position: relative;
}

.ProseMirror table td > *, .ProseMirror table th > * {
    margin-bottom: 0;
}

.ProseMirror table th {
    font-weight: bold;
    text-align: left;
    color: black;
    background-color: #f1f3f5;
}

.ProseMirror table .selectedCell:after {
    z-index: 2;
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    color: white;
    background: rgba(200, 200, 255, 0.4);
    pointer-events: none;
}

.ProseMirror table .column-resize-handle {
    position: absolute;
    right: -2px;
    top: 0;
    bottom: -2px;
    width: 4px;
    background-color: #adf;
    pointer-events: none;
}

.ProseMirror table p {
    margin: 0;
}

.tableWrapper {
    padding: 1rem 0;
    overflow-x: auto;
}

.resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
}
</style>

<script>

import {Editor, EditorContent} from "@tiptap/vue-2";
import {StarterKit}            from "@tiptap/starter-kit";
import * as Y                  from "yjs";
import {WebrtcProvider}        from "y-webrtc";
import {Collaboration}         from "@tiptap/extension-collaboration";
import markdown                from "markdown-it";
import {CollaborationCursor}   from "@tiptap/extension-collaboration-cursor";
import {TextAlign}             from "@tiptap/extension-text-align";
import {TaskList}              from "@tiptap/extension-task-list";
import {TaskItem}              from "@tiptap/extension-task-item";
import {Table}                 from "@tiptap/extension-table";
import {TableRow}              from "@tiptap/extension-table-row";
import {TableHeader}           from "@tiptap/extension-table-header";
import {TableCell}             from "@tiptap/extension-table-cell";

export default {
    components: {
        EditorContent
    },
    data:       () => ({
        workListItem:   {},
        workListItemId: null,
        employeeList:   [],

        loaded:   false,
        editMode: false,

        workListItemModified: '',

        editor:                 null,
        editorDocumentProvider: null,
        editorDocument:         null,
    }),
    methods:    {
        mdToHtml(markdownText) {
            if (markdownText == null || markdownText.trim().length === 0) {
                return '<i>Keine Informationen angegeben.</i>'
            }
            return markdown({breaks: true}).render(markdownText);
        },

        fetchWorkListItem(id) {
            return this.apiGovernmentGetWorkListItemById(id).then(r => {
                this.workListItem = r.data;
                return r.data;
            })
        },
        fetchEmployeeList() {
            this.apiGetMissionReportEmployees().then(r => {
                this.employeeList = r.data;
            })
        },

        saveCurrentState() {
            this.workListItem.description = this.workListItemModified;
            this.apiGovernmentUpdateWorkListItem_OnlyDescription(this.workListItemId, this.workListItem).then(() => {
                this.fetchWorkListItem(this.workListItemId);
            })
        },

        buildEditor() {
            if (this.editor) return;

            this.websocketUrl().then(r => {
                let urlObj = new URL(r);
                if (urlObj.protocol === 'http:') urlObj.protocol = 'ws:';
                if (urlObj.protocol === 'https:') urlObj.protocol = 'wss:';
                urlObj.pathname = '/socket';
                let url         = urlObj.toString();

                this.editorDocument         = new Y.Doc();
                this.editorDocumentProvider = new WebrtcProvider(this.workListItemId, this.editorDocument, {
                    signaling: [url]
                });

                this.editorDocumentProvider.on('synced', event => {
                    if (event.synced) {
                        if (this.editor.getText() !== '') {
                            this.loaded = true;
                        }
                    }
                })

                this.editor = new Editor({
                    extensions: [
                        StarterKit.configure({history: false}),
                        TextAlign.configure({
                            types: ['heading', 'paragraph'],
                        }),
                        TaskList,
                        TaskItem.configure({
                            nested: true,
                        }),
                        Table.configure({
                            resizable: true,
                        }),
                        TableRow,
                        TableHeader,
                        TableCell,
                        Collaboration.configure({document: this.editorDocument}),
                        CollaborationCursor.configure({
                            provider: this.editorDocumentProvider,
                            user:     {
                                name:  this.user.displayName,
                                color: '#ed4354',
                            }
                        }),
                    ],
                    onUpdate:   () => {
                        this.workListItemModified = this.editor.getHTML();
                    },
                });


                setTimeout(() => {
                    if (this.editor.getText() === '') {
                        this.editor.commands.setContent(this.workListItem.description);
                        this.workListItemModified = this.workListItem.description;
                        console.log('[tiptap] pushing initial content');
                    }
                    this.loaded = true;
                }, 500);
            })
        },
    },
    mounted() {
        this.workListItemId = this.$route.params.id;
        this.fetchWorkListItem(this.workListItemId).then(() => {
            this.buildEditor();
        });
        this.fetchEmployeeList();
    },
    beforeDestroy() {
        this.editor.destroy();
        this.editorDocumentProvider.destroy();
    },
}
</script>
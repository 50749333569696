<template>
    <div>
        <v-data-table :loading="loading" :loader-height="5" :items="companies" :headers="companyTableHeader"
                      show-select v-model="selectedCompanies" sort-by="iid">
            <template v-slot:item.licensePrice="{ item }">
                {{ formatMoney(getLicenseFees(item.licenseLinks) * 4) }}
            </template>
            <template v-slot:item.strikes="{ item }">
                {{ getStrikeCount(item.strikes) }}
            </template>
            <template v-slot:item.type="{ item }">
                {{ companyTypes[item.type] || 'Unbekannt' }}
            </template>
            <template v-slot:item.owner.name="{ item }">
                <router-link class="text--secondary" v-if="item.owner" :to="'/criminal-record/' + item.owner.id">
                    {{ item.owner.name }}
                </router-link>
                <span v-else>Gewerbeamt</span>
            </template>

            <template v-slot:item.nextPaymentLicenses="{ item }">
                {{ formatDateToInXDays(item.nextPaymentLicenses) }}
            </template>

            <template v-slot:item.status="{ item }">
                <v-btn icon color="error" v-if="getStrikeCount(item.strikes) >= 10">
                    <v-icon size="24">mdi-store-alert-outline</v-icon>
                </v-btn>
                <v-btn icon color="grey" v-else-if="!item.owner">
                    <v-icon size="24">mdi-account-alert-outline</v-icon>
                </v-btn>
                <v-btn icon color="warning" v-else-if="!item.owner.phoneNumber">
                    <v-icon size="24">mdi-phone-alert-outline</v-icon>
                </v-btn>
                <v-btn icon color="info" v-else-if="isOnVacation(item.vacatedUntil)">
                    <v-icon size="24">mdi-tsunami</v-icon>
                </v-btn>
                <v-btn icon color="error" v-else-if="isPaymentOverdue(item.nextPaymentLicenses)">
                    <v-icon size="24">mdi-currency-eur-off</v-icon>
                </v-btn>
                <v-btn icon color="warning" v-else-if="isPaymentNearlyOverdue(item.nextPaymentLicenses)">
                    <v-icon>mdi-credit-card-clock-outline</v-icon>
                </v-btn>


                <v-btn icon color="success" v-else>
                    <v-icon size="24">mdi-check-all</v-icon>
                </v-btn>
            </template>

            <template v-slot:item.actions="{ item }">
                <v-btn icon :to="'/company/' + item.id">
                    <v-icon color="primary">mdi-eye-outline</v-icon>
                </v-btn>
                <v-btn icon @click="quickPay(item)">
                    <v-icon color="orange">mdi-currency-eur</v-icon>
                </v-btn>
                <v-btn icon @click="deleteItem(item)" v-if="user.admin || user.orgAdmin">
                    <v-icon color="error">mdi-delete-variant</v-icon>
                </v-btn>
            </template>
        </v-data-table>

        <v-dialog v-model="quickPayModal" persistent width="300">
            <v-card dark :loading="quickPaySaving">
                <v-card-title class="pa-1 pl-4">
                    <v-icon left color="yellow">mdi-currency-eur</v-icon>
                    Zahlung erfassen
                </v-card-title>
                <v-divider/>
                <v-card-text>
                    <v-slider v-model="quickPayWeeks" max="4" min="1" :label="quickPayWeeks" hint="Interval in Wochen"
                              persistent-hint/>
                    <br/>
                    Preis: <b>{{ formatMoney(quickPayPrice) }}</b>
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-btn x-small color="success" @click="quickPayComplete()">
                        Zahlung erhalten
                    </v-btn>
                    <v-spacer/>
                    <v-btn x-small color="grey" @click="quickPayModal=false">
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import moment          from "moment";
import {COMPANY_TYPES} from "@/plugins/config";
import Swal            from "sweetalert2";

export default {
    data:     () => ({
        loading:   true,
        companies: [],

        quickPayModal:   false,
        quickPayCompany: null,
        quickPayWeeks:   4,
        quickPaySaving:  false,

        companyTableHeader: [
            {value: 'iid', text: 'ID'},
            {value: 'status', text: 'Status', align: 'center'},
            {value: 'name', text: 'Name'},
            {value: 'type', text: 'Art'},
            {value: 'location', text: 'Adresse'},
            {value: 'owner.name', text: 'Besitzer'},
            {value: 'owner.phoneNumber', text: 'Tel. Nr.'},
            {value: 'strikes', text: 'Strikes'},
            {value: 'licensePrice', text: 'Lizenzkosten'},
            {value: 'nextPaymentLicenses', text: 'Nächste Zahlung in'},

            {value: 'actions', text: 'Aktionen'},
        ],

        selectedCompanies: [],
    }),
    computed: {
        companyTypes() {
            return COMPANY_TYPES;
        },
        quickPayPrice() {
            if (this.quickPayCompany) {
                let price = 0;
                for (let licenseLink of this.quickPayCompany.licenseLinks) {
                    price += (licenseLink.count * licenseLink.license.fee) * this.quickPayWeeks;
                }
                return price;
            }
            return 0;
        }
    },
    methods:  {
        deleteItem(company) {
            Swal.fire({
                title:             'Bist du dir sicher?',
                html:              `Diese Aktion kann nicht rückgängig gemacht werden. Möchtest du das Gewerbe <b>${company.label}</b> wirklich löschen?`,
                showCancelButton:  true,
                cancelButtonText:  "Abbrechen",
                confirmButtonText: "Löschen"
            }).then(r => {
                if (r.isConfirmed) {
                    this.loading = true;
                    this.apiDeleteCompany(company).then(() => {
                        this.fetchCompanies();
                    })
                }
            })
        },
        quickPay(company) {
            this.quickPayModal   = true;
            this.quickPayCompany = company;
        },
        quickPayComplete() {
            this.quickPaySaving = true;

            // custom logic?!
            let nextCycle = this.quickPayCompany.nextPaymentLicenses;
            if (nextCycle && moment(nextCycle).isAfter(moment())) {
                nextCycle = moment(nextCycle).add(this.quickPayWeeks, 'weeks').toISOString()
            } else {
                nextCycle = moment().add(this.quickPayWeeks, 'weeks').toISOString()
            }

            this.apiUpdateCompany(this.quickPayCompany.id, {
                nextPaymentLicenses: nextCycle
            }).then(() => {
                Promise.all([
                    this.fetchCompanies(),
                    this.apiCreateCompanyRecordEntry(this.quickPayCompany, {
                        type:    'PAYMENT',
                        content: `Zahlung für **${this.quickPayWeeks} Woche:n** über **${this.formatMoney(this.quickPayPrice)}** erfasst.`,
                        fine:    this.quickPayPrice
                    })
                ]).then(() => {
                    this.quickPaySaving  = false;
                    this.quickPayModal   = false;
                    this.quickPayCompany = null;
                })
            })
        },
        isPaymentNearlyOverdue(date) {
            if (date) return moment(date).subtract(7, 'd').isBefore(moment());
            return false;
        },
        isPaymentOverdue(date) {
            if (date) return moment(date).isBefore(moment());
            return false;
        },
        isOnVacation(date) {
            if (date) return moment(date).isAfter(moment());
            return false;
        },

        formatDateToInXDays(val) {
            if (val) {
                let start    = moment();
                let end      = moment(val);
                let duration = moment.duration(end.diff(start));
                moment.relativeTimeThreshold('w', 52 * 10);
                return duration.locale('de').humanize(true);
            }
            return '-'
        },

        getStrikeCount(strikes) {
            let count = 0;
            let now   = moment();

            for (let strike of strikes) {
                if (moment(strike.expiresAfter).isAfter(now)) {
                    count += strike.points;
                }
            }

            return count;
        },
        getLicenseFees(links) {
            let price = 0;

            for (let link of links) {
                price += link.count * link.license.fee;
            }

            return price;
        },

        fetchCompanies() {
            this.loading = true;
            return this.apiGetAllCompanies().then(r => {
                this.companies = r.data;
                this.loading   = false;
                return true;
            })
        }
    },
    mounted() {
        this.fetchCompanies();
    }
}
</script>
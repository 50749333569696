<template>
    <v-container fluid fill-height fill-width>
        <v-card height="100%" fill-height fill-width width="100%">
            <div id="placeholder" style="height: 1000px"/>
        </v-card>
    </v-container>
</template>

<style>
iframe {
    height: 100%;
}
</style>

<script>

export default {
    components: {},
    data:       () => ({
        id:         null,
        title:      '',
        content:    '',
        shortName:  '',
        trustLevel: 1,
        tab:        0,

        editor: null,
    }),
    methods:    {
        fetchDocument(id) {
            this.apiGetInternalInfo(id).then(response => {
                let internalInfo = response.data;
                console.log(internalInfo);

                this.title      = internalInfo.title;
                this.content    = internalInfo.content;
                this.trustLevel = internalInfo.trustLevel;
                this.shortName  = internalInfo.shortName;


                this.apiGetOnlyOfficeDocumentUrl(id).then(documentUrl => {
                    let documentId = id;

                    let documentFileType = 'docx';
                    let documentType     = 'word';

                    if (internalInfo.attachmentType === 'FORM') {
                        documentFileType = 'docxf';
                    } else if (internalInfo.attachmentType === 'SHEET') {
                        documentFileType = 'xlsx';
                        documentType     = 'cell';
                    }

                    this.apiGetOnlyOfficeEditorId(id).then(response => {
                        let documentKey = documentId + "_" + response.data.id;

                        // eslint-disable-next-line no-undef
                        this.editor = new DocsAPI.DocEditor("placeholder", {
                            document:     {
                                fileType:      documentFileType,
                                key:           documentKey,
                                title:         documentId + "." + documentFileType,
                                url:           documentUrl,
                                referenceData: {
                                    fileKey:    documentId,
                                    instanceId: "https://gov.alteravitarp.de"
                                }
                            },
                            documentType: documentType,
                            editorConfig: {
                                callbackUrl: this.apiOnlyOfficeUrl(),
                                mode:        "edit",
                                lang:        "de",
                                location:    "de",
                                region:      "de-DE",
                                user:        {
                                    id:    this.user.id,
                                    name:  this.user.displayName,
                                    group: this.user.accountType
                                }
                            }
                        });
                    });
                });
            })
        },
        saveEntry() {
            this.apiUpdateInternalInfo(this.id, {
                title:      this.title,
                content:    this.content,
                trustLevel: this.trustLevel,
                shortName:  this.shortName,
            }).then(r => {
                this.$router.push('/intern-info/' + r.data.id);
            })
        },
        createEntry() {
            this.apiCreateInternalInfo({
                title:      this.title,
                shortName:  this.shortName,
                content:    this.content,
                trustLevel: this.trustLevel
            }).then(r => {
                this.$router.push('/intern-info/' + r.data.id);
            })
        }
    },
    beforeDestroy() {
        this.editor?.requestClose();
        this.editor?.destroyEditor();
        this.editor = null;
    },
    mounted() {
        this.id = this.$route.params.id || null;

        if (this.id) {
            console.log("Opening Editor with existing ID");
            this.fetchDocument(this.id);
        }
    }
}
</script>
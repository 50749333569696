<template>
    <v-card-text class="pt-6 pb-6 pl-2 pr-3 d-flex justify-center">
        <v-img eager max-height="72px" max-width="72px" :src="'https://fivem.r3ktm8.de/items/' + item.name + '.png'"/>

        <div style="position: absolute; top: 4px; left: 6px;">
            <span tile x-small elevation=0 readonly color="grey darken-3">{{ item.label }}</span>
        </div>

        <div style="position: absolute; bottom: 4px; left: 6px;">
            <span tile x-small elevation=0 readonly color="grey darken-3">{{ item.count }}x</span>
            <!-- <v-btn tile x-small elevation=0 readonly class="ml-1" v-if="item.id">#{{ item.id }}</v-btn> -->
        </div>
        <div style="position: absolute; bottom: 4px; right: 6px;" v-if="(item.weight * item.count) > 0">
            <span tile x-small elevation=0 readonly color="grey darken-3">{{ item.weight * item.count }}g</span>
        </div>
    </v-card-text>
</template>

<script>
export default {
    props: ['item'],
}
</script>
<template>
    <v-container fluid>
        <v-card elevation="4" outlined :loading="loading">
            <v-card-title class="pb-2 pt-2">
                <v-icon left color="info">mdi-calendar</v-icon>
                Terminkalender
            </v-card-title>
            <v-divider/>
            <v-card-actions>
                <b>Anstehende Termine</b>
            </v-card-actions>
            <v-divider/>
            <v-card-text>
                <v-card outlined v-for="ev in upcomingEvents" :key="ev.id" dark color="primary darken-4" class="mb-2" @click="openEvent({event: ev})">
                    <v-card-title class="pa-1 pl-2">
                        <v-icon left color="white">mdi-calendar</v-icon>
                        <span v-html="mdToHtml(ev.title + ' ' + ev.startTimeLocal + '')"></span>
                    </v-card-title>
                    <v-card-text class="pl-2">
                        <b v-html="mdToHtml(ev.description)"></b>
                    </v-card-text>
                </v-card>
            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <b>Terminkalender</b>
            </v-card-actions>
            <v-sheet>
                <v-calendar
                    color="primary"
                    :events="allEvents"
                    type="month"
                    :weekdays="[1, 2, 3, 4, 5, 6, 0]"
                    :event-more="false"
                    :event-height="20"
                    :event-ripple="true"
                    :min-weeks="4"
                    @click:event="openEvent">
                </v-calendar>
            </v-sheet>
            <v-card-actions v-if="user.admin || user.orgAdmin || user.flags.includes('CREATE_APPOINTMENT')">
                <v-btn color="primary" small @click="showCreateSheet = true">
                    <v-icon left>mdi-calendar-plus</v-icon>
                    Termin hinzufügen
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-bottom-sheet inset persistent v-model="showCreateSheet">
            <v-card>
                <v-card-title class="pa-2 pl-3">
                    <v-icon left>mdi-calendar-plus</v-icon>
                    <span v-if="!editId">Termin hinzufügen</span>
                    <span v-if="editId">Termin "{{ newAppointment.title }}" bearbeiten</span>

                    <v-spacer/>
                    <v-btn icon color="red" @click="stopEditAppointment()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider/>
                <v-card-text class="pa-3">
                    <v-row>
                        <v-col>
                            <v-datetime-picker v-model="newAppointment.start"
                                               :text-field-props="{'outlined': true, 'solo': true, 'dense': true, 'prependIcon': 'mdi-calendar-alert', 'class': 'mt-5'}"
                                               :time-picker-props="{'format': '24hr'}">
                                <template slot="dateIcon">
                                    <v-icon>mdi-calendar</v-icon>
                                </template>
                                <template slot="timeIcon">
                                    <v-icon>mdi-clock</v-icon>
                                </template>
                            </v-datetime-picker>
                        </v-col>
                        <v-col>
                            <v-datetime-picker v-model="newAppointment.end"
                                               :text-field-props="{'outlined': true, 'solo': true, 'dense': true, 'prependIcon': 'mdi-calendar-alert', 'class': 'mt-5'}"
                                               :time-picker-props="{'format': '24hr'}">
                                <template slot="dateIcon">
                                    <v-icon>mdi-calendar</v-icon>
                                </template>
                                <template slot="timeIcon">
                                    <v-icon>mdi-clock</v-icon>
                                </template>
                            </v-datetime-picker>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="newAppointment.title" solo dense placeholder="Titel" outlined
                                          prepend-icon="mdi-form-textbox"/>
                        </v-col>
                        <v-col>
                            <v-text-field type="number" v-model="newAppointment.userLimit" solo dense
                                          placeholder="Limit für die Anmeldung" outlined
                                          prepend-icon="mdi-form-textbox"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-textarea v-model="newAppointment.description" solo dense placeholder="Beschreibung"
                                        outlined
                                        prepend-icon="mdi-form-textbox"/>
                        </v-col>
                    </v-row>
                    <v-row class="mb-0 mt-0 pb-0 pt-0">
                        <v-col class="mb-0 mt-0 pb-0 pt-0">
                            <v-checkbox dense class="mb-0 mt-0 pb-0 pt-0"
                                        label="Öffentlicher Termin - kann von allen Staatlichen Organisationen besucht werden"
                                        persistent-hint v-model="newAppointment.open"/>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider/>
                <v-card-actions class="pa-2 pl-3">
                    <v-btn color="success" small elevation="4" dark v-if="!editId" @click="createAppointment()">
                        <v-icon left>mdi-content-save-outline</v-icon>
                        Erstellen
                    </v-btn>
                    <v-btn color="error" small elevation="4" dark v-if="editId" @click="updateAppointment()">
                        <v-icon left>mdi-content-save-outline</v-icon>
                        Aktualisieren
                    </v-btn>
                    <v-btn color="warning" small elevation="4" dark @click="showCreateSheet = false;">
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet inset persistent v-model="showActiveEvent">
            <v-card height="500px" v-if="activeEvent">
                <v-btn class="float-end ma-3" icon color="red" @click="showActiveEvent = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-container style="height: 450px">
                    <h2 style="font-weight: normal;">
                        <v-icon left>mdi-calendar</v-icon>
                        <span v-html="mdToHtml(activeEvent.title)"/> ({{ activeEvent.start | formatDate }} bis
                        {{ activeEvent.end | formatDate }})
                    </h2>
                    <v-divider class="mt-3 mb-3"/>
                    <v-row>
                        <v-col>
                            <b>Termin eingestellt von</b><br/>
                            {{ activeEvent.createdBy.displayName }}<br/>
                            <v-btn x-small outlined color="primary">{{ activeEvent.createdBy.rank }}</v-btn>
                            <br/><br/>
                            <b>Termindetails</b><br/>
                            <span v-html="activeEvent.description"/>
                        </v-col>
                        <v-col>
                            <b>Angemeldete Benutzer <span
                                v-if="activeEvent.userLimit">({{ activeEvent.registeredUsers.length }} von {{
                                    activeEvent.userLimit
                                }})</span></b>
                            <v-list>
                                <v-list-item v-for="(item, k) in activeEvent.registeredUsers" :key="k" dense>
                                    <v-list-item-icon>
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        {{ item.displayName }} ({{ item.rank }})
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="activeEvent.registeredUsers.length === 0" dense>
                                    <v-list-item-content>
                                        <i>Niemand</i>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-container>
                <div class="ma-3">
                    <v-btn small class="mr-1" elevation="4" color="info" @click="registerEvent(activeEvent)"
                           v-if="activeEvent.registeredUsers.find(u => u.id === user.id) === undefined">
                        <v-icon left small>mdi-account-multiple-plus-outline</v-icon>
                        Anmelden
                    </v-btn>
                    <v-btn small dark class="mr-1" elevation="4" color="orange" @click="unregisterEvent(activeEvent)"
                           v-if="activeEvent.registeredUsers.find(u => u.id === user.id) !== undefined">
                        <v-icon left small>mdi-account-multiple-minus-outline</v-icon>
                        Abmelden
                    </v-btn>
                    <v-btn color="primary" class="mr-1" elevation="4" small
                           v-if="user.admin || user.orgAdmin || user.id === activeEvent.createdBy.id"
                           @click="startEditAppointment(activeEvent)">
                        <v-icon left small>mdi-notebook-edit-outline</v-icon>
                        Termin bearbeiten
                    </v-btn>
                    <v-btn color="primary" elevation="4" small
                           v-if="user.admin || user.orgAdmin || user.id === activeEvent.createdBy.id"
                           @click="deleteAppointment()">
                        <v-icon left small>mdi-delete-forever-outline</v-icon>
                        Termin löschen
                    </v-btn>
                </div>
            </v-card>
        </v-bottom-sheet>
    </v-container>
</template>

<script>
import Swal     from "sweetalert2";
import moment   from "moment";
import markdown from "markdown-it";

export default {
    computed: {
        upcomingEvents() {
            let ev          = [];
            let currentDate = moment();

            this.events.forEach(e => {
                if (e.start !== null && e.start !== undefined) {
                    let date = moment(e.start);

                    if (date > currentDate && currentDate.diff(moment(e.start), 'days') < 7) {
                        ev.push(this.enrichAppointmentDataForUi(e));
                    }
                }
            })

            return ev;
        },

        allEvents() {
            let ev = [];

            this.events.forEach(e => {
                ev.push(this.enrichAppointmentDataForUi(e));
            })

            return ev;
        }
    },
    data:     () => ({
        loading:         false,
        showActiveEvent: false,
        showCreateSheet: false,
        activeEvent:     null,

        events: [],

        newAppointment: {
            open:        false,
            start:       '',
            end:         '',
            title:       '',
            description: '',
            userLimit:   null,
        },

        editId: null,
    }),
    filters:  {
        formatDate(date) {
            return new Date(date).toLocaleTimeString();
        }
    },
    methods:  {
        startEditAppointment(appointment) {
            this.newAppointment.open        = appointment.open;
            this.newAppointment.start       = moment(appointment.start).toDate();
            this.newAppointment.end         = moment(appointment.end).toDate();
            this.newAppointment.title       = appointment.title;
            this.newAppointment.description = appointment.description;
            this.newAppointment.userLimit   = appointment.userLimit;

            this.editId          = appointment.id;
            this.showCreateSheet = true;
            this.showActiveEvent = false;
        },
        stopEditAppointment() {
            this.newAppointment = {
                open:        false,
                start:       '',
                end:         '',
                title:       '',
                description: '',
                userLimit:   null,
            }

            this.editId          = null;
            this.showCreateSheet = false;
            this.showActiveEvent = false;
        },
        updateAppointment() {
            this.apiUpdateAppointment(this.editId, this.newAppointment).then(() => {
                this.fetchAppointments();
                this.stopEditAppointment();
            });
        },

        mdToHtml(markdownText) {
            if (markdownText == null || markdownText.trim().length === 0) {
                return '<i>Keine Informationen angegeben.</i>'
            }

            let rendered = markdown({breaks: true, typographer: true, xhtmlOut: true})
                .render(markdownText);

            return rendered.substring(3, rendered.length - 5);
        },
        enrichAppointmentDataForUi(appointment) {
            let e = this.mapEvent(JSON.parse(JSON.stringify(appointment)))

            if (e.accountType !== this.user.accountType) {
                e.name = e.accountType + ': ' + e.name;
            }

            e.startTimeLocal = moment(e.start).locale('de').fromNow();

            if (e.accountType === 'MEDIC') {
                e.color = '#e60005';
            } else if (e.accountType === 'POLICE') {
                e.color = '#0e518d'
            } else if (e.accountType === 'ZOLL') {
                e.color = '#0a3a64'
            } else if (e.accountType === 'GOVERNMENT') {
                e.color = '#4c4c4c'
            } else if (e.accountType === 'MECHANIC') {
                e.color = '#F1C40F'
            } else {
                e.color = '#009688'
            }

            return e;
        },

        createAppointment() {
            Swal.showLoading();

            if (this.newAppointment.start == null ||
                this.newAppointment.end == null ||
                this.newAppointment.title == null ||
                this.newAppointment.title.length < 3) {
                Swal.fire('Fehler', 'Die Eingabe für den Termin sind ungültig.', 'error');
                return;
            }

            this.apiCreateAppointment(this.newAppointment).then(() => {
                this.fetchAppointments();
                this.showCreateSheet = false;
            }).finally(() => {
                Swal.hideLoading();
            });
        },
        mapEvent(nativeEvent) {
            nativeEvent.start = moment(nativeEvent.start).toDate();
            nativeEvent.end   = moment(nativeEvent.end).toDate();
            nativeEvent.name  = nativeEvent.title;

            return nativeEvent;
        },
        openEvent(ev) {
            this.loading = true;
            this.apiGetAppointmentById(ev.event.id).then(r => {
                this.activeEvent     = r.data;
                this.showActiveEvent = true;
            }).finally(() => {
                this.loading = false;
            });
        },
        registerEvent(ev) {
            this.apiRegisterForAppointment(ev.id).then(r => {
                this.activeEvent = this.mapEvent(r.data);
                this.fetchAppointments();
            });
        },
        unregisterEvent(ev) {
            this.apiUnRegisterForAppointment(ev.id).then(r => {
                this.activeEvent = this.mapEvent(r.data);
                this.fetchAppointments();
            });
        },
        deleteAppointment() {
            Swal.fire({
                title:             'Bist du dir sicher?',
                html:              'Diese Aktion kann nicht rückgängig gemacht werden. Bist du Sicher dass du den Termin <b>' + this.activeEvent.title + '</b> löschen möchtest?',
                showCancelButton:  true,
                cancelButtonText:  'Nein',
                showConfirmButton: true,
                confirmButtonText: 'Ja',
            }).then(r => {
                if (r.isConfirmed) {
                    this.apiDeleteAppointment(this.activeEvent.id).then(() => {
                        this.fetchAppointments();
                        this.showActiveEvent = false;
                        this.activeEvent     = null;

                        Swal.fire('Erfolgreich!', 'Das Event wurde gelöscht!', 'success');
                    });
                }
            })
        },
        fetchAppointments() {
            this.loading = true;
            this.apiGetAppointments().then(r => {
                this.events = [];
                for (let obj of r.data) {
                    obj = this.mapEvent(obj);
                    this.events.push(obj)
                }
            }).finally(() => {
                this.loading = false;
            })
        }
    },
    mounted() {
        this.fetchAppointments();
    }
}
</script>
<template>
  <v-container fluid>
    <v-card class="pb-0 mb-0" outlined elevation="4">
      <v-tabs height="40px" v-model="tab" color="primary lighten-2">
        <v-tab>
          Editor
        </v-tab>
        <v-tab>
          HTML
        </v-tab>
        <v-tab>
          Preview
        </v-tab>
      </v-tabs>
      <v-card-text>
        <v-row>
          <v-col cols="2">
            <v-text-field prepend-icon="mdi-form-textbox" label="Kurzname" v-model="shortName"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field prepend-icon="mdi-form-textbox" label="Titel" v-model="title"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field label="Sicherheitsstufe" v-model="trustLevel"></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider/>

      <v-row v-if="tab === 2">
        <v-col>
          <v-card-text class="text-box scrollbar-overflow-hidden ql-editor" v-html="content">
          </v-card-text>
        </v-col>
      </v-row>

      <v-card-text>
        <vue-editor v-model="content" v-if="tab === 0" style="height: 100%"
                    :customModules="editor.customModules"
                    :editorOptions="editor.settings"/>
        <v-textarea solo outlined rows="30" v-model="content" v-if="tab === 1" class="pa-4"></v-textarea>
      </v-card-text>

      <v-divider/>
      <v-card-actions>
        <v-btn small color="success" v-if="id" @click="saveEntry">
          <v-icon left>mdi-content-save-outline</v-icon>
          Speichern
        </v-btn>
        <v-btn small color="primary" v-else @click="createEntry">
          <v-icon left>mdi-content-save-outline</v-icon>
          Erstellen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import {VueEditor} from "vue2-editor";
import ImageResize from 'quill-image-resize-module';

export default {
  components: {VueEditor},
  data:       () => ({
    id:         null,
    title:      '',
    content:    '',
    shortName:  '',
    trustLevel: 1,
    tab:        0,

    editor: {
      customModules: [
        {alias: 'imageResize', module: ImageResize},
      ],
      settings:      {
        theme:   'snow',
        modules: {
          table:       false,
          imageResize: {},
        },
      }
    }
  }),
  methods:    {
    fetchDocument(id) {
      this.apiGetInternalInfo(id).then(r => {
        console.log(r.data);
        this.title      = r.data.title;
        this.content    = r.data.content;
        this.trustLevel = r.data.trustLevel;
        this.shortName  = r.data.shortName;
      })
    },
    saveEntry() {
      this.apiUpdateInternalInfo(this.id, {
        title:      this.title,
        content:    this.content,
        trustLevel: this.trustLevel,
        shortName:  this.shortName,
      }).then(r => {
        this.$router.push('/intern-info/' + r.data.id);
      })
    },
    createEntry() {
      this.apiCreateInternalInfo({title: this.title, shortName: this.shortName, content: this.content, trustLevel: this.trustLevel}).then(r => {
        this.$router.push('/intern-info/' + r.data.id);
      })
    }
  },
  mounted() {
    this.id = this.$route.params.id || null;

    if (this.id) {
      console.log("Opening Editor with existing ID");
      this.fetchDocument(this.id);
    }
  }
}
</script>
<template>
    <v-container fluid>
        <v-alert type="info" elevation="4" outlined border="left">
            <p>Hey <b>{{ user.displayName }}</b>, du befindest dich hier in deinen privaten Einstellungen.</p>
        </v-alert>

        <v-card outlined elevation="4">
            <v-card-title class="pt-2 pb-2 pl-3">
                <v-icon left color="error">mdi-cog-outline</v-icon>
                Einstellungen
            </v-card-title>
            <v-divider/>
            <v-card-text class="pt-0">
                <v-switch v-model="darkMode" label="Darkmode" class="pb-0 mb-0"/>
                <v-switch v-model="timeBasedDarkMode" label="Darkmode zwischen 19 und 7 Uhr" class="pt-0 mt-0"/>
                <v-switch v-model="previewFeatures" label="Beta-Modus (Preview Features)" class="pt-0 mt-0"/>
                <v-switch v-model="legacyViews" label="Legacy-Modus (alte Ansicht)" class="pt-0 mt-0"/>

                <v-menu
                    v-model="colorPickerMenu" :close-on-content-click="false" :nudge-width="200" offset-x>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn small color="indigo" dark v-bind="attrs" v-on="on">
                            <v-icon left>mdi-palette</v-icon>
                            Eigenes Farbschema {{ customPrimaryColor?.hex }}
                        </v-btn>
                    </template>

                    <v-card min-width="300">
                        <v-color-picker v-model="customPrimaryColor"
                                        mode="hexa"></v-color-picker>
                    </v-card>
                </v-menu>
            </v-card-text>
            <v-divider/>
            <v-card-title class="pt-2 pb-2 pl-3">
                <v-icon left color="error">mdi-image-outline</v-icon>
                Aktueller Avatar
            </v-card-title>
            <v-divider/>
            <v-card-text v-if="showAvatars">
                <div v-for="v in [128, 64, 32, 24]" :key="v" cols="2" style="display: inline;" class="pr-4">
                    <user-avatar :size="v" :user-obj="user"/>
                </div>
            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-btn color="primary" @click="openChangePasswordDialog">
                    <v-icon left>mdi-lock-outline</v-icon>
                    Passwort ändern
                </v-btn>
                <v-btn color="secondary" @click="openChangeAvatarDialog">
                    <v-icon left>mdi-image-outline</v-icon>
                    Avatar ändern
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-dialog v-model="changeAvatarDialog" width="400">
            <v-card>
                <v-card-title class="pt-2 pb-2 pl-3">
                    <v-icon left>mdi-image-outline</v-icon>
                    Avatar ändern
                </v-card-title>
                <v-divider/>
                <v-card-text class="pt-5 pb-0">
                    <v-file-input v-model="changeAvatarFile" solo dense outlined prepend-icon="mdi-image-outline"
                                  placeholder="Avatar (.png)"/>
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-btn small @click="uploadAvatar" color="success">
                        <v-icon left>mdi-content-save</v-icon>
                        Ändern
                    </v-btn>
                    <v-spacer/>
                    <v-btn small @click="changeAvatarDialog = false;" color="blue-grey" dark>
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="changePasswordDialog" width="700">
            <v-card>
                <v-card-title class="pt-2 pb-2 pl-3">
                    <v-icon left>mdi-lock-outline</v-icon>
                    Passwort ändern
                </v-card-title>
                <v-divider/>
                <v-card-text class="pt-5 pb-0">
                    <v-text-field v-model="changePassword0" solo dense outlined prepend-inner-icon="mdi-lock-outline"
                                  type="password" placeholder="Aktuelles Passwort" autofocus/>
                    <v-text-field v-model="changePassword1" solo dense outlined prepend-inner-icon="mdi-lock-outline"
                                  type="password" placeholder="Neues Passwort" @keydown.enter="changePassword"/>
                    <v-text-field v-model="changePassword2" solo dense outlined prepend-inner-icon="mdi-lock-outline"
                                  type="password" placeholder="Neues Passwort wiederholen" :hint="passwordComplexity"
                                  persistent-hint
                                  @keydown.enter="changePassword"/>

                    <v-alert type="warning" elevation="12" border="left" rounded>
                        Dein Passwort muss folgende Richtlinien befolgen, sonst kannst du es nicht speichern:
                        <ol class="pt-6">
                            <li>Mindestens ein Klein- und Großbuchstabe</li>
                            <li>Mindestens eine Zahl</li>
                            <li>Ein Sonderzeichen aus <pre>$&+,:;=?@#|'.^*()%!-</pre></li>
                            <li>Mindestens 8 Zeichen lang</li>
                        </ol>
                    </v-alert>
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-btn small @click="changePassword" color="success"
                           :disabled="changePassword1 !== changePassword2 || passwordComplexity < 0.75">
                        <v-icon left>mdi-content-save</v-icon>
                        Ändern
                    </v-btn>
                    <v-spacer/>
                    <v-btn small @click="changePasswordDialog = false;" color="blue-grey" dark>
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import Swal       from 'sweetalert2';
import UserAvatar from "@/components/UserAvatar.vue";

export default {
    components: {UserAvatar},
    data:       () => ({
        darkMode:          false,
        legacyViews:       false,
        previewFeatures:   false,
        timeBasedDarkMode: false,

        showAvatars: true,

        changePassword0: '',
        changePassword1: '',
        changePassword2: '',

        changeAvatarFile: null,

        customPrimaryColor:      null,
        initialColorPickerValue: null,
        colorPickerMenu:         false,

        changePasswordDialog: false,
        changeAvatarDialog:   false,
    }),
    mounted() {
        this.darkMode          = this.$store.state.darkMode;
        this.legacyViews       = this.$store.state.legacyViews;
        this.previewFeatures   = this.$store.state.previewFeatures;
        this.timeBasedDarkMode = this.$store.state.timeBasedDarkMode;

        this.updateVuetifyTheme();

        this.customPrimaryColor = this.$vuetify.theme.dark ? this.$vuetify.theme.themes.dark.primary : this.$vuetify.theme.themes.light.primary
        if (this.$store.state.customColor) {
            this.customPrimaryColor = this.$store.state.customColor;
        }
    },
    methods:  {
        openChangeAvatarDialog() {
            this.changeAvatarFile   = null;
            this.changeAvatarDialog = true;
        },
        uploadAvatar() {
            if (this.changeAvatarFile) {
                this.toBase64(this.changeAvatarFile).then(r => {
                    this.apiSetMyAvatar(r).then(userResponse => {
                        this.$store.commit('update', {user: userResponse.data})
                        Swal.fire({
                            title: 'Erfolgreich',
                            html:  'Dein Avatar wurde aktualisiert!',
                            icon:  "success"
                        })

                        this.showAvatars = false;
                        this.$nextTick(() => {
                            this.showAvatars = true;
                        })
                    });
                });
            }
        },
        openChangePasswordDialog() {
            this.changePassword0      = '';
            this.changePassword1      = '';
            this.changePassword2      = '';
            this.changePasswordDialog = true;
        },
        changePassword() {
            this.apiSetMyPassword(
                this.changePassword0,
                this.changePassword1,
                this.changePassword2,
            ).then(() => {
                Swal.fire({
                    title: 'Erfolgreich',
                    html:  'Dein Passwort wurde geändert!',
                    icon:  "success"
                });


                this.$store.commit('update', {
                    'passwordComplexity': this.calculatePasswordComplexity(this.changePassword1),
                });

                this.openChangePasswordDialog();
                this.changePasswordDialog = false;
            }).catch(() => {
                Swal.fire({
                    title: 'Oho! Fehler aufgetreten',
                    html:  'Dein altes Passwort oder die neuen Passwörter stimmen nicht überein.',
                    icon:  "error"
                });
            })
        }
    },
    computed: {
        passwordComplexity() {
            return this.calculatePasswordComplexity(this.changePassword1);
        }
    },
    watch:    {
        customPrimaryColor: function (newValue) {
            this.$store.commit('update', {customColor: newValue})
            this.updateVuetifyTheme();
        },
        darkMode:           function (newValue) {
            if (newValue) this.timeBasedDarkMode = false;
            this.$store.commit('update', {darkMode: newValue})
            this.updateVuetifyTheme();
        },
        legacyViews:        function (newValue) {
            this.$store.commit('update', {legacyViews: newValue})
            this.updateVuetifyTheme();
        },
        previewFeatures:    function (newValue) {
            this.$store.commit('update', {previewFeatures: newValue})
            this.updateVuetifyTheme();
        },
        timeBasedDarkMode:  function (newValue) {
            if (newValue) this.darkMode = false;
            this.$store.commit('update', {timeBasedDarkMode: newValue})
            this.updateVuetifyTheme();
        },
    }
}
</script>
import Vue                          from 'vue'
import VueRouter                    from 'vue-router'
import Login                        from "@/views/Login";
import Logout                       from "@/views/Logout";
import CriminalRecordList           from "@/views/police/CriminalRecordList";
import CriminalRecord               from "@/views/police/CriminalRecord";
import CriminalRecordAdd            from "@/views/police/CriminalRecordAdd";
import NotImplementedYet            from "@/views/shared/NotImplementedYet";
import InternInfos                  from "@/views/shared/InternInfos";
import InternInfoEditor             from "@/views/shared/InternInfoEditor";
import InternInfoView               from "@/views/shared/InternInfoView";
import MissionReportList            from "@/views/shared/MissionReportList";
import MissionReportEditor          from "@/views/shared/MissionReportEditor";
import MissionReportView            from "@/views/shared/MissionReportView";
import Settings                     from "@/views/shared/Settings";
import Employees                    from "@/views/shared/Employees";
import EmployeeEdit                 from "@/views/shared/EmployeeEdit";
import Appointments                 from "@/views/shared/Appointments";
import MedicalRecordList            from "@/views/medic/MedicalRecordList";
import MedicalRecordAdd             from "@/views/medic/MedicalRecordAdd";
import MedicalRecord                from "@/views/medic/MedicalRecord";
import CriminalRecordEntryDashboard from "@/views/police/CriminalRecordEntryDashboard";
import MedicalRecordEntryDashboard  from "@/views/medic/MedicalRecordEntryDashboard";
import FastRecordView               from "@/views/police/FastRecordView";
import ComplaintOverview            from "@/views/shared/ComplaintOverview";
import PatrolUnits                  from "@/views/shared/PatrolUnits";
import Courses                      from "@/views/shared/Courses";
import CourseCreate                 from "@/views/shared/CourseCreate";
import TrafficControlView           from "@/views/police/TrafficControlView";
import CriminalFilesList            from "@/views/police/CriminalFilesList";
import CriminalFileEditor           from "@/views/police/CriminalFileEditor";
import CriminalFileView             from "@/views/police/CriminalFileView";
import CreditInvoiceList            from "@/views/admin/CreditInvoiceList.vue";
import CreditInvoiceView            from "@/views/admin/CreditInvoiceView.vue";
import TrafficSpeedTool             from "@/views/police/TrafficSpeedTool.vue";
import ComplaintView                from "@/views/shared/ComplaintView.vue";
import ControlCenterView            from "@/views/shared/ControlCenterView.vue";
import RunningMedicalExamination    from "@/views/medic/RunningMedicalExamination.vue";
import OrgApplication               from "@/views/public/OrgApplication.vue";
import GovernmentWorkListView       from "@/views/gov/GovernmentWorkListView.vue";
import GovernmentWorkListItem       from "@/views/gov/GovernmentWorkListItem.vue";
import PersonDownDispatches         from "@/views/shared/PersonDownDispatches.vue";
import InternInfoEditorV2           from "@/views/shared/InternInfoEditorV2.vue";
import InternInfoViewV2             from "@/views/shared/InternInfoViewV2.vue";
import AuditLogViewer               from "@/views/admin/AuditLogViewer.vue";
import ClothingTool                 from "@/views/admin/tool/ClothingTool.vue";
import CompanyListView              from "@/views/gov/CompanyListView.vue";
import CompanyView                  from "@/views/gov/CompanyView.vue";
import CrimeHeatmap                 from "@/views/police/CrimeHeatmap.vue";
import MedicHeatmap                 from "../views/medic/MedicHeatmap.vue";
import UserActivityReport           from "@/views/shared/UserActivityReport.vue";
import JobClothingTool              from "@/views/admin/tool/JobClothingTool.vue";
import DataListOverview             from "@/views/shared/DataListOverview.vue";
import DataList                     from "@/views/shared/DataList.vue";
import InventoryViewer              from "@/views/admin/InventoryViewer.vue";
import CompanyCreateView            from "@/views/gov/CompanyCreateView.vue";
import MissionReportEditorAsciidoc from "@/views/shared/MissionReportEditorAsciidoc.vue";
import LetterListView               from "@/views/shared/LetterListView.vue";
import LetterView                   from "@/views/shared/LetterView.vue";
import LetterCreate                 from "@/views/shared/LetterCreate.vue";

Vue.use(VueRouter)

export default new VueRouter({
  //mode: 'history',
  routes: [
    {
      path:      '/',
      component: Login
    },
    {
      path:      '/login',
      component: Login
    },
    {
      name:      'Logout',
      path:      '/logout',
      component: Logout
    },

    {
      name:      'OrgApplication',
      path:      '/application/police',
      component: OrgApplication,
      meta:      {
        accountType: 'POLICE',
      }
    },
    {
      name:      'OrgApplication',
      path:      '/application/medic',
      component: OrgApplication,
      meta:      {
        accountType: 'MEDIC',
      }
    },

    // POLICE
    {
      path:      '/criminal-records',
      component: CriminalRecordList
    },
    {
      path:      '/criminal-record/add',
      component: CriminalRecordAdd
    },
    {
      path:      '/criminal-record/:id',
      component: CriminalRecord
    },
    {
      path:      '/criminal-records-groups',
      component: NotImplementedYet
    },

    // MEDIC

    {
      path:      '/medical-records',
      component: MedicalRecordList
    },
    {
      path:      '/medical-record/add',
      component: MedicalRecordAdd
    },
    {
      path:      '/medical-record/:id',
      component: MedicalRecord
    },
    {
      path:      '/medical-examination/:id',
      component: RunningMedicalExamination,
    },
    {
      name:      'MedicHeatmap',
      path:      '/medic-heatmap',
      component: MedicHeatmap,
    },

    // GOVERNMENT
    {
      path:      '/government/work-list',
      component: GovernmentWorkListView,
    },
    {
      path:      '/government/work-list/:id',
      component: GovernmentWorkListItem,
    },

    // SHARED PAGES
    {
      path:      '/intern-infos',
      component: InternInfos
    },
    {
      path:      '/intern-info/add',
      component: InternInfoEditor
    },
    {
      path:      '/intern-info/:id/edit',
      component: InternInfoEditor
    },
    {
      path:      '/intern-info/:id/edit-v2',
      component: InternInfoEditorV2
    },
    {
      path:      '/intern-info/:id',
      component: InternInfoView
    },
    {
      path:      '/intern-info/:id/pdf',
      component: InternInfoViewV2
    },

    {
      path:      '/appointments',
      component: Appointments
    },

    /* DASHBOARDS */
    {
      path:      '/dashboard-government',
      component: CriminalRecordEntryDashboard
    },
    {
      path:      '/dashboard-police',
      component: CriminalRecordEntryDashboard
    },
    {
      path:      '/dashboard-federal_intelligence_service',
      component: CriminalRecordEntryDashboard
    },
    {
      path:      '/dashboard-zoll',
      component: CriminalRecordEntryDashboard
    },
    {
      path:      '/dashboard-correctional',
      component: CriminalRecordEntryDashboard
    },
    {
      path:      '/dashboard-medic',
      component: MedicalRecordEntryDashboard
    },
    {
      path:      '/dashboard-admin',
      component: Appointments
    },
    {
      path:      '/dashboard-mechanic',
      component: Appointments
    },

    /* COMPANY */
    {
      name:      'CompanyListView',
      path:      '/companies',
      component: CompanyListView
    },
    {
      name:      'CompanyView',
      path:      '/company/new',
      component: CompanyCreateView
    },
    {
      name:      'CompanyView',
      path:      '/company/:id',
      component: CompanyView
    },

    /* FAST RECORDS */
    {
      name:      'FastRecords',
      path:      '/fast-records',
      component: FastRecordView
    },
    {
      name:      'TrafficSpeedTool',
      path:      '/traffic-speed-tool',
      component: TrafficSpeedTool
    },

    /* EINSATZBERICHTE */
    {
      path:      '/reports',
      component: MissionReportList
    },
    {
      path:      '/reports/add',
      component: MissionReportEditor
    },
    {
      path:      '/report/:id/edit',
      component: MissionReportEditor
    },
    {
      path:      '/report/:id/edit-asciidoc',
      component: MissionReportEditorAsciidoc
    },
    {
      path:      '/report/:id',
      component: MissionReportView
    },

    {
      path:      '/radio-infos',
      component: NotImplementedYet
    },
    {
      path:      '/employees',
      component: Employees
    },
    {
      path:      '/employees/add',
      component: EmployeeEdit
    },
    {
      path:      '/employee/:id',
      component: EmployeeEdit
    },
    {
      path:      '/employees/activity',
      component: UserActivityReport
    },
    {
      path:      '/employee/:id/activity',
      component: UserActivityReport
    },
    {
      path:      '/settings',
      component: Settings
    },
    {
      path:      '/courses',
      component: Courses
    },
    {
      path:      '/courses/add',
      component: CourseCreate
    },
    {
      path:      '/course/:id',
      component: CourseCreate
    },

    {
      path:      '/complaints',
      component: ComplaintOverview
    },
    {
      path:      '/complaint/:id',
      component: ComplaintView
    },

    {
      name:      'ControlCenter',
      path:      '/control-center',
      component: ControlCenterView
    },

    {
      name:      'PatrolUnits',
      path:      '/patrol-units',
      component: PatrolUnits
    },

    {
      name:      'TrafficControl',
      path:      '/traffic-control',
      component: TrafficControlView
    },

    {
      name:      'CrimeHeatmap',
      path:      '/crime-heatmap',
      component: CrimeHeatmap,
    },

    {
      name:      'CriminalFilesList',
      path:      '/criminal-files',
      component: CriminalFilesList
    },
    {
      name:      'CriminalFilesCreate',
      path:      '/criminal-files/create',
      component: CriminalFileEditor
    },
    {
      name:      'CriminalFilesCreateSecure',
      path:      '/criminal-files/create-secure',
      component: CriminalFileEditor
    },
    {
      name:      'CriminalFilesEditor',
      path:      '/criminal-file/:id/edit',
      component: CriminalFileEditor
    },
    {
      name:      'CriminalFilesView',
      path:      '/criminal-file/:id',
      component: CriminalFileView
    },

    {
      name:      'PersonDownDispatch',
      path:      '/medic-dispatches',
      component: PersonDownDispatches,
    },

    {
      name:      'DataListOverview',
      path:      '/data-lists',
      component: DataListOverview
    },
    {
      name:      'DataList',
      path:      '/data-list/:id',
      component: DataList
    },
    {
      name: 'LetterListView',
      path: '/letters',
      component: LetterListView
    },
    {
      name: 'LetterCreate',
      path: '/letter/create',
      component: LetterCreate,
      props: true,
    },
    {
      name: 'LetterView',
      path: '/letter/:letterId',
      component: LetterView
    },

    /* ADMIN AREA (INVOICE) */
    {
      name:      'AuditLog',
      path:      '/admin/audit-log',
      component: AuditLogViewer
    },
    {
      name:      'CreditInvoiceList',
      path:      '/admin/credit-invoices',
      component: CreditInvoiceList
    },
    {
      path:      '/admin/credit-invoice/:id',
      component: CreditInvoiceView
    },
    {
      path:      '/admin/tools/clothing',
      component: ClothingTool,
    },
    {
      path:      '/admin/tools/job-clothes',
      component: JobClothingTool,
    },
    {
      path:      '/admin/tools/inv-viewer',
      component: InventoryViewer
    },
  ]
})
<template>
  <v-data-table :loading="loading" :loader-height="5" :items="recordEntries" :headers="tableHeader"
                sort-by="createdAt" sort-desc>

    <template v-slot:item.createdAt="{ item }">
      {{ formatDate(item.createdAt) }}
    </template>

    <template v-slot:item.createdBy="{ item }">
        <span v-if="item.createdBy">
          <user-avatar :size="24" :user-obj="item.createdBy" cache class="mr-2"/>
          {{ item.createdBy.displayName }}
        </span>
      <span v-else>System</span>
    </template>

    <template v-slot:item.type="{ item }">
      {{ companyRecordEntryTypes[item.type] || item.type }}
    </template>

    <template v-slot:item.fine="{ item }">
      {{ formatMoney(item.fine || 0) }}
    </template>

    <template v-slot:item.actions="{ item }">
      <v-btn icon :to="'/company/' + item.id">
        <v-icon color="primary">mdi-eye-outline</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>
<script>
import {COMPANY_RECORD_ENTRY_TYPES} from "@/plugins/config";
import moment                                      from "moment";
import UserAvatar      from "@/components/UserAvatar.vue";

export default {
  components: {UserAvatar},
  data:       () => ({
    loading:       true,
    recordEntries: [],

    tableHeader: [
      {value: 'company.name', text: 'Unternehmen'},
      {value: 'type', text: 'Art'},
      {value: 'createdBy', text: 'Ersteller'},
      {value: 'fine', text: 'Betrag (in EUR)'},
      {value: 'createdAt', text: 'Erstelldatum'},
    ],
  }),
  computed:   {
    companyRecordEntryTypes() {
      return COMPANY_RECORD_ENTRY_TYPES;
    }
  },
  methods:    {
    formatDate(date) {
      return moment(date).format("D.MM.YYYY HH:mm:ss");
    }
  },
  mounted() {
    this.loading = true;
    this.apiGetTopCompanyRecordEntries().then(r => {
      this.recordEntries = r.data;
      this.loading       = false;
    })
  }
}
</script>
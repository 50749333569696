<template>
    <v-container fluid fill-height fill-width>
        <v-card height="100%" fill-height fill-width width="100%" class="pb-12">
            <v-card-title class="pb-2 pt-2">
                <v-icon left color="primary">mdi-map-marker-path</v-icon>
                Analyse Heatmap Unfälle / Einsätze
                <v-spacer/>
            </v-card-title>
            <v-divider/>

            <div style="z-index: 0;" id="map"></div>
        </v-card>
    </v-container>
</template>

<style>
#map {
    width: 100%;
    height: 100%;
}
</style>

<script>
import HeatmapLayer from '@/plugins/leaflet-heatmap';

import L      from 'leaflet';
import moment from "moment";

const center_x = 117.3;
const center_y = 172.8;
const scale_x  = 0.02072;
const scale_y  = 0.0205;

const CUSTOM_CRS = L.extend({}, L.CRS.Simple, {
    projection: L.Projection.LonLat,

    scale: function (zoom) {
        return Math.pow(2, zoom);
    },

    zoom: function (sc) {
        return Math.log(sc) / 0.6931471805599453;
    },

    distance: function (pos1, pos2) {
        let x_difference = pos2.lng - pos1.lng;
        let y_difference = pos2.lat - pos1.lat;
        return Math.sqrt(x_difference * x_difference + y_difference * y_difference);
    },

    transformation: new L.Transformation(scale_x, center_x, -scale_y, center_y),
    infinite:       true
});

export default {
    data:    () => ({
        blips:   {},
        map:     null,
        markers: [],
        groups:  {},

        heatmap: null,

        from: moment().subtract(1, 'month'),
        to:   moment(),

        editMode: false,

        markerIdx: 1,

        availableCategories: [],
    }),
    methods: {
        // lib
        leafletRemoveMarker(idx) {
            if (!this.markers[idx]) return;
            let marker = this.markers[idx];

            Object.keys(this.groups).forEach(key => {
                let g = this.groups[key];
                g.removeLayer(marker);
            })

            this.markers[idx] = null;
        },
        leafletGetLayer(name, enabled) {
            if (!this.groups[name]) {
                this.groups[name] = L.layerGroup();
                if (enabled) this.groups[name].addTo(this.map);
                this.map.layerControl.addOverlay(this.groups[name], name)
            }

            return this.groups[name];
        },
        leafletGetMarker(idx, coords, icon, options) {
            if (this.markers[idx]) {
                return this.markers[idx];
            }

            let markerOpts = Object.assign({}, options, {
                icon: icon
            });

            this.markers[idx] = L.marker(coords, markerOpts);

            return this.markers[idx];
        },
        leafletGetIcon(image) {
            if (this.blips[image]) return this.blips[image];

            let icon = null;

            if (image.startsWith('http')) {
                icon = L.icon({iconUrl: image, iconSize: [24, 24]})
            } else {
                icon = L.icon({iconUrl: this.apiImageUrl(image), iconSize: [24, 24]})
            }

            this.blips[image] = icon;

            return icon;
        },

        fetchMarkers() {
            let start = this.from.toISOString(true);
            let end   = this.to.toISOString(true);

            console.log(start, end);

            this.apiGetAllMedicHeatmapMarkers(start, end).then(r => {
                console.log(r.data);

                let coords = {};

                for (let data of r.data) {
                    coords[data.x]         = coords[data.x] || {};
                    coords[data.x][data.y] = (coords[data.x][data.y] || 0) + 1
                }

                let heatmapData = {
                    max:  10,
                    data: []
                };

                for (let x in coords) {
                    for (let y in coords[x]) {
                        heatmapData.data.push({
                            lat:   y,
                            lng:   x,
                            count: coords[x][y]
                        })
                    }
                }

                console.log(heatmapData);

                this.heatmap.setData(heatmapData);
            })
        },
    },
    async mounted() {
        const AtlasStyle = L.tileLayer(this.apiImageUrl('styleAtlas/{z}/{x}/{y}', 'jpg'), {
            minZoom:         0,
            maxZoom:         5,
            noWrap:          true,
            continuousWorld: false,
            attribution:     'Online map GTA V',
            id:              'atlasStyle map',
        });

        const GridStyle = L.tileLayer(this.apiImageUrl('styleGrid/{z}/{x}/{y}', 'png'), {
            minZoom:         0,
            maxZoom:         5,
            noWrap:          true,
            continuousWorld: false,
            attribution:     'Online map GTA V',
            id:              'gridStyle map',
        });

        const SatelliteStyle = L.tileLayer(this.apiImageUrl('styleSatellite/{z}/{x}/{y}', 'jpg'), {
            minZoom:         0,
            maxZoom:         5,
            noWrap:          true,
            continuousWorld: false,
            attribution:     'Online map GTA V',
            id:              'satelliteStyle map',
        });

        const PostalStyle = L.tileLayer(this.apiImageUrl('stylePostal/{z}/{x}/{y}', 'png'), {
            minZoom:         0,
            maxZoom:         5,
            noWrap:          true,
            continuousWorld: false,
            attribution:     'Online map GTA V',
            id:              'postalStyle map',
        });

        this.heatmap = new HeatmapLayer({
            // radius should be small ONLY if scaleRadius is true (or small radius is intended)
            // if scaleRadius is false it will be the constant radius used in pixels
            "radius":     4,
            "maxOpacity": .8,
            // scales the radius based on map zoom
            "scaleRadius": true,
            // if set to false the heatmap uses the global maximum for colorization
            // if activated: uses the data maximum within the current map boundaries
            //   (there will always be a red spot with useLocalExtremas true)
            "useLocalExtrema": true,
            // which field name in your data represents the latitude - default "lat"
            latField: 'lat',
            // which field name in your data represents the longitude - default "lng"
            lngField: 'lng',
            // which field name in your data represents the data value - default "value"
            valueField: 'count'
        });

        this.map = L.map('map', {
            crs:           CUSTOM_CRS,
            minZoom:       0,
            maxZoom:       5,
            maxNativeZoom: 5,
            preferCanvas:  true,
            layers:        [PostalStyle],
            center:        [0, 0],
            zoom:          2,
        });

        this.map.layerControl = L.control.layers({
            'Atlas':     AtlasStyle,
            'Satellite': SatelliteStyle,
            'Grid':      GridStyle,
            'Zip Code':  PostalStyle,
        }).addTo(this.map);

        this.map.layerControl.addOverlay(this.heatmap, 'Heatmap');
        this.heatmap.addTo(this.map);

        const testData = {
            max:  8,
            data: [{lat: 100, lng: 100, count: 3}, {lat: 50.75, lng: -1.55, count: 1}]
        };

        this.heatmap.setData(testData);

        //let marker = this.leafletGetMarker(1, [100, 100], this.leafletGetIcon('https://cdn-icons-png.flaticon.com/128/10370/10370534.png'));
        //let category = this.leafletGetLayer('Test', true);
        //category.addLayer(marker);

        this.fetchMarkers();
    },
}
</script>
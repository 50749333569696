<template>
  <v-container fluid>
    <v-card elevation="4" outlined>
      <v-card-title class="pb-2 pt-2">
        <v-icon left color="info">mdi-archive-star-outline</v-icon>
        Kriminalakten
        <v-spacer/>
        <v-text-field dense v-model="search" append-icon="mdi-magnify" label="Suche" single-line hide-details/>
      </v-card-title>

      <v-divider/>
      <v-card-text>
        <v-select solo dense outlined v-model="statusFilter" :items="[true, false]" item-value="key"
                  hint="Status Filter" persistent-hint>
          <template v-slot:selection="{item}">
            <v-btn x-small v-if="item" color="success" class="mr-2">Offen</v-btn>
            <v-btn x-small v-else-if="!item" color="warning" class="mr-2">Geschlossen</v-btn>
          </template>
          <template v-slot:item="{item}">
            <v-btn x-small v-if="item" color="success" class="mr-2">Offen</v-btn>
            <v-btn x-small v-else-if="!item" color="warning" class="mr-2">Geschlossen</v-btn>
          </template>
        </v-select>
      </v-card-text>

      <v-data-table :loading="loading" :headers="tblHeaders" :items="criminalFiles" item-key="id" sort-by="modifiedAt"
                    sort-desc
                    :search="search">
        <template v-slot:item.actions="{ item }">
          <v-btn icon color="primary" @click="openRecord(item)">
            <v-icon>mdi-eye-outline</v-icon>
          </v-btn>
          <v-btn icon color="error" @click="deleteRecord(item)"
                 v-if="user.orgAdmin || user.admin || user.flags.includes('DELETE_RECORD')">
            <v-icon>mdi-delete-forever-outline</v-icon>
          </v-btn>
          <v-btn icon color="info" @click="claimFile(item)" v-if="item.createdBy.id !== user.id">
            <v-icon>mdi-account-tie-hat-outline</v-icon>
          </v-btn>
          <v-btn icon color="success" @click="closeFile(item)" v-if="item.createdBy.id === user.id && !item.closed">
            <v-icon>mdi-archive-check-outline</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.closed="{ item }">
          <v-btn x-small v-if="item.closed" color="blue-grey" dark>GESCHLOSSEN</v-btn>
          <v-btn x-small v-else color="orange darken-3" dark>OFFEN</v-btn>
        </template>
        <template v-slot:item.modifiedAt="{ item }">
          {{ item.modifiedAt | datetime }}
        </template>
        <template v-slot:item.number="{ item }">
          <v-icon v-if="item.requiredSecurityLevel" title="Sichere Akte" left color="error">mdi-star-check-outline</v-icon>
          {{ item.number }}
        </template>
      </v-data-table>
      <v-divider/>
      <v-card-actions>
        <v-btn small color="primary" elevation="3" to="/criminal-files/create">
          <v-icon left small>mdi-plus</v-icon>
          Akte Erstellen
        </v-btn>
        <v-btn small color="error" to="/criminal-files/create-secure" v-if="userHasSecurityLevel('HIGH_CRIMINAL_OFFICER')">
          <v-icon left small>mdi-plus</v-icon>
          Sichere Akte erstellen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import Swal from 'sweetalert2';

export default {
  data: () => ({
    search: '',
    loading: false,

    statusFilter: true,

    tblHeaders: [
      {text: 'Aktenzeichen', value: 'number'},
      {text: 'Status', value: 'closed'},
      {text: 'Beamten', value: 'createdBy.displayName'},
      {text: 'Ermittlungstitel', value: 'subject'},
      {text: 'Zuletzt verändert am', value: 'modifiedAt'},
      {text: 'Aktionen', value: 'actions', sortable: false}
    ],
    records: [],
  }),
  computed: {
    criminalFiles() {
      let criminalFiles = [];

      for (let criminalFile of this.records) {
        if (this.statusFilter === true && !criminalFile.closed) {
          criminalFiles.push(criminalFile);
        } else if (this.statusFilter === false && criminalFile.closed) {
          criminalFiles.push(criminalFile);
        }
      }

      return criminalFiles;
    }
  },
  methods: {
    openRecord(record) {
      this.$router.push('/criminal-file/' + record.id);
    },
    claimFile(record) {
      this.apiClaimCriminalFile(record.id).then(() => {
        this.fetchRecords();
      })
    },
    closeFile(record) {
      this.apiCloseCriminalFile(record.id).then(() => {
        this.fetchRecords();
      })
    },
    deleteRecord(record) {
      Swal.fire({
        title: 'Bist du dir sicher?',
        html: 'Du kannst diese Aktion nicht rückgängig machen. Möchtest du den Eintrag "<b>' + record.number + '</b>" wirklich löschen?',
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: `Löschen`,
        confirmButtonColor: '#d73939',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          Swal.showLoading();
          this.apiDeleteCriminalFile(record.id).then(() => {
            Swal.hideLoading();
            Swal.fire('Erfolgreich!', 'Der Eintrag wurde gelöscht.', 'success')
            this.fetchRecords();
          }).catch(err => {
            Swal.hideLoading();
            Swal.fire('Fehler', 'Der Eintrag konnte nicht gelöscht werden:<br>' + err.response.statusCode, 'error');
          })
        }
      })
    },
    fetchRecords() {
      this.loading = true;

      this.apiGetAllCriminalFiles().then(r => {
        this.records = r.data
      }).finally(() => {
        this.loading = false;
      });
    }
  },
  mounted() {
    this.fetchRecords();
  }
}
</script>
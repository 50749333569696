<template>
    <v-container fluid>
        <v-card elevation="4" outlined>
            <v-card-title>
                <v-icon left color="error">mdi-hospital</v-icon>
                Patientenakten
                <v-spacer/>
                <v-row>
                    <v-col>
                        <v-select dense solo v-model="searchStatus"
                                  :items="[{value: 'ALIVE', text: 'Lebend'}, {value: 'DEAD', text: 'Verstorben'}, {value: 'MISSING', text: 'Vermisst'}, {value: 'BURIED', text: 'An Regierung übergeben / begraben'}]"
                                  item-text="text" prepend-icon="mdi-delta" multiple hide-details
                                  item-value="value" placeholder="Alle">
                        </v-select>
                    </v-col>
                    <v-col>
                        <v-text-field dense solo v-model="search" append-icon="mdi-magnify" label="Suche" single-line hide-details prepend-icon="mdi-text"/>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-divider/>

            <v-data-table :loading="loading" :headers="tblHeaders" :items="filteredItems" item-key="id" sort-by="wanted" sort-desc>
                <template v-slot:item.actions="{ item }">
                    <v-btn icon color="primary" @click="openRecord(item)" v-if="user.trustLevel >= item.trustLevel">
                        <v-icon>mdi-eye-outline</v-icon>
                    </v-btn>
                    <v-btn icon color="error" @click="deleteRecord(item)"
                           v-if="user.orgAdmin || user.admin || user.flags.includes('DELETE_RECORD')">
                        <v-icon>mdi-delete-forever-outline</v-icon>
                    </v-btn>
                </template>
                <template v-slot:item.createdAt="{ item }">
                    {{ item.createdAt.substr(0, 10) }}
                </template>
                <template v-slot:item.gender="{ item }">
                    <v-icon v-if="item.gender === 'FEMALE'" color="pink">mdi-gender-female</v-icon>
                    <v-icon v-if="item.gender === 'MALE'" color="primary lighten-2">mdi-gender-male</v-icon>
                    <v-icon v-if="item.gender == null" color="warning darken-1">mdi-gender-male-female</v-icon>
                </template>
                <template v-slot:item.status="{ item }">
                    <v-icon v-if="item.status === 'DEAD'" color="pink">mdi-heart-broken-outline</v-icon>
                    <v-icon v-if="item.status === 'BURIED'" color="blue-grey lighten-2">mdi-grave-stone</v-icon>
                    <v-icon v-if="item.status === 'ALIVE'" color="green darken-1">mdi-pulse</v-icon>
                    <v-icon v-if="item.status === 'MISSING'" color="warning darken-1">mdi-account-question-outline</v-icon>
                    <v-icon v-if="item.status == null" color="green darken-1">mdi-pulse</v-icon>
                </template>
            </v-data-table>
            <v-divider/>
            <v-card-actions>
                <v-btn small color="success" elevation="3" to="/medical-record/add">
                    <v-icon left small>mdi-plus</v-icon>
                    Akte Erstellen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
import Swal from 'sweetalert2';

export default {
    data:     () => ({
        search:       '',
        searchStatus: [],
        loading:      false,

        tblHeaders: [
            {text: 'Name des Bürger', value: 'name'},
            {text: 'Geburtsdatum', value: 'birthday'},
            {text: 'Blutgruppe', value: 'bloodType'},
            {text: 'Geschlecht', value: 'gender'},
            {text: 'Status', value: 'status'},
            {text: 'Aktenkundig seit', value: 'createdAt'},
            {text: 'Aktionen', value: 'actions', sortable: false}
        ],
        records:    [],
    }),
    computed: {
        filteredItems() {
            let items = [];

            for (let obj of this.records) {
                let cond1 = this.search && obj.name.toLowerCase().includes(this.search.toLowerCase());
                let cond2 = this.searchStatus && this.searchStatus.includes(obj.status || 'ALIVE');

                if (this.search.length > 0 && this.searchStatus.length > 0) {
                    if (cond1 && cond2) items.push(obj);
                } else if (this.search.length > 0) {
                    if (cond1) items.push(obj);
                } else if (this.searchStatus.length > 0) {
                    if (cond2) items.push(obj);
                } else {
                    items.push(obj);
                }
            }

            return items;
        }
    },
    methods:  {
        openRecord(record) {
            this.$router.push('/medical-record/' + record.id);
        },
        deleteRecord(record) {
            Swal.fire({
                title:              'Bist du dir sicher?',
                html:               'Du kannst diese Aktion nicht rückgängig machen. Möchtest du den Eintrag "<b>' + record.name + '</b>" wirklich löschen?',
                showDenyButton:     false,
                showCancelButton:   true,
                confirmButtonText:  `Löschen`,
                confirmButtonColor: '#d73939',
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    Swal.showLoading();
                    this.apiDeleteMedicalRecord(record.id).then(() => {
                        Swal.hideLoading();
                        Swal.fire('Erfolgreich!', 'Der Eintrag wurde gelöscht.', 'success')
                        this.fetchRecords();
                    }).catch(err => {
                        Swal.hideLoading();
                        Swal.fire('Fehler', 'Der Eintrag konnte nicht gelöscht werden:<br>' + err.response.statusCode, 'error');
                    })
                }
            })
        },
        fetchRecords() {
            this.loading = true;

            this.apiGetMedicalRecords().then(r => {
                this.records = r.data
            }).finally(() => {
                this.loading = false;
            });
        }
    },
    mounted() {
        this.fetchRecords();
    }
}
</script>
<template>
    <div>
        <v-container fluid>
            <v-card elevation="4" tile v-if="!company">
                <v-skeleton-loader type="card"></v-skeleton-loader>
            </v-card>
            <v-card elevation="4" tile v-else>
                <v-card-title class="pa-2 pl-4">
                    <v-icon left color="primary">mdi-chair-rolling</v-icon>
                    {{ company.name }}
                </v-card-title>
                <v-divider/>
                <v-card-text>
                    <div class="d-flex">
                        <div class="pa-3 hidden-sm-and-down">
                            <div class="company-file-image-background pa-3 mt-3 elevation-15">
                                <v-img :src="require('../../assets/paperclip.png')"
                                       class="criminal-file-image-paperclip"/>
                                <v-img
                                    :src="company.picture === null ? require('../../assets/company.png') : apiAttachmentUrl(company.picture)"
                                    height="320" width="320" class="rounded"/>
                            </div>
                        </div>
                        <div class="flex-fill pa-3">
                            <v-row>
                                <v-col sm="12" md="4" lg="4" xl="2" cols="12">
                                    <v-text-field v-model="company.iid" type="number" solo dense outlined hint="ID"
                                                  persistent-hint
                                                  prepend-inner-icon="mdi-identifier"/>
                                </v-col>
                                <v-col sm="12" md="4" lg="4" xl="8" cols="12">
                                    <v-text-field v-model="company.name" solo dense outlined hint="Name" persistent-hint
                                                  prepend-inner-icon="mdi-id-card"/>
                                </v-col>
                                <v-col sm="12" md="4" lg="4" xl="2" cols="12">
                                    <v-select solo dense outlined hint="Art" persistent-hint v-model="company.type"
                                              :items="companyTypes" item-text="label" item-value="type"
                                              prepend-inner-icon="mdi-smart-card-reader-outline"/>
                                </v-col>

                                <v-col sm="12" md="12" lg="6" xl="6" cols="12">
                                    <custom-date-picker :datetime="datePickerValue(company.nextPaymentLicenses)"
                                                        :text-field-props="{'color': 'info', 'outlined': true, 'dense': true, 'prependInnerIcon': 'mdi-calendar', 'hint': 'Nächster Zahlungsintervall', 'persistentHint': true}"
                                                        :time-picker-props="{'format': '24hr'}"
                                                        v-on:input="modifyEvent_nextPaymentLicenses"/>
                                </v-col>
                                <v-col sm="12" md="12" lg="6" xl="6" cols="12">
                                    <custom-date-picker :datetime="datePickerValue(company.vacatedUntil)"
                                                        :text-field-props="{'color': 'info', 'outlined': true, 'dense': true, 'prependInnerIcon': 'mdi-beach', 'hint': 'Urlaub bis', 'persistentHint': true}"
                                                        :time-picker-props="{'format': '24hr'}"
                                                        v-on:input="modifyEvent_vacatedUntil"/>
                                </v-col>

                                <v-col sm="12" md="12" lg="4" xl="4" cols="12">
                                    <v-text-field v-model="company.location" solo dense outlined hint="Adresse"
                                                  persistent-hint
                                                  prepend-inner-icon="mdi-home-city"/>
                                </v-col>
                                <v-col sm="12" md="12" lg="4" xl="4" cols="12">
                                    <v-text-field v-model="company.iban" solo dense outlined hint="IBAN" persistent-hint
                                                  prepend-inner-icon="mdi-credit-card-outline"/>
                                </v-col>
                                <v-col sm="12" md="12" lg="4" xl="4" cols="12">
                                    <v-text-field v-model="company.propertyStatus" solo dense outlined
                                                  hint="Zustand des Gebäude" persistent-hint
                                                  prepend-inner-icon="mdi-glass-fragile"/>
                                </v-col>
                                <v-col sm="12" md="12" lg="12" xl="12" cols="12">
                                    <v-card elevation="2" outlined>
                                        <v-list nav dense>
                                            <v-autocomplete v-model="company.owner" :items="criminals" return-object
                                                            item-text="name" clearable
                                                            solo dense outlined hint="Besitzer" persistent-hint
                                                            prepend-inner-icon="mdi-account"/>
                                            <v-divider class="pb-2 mt-2"/>
                                            <v-list-item @click="test"
                                                         v-for="(v, k) in company.authorizedRepresentatives" :key="k">
                                                <v-list-item-icon>
                                                    <v-icon>mdi-account-tag-outline</v-icon>
                                                </v-list-item-icon>

                                                <v-list-item-content>
                                                    <v-list-item-title v-text="v.name"></v-list-item-title>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    <v-btn x-small color="error" @click="removeRepresentative(v)">
                                                        <v-icon left small>mdi-delete-empty-outline</v-icon>
                                                        Löschen
                                                    </v-btn>
                                                </v-list-item-action>
                                            </v-list-item>
                                            <v-list-item v-if="company.authorizedRepresentatives.length === 0">
                                                <v-list-item-content>
                                                    <i>Kein Vertreter</i>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                        <v-divider/>
                                        <v-card-actions>
                                            <v-btn small color="primary" @click="addRepresentativeModal = true;">
                                                <v-icon left>mdi-account-plus</v-icon>
                                                Neuen Vertreter Hinzufügen
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="warning" small :loading="saving" :disabled="dirtyFields.length === 0"
                           @click="updateCompany()">
                        Speichern
                    </v-btn>
                </v-card-actions>
                <v-divider/>
                <v-tabs color="warning" v-model="tabIndex">
                    <v-tab>Einträge</v-tab>
                    <v-tab>Strikes</v-tab>
                    <v-tab>Access Tokens</v-tab>
                </v-tabs>
                <v-divider/>
                <v-card-text v-if="tabIndex === 0">
                    <v-row v-for="entry in company.entries" :key="entry.id">
                        <v-col class="pa-0">
                            <company-record-entry v-on:updated="fetchCompany" :company="company"
                                                  :entry="entry"/>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-text v-if="tabIndex === 1">
                    Strikes
                </v-card-text>
                <div v-if="tabIndex === 2">
                    <v-data-table :items="company.accessTokens" :headers="accessTokenHeaders">
                        <template v-slot:item.actions="{ item }">
                            <v-btn small icon color="error" @click="revokeAccessToken(item.id)">
                                <v-icon>mdi-delete-variant</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                    <v-divider/>
                    <v-card-actions>
                        <v-btn small color="primary">Zugriffstoken erstellen</v-btn>
                    </v-card-actions>
                </div>
            </v-card>
        </v-container>

        <v-dialog v-model="addRepresentativeModal" @close="addRepresentativeModal=false;" width="500">
            <v-card>
                <v-card-title class="pb-2 pt-2">
                    <v-icon left>mdi-account-plus</v-icon>
                    Vertreter hinzufügen
                </v-card-title>
                <v-divider/>
                <v-card-text class="pb-0 mt-4">
                    <v-autocomplete v-model="addRepresentativeRecord" :items="criminals" return-object
                                    item-text="name" clearable
                                    solo dense outlined hint="Vertreter" persistent-hint
                                    prepend-inner-icon="mdi-account"/>
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-btn color="primary" small @click="addRepresentative()">
                        <v-icon left>mdi-content-save</v-icon>
                        Hinzufügen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<style>
.company-file-image-background {
    background-color: #838486;
    border-radius: 15px;
    transform: rotate(-3deg);
}
</style>

<!--suppress EqualityComparisonWithCoercionJS -->
<script>

import moment             from "moment";
import CustomDatePicker   from "@/components/CustomDatePicker.vue";
import CompanyRecordEntry from "@/components/CompanyRecordEntry.vue";
import {COMPANY_TYPES}    from "../../plugins/config";
import Swal               from 'sweetalert2';

export default {
    components: {CompanyRecordEntry, CustomDatePicker},
    data:       () => ({
        companyId:         null,
        company:           null,
        companyUnmodified: null,
        saving:            false,

        tabIndex: 0,

        dirtyFields: [],

        addRepresentativeModal:  false,
        addRepresentativeRecord: null,

        accessTokenHeaders: [
            {text: 'ID', value: 'id'},
            {text: 'Token', value: 'token'},
            {text: 'Ersteller', value: 'createdBy.displayName'},
            {text: 'Aktionen', value: 'actions'},
        ],

        criminals: [],
    }),
    computed:   {
        companyTypes() {
            let obj = [];

            for (let typeName in COMPANY_TYPES) {
                obj.push({type: typeName, label: COMPANY_TYPES[typeName]})
            }
            return obj;
        }
    },
    methods:    {
        test() {
        },
        updateCompany() {
            this.saving = true;

            Swal.fire({
                title:             'Update',
                html:              'Die Felder ' + (this.dirtyFields.toString()) + ' wurden geändert. Möchtest du diese wirklich aktualisieren?',
                showCancelButton:  true,
                cancelButtonText:  'Abbrechen',
                confirmButtonText: 'Speichern',
            }).then(r => {
                if (r.isConfirmed) {
                    let data = {};
                    for (let fieldKey of this.dirtyFields) {
                        data[fieldKey] = this.company[fieldKey];
                    }
                    this.apiUpdateCompany(this.companyId, data).then(() => {
                        this.fetchCompany().finally(() => {
                            this.saving = false;
                        });
                    })
                } else {
                    this.saving = false;
                }
            })
        },
        fetchCompany() {
            return this.apiGetCompanyById(this.companyId).then(r => {
                this.company = r.data;

                this.company.entries = this.company.entries.sort((b, a) => {
                    return moment(a.createdAt).diff(b.createdAt);
                })

                this.companyUnmodified = JSON.parse(JSON.stringify(this.company));
            })
        },
        fetchCriminals() {
            return this.apiGetCriminalRecords().then(r => {
                this.criminals = r.data;
            })
        },
        modifyEvent_nextPaymentLicenses(val) {
            if (val) {
                this.company.nextPaymentLicenses = moment(val).startOf('d').add(18, 'h').toISOString(true);
            } else {
                this.company.nextPaymentLicenses = null;
            }
            console.log(this.company.nextPaymentLicenses);
        },
        modifyEvent_vacatedUntil(val) {
            if (val) {
                this.company.vacatedUntil = moment(val).endOf('d').toISOString(true);
            } else {
                this.company.vacatedUntil = null;
            }
        },
        datePickerValue(val) {
            if (!val) {return null; }
            let date = moment(val);
            console.log(date.toISOString(true))
            return date.toISOString(true).substring(0, 10);
        },
        removeRepresentative(rep) {
            this.saving = true;
            this.apiCompanyRemoveRepresentative(this.company, rep).then(() => {
                return this.fetchCompany();
            }).finally(() => {
                this.saving = false;
            })
        },
        addRepresentative() {
            this.saving = true;
            this.apiCompanyAddRepresentative(this.company, this.addRepresentativeRecord).then(() => {
                return this.fetchCompany();
            }).finally(() => {
                this.addRepresentativeRecord = null;
                this.addRepresentativeModal  = false;
                this.saving                  = false;
            })
        }
    },
    mounted() {
        this.companyId = this.$route.params.id;
        this.fetchCompany();
        this.fetchCriminals();
    },
    watch: {
        company: {
            deep:    true,
            handler: function (val, oldVal) {
                if (val && oldVal) {
                    for (let valKey in val) {
                        if (['strikes', 'accessTokens', 'authorizedRepresentatives', 'createdBy', 'entries', 'licenseLinks'].includes(valKey)) continue;

                        if (valKey === 'owner') {
                            let oOwner = this.companyUnmodified[valKey];
                            let nOwner = val[valKey];

                            if (oOwner?.id !== nOwner?.id) {
                                this.dirtyFields.push(valKey);
                            } else if (this.dirtyFields.includes(valKey)) {
                                this.dirtyFields.splice(this.dirtyFields.indexOf(valKey), 1);
                            }
                            continue;
                        }

                        let newVal  = val[valKey];
                        let origVal = this.companyUnmodified[valKey];

                        if (newVal != origVal && !this.dirtyFields.includes(valKey)) {
                            this.dirtyFields.push(valKey);
                        } else if (newVal == origVal && this.dirtyFields.includes(valKey)) {
                            this.dirtyFields.splice(this.dirtyFields.indexOf(valKey), 1);
                        }
                    }
                }
            }
        }
    }
}
</script>
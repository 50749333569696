<template>
    <v-container fluid>
        <v-card elevation="4" outlined v-if="report && report.type === 'COMPLAINT'">
            <v-card-title class="pb-2 pt-2 pl-3">
                <v-icon left color="error">mdi-alert-rhombus-outline</v-icon>
                Beschwerde {{ report.id }}
            </v-card-title>
            <v-divider/>
            <v-card-text>
                <p>Eine Beschwerde gegen <b>{{ report.targetUser.displayName }}</b> als <i>{{ report.currentTargetUserRank }}</i> wurde mit folgender Begründung eingelegt:</p>
                <v-divider class="mt-3 pb-4"/>
                <v-textarea rows="15" v-model="report.content" :readonly="report.sourceUser.id !== user.id || report.status === 'CLOSED'" solo dense outlined hint="Beschwerde" persistent-hint/>
            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-btn small color="info" dark @click="$router.push('/complaints')">
                    <v-icon left>mdi-chevron-left</v-icon>
                    Zurück
                </v-btn>
                <v-btn small color="success" v-if="report.sourceUser.id === user.id && report.status === 'OPEN'" dark @click="updateComplaint(report)">
                    <v-icon left>mdi-content-save</v-icon>
                    Aktualisieren
                </v-btn>
                <v-spacer/>
                <v-btn small color="warning" dark @click="setStatusOfReport(report, 'CLOSED')" v-if="report.status !== 'CLOSED'">
                    <v-icon left>mdi-close</v-icon>
                    Abschließen
                </v-btn>
                <v-btn small color="error" dark @click="deleteReport(report)" v-if="user.admin">
                    <v-icon left>mdi-delete-alert-outline</v-icon>
                    Löschen
                </v-btn>
                <v-btn small color="warning" dark @click="setStatusOfReport(report, 'OPEN')" v-if="report.status === 'CLOSED'">
                    <v-icon left>mdi-lock-open-outline</v-icon>
                    Re-open
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-card elevation="4" outlined v-else-if="report && report.type === 'RECOMMENDATION'">
            <v-card-title class="pb-2 pt-2 pl-3">
                <v-icon left color="yellow darken-1">mdi-star-shooting-outline</v-icon>
                Empfehlung {{ report.id }}
            </v-card-title>
            <v-divider/>
            <v-card-text>
                <p>Der/Die Beamte:in <b>{{ report.targetUser.displayName }}</b> als <i>{{ report.currentTargetUserRank }}</i> wurde mit folgender Begründung empfohlen:</p>
                <v-divider class="mt-3 pb-4"/>
                <v-textarea v-model="report.content" :readonly="report.sourceUser.id !== user.id || report.status === 'CLOSED'" solo dense hint="Beschwerde" persistent-hint/>
            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-btn small color="info" dark @click="$router.push('/complaints')">
                    <v-icon left>mdi-chevron-left</v-icon>
                    Zurück
                </v-btn>
                <v-btn small color="success" v-if="report.sourceUser.id === user.id && report.status === 'OPEN'" dark @click="updateComplaint(report)">
                    <v-icon left>mdi-content-save</v-icon>
                    Aktualisieren
                </v-btn>
                <v-spacer/>
                <v-btn small color="warning" dark @click="setStatusOfReport(report, 'CLOSED')" v-if="report.status !== 'CLOSED'">
                    <v-icon left>mdi-close</v-icon>
                    Abschließen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
//import Swal from 'sweetalert2';

export default {
    data:     () => ({
        report: null,
    }),
    computed: {},
    methods:  {
        fetchComplaint(id) {
            this.apiGetComplaint(id).then(r => {
                this.report = r.data;
            })
        },
        setStatusOfReport(complaint, newStatus) {
            complaint.status = newStatus
            this.apiUpdateComplaint(complaint.id, {
                status: complaint.status,
            })
        },
        updateComplaint(complaint) {
            this.apiUpdateComplaint(complaint.id, {
                content: complaint.content,
            })
        },
        deleteReport(complaint) {
            this.apiDeleteComplaint(complaint.id).then(() => {
                this.$router.push('/complaints')
            })
        }
    },
    mounted() {
        if (this.$route.params.id) {
            this.fetchComplaint(this.$route.params.id);
        } else {
            this.$router.push('/complaints');
        }
    }
}
</script>
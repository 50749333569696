<template>
  <v-container fluid>
    <v-card elevation="4" outlined>
      <v-card-title>
        <v-icon left>mdi-card-account-details-outline</v-icon>
        Vorstrafenregister - Akte Anlegen
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-row>
          <v-col class="pt-4 pb-1">
            <v-text-field v-model="name" label="Name des Bürgers" dense single-line counter prepend-icon="mdi-account"/>
          </v-col>
          <v-col class="pt-4 pb-1">
            <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="birthday"
                    transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field dense v-model="birthday" label="Geburtsdatum" prepend-icon="mdi-calendar" readonly
                              v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="birthday" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(birthday)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-select dense :items="[{value: 'MALE', text: 'Männlich'}, {value: 'FEMALE', text: 'Weiblich'}]"
                      item-text="text" prepend-icon="mdi-gender-male-female"
                      item-value="value" v-model="gender"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0 pb-1">
            <v-text-field v-model="org" label="Organisation" dense prepend-icon="mdi-account-multiple"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0 pb-1">
            <v-text-field v-model="phoneNumber" label="Telefonnummer" dense prepend-icon="mdi-cellphone-basic"/>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn small color="primary" @click="addRecord">
          <v-icon left>mdi-check</v-icon>
          Akte Anlegen
        </v-btn>
        <v-spacer/>
        <v-btn small color="blue-grey" to="/criminal-records">
          Abbrechen
        </v-btn>
      </v-card-actions>

      <v-divider v-if="error"/>
      <v-card-text v-if="error" class="pb-0">
        <v-alert type="error" dense outlined elevation="4" border="left">
          {{ error }}
        </v-alert>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data:    () => ({
    name:        '',
    birthday:    '1997-01-01',
    org:         '',
    address:     '',
    phoneNumber: '',
    gender:      'MALE',

    trustLevel: 0,

    error: null,

    menu: false,
  }),
  methods: {
    addRecord() {
      this.error = null;

      if (this.name.length < 3) {
        this.error = 'Name des Bürgers darf nicht weniger als 3 Zeichen enthalten.';
      } else if (!this.name.includes(' ')) {
        this.error = 'Name des Bürgers muss mindestens ein Leerzeichen enthalten.';
      } else {
        this.apiCreateCriminalRecord({
          name:              this.name,
          birthday:          this.birthday,
          gender:            this.gender,
          trustLevel:        this.trustLevel,
          organization:      this.org,
          phoneNumber:       this.phoneNumber,
          address:           this.address,
        }).then(() => {
          this.$router.push('/criminal-records');
        })
      }
    }
  }
}
</script>
<template>
    <v-dialog v-model="display" :width="dialogWidth" persistent>
        <template v-slot:activator="{ on }">
            <v-text-field
                v-bind="textFieldProps"
                :disabled="disabled"
                :loading="loading"
                :label="label"
                :value="formattedDatetime"
                v-on="on"
                readonly
            >
                <template v-slot:progress>
                    <slot name="progress">
                        <v-progress-linear color="primary" indeterminate absolute height="2"></v-progress-linear>
                    </slot>
                </template>
            </v-text-field>
        </template>

        <v-card>
            <v-card-text class="px-0 py-0">
                <v-date-picker v-model="date" v-bind="datePickerProps" @input="showTimePicker" full-width></v-date-picker>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <slot name="actions" :parent="this">
                    <v-btn color="grey lighten-1" text @click.native="clearHandler">{{ clearText }}</v-btn>
                    <v-btn color="green darken-1" text @click="okHandler">{{ okText }}</v-btn>
                </slot>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {format, parse} from 'date-fns'

const DEFAULT_DATE         = ''
const DEFAULT_TIME         = '00:00:00'
const DEFAULT_DATE_FORMAT  = 'yyyy-MM-dd'
const DEFAULT_DIALOG_WIDTH = 340
const DEFAULT_CLEAR_TEXT   = 'CLEAR'
const DEFAULT_OK_TEXT      = 'OK'

export default {
    model: {
        prop:  'datetime',
        event: 'input'
    },
    props: {
        datetime:        {
            type:    [Date, String],
            default: null
        },
        disabled:        {
            type: Boolean
        },
        loading:         {
            type: Boolean
        },
        label:           {
            type:    String,
            default: ''
        },
        dialogWidth:     {
            type:    Number,
            default: DEFAULT_DIALOG_WIDTH
        },
        dateFormat:      {
            type:    String,
            default: DEFAULT_DATE_FORMAT
        },
        clearText:       {
            type:    String,
            default: DEFAULT_CLEAR_TEXT
        },
        okText:          {
            type:    String,
            default: DEFAULT_OK_TEXT
        },
        textFieldProps:  {
            type: Object
        },
        datePickerProps: {
            type: Object
        }
    },
    data() {
        return {
            display:   false,
            activeTab: 0,
            date:      DEFAULT_DATE
        }
    },
    mounted() {
        this.init()
    },
    computed: {
        dateTimeFormat() {
            return this.dateFormat
        },
        defaultDateTimeFormat() {
            return DEFAULT_DATE_FORMAT
        },
        formattedDatetime() {
            return this.selectedDatetime ? format(this.selectedDatetime, this.dateTimeFormat) : ''
        },
        selectedDatetime() {
            if (this.date) {
                let datetimeString = this.date
                return parse(datetimeString, this.defaultDateTimeFormat, new Date())
            } else {
                return null
            }
        },
        dateSelected() {
            return !this.date
        }
    },
    methods:  {
        init() {
            if (!this.datetime) {
                return
            }

            let initDateTime
            if (this.datetime instanceof Date) {
                initDateTime = this.datetime
            } else if (typeof this.datetime === 'string' || this.datetime instanceof String) {
                // see https://stackoverflow.com/a/9436948
                let date = this.datetime.substring(0, this.dateTimeFormat.length);
                initDateTime = parse(date, this.dateTimeFormat, new Date())
            }

            this.date = format(initDateTime, DEFAULT_DATE_FORMAT)
        },
        okHandler() {
            this.resetPicker()
            this.$emit('input', this.formattedDatetime)
        },
        clearHandler() {
            this.resetPicker()
            this.date = DEFAULT_DATE
            this.time = DEFAULT_TIME
            this.$emit('input', null)
        },
        resetPicker() {
            this.display   = false
            this.activeTab = 0
            if (this.$refs.timer) {
                this.$refs.timer.selectingHour = true
            }
        },
        showTimePicker() {
            this.activeTab = 1
        }
    },
    watch:    {
        datetime: function () {
            this.init()
        }
    }
}
</script>

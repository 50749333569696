import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex);

const vuexSession = new VuexPersistence({
    storage: window.localStorage
})

export default new Vuex.Store({
    state:     {
        user:     null,
        darkMode: false,
        timeBasedDarkMode: false,

        legacyViews: false,

        previewFeatures: false,

        hiddenCriminalRecordTypes: [],

        autoCloseRecord: false,

        applicationId: null,

        websocketSessionId: null,

        passwordComplexity: 0,

        discordName: null,
        discordId: null,
    },
    mutations: {
        update(state, obj) {
            Object.keys(obj).forEach(key => {
                state[key] = obj[key]
            });
        }
    },
    plugins:   [vuexSession.plugin]
})
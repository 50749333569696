<template>
    <v-alert outlined elevation="2" dense border="left" class="pa-0" :color="entryEntryColor(entry)">
        <v-card-text :style="$vuetify.theme.dark ? 'color: white !important;' : 'color: black !important;'">
            <v-row>
                <v-col class="pt-1 pb-0" cols="3">
                    <b>Ersteller:</b> {{ entry.createdBy.displayName }}<br/>
                    <b>Datum:</b> {{ entry.createdAt | datetime }}

                    <div v-if="entry.zipCode">
                        <b>Postleitzahl: </b> {{ entry.zipCode }}<br>
                    </div>
                </v-col>
                <v-col class="pl-2 pt-1 pb-0">
                    <div v-html="mdToHtml(entry.content)"></div>
                    <div v-if="entry.discarded">
                        <b>Eintrag wurde verworfen. Begründung:</b>
                        <div v-html="mdToHtml(entry.discardedReason)"></div>
                    </div>
                </v-col>
                <v-col cols="2" class="text-right pt-1 pb-0 pr-1">
                    <div v-if="entry.entryType === 'FINDING'">Medizinischer Befund</div>
                    <div v-if="entry.entryType === 'RECORD_CHANGE'">Aktenänderung</div>
                    <div v-if="entry.entryType === 'OP'">Operation</div>
                    <div v-if="entry.entryType === 'ACCIDENT'">Unfall</div>
                    <div v-if="entry.entryType === 'REPORT'">Meldung</div>
                    <div v-if="entry.entryType === 'NOTE'">Anmerkung / Notiz</div>
                    <div v-if="entry.entryType === 'MALNUTRITION'">Mangelernährung</div>
                    <div v-if="entry.entryType === 'MPE'">MPU</div>

                    <div style="position: absolute; bottom: 4px; right: 4px;">
                        <v-btn x-small icon color="warning" @click="openEditor()" v-if="((user.admin || user.orgAdmin || user.flags.includes('DELETE_RECORD_ENTRY')) || (entry.createdBy.id === user.id && isYoungEnough())) && !['RECORD_CHANGE', 'MPU'].includes(entry.entryType)">
                            <v-icon small>mdi-pencil-outline</v-icon>
                        </v-btn>
                        <v-btn x-small icon color="error"
                               v-if="user.admin || user.orgAdmin || user.flags.includes('DELETE_RECORD_ENTRY')"
                               @click="deleteEntry()">
                            <v-icon>mdi-delete-forever-outline</v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
    </v-alert>
</template>

<script>
import markdown from "markdown-it";
import Swal     from "sweetalert2";
import moment   from "moment";

export default {
    props:   ['entry', 'record'],
    methods: {
        openEditor() {
            this.$emit('edit', this.entry, this.record, this.isYoungEnough());
        },
        isYoungEnough() {
            return moment().isBefore(moment(this.entry.createdAt).add(2, 'hours'));
        },
        mdToHtml(markdownText) {
            if (markdownText == null || markdownText.trim().length === 0) {
                return '<i>Keine Informationen angegeben.</i>'
            }

            return markdown({breaks: true}).render(markdownText);
        },
        deleteEntry() {
            Swal.fire({
                title:             'Eintrag endgültig löschen?',
                showCancelButton:  true,
                showConfirmButton: true,
                confirmButtonText: 'Ja',
                cancelButtonText:  'Nein',
            }).then(r => {
                if (r.isConfirmed) {
                    this.apiDeleteMedicalRecordEntry(this.record.id, this.entry.id).then(() => this.$emit('updated'));
                }
            })
        },
        entryEntryColor(entry) {
            if (entry.entryType === 'FINDING') return 'info';
            if (entry.entryType === 'REPORT') return 'info';
            if (entry.entryType === 'ACCIDENT') return 'warning';
            if (entry.entryType === 'OP') return 'error';
            if (entry.entryType === 'RECORD_CHANGE') return 'success';
            if (entry.entryType === 'NOTE') return 'grey';
            if (entry.entryType === 'MPE') return 'info lighten-3';
            if (entry.entryType === 'MALNUTRITION') return 'error lighten-3';

            return 'primary';
        },
        formatMoney(money) {
            return new Intl.NumberFormat('de-DE', {
                style:    'currency',
                currency: 'EUR',

                // These options are needed to round to whole numbers if that's what you want.
                //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
            }).format(money);
        }
    }
}
</script>
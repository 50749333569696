<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-card elevation="6" tile>
                    <v-card-title class="pt-1 pb-1">
                        <v-icon left color="secondary">mdi-format-list-checkbox</v-icon>
                        Interne Listen
                    </v-card-title>
                    <v-divider/>
                    <v-data-table :headers="dataTableHeader" :items="lists">

                        <template v-slot:item.createdAt="{ item }">
                            <span>{{ item.createdAt | datetime }}</span>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-btn icon color="primary" :to="'/data-list/' + item.id">
                                <v-icon>mdi-eye</v-icon>
                            </v-btn>

                            <v-btn icon color="error" v-if="user.admin || user.orgAdmin || userHasFlag('DATALIST_MANAGER')" @click="deleteDataList(item)">
                                <v-icon>mdi-delete-sweep-outline</v-icon>
                            </v-btn>
                        </template>

                        <template v-slot:item.protection_r="{ item }">
                            <div v-if="item.readTrustLevel">Sicherheitsstufe: <b>{{ item.readTrustLevel }}</b></div>
                            <div v-if="item.readUserFlag">Flag: <b>{{ toReadableUserFlag(item.readUserFlag) }}</b></div>
                        </template>
                        <template v-slot:item.protection_w="{ item }">
                            <div v-if="item.writeTrustLevel">Sicherheitsstufe <b>{{ item.writeTrustLevel }}</b></div>
                            <div v-if="item.writeUserFlag">Flag: <b>{{ toReadableUserFlag(item.writeUserFlag) }}</b></div>
                        </template>
                    </v-data-table>
                    <v-divider/>
                    <v-card-actions>
                        <v-btn small color="warning" @click="createListUI = !createListUI" v-if="userHasFlag('DATALIST_MANAGER')">
                            <v-icon left>mdi-database-plus</v-icon>
                            Liste erstellen
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card v-if="createListUI" elevation="6" tile>
                    <v-card-title class="pt-1 pb-1">
                        <v-icon left>mdi-database-plus</v-icon>
                        Liste erstellen
                    </v-card-title>
                    <v-divider/>
                    <v-card-actions>
                        <v-btn color="primary" @click="createList_addColumn" small>
                            <v-icon left>mdi-table-column-plus-after</v-icon>
                            Spalte hinzufügen
                        </v-btn>

                        <v-spacer/>
                        <v-btn color="success" @click="createDataList" small>
                            <v-icon left>mdi-content-save-move-outline</v-icon>
                            Liste speichern
                        </v-btn>
                    </v-card-actions>
                    <v-divider/>
                    <v-card-text>
                        <v-text-field v-model="createListObj.label" placeholder="Name der Liste" solo dense outlined prepend-inner-icon="mdi-text-short"/>
                        <v-row>
                            <v-col>
                                <v-text-field type="number" v-model="createListObj.readTrustLevel" placeholder="Sicherheitsstufe (Lesend)" clearable solo dense outlined/>
                            </v-col>
                            <v-col>
                                <v-select v-model="createListObj.readUserFlag" solo outlined dense clearable
                                          prepend-inner-icon="mdi-shield-outline"
                                          placeholder="Benutzer-Flag (Lesend)" :items="grantableUserFlags" item-value="key"
                                          item-text="label"
                                          item-color="primary lighten-3">
                                    <template v-slot:item="{ item }">
                                        <v-icon left v-if="item.requiresAdmin" color="red">mdi-star-outline</v-icon>
                                        {{ item.label }}
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col>
                                <v-text-field type="number" v-model="createListObj.writeTrustLevel" placeholder="Sicherheitsstufe (Schreibend)" solo dense outlined clearable/>
                            </v-col>
                            <v-col>
                                <v-select v-model="createListObj.writeUserFlag" solo outlined dense clearable
                                          prepend-inner-icon="mdi-shield-outline"
                                          placeholder="Benutzer-Flag (Schreibend)" :items="grantableUserFlags" item-value="key"
                                          item-text="label"
                                          item-color="primary lighten-3">
                                    <template v-slot:item="{ item }">
                                        <v-icon left v-if="item.requiresAdmin" color="red">mdi-star-outline</v-icon>
                                        {{ item.label }}
                                    </template>
                                </v-select>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col v-for="(col, k) in createListObj.columns" :key="k">
                                <v-card outlined>
                                    <v-card-text>
                                        <v-text-field v-model="col.label" placeholder="Name" solo dense outlined/>
                                        <v-select
                                            :items="['STRING', 'BOOLEAN', 'INTEGER', 'MONEY', 'DATE', 'DATETIME', 'IMAGE', 'ICON', 'SELECT', 'ICON_SELECT', 'USER', 'CRIMINAL_RECORD', 'MEDICAL_RECORD']"
                                            v-model="col.dataType" solo dense outlined/>

                                        <v-combobox deletable-chips small-chips v-if="['SELECT', 'ICON_SELECT'].includes(col.dataType)" v-model="col.defaults" label="Default Value(s)" multiple solo
                                                    dense outlined placeholder="Default Value(s)"/>

                                        <v-btn x-small color="error" @click="createList_removeColumn(k)">
                                            Löschen
                                        </v-btn>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <v-text-field v-model="createListObj.onRowAdded" placeholder="Webhook (OnRowAdded)" prepend-inner-icon="mdi-webhook" solo dense outlined clearable/>
                            </v-col>
                            <v-col>
                                <v-text-field v-model="createListObj.onRowUpdated" placeholder="Webhook (OnRowUpdated)" prepend-inner-icon="mdi-webhook" solo dense outlined clearable/>
                            </v-col>
                            <v-col>
                                <v-text-field v-model="createListObj.onRowRemoved" placeholder="Webhook (OnRowRemoved)" prepend-inner-icon="mdi-webhook" solo dense outlined clearable/>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <!-- debug -->

                    <v-divider/>
                    <v-card-text>
                        <pre>{{ createListObj }}</pre>
                    </v-card-text>

                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {UserFlags} from "@/config";
import Swal        from "sweetalert2";

export default {
    data:    () => ({
        lists: [],

        createListUI:  false,
        createListObj: {
            label:           '',
            columns:         [],
            writeTrustLevel: null,
            writeUserFlag:   null,
            readTrustLevel:  null,
            readUserFlag:    null,

            onRowAdded:   null,
            onRowUpdated: null,
            onRowRemoved: null,
        },

        dataTableHeader: [
            //{value: 'id', text: 'ID'},
            {value: 'label', text: 'Name'},
            {value: 'createdAt', text: 'Erstelldatum'},
            {value: 'protection_r', text: 'Leseberechtigungen'},
            {value: 'protection_w', text: 'Schreibberechtigungen'},
            {value: 'accountTypes', text: 'Zugangsberechtigungen'},
            {value: 'actions', text: 'Aktionen'},
        ],
    }),
    methods: {
        createList_addColumn() {
            this.createListObj.columns.push({
                label:    'Neue Spalte',
                dataType: 'STRING',
                defaults: [],
            })
        },
        createList_removeColumn(index) {
            this.createListObj.columns.splice(index, 1);
        },
        createDataList() {
            this.apiCreateDataList(this.createListObj).then(() => {
                this.apiGetAllDataLists().then(r => {
                    this.lists = r.data;
                })

                this.createListObj = {
                    label:           '',
                    columns:         [],
                    writeTrustLevel: null,
                    writeUserFlag:   null,
                    readTrustLevel:  null,
                    readUserFlag:    null,
                }
            })
        },

        deleteDataList(list) {
            Swal.fire({
                html:              'Möchtest du die List <b>' + list.label + '</b> wirklich löschen? Alle Einträge gehen damit verloren.',
                title:             'Bist du sicher?',
                icon:              'warning',
                showCancelButton:  true,
                cancelButtonText:  'Abbrechen',
                confirmButtonText: 'Löschen'
            }).then(r => {
                if (r.isConfirmed) {
                    this.apiDeleteDataList(list.id).then(() => {
                        this.apiGetAllDataLists().then(r2 => {
                            this.lists = r2.data;
                        })
                    })
                }
            })
        },

        toReadableUserFlag(flag) {
            for (let obj of UserFlags) {
                if (obj.key === flag) return obj.label;
            }
            return flag;
        },
    },
    mounted() {
        this.apiGetAllDataLists().then(r => {
            this.lists = r.data;
        })
    }
}
</script>
<template>
    <v-container fluid>
        <v-card outlined elevation="4" :loading="loading">
            <v-card-title class="pb-2 pt-2">
                <v-icon left color="info">mdi-folder-information-outline</v-icon>
                Interne Informationen
                <v-spacer/>
                <v-text-field dense solo clearable v-model="search" prepend-inner-icon="mdi-magnify" label="Suche" single-line hide-details/>
            </v-card-title>
            <v-tabs height="40" v-if="accountTypes.length > 1" v-model="activeAccountType">
                <v-tab v-for="type in accountTypes" :key="type">{{ type }}</v-tab>
            </v-tabs>
            <v-divider/>
            <v-tabs height="40" v-if="categories.length > 1" v-model="activeCategory">
                <v-tab v-for="category in categories" :key="category">{{ category }}</v-tab>
            </v-tabs>
            <v-divider/>
            <v-simple-table>
                <thead>
                <tr>
                    <th>Name des Dokuments</th>
                    <th>Aktionen</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="document in filteredDocuments" :key="document.id">
                    <td class="table-header-cell-p">
                        <v-icon v-if="document.attachmentType === 'SHEET'" color="green" class="mr-2">mdi-file-excel
                        </v-icon>
                        <v-icon v-if="document.attachmentType === 'DOCUMENT'" color="red" class="mr-2">
                            mdi-file-pdf-box
                        </v-icon>
                        <v-icon v-if="document.attachmentType === 'FORM'" color="orange" class="mr-2">
                            mdi-form-textbox-lock
                        </v-icon>

                        <v-btn v-if="document.shortName" x-small color="primary lighten-2" class="mr-3">
                            {{ document.shortName }}
                        </v-btn>
                        <span v-html="mdToHtml(document.title)"></span>
                        <span
                            v-if="document.sharedWithAccountTypes?.length > 0"> (Geteilt mit {{
                                document.sharedWithAccountTypes | formatAccountTypeList
                            }})</span>
                    </td>
                    <td>

                        <v-btn icon color="green lighten-2" class="mr-1" :to="'/intern-info/' + document.id + '/pdf'"
                               v-if="document.attachmentType === 'DOCUMENT'">
                            <v-icon>mdi-eye-outline</v-icon>
                        </v-btn>

                        <v-btn icon color="primary lighten-2" class="mr-1" :to="'/intern-info/' + document.id"
                               v-else>
                            <v-icon>mdi-eye-outline</v-icon>
                        </v-btn>

                        <v-btn icon color="warning lighten-1" class="mr-1"
                               v-if="(user.trustLevel >= 1000 || user.orgAdmin || document.createdBy.id === user.id || user.flags.includes('MODIFY_INTERNAL_INFORMATION')) && user.accountType === document.accountType && !document.attachmentType"
                               :to="'/intern-info/' + document.id + '/edit'">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>

                        <v-btn icon color="warning lighten-1" class="mr-1"
                               v-if="(user.trustLevel >= 1000 || user.orgAdmin || document.createdBy.id === user.id || user.flags.includes('MODIFY_INTERNAL_INFORMATION')) && user.accountType === document.accountType && document.attachmentType"
                               :to="'/intern-info/' + document.id + '/edit-v2'">
                            <v-icon>mdi-progress-pencil</v-icon>
                        </v-btn>

                        <v-btn icon color="error lighten-1" class="mr-1"
                               v-if="(user.trustLevel >= 1000 || user.orgAdmin || document.createdBy.id === user.id) && user.accountType === document.accountType"
                               @click="deleteInternalInfo(document)">
                            <v-icon>mdi-delete-outline</v-icon>
                        </v-btn>

                        <v-btn icon color="blue-grey" class="mr-1"
                               v-if="(user.orgAdmin || user.admin) && user.accountType === document.accountType"
                               @click="openShareDialog(document)">
                            <v-icon>mdi-share-outline</v-icon>
                        </v-btn>

                        <small v-if="document.createdBy">(Erstellt von <b>{{
                                document.createdBy.displayName
                            }}</b>)</small>
                    </td>
                </tr>
                <tr v-if="documents.length === 0">
                    <td colspan="2">
                        <i>Keine Dokumente gefunden.</i>
                    </td>
                </tr>
                </tbody>
            </v-simple-table>
            <div
                v-if="user.trustLevel >= 1000 || user.orgAdmin || user.admin || user.flags.includes('MODIFY_INTERNAL_INFORMATION')">
                <v-divider/>
                <v-card-actions>
                    <v-btn small color="primary" to="/intern-info/add">
                        <v-icon left>mdi-plus</v-icon>
                        Dokument Erstellen
                    </v-btn>

                    <v-btn small color="primary lighten-3" @click="createDocument()" :loading="dialogCreateDocument">
                        <v-icon left>mdi-file-pdf-box</v-icon>
                        Word Dokument erstellen
                    </v-btn>
                </v-card-actions>
            </div>
        </v-card>

        <v-dialog :value="dialogShare" width="400" dark v-on:click:outside="dialogShare = false;">
            <v-card color="primary darken-1">
                <v-card-title class="pt-2 pb-2">
                    Dokument teilen
                </v-card-title>
                <v-divider class="pb-4"/>
                <v-card-text class="pb-0">
                    <v-select solo outlined multiple item-value="accountType" item-text="label"
                              :items="availableAccountTypes"
                              v-model="dialogShareValue"></v-select>
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-btn small color="primary" @click="saveShare()">Speichern</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog :value="dialogCreateDocument" width="600" dark v-on:click:outside="dialogCreateDocument = false;" v-on:keydown.esc="dialogCreateDocument = false;">
            <v-card color="primary darken-1">
                <v-card-title class="pt-2 pb-2">
                    Dokument erstellen (Word, Excel, ...)
                </v-card-title>
                <v-divider class="pb-4"/>
                <v-card-text class="pb-0">
                    <v-select solo outlined item-value="id" item-text="title" dense
                              prepend-inner-icon="mdi-clipboard-outline"
                              :items="dialogCreateDocumentTemplates"
                              v-model="dialogSelectedTemplate"></v-select>
                    <v-text-field solo dense placeholder="Dokument Name" outlined
                                  prepend-inner-icon="mdi-text-box-outline"
                                  v-model="dialogCreateDocumentName"/>
                    <v-text-field solo dense placeholder="Dokument Kategorie (Short Name)" outlined
                                  prepend-inner-icon="mdi-text-box-outline"
                                  v-model="dialogCreateDocumentCategory"/>
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-btn small color="primary" @click="createDocument()">Erstellen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<style>
.table-header-cell-p > p {
    display: inline !important;
    margin: 0 !important;
}
</style>

<script>
import markdown        from "markdown-it";
import Swal            from "sweetalert2";
import {ACCOUNT_TYPES} from "@/config";

export default {
    components: {},
    data:       () => ({
        documents:         [],
        loading:           false,
        accountTypes:      [],
        activeAccountType: '',
        activeCategory:    0,

        dialogShare:        false,
        dialogShareValue:   [],
        dialogInternalInfo: null,

        dialogCreateDocument:         false,
        dialogSelectedTemplate:       null,
        dialogCreateDocumentName:     '',
        dialogCreateDocumentCategory: '',

        search: null,
    }),
    computed:   {
        isFlatRenderModeEnabled() {
            if (this.search) return true;
            return this.legacyViews;
        },
        availableAccountTypes() {
            let types = [];
            for (let at of ACCOUNT_TYPES) {
                if (at.accountType !== this.userAccountType) types.push(at);
            }
            return types;
        },
        filteredDocuments() {
            if (this.search) {
                return this.documents
                    .filter(i => i.accountType === this.accountTypes[this.activeAccountType])
                    .filter(i => i.title.toLowerCase().includes(this.search.toLowerCase()));
            }

            return this.documents
                .filter(i => i.accountType === this.accountTypes[this.activeAccountType])
                .filter(i => this.isFlatRenderModeEnabled || this.categories.length <= 1 || i.shortName === this.categories[this.activeCategory]);
        },
        categories() {
            let items = ['Unsortiert'];

            if (this.isFlatRenderModeEnabled) return items;

            this.documents.forEach(i => {
                if (i.accountType === this.accountTypes[this.activeAccountType]) {
                    if (!items.includes(i.shortName)) {
                        items.push(i.shortName);
                    }
                }
            })

            if (this.documents.filter(i => i.accountType === this.accountTypes[this.activeAccountType]).filter(i => i.shortName === 'Unsortiert').length === 0) {
                items.splice(items.indexOf('Unsortiert'), 1);
            }

            return items;
        },

        dialogCreateDocumentTemplates() {
            return [{id: null, title: 'Leeres Blatt'}, ...this.documents.filter(d => d.shortName === 'Templates')];
        }
    },
    filters:    {
        formatAccountTypeList(val) {
            let arr = [];
            for (let el of val) {
                for (let at of ACCOUNT_TYPES) {
                    if (at.accountType === el) {
                        arr.push(at.label);
                        break;
                    }
                }
            }
            return arr.join(", ");
        }
    },
    methods:    {
        openShareDialog(internalInfo) {
            this.dialogShareValue   = internalInfo.sharedWithAccountTypes || [];
            this.dialogInternalInfo = internalInfo;
            this.dialogShare        = true;
        },
        saveShare() {
            this.dialogInternalInfo.sharedWithAccountTypes = this.dialogShareValue;
            this.apiUpdateInternalInfo(this.dialogInternalInfo.id, this.dialogInternalInfo)
            this.dialogShare = false;
        },
        mdToHtml(markdownText) {
            if (markdownText == null || markdownText.trim().length === 0) {
                return '<i>Keine Informationen angegeben.</i>'
            }

            let md = markdown({breaks: true, typographer: true, xhtmlOut: true})
                .render(markdownText)
                .substring(3);

            return md.substring(0, md.length - 5);
        },
        createDocument() {

            if (this.dialogCreateDocument === false) {
                this.dialogSelectedTemplate       = null;
                this.dialogCreateDocumentName     = '';
                this.dialogCreateDocumentCategory = '';
                this.dialogCreateDocument         = true;
                return;
            }

            const shortName = this.dialogCreateDocumentCategory || 'WIP';
            const title     = this.dialogCreateDocumentName || 'Dokument von ' + this.user.displayName;

            const documentType = 'DOCUMENT';

            if (this.dialogSelectedTemplate) {
                this.apiCreateInternalInfoCopyFrom(this.dialogSelectedTemplate, {
                    accountType:    this.userAccountType,
                    shortName:      shortName,
                    title:          title,
                    attachmentType: documentType,
                }).then(r => {
                    this.$router.push('/intern-info/' + r.data.id + '/edit-v2');
                })
            } else {
                this.apiCreateInternalInfo({
                    accountType:    this.userAccountType,
                    shortName:      shortName,
                    title:          title,
                    attachmentType: documentType,
                }).then(r => {
                    this.$router.push('/intern-info/' + r.data.id + '/edit-v2');
                })
            }
        },
        deleteInternalInfo(document) {
            Swal.fire({
                title:              'Bist du dir sicher?',
                html:               'Du kannst diese Aktion nicht rückgängig machen. Möchtest du den Eintrag "<b>' + this.mdToHtml(document.title) + '</b>" wirklich löschen?',
                showDenyButton:     false,
                showCancelButton:   true,
                confirmButtonText:  `Löschen`,
                confirmButtonColor: '#d73939',
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    Swal.showLoading();
                    this.apiDeleteInternalInfo(document.id).then(() => {
                        Swal.hideLoading();
                        Swal.fire('Erfolgreich!', 'Der Eintrag wurde gelöscht.', 'success')
                        this.fetchDocuments();
                    }).catch(err => {
                        Swal.hideLoading();
                        Swal.fire('Fehler', 'Der Eintrag konnte nicht gelöscht werden:<br>' + err.response.statusCode, 'error');
                    })
                }
            })
        },
        fetchDocuments() {
            this.loading        = true;
            this.accountTypes   = [];
            this.documents      = [];
            this.activeCategory = 0;

            this.apiGetInternalInfos().then(r => {
                this.documents = r.data;
                this.documents.sort((a, b) => a.title?.localeCompare(b.title))
                //this.documents.sort((a, b) => a.shortName?.localeCompare(b.shortName))

                this.accountTypes.push(this.user.accountType);

                this.documents.forEach(item => {
                    if (!this.accountTypes.includes(item.accountType)) {
                        this.accountTypes.push(item.accountType);
                    }

                    if (!item.shortName) {
                        item.shortName = 'Unsortiert';
                    }
                })

                this.activeAccountType = this.accountTypes.indexOf(this.user.accountType);
            }).finally(() => {
                this.loading = false;
            })
        }
    },
    mounted() {
        this.fetchDocuments();
    }
}
</script>
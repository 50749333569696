<template>
  <v-container>
    <v-card>
      <v-card-text>
        <b>Hey! Diese Funktion ist leider noch nicht fertig! Aber sei nicht traurig! Sie wird so schnell wie möglich fertig gestellt!</b>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {}
</script>
<template>
    <v-container fluid>
        <v-card class="pb-0 mb-0" outlined elevation="4" :loading="loading">
            <v-card-title class="pt-2 pb-2">
                <v-icon left>mdi-email-outline</v-icon>
                E-Mail verfassen
            </v-card-title>
            <v-divider/>
            <v-tabs height="40px" v-model="tab" color="primary">
                <v-tab>
                    Editor
                </v-tab>
                <v-tab>
                    Preview
                </v-tab>
            </v-tabs>
            <v-divider/>
            <v-card-text class="pb-0">
                <v-row>
                    <v-col cols="2">
                        <v-text-field prepend-icon="mdi-form-textbox" label="Kategorie"
                                      solo outlined
                                      v-model="letter.category" dense></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field prepend-icon="mdi-form-textbox" label="Titel"
                                      solo outlined
                                      v-model="letter.title" dense></v-text-field>
                    </v-col>
                    <v-col>
                        <v-autocomplete :items="partners" return-object item-text="displayName" v-model="letter.recipient"
                                        label="Empfänger" solo outlined
                                        prepend-icon="mdi-account-group" dense></v-autocomplete>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-divider/>

            <v-row v-if="tab === 1">
                <v-col>
                    <v-card-text class="text-box scrollbar-overflow-hidden ql-editor">
                        <div style="padding: 10px 10px 10px 20px;border-radius: 15px; background-color: rgba(98,82,74,0.87)">
                            <iframe width="100%" scrolling="no" src="about:blank" @load="resizeIframe" id="preview"/>
                        </div>
                    </v-card-text>
                </v-col>
            </v-row>

            <v-card-text v-if="tab === 0" class="pb-0">
                <v-textarea solo outlined rows="20" v-model="letter.content" class="pa-1"></v-textarea>
            </v-card-text>

            <v-divider/>
            <v-card-actions>
                <v-btn small color="primary" @click="createEntry" v-if="letter.recipient" :disabled="loading || !isValid">
                    <v-icon left>mdi-email-fast</v-icon>
                    Senden an {{ letter.recipient.displayName }}
                </v-btn>
                <v-btn small color="primary" @click="createEntry" v-else disabled>
                    <v-icon left>mdi-email-fast</v-icon>
                    Senden an [Empfänger]
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<style>
iframe {
    width: 100%;
    border: 0;
    overflow: hidden;
}
</style>

<script>
import {nextTick}              from "vue";
import markdown                from "markdown-it";
import markdownContainer       from 'markdown-it-container';
import markdownAbbr            from 'markdown-it-abbr';
import {full as markdownEmoji} from 'markdown-it-emoji'
import markdownDefList         from 'markdown-it-deflist'
import markdownIns             from 'markdown-it-ins'
import twemoji                 from "twemoji";

const md = markdown("commonmark", {
    html:        true,
    linkify:     true,
    typographer: true,
    table:       true,
})
    .use(markdownContainer, 'warning')
    .use(markdownEmoji)
    .use(markdownAbbr)
    .use(markdownDefList)
    .use(markdownIns);

md.renderer.rules.emoji = function (token, idx) {
    return twemoji.parse(token[idx].content, {base: 'https://raw.githubusercontent.com/twitter/twemoji/master/assets/'});
}

export default {
    data:     () => ({
        id: null,

        letter: {
            title:     '',
            category:  'DM',
            recipient: '',
            content:   '',
        },

        loading: false,

        tab: 0,

        criminals: [],
        patients:  [],
        partners:  [],
    }),
    computed: {
        asciidocRendered() {
            return md.render(this.letter.content);
        },
        isValid() {
            return this.letter.content.length > 20;
        }
    },
    watch:    {
        'tab': function (val) {
            if (val === 1) {
                console.log('Changed to preview');
                nextTick(() => {
                    const html = '<!DOCTYPE html><html><head><link rel="stylesheet" href="/markdown.css"><link rel="stylesheet" href="/github.min.css"><link rel="stylesheet" href="/bootstrap.css"></head><body class="doc">' + this.asciidocRendered + '</body></html>';

                    const doc = document.getElementById('preview').contentWindow.document;
                    doc.open();
                    doc.write(html);
                    doc.close();
                })
            }
        }
    },
    methods:  {
        resizeIframe(obj) {
            obj.target.style.height = obj.target.contentWindow.document.documentElement.scrollHeight + 'px';
        },
        fetchPartners() {
            this.apiGetMissionReportEmployees().then(r => {
                this.partners = r.data.filter(r => r.id !== this.user.id);
            });
        },

        createEntry() {
            this.loading                = true;
            this.letter.recipientUserId = this.letter.recipient.id;
            this.apiCreateMessage(this.letter).then(r => {
                this.loading = false;
                this.$router.push('/letter/' + r.data.id);
            })
        }
    },
    beforeDestroy() {
    },
    mounted() {
        for (let key in this.$route.params) {
            this.letter[key] = this.$route.params[key];
        }

        this.fetchPartners();
    }
}
</script>
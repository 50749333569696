<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" :lg="(isHrAdmin && employeeChanges.length > 0) ? 8 : 12"
                   :xl="(isHrAdmin  && employeeChanges.length > 0) ? 8 : 12" md="12" sm="12">
                <v-card>
                    <v-card-title class="pb-2 pt-2">
                        <v-icon left color="warning">mdi-account-outline</v-icon>
                        <span v-if="id">Mitarbeiter bearbeiten</span>
                        <span v-else>Mitarbeiter anlegen</span>
                    </v-card-title>
                    <v-divider/>
                    <v-card-text>
                        <!-- USERNAME, TRUST_LEVEL -->
                        <v-row>
                            <v-col>
                                <v-text-field prepend-icon="mdi-form-textbox" label="Benutzername"
                                              v-model="employee.username"
                                              solo
                                              outlined dense hint="Benutzername" persistent-hint/>
                            </v-col>
                            <v-col>
                                <v-text-field prepend-icon="mdi-shield-star-outline" type="number"
                                              label="Sicherheitsstufe"
                                              v-model="employee.trustLevel" solo outlined dense
                                              hint="Sicherheitsstufe" persistent-hint/>
                            </v-col>
                        </v-row>
                        <!-- DISPLAY NAMES -->
                        <v-row>
                            <v-col>
                                <v-text-field prepend-icon="mdi-rename-box" label="Anzeigename"
                                              v-model="employee.displayName"
                                              solo :rules="[displayNameValidator]"
                                              outlined dense hint="Anzeigename" persistent-hint/>
                            </v-col>
                            <v-col>
                                <v-text-field prepend-icon="mdi-chevron-triple-up" label="Rank" v-model="employee.rank"
                                              solo
                                              outlined dense hint="Rank" persistent-hint/>
                            </v-col>
                        </v-row>
                        <!-- USER FLAGS -->
                        <v-row>
                            <v-col>
                                <v-select v-model="employee.flags" multiple solo outlined dense
                                          prepend-icon="mdi-shield-outline"
                                          placeholder="Userflags" :items="grantableUserFlags" item-value="key"
                                          item-text="label"
                                          item-color="primary lighten-3">
                                    <template v-slot:item="{ item }">
                                        <v-icon left v-if="item.requiresAdmin" color="red">mdi-star-outline</v-icon>
                                        {{ item.label }}
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col v-if="user.admin || user.orgAdmin">
                                <v-select v-model="employee.securityLevels" multiple solo outlined dense
                                          prepend-icon="mdi-badge-account-outline"
                                          placeholder="Security Levels" :items="securityLevels" item-value="name"
                                          item-text="description"
                                          item-color="primary lighten-3">
                                </v-select>
                            </v-col>
                        </v-row>
                        <!-- NOTIZ -->
                        <v-row v-if="user.admin || user.orgAdmin || userHasFlag(UserFlag.HR_CREATE)">
                            <v-col>
                                <v-textarea v-model="employee.note" solo outlined dense prepend-icon="mdi-information"
                                            placeholder="Notiz">
                                </v-textarea>
                            </v-col>
                        </v-row>
                        <!-- PASSWORT -->
                        <v-row>
                            <v-col>
                                <v-text-field prepend-icon="mdi-form-textbox-password" label="Password setzen"
                                              v-model="employee.password" solo
                                              outlined dense hint="Passwort" type="password" persistent-hint/>
                            </v-col>
                            <v-col>
                                <custom-date-picker v-model="employee.absentUntil" :datetime="employee.absentUntil" label="Abwesend bis"
                                                    :text-field-props="{prependInnerIcon: 'mdi-umbrella-beach-outline', solo: true, dense: true, outlined: true}"/>
                            </v-col>
                        </v-row>
                        <!-- IS_ADMIN, IS_ORG_ADMIN, IS_LOCKED, IS_HIDDEN -->
                        <v-row v-if="user.admin || user.orgAdmin">
                            <v-col v-if="user.admin">
                                <v-checkbox label="Administrator" v-model="employee.admin"
                                            hint="Admin's können neue Admins und Organisation-Admins hinzufügen und bestehende bearbeiten. Sie sind außerdem OrgAdmins"
                                            persistent-hint dense/>
                            </v-col>
                            <v-col v-if="user.admin">
                                <v-checkbox label="Organisation Admin" v-model="employee.orgAdmin"
                                            hint="OrgAdmin's können neue Mitarbeiter hinzufügen, bestehnde bearbeiten und mehr."
                                            persistent-hint dense/>
                            </v-col>
                            <v-col>
                                <v-checkbox label="Gesperrt" v-model="employee.locked"
                                            hint="Gesperrte Benutzer können keine Aktionen mehr im Netzwerk durchführen."
                                            persistent-hint dense/>
                            </v-col>
                            <v-col>
                                <v-checkbox label="Versteckt" v-model="employee.hide"
                                            hint="Versteckter Benutzer können nur über die ID oder die Datenbank bearbeitet werden!"
                                            persistent-hint dense/>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-divider/>
                    <v-card-actions>
                        <v-btn small color="primary lighten-2" @click="saveEmployee">
                            <v-icon left>mdi-content-save-outline</v-icon>
                            Speichern
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="4" v-if="isHrAdmin && employeeChanges.length > 0" class="hidden-md-and-down">
                <v-card>
                    <v-card-title class="pt-2 pb-2">
                        Änderungen
                    </v-card-title>
                    <v-divider/>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" v-for="(v, k) in employeeChanges" :key="k">
                                <v-card outlined tile>
                                    <div style="position: absolute; top: 5px; right: 5px">
                                        <span v-if="v.type === 'MODIFY_ENTITY'">Änderung</span>
                                        <span v-if="v.type === 'CREATE_ENTITY'">Erstellung</span>
                                    </div>
                                    <div style="position: absolute; top: 25px; right: 5px">
                                        {{ v.createdAt | datetime }}
                                    </div>
                                    <div style="position: absolute; top: 5px; left: 15px">
                                        <u>{{ v.userLogin }}</u>
                                    </div>
                                    <div style="height: 55px"></div>
                                    <v-divider/>
                                    <v-data-table :items="v.payload" dense
                                                  :headers="[{value: 'fieldLabel', text: 'Feld'}, {value: 'oldValue', text: 'Alt'}, {value: 'newValue', text: 'Neu'}]"
                                                  hide-default-footer hide-default-header>
                                        <template v-slot:item.newValue="{ item }">
                                            <small>{{ item.newValue }}</small>
                                        </template>
                                        <template v-slot:item.oldValue="{ item }">
                                            <small>{{ item.oldValue }}</small>
                                        </template>
                                    </v-data-table>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {UserFlagEnum} from "../../config";
import moment         from "moment";
import CustomDatePicker from "@/components/CustomDatePicker.vue";

const NAME_VALIDATOR_REGEX = /\([A-Z]-\d+\)\s+[A-Z].*/;

export default {
    components: {CustomDatePicker},
    data:     () => ({
        id: null,

        employeeChanges: [],

        employee: {
            id:          null,
            username:    '',
            rank:        '',
            displayName: '',
            password:    null,
            accountType: null,
            locked:      false,
            admin:       false,
            hide:        false,
            note:        null,
            orgAdmin:    false,
            absentUntil: null,
            trustLevel:  1,
        },

        securityLevels: [],
    }),
    computed: {
        UserFlag() {
            return UserFlagEnum;
        },

        isHrAdmin() {
            return (this.user.admin || this.user.orgAdmin || this.userHasFlag(this.UserFlag.HR_CREATE));
        }
    },
    methods:  {
        fetchEmployee(id) {
            this.apiGetEmployee(id).then(r => {
                this.employee = r.data;
            });

            if (this.isHrAdmin) {
                this.apiGetEmployeeChanges(id).then(r => {
                    this.employeeChanges = r.data;

                    for (let employeeChange of this.employeeChanges) {
                        employeeChange.payload = JSON.parse(employeeChange.payload);

                        for (let pel of employeeChange.payload) {
                            if (pel.fieldLabel === 'rank') pel.fieldLabel = 'Dienstgrad';
                            if (pel.fieldLabel === 'username') pel.fieldLabel = 'Benutzername';
                            if (pel.fieldLabel === 'trustLevel') pel.fieldLabel = 'Sicherheitsstufe';
                            if (pel.fieldLabel === 'note') pel.fieldLabel = 'Notiz';
                            if (pel.fieldLabel === 'flags') {
                                pel.fieldLabel = 'Userflags';
                                pel.newValue   = pel.newValue.join(', ')
                                pel.oldValue   = pel.oldValue.join(', ')
                            }
                        }
                    }

                    this.employeeChanges
                        .sort((b, a) => {
                            return moment(a.createdAt).diff(b.createdAt);
                        });

                    this.employeeChanges = this.employeeChanges.filter(row => row.payload.length > 0);
                })
            }
        },
        fetchSecurityLevels() {
            this.apiGetAllSecurityLevels().then(r => {
                this.securityLevels = r.data;
            })
        },
        saveEmployee() {
            if (this.id) {
                this.apiUpdateEmployee(this.id, this.employee).then(() => {
                    this.fetchEmployee(this.id);
                });
            } else {
                this.apiCreateEmployee(this.employee).then(() => {
                    this.$router.push('/employees');
                });
            }
        },

        displayNameValidator(name) {
            if (NAME_VALIDATOR_REGEX.test(name)) return true;
            return 'Format: (P-34) Max Mustermann';
        }
    },
    mounted() {
        this.id = this.$route.params.id || null;

        this.fetchSecurityLevels();

        if (this.id) {
            this.fetchEmployee(this.id);
        } else {
            if (this.userAccountType === 'POLICE') {
                this.employee.username = 'POL-';
            } else if (this.userAccountType === 'MEDIC') {
                this.employee.username = 'MED-';
            } else if (this.userAccountType === 'GOVERNMENT') {
                this.employee.username = 'GOV-';
            } else if (this.userAccountType === 'ZOLL') {
                this.employee.username = 'ZOLL-';
            } else if (this.userAccountType === 'CORRECTIONAL') {
                this.employee.username = 'JVD-';
            } else if (this.userAccountType === 'FEDERAL_INTELLIGENCE_SERVICE') {
                this.employee.username = 'BND-';
            } else if (this.userAccountType === 'MECHANIC') {
                this.employee.username = 'ADAC-';
            }

            this.employee.username += Math.floor(Math.random() * 10000) + 1;
        }
    }
}
</script>
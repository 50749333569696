<template>
    <v-container fluid>
        <v-card :loading="loading">
            <v-card-title class="pt-2 pb-2">
                <v-icon left>mdi-email-variant</v-icon>
                Postfach
            </v-card-title>
            <v-divider/>
            <v-simple-table>
                <thead>
                <tr>
                    <th>#</th>
                    <th>Kategorie</th>
                    <th>Titel</th>
                    <th>Zustelldatum</th>
                    <th>Gelesen am</th>
                    <th>Empfänger</th>
                    <th colspan="2">Author</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(v, k) in messages" :key="k">
                    <td><v-icon>mdi-{{ v.icon || ( v.openedAt ? 'email-open-outline' : 'email-outline' ) }}</v-icon></td>
                    <td><v-btn color="primary" x-small>{{ v.category }}</v-btn></td>
                    <td>{{ v.title }}</td>
                    <td>{{ v.createdAt | datetime }}</td>
                    <td>{{ v.openedAt | datetime }}</td>
                    <td>{{ v.recipient?.displayName || 'System' }}</td>
                    <td>{{ v.author?.displayName || 'System' }}</td>
                    <td>
                        <v-btn icon color="primary" :to="'/letter/' + v.id">
                            <v-icon>mdi-eye</v-icon>
                        </v-btn>
                    </td>
                </tr>
                </tbody>
            </v-simple-table>
            <v-divider/>
            <v-card-actions>
                <v-btn small color="primary" to="/letter/create">
                    <v-icon left>mdi-email-fast</v-icon>
                    E-Mail verfassen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
export default {
    data: () => ({
        loading: false,
        messages: [],
    }),
    methods: {
        fetchMessages() {
            this.loading = true;
            this.apiGetAllMessages().then(r => {
                this.messages = r.data.sort((a, b) => {
                    return new Date(b.createdAt) - new Date(a.createdAt);
                });
                this.loading = false;
            })
        }
    },
    mounted() {
        this.fetchMessages();
    }
}
</script>
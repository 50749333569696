export const ORGANIZATIONS = {
  POL:  'POLICE',
  BND:  'FEDERAL_INTELLIGENCE_SERVICE',
  ZOLL: 'ZOLL',
  DRK:  'MEDIC',
  REG:  'GOVERNMENT',
  BFJ:  'CORRECTIONAL',

  LEGISLATIVE: ['GOVERNMENT', 'POLICE'],
  EXECUTIVE:   ['POLICE', 'ZOLL', 'FEDERAL_INTELLIGENCE_SERVICE', 'CORRECTIONAL'],
  MEDIC:       ['MEDIC', 'FIREFIGHTER']
}

export const ACCOUNT_TYPES = [
  {accountType: ORGANIZATIONS.POL, label: 'Bundespolizei'},
  {accountType: ORGANIZATIONS.ZOLL, label: 'Bundeszollverwaltung'},
  {accountType: ORGANIZATIONS.REG, label: 'Bundesregierung'},
  {accountType: ORGANIZATIONS.DRK, label: 'Deutsches Rotes Kreuz'},
  {accountType: ORGANIZATIONS.BFJ, label: 'Bundesamt für Justiz (BfJ)'},
]

const OfficialOrganizations = [
  'Polizei', 'DRK', 'Deutsches Rotes Kreuz',
  'Justiz', 'Zoll', 'Stadtverwaltung', 'Regierung', 'Gewerbeamt'
];

const FlagKeys = {
  TRAINER:                           'TRAINER',
  CREATE_APPOINTMENT:                'CREATE_APPOINTMENT',
  MODIFY_INTERNAL_INFORMATION:       'MODIFY_INTERNAL_INFORMATION',
  HR_CREATE:                         'HR_CREATE',
  HR_MODIFY:                         'HR_MODIFY',
  HR_LOCK:                           'HR_LOCK',
  HR_DELETE:                         'HR_DELETE',
  DELETE_RECORD:                     'DELETE_RECORD',
  DELETE_RECORD_ENTRY:               'DELETE_RECORD_ENTRY',
  CRIMINAL_POLICE:                   'CRIMINAL_POLICE',
  MEDIC_POLICE:                      'MEDIC_POLICE',
  SPECIAL_TASK_FORCE_POLICE:         'SPECIAL_TASK_FORCE_POLICE',
  TAX_OFFICER:                       'TAX_OFFICER',
  ORDER_POLICE:                      'ORDER_POLICE',
  COMPLAINT_MANAGER:                 'COMPLAINT_MANAGER',
  COMMERCIAL_OFFICE:                 'COMMERCIAL_OFFICE',
  DATALIST_MANAGER:                  'DATALIST_MANAGER',
  MEDICAL_PSYCHOLOGICAL_EXAMINATION: 'MEDICAL_PSYCHOLOGICAL_EXAMINATION',
}

const UserFlags = [
  {
    key:   FlagKeys.TRAINER,
    label: 'Ausbilder'
  },
  {
    key:   FlagKeys.CREATE_APPOINTMENT,
    label: 'Termine erstellen'
  },
  {
    key:   FlagKeys.MODIFY_INTERNAL_INFORMATION,
    label: 'Interne Informationen bearbeiten'
  },
  {
    key:           FlagKeys.HR_CREATE,
    label:         'Mitarbeiter erstellen',
    requiresAdmin: true
  },
  {
    key:           FlagKeys.HR_MODIFY,
    label:         'Mitarbeiter bearbeiten',
    requiresAdmin: true
  },
  {
    key:           FlagKeys.HR_LOCK,
    label:         'Mitarbeiter ent/sperren',
    requiresAdmin: false
  },
  {
    key:           FlagKeys.HR_DELETE,
    label:         'Mitarbeiter löschen',
    requiresAdmin: true
  },
  {
    key:           FlagKeys.DELETE_RECORD,
    label:         'Akten löschen',
    requiresAdmin: true
  },
  {
    key:           FlagKeys.DELETE_RECORD_ENTRY,
    label:         'Akteneinträge löschen',
    requiresAdmin: true
  },
  {
    key:           FlagKeys.CRIMINAL_POLICE,
    label:         'Kriminalpolizei',
    requiresAdmin: false,
    accountTypes:  [ORGANIZATIONS.POL, ORGANIZATIONS.BND, ORGANIZATIONS.ZOLL]
  },
  {
    key:           FlagKeys.MEDIC_POLICE,
    label:         'Polizei medizinischer Dienst',
    requiresAdmin: false,
    accountTypes:  [ORGANIZATIONS.POL, ORGANIZATIONS.ZOLL, ORGANIZATIONS.DRK]
  },
  {
    key:           FlagKeys.SPECIAL_TASK_FORCE_POLICE,
    label:         'Spezialeinsatzkommando',
    requiresAdmin: false,
    accountTypes:  [ORGANIZATIONS.POL, ORGANIZATIONS.ZOLL]
  },
  {
    key:           FlagKeys.TAX_OFFICER,
    label:         'Vollstreckungsbeamter',
    requiresAdmin: true,
    accountTypes:  [ORGANIZATIONS.REG, ORGANIZATIONS.POL, ORGANIZATIONS.ZOLL]
  },
  {
    key:           FlagKeys.ORDER_POLICE,
    label:         'Ordnungsamt',
    requiresAdmin: true,
    accountTypes:  [ORGANIZATIONS.POL, ORGANIZATIONS.REG, ORGANIZATIONS.ZOLL]
  },
  {
    key:           FlagKeys.COMPLAINT_MANAGER,
    label:         'Beschwerdemanagement',
    requiresAdmin: false,
  },
  {
    key:           FlagKeys.COMMERCIAL_OFFICE,
    label:         'Gewerbeamt',
    requiresAdmin: true,
    accountTypes:  [ORGANIZATIONS.POL, ORGANIZATIONS.ZOLL, ORGANIZATIONS.REG],
  },
  {
    key:           FlagKeys.DATALIST_MANAGER,
    label:         'Interne Listen Manager',
    requiresAdmin: true,
  },
  {
    key:           FlagKeys.MEDICAL_PSYCHOLOGICAL_EXAMINATION,
    label:         'Verwaltung & Ausbildung von MPUs',
    requiresAdmin: false,
    accountTypes:  [ORGANIZATIONS.POL, ORGANIZATIONS.DRK],
  }
];

export {UserFlags, ORGANIZATIONS as Organizations, FlagKeys as UserFlagEnum, OfficialOrganizations}
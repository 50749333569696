<template>
    <v-alert outlined elevation="2" dense border="left" class="pa-0" :color="entryEntryColor(entry)">
        <v-card-text :style="$vuetify.theme.dark ? 'color: white !important;' : 'color: black !important;'">
            <v-row>
                <v-col class="pt-1 pb-0" cols="3">
                    <b>Ersteller:</b> {{ entry.createdBy.displayName }}<br/>
                    <b>Datum:</b> {{ entry.createdAt.substring(0, 10) }}

                    <div v-if="entry.zipCode">
                        <b>Postleitzahl: </b> {{ entry.zipCode }}<br>
                    </div>
                </v-col>
                <v-col class="pl-2 pt-1 pb-0">
                    <div v-html="mdToHtml(entry.content)"></div>
                    <div v-if="entry.discarded">
                        <b>Eintrag wurde verworfen. Begründung:</b>
                        <div v-html="mdToHtml(entry.discardedReason)"></div>
                    </div>
                </v-col>
                <v-col cols="2" class="text-right pt-1 pb-0 pr-1">
                    <div v-if="entry.type === 'PAYMENT'">Zahlung</div>
                    <div v-if="entry.type === 'RECORD_CHANGE'">Aktenänderung</div>
                    <div v-if="entry.type === 'CRIME'">Strafmeldung</div>
                    <div v-if="entry.type === 'NOTE'">Anmerkung / Notiz</div>

                    <div style="position: absolute; bottom: 4px; right: 4px;">
                        <v-btn x-small icon color="error"
                               v-if="user.admin || user.orgAdmin || user.flags.includes('DELETE_RECORD_ENTRY')"
                               @click="deleteEntry()">
                            <v-icon>mdi-delete-forever-outline</v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
    </v-alert>
</template>

<script>
import markdown from "markdown-it";
import Swal     from "sweetalert2";

export default {
    props:   ['entry', 'company'],
    methods: {
        mdToHtml(markdownText) {
            if (markdownText == null || markdownText.trim().length === 0) {
                return '<i>Keine Informationen angegeben.</i>'
            }

            return markdown({breaks: true}).render(markdownText);
        },
        deleteEntry() {
            Swal.fire({
                title:             'Eintrag endgültig löschen?',
                showCancelButton:  true,
                showConfirmButton: true,
                confirmButtonText: 'Ja',
                cancelButtonText:  'Nein',
            }).then(r => {
                if (r.isConfirmed) {
                    this.apiDeleteCompanyRecordEntry(this.company.id, this.entry.id).then(() => this.$emit('updated'));
                }
            })
        },
        entryEntryColor(entry) {
            if (entry.type === 'RECORD_CHANGE') return 'info';
            if (entry.type === 'PAYMENT') return 'success';
            if (entry.type === 'NOTE') return 'blue-grey';
            if (entry.type === 'CRIME') return 'error';

            return 'primary';
        },
        formatMoney(money) {
            return new Intl.NumberFormat('de-DE', {
                style:    'currency',
                currency: 'EUR',

                // These options are needed to round to whole numbers if that's what you want.
                //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
            }).format(money);
        }
    }
}
</script>
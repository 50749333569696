<!--suppress JSUnresolvedVariable -->
<template>
    <v-container fluid>
        <v-card elevation="4" outlined>
            <v-card-title class="pb-2 pt-2">
                <v-icon left color="info">mdi-post-outline</v-icon>
                Audit Log
            </v-card-title>
            <v-divider/>
            <v-data-table :items="auditLog" :search="search" :headers="auditLogTableHeaders" sort-by="createdAt"
                          sort-desc>
                <template v-slot:item.createdAt="{ item }">
                    {{ item.createdAt | datetime }}
                </template>

                <template v-slot:item.payload="{ item }">
                    <div v-if="item.entityId">
                        <b>Entity-ID </b> (of type <b>{{ item.entityName }}</b>): {{ item.entityId }}<br/>
                        <div v-if="item.type === 'MODIFY_ENTITY'">
                            <b>Modifications:</b><br/>
                            <li v-for="(k, i) in payloadParse(item.payload)" :key="i">
                                <b>{{ k.fieldLabel }}</b>: <span :title="k.oldValue">{{ wrap(k.oldValue) }}</span> ➜ <span :title="k.newValue">{{ wrap(k.newValue) }}</span>
                            </li>
                        </div>
                        <div v-else-if="item.type === 'CREATE_ENTITY'">
                            <i :title="item.payload">Item was created (hover for more info).</i>
                        </div>
                    </div>
                    <div v-else>
                        {{ item.payload }}
                    </div>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>

<script>
import Swal from 'sweetalert2';

export default {
    data:    () => ({

        auditLog: [],

        auditLogTableHeaders: [
            {value: 'type', text: 'Event'},
            {value: 'payload', text: 'Beschreibung'},
            {value: 'ipAddress', text: 'IP Address'},
            {value: 'userId', text: 'User-ID'},
            {value: 'userLogin', text: 'User-Login'},
            {value: 'userAccountType', text: 'Account-Type'},
            {value: 'createdAt', text: 'Datum'},
        ],
    }),
    methods: {
        wrap(data) {
            if (Array.isArray(data)) return 'array[' + data.length + ']'
            if (data.length > 20) {
                return data.substring(0, 20) + '...'
            }
            return data;
        },

        payloadParse(payload) {
            return JSON.parse(payload);
        },

        openInvoice(invoice) {
            this.$router.push('/admin/credit-invoice/' + invoice.id);
        },
        deleteInvoice(invoice) {
            console.log(invoice.id + " deleted");
        },

        publishInvoice() {
            if (this.createInvoiceFile != null) {
                this.toBase64(this.createInvoiceFile).then(fileContent => {
                    this.apiCreateCreditInvoice(this.createInvoice, fileContent).then(() => {
                        this.createInvoiceDialog = false;
                        Swal.fire('Rechnung erstellt');
                    });
                });
            } else {
                this.apiCreateCreditInvoice(this.createInvoice, null).then(() => {
                    this.createInvoiceDialog = false;
                    Swal.fire('Rechnung erstellt');
                });
            }
            //
        },
    },
    mounted() {
        this.apiGetAuditLog().then(r => {
            this.auditLog = r.data;
        })
    }
}
</script>
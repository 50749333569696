<template>
  <v-container fluid>
    <v-card outlined elevation="4" v-if="report.createdBy">
      <v-card-title v-html="mdToHtml(report.summary)" class="pb-2 pt-2">
      </v-card-title>
      <v-divider/>
      <v-row>
        <v-col>
          <v-card-text class="text-box scrollbar-overflow-hidden ql-editor scrollbox" v-html="report.content">
          </v-card-text>
        </v-col>
        <v-col cols="3">
          <v-card-text>
            <v-row>
              <v-col class="pb-0 pt-2">
                <v-text-field solo outlined disabled v-model="report.createdBy.displayName" dense
                              :prepend-icon="userTypeToIcon()"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0 pt-2">
                <v-text-field solo outlined disabled :value="formatDate(report.createdAt)" dense
                              prepend-icon="mdi-calendar-edit"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0 pt-2">
                <v-text-field solo outlined disabled :value="formatDate(report.missionTime)" dense
                              prepend-icon="mdi-calendar-alert"/>
              </v-col>
            </v-row>
            <v-row v-if="userAccountType === 'MEDIC' || userAccountType === 'GOVERNMENT'" class="pb-0 pt-0">
              <v-col class="pb-0 pt-0">
                <v-select multiple :items="report.patients" return-object item-text="name" v-model="report.patients"
                          label="Keine Patienten" solo outlined disabled
                          prepend-icon="mdi-hospital-box" dense></v-select>
              </v-col>
            </v-row>
            <v-row v-if="userAccountType === 'POLICE' || userAccountType === 'GOVERNMENT'" class="pb-0 pt-0">
              <v-col class="pb-0 pt-0">
                <v-autocomplete multiple :items="report.criminals" return-object item-text="name"
                                v-model="report.criminals"
                                label="Keine Täter oder Opfer" solo outlined disabled
                                prepend-icon="mdi-handcuffs" dense></v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="pb-0 pt-0">
              <v-col class="pb-0 pt-0">
                <v-autocomplete multiple :items="report.partners" return-object item-text="displayName"
                                v-model="report.partners" disabled
                                label="Keine Kollegen" solo outlined
                                prepend-icon="mdi-account-group" dense></v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
      </v-row>
      <v-divider/>
      <v-card-actions>
        <v-btn small icon color="warning lighten-1" class="mr-1"
               :to="'/report/' + id + '/edit'">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>


<script>
import markdown from "markdown-it";
import moment   from 'moment';

export default {
  components: {},
  data:       () => ({
    id: null,

    report: {
      summary:   '',
      content:   '',
      partners:  [],
      criminals: [],
      patients:  [],
    },

    tab: 0,
  }),
  methods:    {
    mdToHtml(markdownText) {
      if (markdownText == null || markdownText.trim().length === 0) {
        return '<i>Keine Informationen angegeben.</i>'
      }

      return markdown({breaks: true, typographer: true, xhtmlOut: true}).render(markdownText);
    },
    fetchDocument(id) {
      this.apiGetMissionReport(id).then(r => {
        this.report = r.data;

        this.report.summary = '**Einsatzbericht** vom **' + moment(this.report.createdAt).format('DD.MM.YYYY HH:ss') + '** :: ' + this.report.summary;
      })
    },
    formatDate(date) {
      if (date)
        return moment(date).format('DD.MM.YYYY HH:mm')
      return 'Keine Angabe';
    },
    userTypeToIcon() {
      if (this.userAccountType === 'POLICE') return 'mdi-police-badge-outline'
      if (this.userAccountType === 'MEDIC') return 'mdi-doctor'
      if (this.userAccountType === 'GOVERNMENT') return 'mdi-account-tie'
      return 'mdi-account';
    }
  },
  mounted() {
    this.id = this.$route.params.id || null;

    if (this.id) {
      this.fetchDocument(this.id);
    } else {
      this.$router.push('/reports')
    }
  }
}
</script>
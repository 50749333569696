<template>
  <v-container>
    <v-row>
      <v-col cols="8" offset="2">
        <v-card outlined elevation="2">
          <v-card-title class="pb-2 pt-2 pl-3">
            <v-icon color="primary" left>mdi-file-outline</v-icon>
            {{ applicationConfig.label }}: Bewerbungsprozess
          </v-card-title>
          <v-divider/>
          <v-card-text>
            <p>Hey! Hier kannst du dich für eine Stelle bei {{ applicationConfig.label }} bewerben.</p>
            <p>Beantworte das Formular bitte nur einmal.</p>
          </v-card-text>

          <div v-if="discordName == null">
            <v-card-text>
              <v-btn :href="discordAuthUrl" block color="primary" large>
                <v-icon class="pr-2">mdi-lock-open-outline</v-icon>
                über Discord anmelden
              </v-btn>
            </v-card-text>
          </div>

          <div v-else>
            <!-- OOC INFOS -->
            <v-divider/>
            <v-alert type="error" tile prominent elevation="4">
              <h3>OOC (<b>O</b>ut <b>O</b>f <b>C</b>haracter)</h3>
              Hier sind Informationen über dich <small>(nicht deinen Charakter)</small> gefragt
            </v-alert>
            <v-card-text class="pb-0 pt-4">
              <v-text-field v-model="discordName" placeholder="Discord-ID" solo dense outlined
                            prepend-inner-icon="mdi-message-text" readonly :hint="'ID: ' + discordId"
                            persistent-hint clearable @click:clear="clearDiscord()"/>
              <v-text-field v-model="entity.oocName" placeholder="Vorname" solo dense outlined
                            prepend-inner-icon="mdi-rename-box-outline"/>
              <v-text-field v-model="entity.oocBirthday" type="date" placeholder="Alter (mindestens 18)" solo dense
                            outlined prepend-inner-icon="mdi-counter"/>
            </v-card-text>

            <!-- IC INFOS -->
            <v-alert type="info" color="primary" tile prominent elevation="4">
              <h3>IC (<b>I</b>n <b>C</b>haracter)</h3>
              Hier sind Informationen über dein Charakter auf unserem Server gefragt
            </v-alert>
            <v-card-text class="pb-0 pt-4">
              <div v-for="(v, k) in applicationConfig.fields" :key="k">
                <v-textarea v-model="entity.custom[v.key]" v-if="v.type === 'textarea'" solo dense outlined
                            :hint="v.hint" persistent-hint :prepend-inner-icon="v.icon"/>
                <v-text-field v-model="entity.custom[v.key]" v-if="v.type === 'text-field'" solo dense outlined
                              :type="v.textFieldType ?? 'text'"
                              :hint="v.hint" persistent-hint :prepend-inner-icon="v.icon"/>
                <div v-if="v.type === 'rating'" class="pt-3 pb-3">
                  <h4>{{ v.hint }} ({{ entity.custom[v.key] || 0 }})</h4>
                  <v-rating :half-icon="v.halfIcon" v-model="entity.custom[v.key]" :full-icon="v.fullIcon"
                            :empty-icon="v.emptyIcon" :half-increments="v.halfIncrements" :length="v.length || 10"/>
                </div>
              </div>
            </v-card-text>
            <v-card-text>
              {{ entity }}
            </v-card-text>
            <v-divider/>
            <v-card-actions>
              <v-btn small color="primary" @click="submit">
                <v-icon class="pr-2">mdi-clipboard-text-outline</v-icon>
                Bewerbung einreichen
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Swal from 'sweetalert2';
import {POLICE as APPLICATION_CONFIG_POLICE} from "@/plugins/application-config";

export default {
  data:     () => ({
    entity: {
      oocName:     '',
      oocBirthday: null,

      custom: {},
    },
  }),
  methods:  {
    clearDiscord() {
      this.$store.commit('update', {
        discordName: null,
        discordId:   null
      });
    },

    submit() {
      let correct = true;

      let fields = JSON.parse(JSON.stringify(this.applicationConfig.fields)).reverse();

      for (let field of fields) {
        if (!this.entity.custom[field.key] && !field.optional) {
          correct = false;
          Swal.fire({
            icon: 'error',
            html: 'Das Feld <b>' + field.hint + '</b> muss ausgefüllt werden!'
          })
        }
      }

      if (correct) {
        // ignore
      }
    }
  },
  computed: {
    applicationConfig() {
      if (this.userAccountType === 'POLICE')
        return APPLICATION_CONFIG_POLICE;

      return {fields: []}
    },
    discordName() {
      return this.$store.state.discordName;
    },
    discordId() {
      return this.$store.state.discordId;
    },
    discordAuthUrl() {
      let redirectUrl = window.location.protocol + '//' + window.location.host + '/application/' + this.$route.meta.accountType.toLowerCase();
      return 'https://discord.com/api/oauth2/authorize?client_id=1071862929730842684&redirect_uri=' + encodeURIComponent(redirectUrl) + '&response_type=token&scope=identify'
    },
  },
}
</script>
<template>
  <v-container fluid fill-height fill-width>
    <v-card height="100%" fill-height fill-width width="100%">
      <iframe height="100%" width="100%" ref="iframe"></iframe>
    </v-card>
  </v-container>
</template>


<script>
export default {
  components: {},
  data:       () => ({}),
  methods:    {
    fetch(id) {
      this.apiGetInternalInfo(id).then(response => {
        let internalInfo  = response.data;

        this.apiGetInternalInfoDocumentUrlForPdf(internalInfo.id).then(attachmentUrl => {
          this.$refs.iframe.src  = attachmentUrl;
          this.$refs.iframe.type = 'application/json';
        })
      })
    }
  },
  mounted() {
    this.id = this.$route.params.id || null;

    if (this.id) {
      this.fetch(this.id);
    }
  }
}
</script>
<!--suppress JSUnresolvedVariable -->
<template>
  <v-container fluid>
    <v-card elevation="4" outlined>
      <v-card-title class="pb-2 pt-2">
        <v-icon left color="info">mdi-receipt-text-plus-outline</v-icon>
        Eingangsrechnung {{ invoice.id }}
        <v-spacer/>
      </v-card-title>
      <v-divider/>
      <v-row>
        <v-col>
          <v-card-text>
            <v-text-field type="text" v-model="invoice.source" solo dense outlined persistent-hint hint="Quelle"/>
            <v-text-field type="text" v-model="invoice.description" solo dense outlined persistent-hint
                          hint="Beschreibung"/>
            <v-text-field type="number" v-model="invoice.amount" solo dense outlined persistent-hint
                          hint="Rechnungsbetrag" append-icon="mdi-currency-eur"/>
            <v-select v-model="invoice.tax" solo dense outlined persistent-hint :items="TAX_TYPES" item-value="value"
                      item-text="label"
                      hint="Steuersatz"/>
          </v-card-text>
        </v-col>
        <v-col cols="12" md="6" lg="6" sm="12">
          <v-card-text>
            <iframe width="100%" height="1100px" :src="apiAttachmentUrl(invoice.attachment)+ '#zoom=100&toolbar=0'"/>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data:    () => ({
    invoice: {
      id: '',
    },
  }),
  methods: {
    fetchInvoice() {
      this.apiGetCreditInvoiceById(this.$route.params.id).then(r => {
        this.invoice = r.data;
      }).catch(() => {
        this.$router.push('/admin/credit-invoices');
      })
    },
  },
  mounted() {
    this.fetchInvoice();
  }
}
</script>
<template>
    <v-container fluid>
        <v-card :loading="loading">
            <v-card-title class="pb-2 pt-2">
                <v-icon class="pr-2">mdi-clipboard-alert-outline</v-icon>
                Schwer verletzte Personen
            </v-card-title>
            <v-divider/>

            <v-data-table sort-desc sort-by="createdAt"
                          :headers="[{text: 'Kritikalität', value: 'type'}, {text: 'Telefon-Nummer', value: 'phoneNumber'}, {text: 'Koordinaten', value: 'coordinate'}, {text: 'Erstellt am', value: 'createdAt'}, {text: 'Beamter', value: 'takenBy'}, {text: 'Aktionen', value: 'actions'}]"
                          :items="dispatches">
                <template v-slot:item.type="{item}">
                    <v-btn x-small v-if="item.type === 'OFFICIAL'" color="error" elevation="5" tile>HOCH</v-btn>
                    <v-btn x-small v-else tile elevation="0" color="blue-grey">NIEDRIG</v-btn>
                </template>
                <template v-slot:item.coordinate="{item}">
                    X={{ item.coordinate.x }}, Y={{ item.coordinate.y }}
                </template>
                <template v-slot:item.createdAt="{item}">
                    <span style="font-family: monospace;">{{ item.createdAt | datetime }}</span>
                </template>
                <template v-slot:item.takenBy="{item}">
                    {{ item.takenBy?.displayName }}
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn x-small fab color="red" :disabled="item.takenBy?.id === user.id"
                           @click="assignDispatch(item)" class="mr-2">
                        <v-icon>mdi-ambulance</v-icon>
                    </v-btn>
                    <v-btn x-small fab color="info" @click="apiMedicDispatchWaypoint(item)" class="mr-2">
                        <v-icon>mdi-google-maps</v-icon>
                    </v-btn>
                    <v-btn icon color="grey" @click="deleteDispatch(item)">
                        <v-icon>mdi-delete-sweep-outline</v-icon>
                    </v-btn>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>

<script>
export default {
    data: () => ({

        dispatches: [],
        loading:    false,
        interval:   null,
    }),

    methods: {
        fetch() {
            this.loading = true;
            return this.apiGetAllMedicDispatches().then(response => {
                this.dispatches = response.data;
            }).finally(() => {
                this.loading = false;
            })
        },
        assignDispatch(dispatch) {
            this.apiMedicDispatchAssignToMe(dispatch).then(() => {
                this.fetch();
            })
        },
        deleteDispatch(dispatch) {
            this.apiDeleteMedicDispatch(dispatch).then(() => {
                this.fetch();
            })
        },
    },
    mounted() {
        this.fetch();

        this.interval = setInterval(() => {
            this.fetch();
        }, 2000)
    },
    destroyed() {
        console.log('Leave PersonDownDispatch (closing Interval)')
        clearInterval(this.interval);
    }
}
</script>
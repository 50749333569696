export const POLICE = {
    label: 'Bundespolizei',

    fields: [
        {
            key:  'name',
            type: 'text-field',
            hint: 'Name',
            icon: 'mdi-account-outline',
        },

        {
            key:  'birthday',
            type: 'text-field',
            hint: 'Geburtsdatum',
            icon: 'mdi-calendar',

            textFieldType: 'date',
        },

        {
            key:  'why',
            type: 'textarea',
            hint: 'Wieso möchtest du zur Polizei?',
            icon: 'mdi-chat-question-outline',
        },

        {
            key:  'history',
            type: 'textarea',
            hint: 'Erzähl uns etwas über deine Vergangenheit (Lebenslauf)',
            icon: 'mdi-chat-question-outline',
        },
        {
            key: 'rp_rating',
            type: 'rating',
            hint: 'Wie schätzt du dein RP ein?',
            fullIcon: 'mdi-circle',
            halfIcon: 'mdi-circle-half-full',
            emptyIcon: 'mdi-circle-outline'
        }
    ]
}
<template>
    <div>
        <v-container fluid>
            <v-card elevation="4" tile>
                <v-card-title class="pa-2 pl-4">
                    <v-icon left color="primary">mdi-chair-rolling</v-icon>
                    Gewerbe Erstellen
                </v-card-title>
                <v-divider/>
                <v-card-text>
                    <div class="d-flex">
                        <div class="pa-3 hidden-sm-and-down">
                            <div class="company-file-image-background pa-3 mt-3 elevation-15">
                                <v-img :src="require('../../assets/paperclip.png')"
                                       class="criminal-file-image-paperclip"/>
                                <v-img
                                    :src="company.picture === null ? require('../../assets/company.png') : apiAttachmentUrl(company.picture)"
                                    height="320" width="320" class="rounded"/>
                            </div>
                        </div>
                        <div class="flex-fill pa-3">
                            <v-row>
                                <v-col sm="12" md="4" lg="4" xl="2" cols="12">
                                    <v-text-field v-model="company.iid" type="number" solo dense outlined hint="ID"
                                                  persistent-hint
                                                  prepend-inner-icon="mdi-identifier"/>
                                </v-col>
                                <v-col sm="12" md="4" lg="4" xl="8" cols="12">
                                    <v-text-field v-model="company.name" solo dense outlined hint="Name" persistent-hint
                                                  prepend-inner-icon="mdi-id-card"/>
                                </v-col>
                                <v-col sm="12" md="4" lg="4" xl="2" cols="12">
                                    <v-select solo dense outlined hint="Art" persistent-hint v-model="company.type"
                                              :items="companyTypes" item-text="label" item-value="type"
                                              prepend-inner-icon="mdi-smart-card-reader-outline"/>
                                </v-col>

                                <v-col sm="12" md="12" lg="6" xl="6" cols="12">
                                    <custom-date-picker :datetime="datePickerValue(company.nextPaymentLicenses)"
                                                        :text-field-props="{'color': 'info', 'outlined': true, 'dense': true, 'prependInnerIcon': 'mdi-calendar', 'hint': 'Nächster Zahlungsintervall', 'persistentHint': true}"
                                                        :time-picker-props="{'format': '24hr'}"
                                                        v-on:input="modifyEvent_nextPaymentLicenses"/>
                                </v-col>
                                <v-col sm="12" md="12" lg="6" xl="6" cols="12">
                                    <custom-date-picker :datetime="datePickerValue(company.vacatedUntil)"
                                                        :text-field-props="{'color': 'info', 'outlined': true, 'dense': true, 'prependInnerIcon': 'mdi-beach', 'hint': 'Urlaub bis', 'persistentHint': true}"
                                                        :time-picker-props="{'format': '24hr'}"
                                                        v-on:input="modifyEvent_vacatedUntil"/>
                                </v-col>

                                <v-col sm="12" md="12" lg="4" xl="4" cols="12">
                                    <v-text-field v-model="company.location" solo dense outlined hint="Adresse"
                                                  persistent-hint
                                                  prepend-inner-icon="mdi-home-city"/>
                                </v-col>
                                <v-col sm="12" md="12" lg="4" xl="4" cols="12">
                                    <v-text-field v-model="company.iban" solo dense outlined hint="IBAN" persistent-hint
                                                  prepend-inner-icon="mdi-credit-card-outline"/>
                                </v-col>
                                <v-col sm="12" md="12" lg="4" xl="4" cols="12">
                                    <v-text-field v-model="company.propertyStatus" solo dense outlined
                                                  hint="Zustand des Gebäude" persistent-hint
                                                  prepend-inner-icon="mdi-glass-fragile"/>
                                </v-col>
                                <v-col sm="12" md="12" lg="12" xl="12" cols="12">
                                    <v-autocomplete v-model="company.owner" :items="criminals" return-object
                                                    item-text="name" clearable
                                                    solo dense outlined hint="Besitzer" persistent-hint
                                                    prepend-inner-icon="mdi-account"/>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="warning" small :loading="saving"
                           @click="updateCompany()">
                        Erstellen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-container>

        <v-dialog v-model="addRepresentativeModal" @close="addRepresentativeModal=false;" width="500">
            <v-card>
                <v-card-title class="pb-2 pt-2">
                    <v-icon left>mdi-account-plus</v-icon>
                    Vertreter hinzufügen
                </v-card-title>
                <v-divider/>
                <v-card-text class="pb-0 mt-4">
                    <v-autocomplete v-model="addRepresentativeRecord" :items="criminals" return-object
                                    item-text="name" clearable
                                    solo dense outlined hint="Vertreter" persistent-hint
                                    prepend-inner-icon="mdi-account"/>
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-btn color="primary" small @click="addRepresentative()">
                        <v-icon left>mdi-content-save</v-icon>
                        Hinzufügen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<style>
.company-file-image-background {
    background-color: #838486;
    border-radius: 15px;
    transform: rotate(-3deg);
}
</style>

<!--suppress EqualityComparisonWithCoercionJS -->
<script>

import moment           from "moment";
import CustomDatePicker from "@/components/CustomDatePicker.vue";
import {COMPANY_TYPES}  from "../../plugins/config";

export default {
    components: {CustomDatePicker},
    data:       () => ({
        companyUnmodified: null,
        saving:            false,

        company: {
            iid:  0,
            name: '',
            picture: null,
            entries: [],
            authorizedRepresentatives: [],
            nextPaymentLicenses: new Date(),
            vacatedUntil: null,
            location: '',
            iban: 'DE',
            propertyStatus: '',
            type: 'RESTAURANT',
        },

        tabIndex: 0,

        addRepresentativeModal:  false,
        addRepresentativeRecord: null,

        criminals: [],
    }),
    computed:   {
        companyTypes() {
            let obj = [];

            for (let typeName in COMPANY_TYPES) {
                obj.push({type: typeName, label: COMPANY_TYPES[typeName]})
            }
            return obj;
        }
    },
    methods:    {
        test() {
        },
        updateCompany() {
            this.saving = true;

            this.apiCreateCompany(this.company).then(response => {
                this.$router.push('/company/' + response.data.id)
            })
        },
        fetchCriminals() {
            return this.apiGetCriminalRecords().then(r => {
                this.criminals = r.data;
            })
        },
        modifyEvent_nextPaymentLicenses(val) {
            if (val) {
                this.company.nextPaymentLicenses = moment(val).startOf('d').add(18, 'h').toISOString(true);
            } else {
                this.company.nextPaymentLicenses = null;
            }
            console.log(this.company.nextPaymentLicenses);
        },
        modifyEvent_vacatedUntil(val) {
            if (val) {
                this.company.vacatedUntil = moment(val).endOf('d').toISOString(true);
            } else {
                this.company.vacatedUntil = null;
            }
        },
        datePickerValue(val) {
            if (!val) {return null; }
            let date = moment(val);
            console.log(date.toISOString(true))
            return date.toISOString(true).substring(0, 10);
        },
    },
    mounted() {
        this.fetchCriminals();
    },
    watch: {}
}
</script>
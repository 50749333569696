<template>
    <v-container fluid>
        <v-card outlined elevation="4">
            <v-card-title class="pt-2 pb-2">
                <v-icon left color="warning">mdi-account-group-outline</v-icon>
                Mitarbeiter
                <v-spacer/>
                <v-text-field dense v-model="search" append-icon="mdi-magnify" label="Suche" single-line hide-details/>
            </v-card-title>

            <v-divider/>

            <v-data-table :items="remote.employees.data" :loading="remote.employees.loading"
                          :headers="remote.employees.header" :search="search">

                <template v-slot:item.username="{ item }">
                    {{ item.username }}
                    <v-icon color="info" right v-if="item.id === user.id">mdi-account</v-icon>
                </template>

                <template v-slot:item.actions="{ item }">
                    <v-btn icon small color="secondary"
                           v-if="userHasFlag('HR_CREATE') || user.admin || user.orgAdmin || user.id === item.id"
                           :to="'/employee/' + item.id + '/activity'">
                        <v-icon>mdi-chart-bar-stacked</v-icon>
                    </v-btn>
                    <v-btn icon small color="warning"
                           v-if="user.orgAdmin || user.admin || (user.flags.includes('HR_MODIFY') && user.id !== item.id && item.trustLevel < user.trustLevel)"
                           :to="'/employee/' + item.id">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon small color="error"
                           v-if="!item.locked && (user.orgAdmin || user.admin || (user.flags.includes('HR_LOCK') && user.id !== item.id && item.trustLevel < user.trustLevel))"
                           @click="toggleUserLock(item)">
                        <v-icon>mdi-lock</v-icon>
                    </v-btn>
                    <v-btn icon small color="success"
                           v-if="item.locked && (user.orgAdmin || user.admin || (user.flags.includes('HR_LOCK') && user.id !== item.id && item.trustLevel < user.trustLevel))"
                           @click="toggleUserLock(item)">
                        <v-icon>mdi-lock-open</v-icon>
                    </v-btn>
                    <v-btn icon small color="error"
                           v-if="item.locked && (user.orgAdmin || user.admin || user.flags.includes('HR_DELETE'))"
                           @click="deleteUser(item)">
                        <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                </template>

                <template v-slot:item.lastLogin="{ item }">
                    {{ item.lastLogin | datetime }}
                </template>

                <template v-slot:item.status="{ item }">
                    <v-btn v-if="item.absentUntil && moment(item.absentUntil).isAfter(moment())" outlined x-small color="warning" elevation="0">
                        <v-icon small left>mdi-umbrella-beach-outline</v-icon>
                        <b>Abwesend bis: {{ item.absentUntil | date }}</b>
                    </v-btn>

                    <v-btn v-else-if="item.locked" outlined x-small color="error" elevation="0">
                        <v-icon small left>mdi-lock</v-icon>
                        <b>Gesperrt</b>
                    </v-btn>
                    <v-btn v-else-if="item.admin" outlined x-small color="pink lighten-1" dark elevation="0">
                        <v-icon left small>mdi-shield-star-outline</v-icon>
                        <b>Administrator</b>
                    </v-btn>
                    <v-btn v-else-if="item.orgAdmin" outlined x-small color="success" elevation="0">
                        <v-icon left small>mdi-account-star-outline</v-icon>
                        <b>Manager</b>
                    </v-btn>
                    <v-btn v-else x-small color="blue-grey" outlined>
                        <v-icon left small>mdi-account-outline</v-icon>
                        <b>Mitarbeiter</b>
                    </v-btn>
                </template>
            </v-data-table>
            <div v-if="userHasFlag('HR_CREATE') || user.admin || user.orgAdmin">
                <v-divider/>
                <v-card-actions>
                    <v-btn color="primary" small to="/employees/add">
                        <v-icon left>mdi-plus</v-icon>
                        Mitarbeiter hinzufügen
                    </v-btn>

                    <v-btn color="secondary" small to="/employees/activity">
                        <v-icon left>mdi-chart-bar-stacked</v-icon>
                        Aktivität
                    </v-btn>
                </v-card-actions>
            </div>
        </v-card>
    </v-container>
</template>

<script>
import Swal from 'sweetalert2';
import moment from "moment";

export default {
    computed: {
        moment() {
            return moment
        }
    },
    data:    () => ({
        search: '',
        remote: {
            employees: {
                header:  [
                    {text: 'Benutzername', value: 'username'},
                    {text: 'Name', value: 'displayName'},
                    {text: 'Rank', value: 'rank'},
                    {text: 'Status', value: 'status'},
                    {text: 'Sicherheitsstufe', value: 'trustLevel'},
                    {text: 'Aktionen', value: 'actions'}
                ],
                loading: false,
                error:   null,
                data:    [],
            }
        }
    }),
    methods: {
        fetchEmployees() {
            this.remote.employees.loading = true;
            this.remote.employees.error   = null;

            this.apiGetEmployees().then(r => {
                this.remote.employees.data = r.data;
            }).catch(err => {
                this.remote.employees.error = err;
            }).finally(() => {
                this.remote.employees.loading = false;
            })
        },

        deleteUser(user) {
            Swal.fire({
                title:             'Bist du sicher?',
                html:              'Du kannst den Benutzer nicht wieder herstellen!<br/>Bist du dir zu <b>100%</b> sicher?',
                showCancelButton:  true,
                confirmButtonText: `Ja`,
                denyButtonText:    `Nein`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    this.quickUpdateUser(user, {hide: true})
                }
            })
        },

        quickUpdateUser(user, fields) {
            Object.keys(fields).forEach(k => {
                user[k] = fields[k];
            })

            this.apiUpdateEmployee(user.id, user).then(() => {
                this.fetchEmployees();
            });
        },

        toggleUserLock(user) {
            this.apiUpdateEmployeeToggleLock(user.id).then(() => {
                this.fetchEmployees();
            });
        }
    },
    mounted() {
        this.fetchEmployees();

        if (this.user.admin || this.user.orgAdmin || this.user.flags.includes('HR_CREATE')) {
            this.remote.employees.header.splice(this.remote.employees.header.length - 1, 0, {
                text:  'Letzte Anmeldung',
                value: 'lastLogin'
            });
        }
    }
}
</script>
<template>
  <v-container fluid>
    <v-card outlined elevation="4">
      <v-card-title v-html="mdToHtml(title)" class="pb-2 pt-2">
      </v-card-title>
      <v-divider/>

      <v-row>
        <v-col>
          <v-card-text class="text-box scrollbar-overflow-hidden ql-editor scrollbox" v-html="content">
          </v-card-text>
        </v-col>
      </v-row>

      <div v-if="user.trustLevel >= 1000 || user.orgAdmin || user.admin || user.flags.includes('MODIFY_INTERNAL_INFORMATION')">
        <v-divider/>
        <v-card-actions>
          <v-btn small icon color="warning lighten-1" class="mr-1"
                 :to="'/intern-info/' + id + '/edit'">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn small icon color="error lighten-1" class="mr-1">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-container>
</template>


<script>
import markdown from "markdown-it";

export default {
  components: {},
  data:       () => ({
    id:      null,
    title:   '',
    content: '',
    tab:     0,
  }),
  methods:    {
    mdToHtml(markdownText) {
      if (markdownText == null || markdownText.trim().length === 0) {
        return '<i>Keine Informationen angegeben.</i>'
      }

      return markdown({breaks: true, typographer: true, xhtmlOut: true}).render(markdownText);
    },
    fetchDocument(id) {
      this.apiGetInternalInfo(id).then(r => {
        console.log(r.data);
        this.title   = r.data.title;
        this.content = r.data.content;
      })
    },
  },
  mounted() {
    this.id = this.$route.params.id || null;

    if (this.id) {
      console.log("Opening Editor with existing ID");
      this.fetchDocument(this.id);
    }
  }
}
</script>
<template>
  <v-container fluid>
    <v-card class="pb-0 mb-0" outlined elevation="4">
      <v-tabs height="40px" v-model="tab" color="primary lighten-2">
        <v-tab>
          Editor
        </v-tab>
        <v-tab>
          HTML
        </v-tab>
        <v-tab>
          Preview
        </v-tab>
      </v-tabs>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field prepend-icon="mdi-form-textbox" label="Zusammenfassung / Titel"
                          solo outlined
                          v-model="report.summary" dense></v-text-field>
          </v-col>
          <v-col>
            <v-datetime-picker v-model="report.missionTime"
                               :text-field-props="{'outlined': true, 'solo': true, 'dense': true, 'prependIcon': 'mdi-calendar-alert'}"
                               :time-picker-props="{'format': '24hr'}">
              <template slot="dateIcon">
                <v-icon>mdi-calendar</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>mdi-clock</v-icon>
              </template>
            </v-datetime-picker>
          </v-col>
        </v-row>

        <v-row>
          <v-col v-if="userAccountType === 'MEDIC' || userAccountType === 'GOVERNMENT'">
            <v-select multiple :items="patients" return-object item-text="name" v-model="report.patients"
                      label="Betroffene Patienten" solo outlined
                      prepend-icon="mdi-account" dense></v-select>
          </v-col>
          <v-col v-if="userAccountType === 'POLICE' || userAccountType === 'GOVERNMENT'">
            <v-autocomplete multiple :items="criminals" return-object item-text="name" v-model="report.criminals"
                            label="Täter / Opfer" solo outlined
                            prepend-icon="mdi-account" dense></v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete multiple :items="partners" return-object item-text="displayName" v-model="report.partners"
                            label="Kollegen" solo outlined
                            prepend-icon="mdi-account-group" dense></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider/>

      <v-row v-if="tab === 2">
        <v-col>
          <v-card-text class="text-box scrollbar-overflow-hidden ql-editor" v-html="report.content">
          </v-card-text>
        </v-col>
      </v-row>

      <v-card-text>
        <vue-editor v-model="report.content" v-if="tab === 0" style="height: 100%"
                    :customModules="editor.customModules"
                    :editorOptions="editor.settings"/>
        <v-textarea solo outlined rows="30" v-model="report.content" v-if="tab === 1" class="pa-4"></v-textarea>
      </v-card-text>

      <v-divider/>
      <v-card-actions>
        <v-btn small color="success" v-if="id" @click="saveEntry">
          <v-icon left>mdi-content-save-outline</v-icon>
          Speichern
        </v-btn>
        <v-btn small color="primary" v-else @click="createEntry">
          <v-icon left>mdi-content-save-outline</v-icon>
          Erstellen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import {VueEditor} from "vue2-editor";
import ImageResize from 'quill-image-resize-module';

export default {
  components: {VueEditor},
  data:       () => ({
    id: null,

    report: {
      summary:     '',
      content:     '',
      partners:    [],
      criminals:   [],
      patients:    [],
      missionTime: null,
    },

    tab: 0,

    criminals: [],
    patients:  [],
    partners:  [],

    editor: {
      customModules: [
        {alias: 'imageResize', module: ImageResize},
      ],
      settings:      {
        theme:   'snow',
        modules: {
          table:       false,
          imageResize: {},
        },
      }
    }
  }),
  methods:    {
    fetchMissionReport(id) {
      this.apiGetMissionReport(id).then(r => {
        this.report             = r.data;
        this.report.missionTime = new Date(this.report.missionTime);
      })
    },

    fetchCriminalRecords() {
      this.apiGetCriminalRecords().then(r => {
        this.criminals = r.data;
      });
    },
    fetchPatients() {
      this.apiGetMedicalRecords().then(r => {
        this.patients = r.data;
      });
    },
    fetchPartners() {
      this.apiGetMissionReportEmployees().then(r => {
        this.partners = r.data.filter(r => r.id !== this.user.id);
      });
    },

    saveEntry() {
      if (this.report.missionTime)
        this.report.missionTime = new Date(this.report.missionTime.getTime() - (this.report.missionTime.getTimezoneOffset() * 60000));

      this.apiUpdateMissionReport(this.id, this.report).then(r => {
        this.$router.push('/report/' + r.data.id);
      })
    },
    createEntry() {
      if (this.report.missionTime)
        this.report.missionTime = new Date(this.report.missionTime.getTime() - (this.report.missionTime.getTimezoneOffset() * 60000));

      this.apiCreateMissionReport(this.report).then(r => {
        this.$router.push('/report/' + r.data.id);
      })
    }
  },
  mounted() {
    this.id = this.$route.params.id || null;

    if (this.id) {
      this.fetchMissionReport(this.id);
    }

    if (this.userAccountType === 'MEDIC' || this.userAccountType === 'GOVERNMENT') this.fetchPatients();
    if (this.userAccountType === 'POLICE' || this.userAccountType === 'GOVERNMENT') this.fetchCriminalRecords();
    this.fetchPartners();
  }
}
</script>
<template>
    <v-container fluid>
        <v-card :loading="loading">
            <v-card-title class="pt-2 pb-2">
                <v-icon left>mdi-{{ message.icon || (message.openedAt ? 'email-open-outline' : 'email-outline') }}</v-icon>
                {{ message.title }}
            </v-card-title>
            <v-divider/>
            <v-card-text v-if="loading || resizeLoader">
                <v-progress-circular indeterminate></v-progress-circular>
            </v-card-text>
            <v-card-text v-show="!resizeLoader && !loading">
                <div style="padding: 10px 10px 10px 20px;border-radius: 15px; background-color: rgba(98,82,74,0.87)">
                    <iframe scrolling="no" id="letter" @load="resizeIframe" src="about:blank" style="height: 1px;"></iframe>
                </div>
            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-btn small color="primary" @click="respondToMail">
                    <v-icon left>mdi-email-fast</v-icon>
                    Auf E-Mail antworten
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<style>
iframe {
    width: 100%;
    border: 0;
    overflow: hidden;
}

</style>

<script>
import markdown                from "markdown-it";
import markdownContainer       from 'markdown-it-container';
import markdownAbbr            from 'markdown-it-abbr';
import {full as markdownEmoji} from 'markdown-it-emoji'
import markdownDefList         from 'markdown-it-deflist'
import markdownIns             from 'markdown-it-ins'
import twemoji                 from "twemoji";

export default {
    data:     () => ({
        letterId: null,
        loading:  false,
        message:  {
            content: ''
        },

        resizeLoader:  false,
        resizeWatcher: null,
    }),
    watch:    {
        'message.content': function (val) {
            const md = markdown("commonmark", {
                html:        true,
                linkify:     true,
                typographer: true,
                table:       true,
            })
                .use(markdownContainer, 'warning')
                .use(markdownEmoji)
                .use(markdownAbbr)
                .use(markdownDefList)
                .use(markdownIns);

            console.log(val);

            if (this.message?.content) {

                console.log('Writing to iframe 1');

                md.renderer.rules.emoji = function (token, idx) {
                    console.log(token);
                    return twemoji.parse(token[idx].content, {base: 'https://raw.githubusercontent.com/twitter/twemoji/master/assets/'});
                }

                const iframe = document.getElementById('letter');

                const css = '<link rel="stylesheet" type="text/css" href="/bootstrap.css" />\n' +
                    '<link rel="stylesheet" type="text/css" href="/github.min.css" />\n' +
                    '<link rel="stylesheet" type="text/css" href="/markdown.css" />';

                const html = css + md
                    .render(val);

                console.log('Writing to iframe 2');

                const doc = iframe.contentWindow.document;
                doc.open();
                doc.write(html);
                doc.close();
            } else {
                return '<p>No content</p>';
            }
        }
    },
    computed: {},
    methods:  {
        respondToMail() {
            if (this.message.author.id === this.user.id) {
                this.$router.replace({
                    name: 'LetterCreate', params: {
                        title:     'Re: ' + this.message.title,
                        recipient: this.message.recipient,
                        category:  this.message.category,
                    }
                });
            } else {
                this.$router.replace({
                    name: 'LetterCreate', params: {
                        title:     'Re: ' + this.message.title,
                        recipient: this.message.author,
                        category:  this.message.category,
                    }
                });
            }
        },
        fetchMessage(letterId) {
            this.loading = true;
            this.apiGetMessage(letterId).then(r => {
                this.message = r.data
                this.loading = false;
            })
        },
        resizeIframe() {
            this.resizeLoader = false;
            const iframe      = document.getElementById('letter');
            if (iframe) {
                const height        = iframe.contentWindow.document.body.scrollHeight
                iframe.style.height = height + 'px';
            }
        }
    },
    mounted() {
        this.letterId = this.$route.params.letterId;
        this.fetchMessage(this.letterId);
    },
    beforeDestroy() {
        clearInterval(this.resizeWatcher);
    },
}
</script>
<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="pt-2 pb-2 pl-3">
        <v-icon left>mdi-archive</v-icon>
        Medizinische Behandlung
      </v-card-title>
      <v-divider/>
      <v-card-text class="pb-0">
        <v-autocomplete :items="medicalRecords" return-object :item-text="formatMedicalRecordName"
                        v-model="entry.record"
                        hint="Patient" solo outlined clearable persistent-hint
                        prepend-inner-icon="mdi-account" dense></v-autocomplete>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-btn color="primary" outlined small @click="createStep(v)" v-for="(v) in types" :key="v">
          <v-icon left>mdi-plus</v-icon>
          {{ v }}
        </v-btn>
      </v-card-actions>
      <v-divider/>
      <v-tabs v-model="currentStep">
        <v-tab v-for="v in entry.steps" :key="v.id">
          {{ v.type }}
        </v-tab>
      </v-tabs>
      <div v-if="activeStep">
        <v-divider/>
        <v-card-text>
          <v-text-field solo dense outlined :value="activeStep.user.displayName" disabled hint="Mediziner"
                        persistent-hint/>

          <v-row>
            <v-col cols="6">
              <b>Notiz</b>
              <v-textarea v-model="activeStep.content" solo dense outlined/>
            </v-col>
            <v-col cols="6">
              <b>Medikamente</b>
              <v-card outlined elevation="2">
                <v-list dense>
                  <v-subheader>Medikamente ({{ activeStep.medication?.length }})</v-subheader>
                  <v-list-item-group>
                    <v-list-item v-for="(v, k) in activeStep.medication" :key="v.name" inactive>
                      <v-list-item-icon>
                        <v-icon>mdi-pill-multiple</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ v.name }} x{{ v.amount }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon x-small @click="removeMedication(k)">
                          <v-icon size="24">mdi-delete</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list-item-group>

                  <v-subheader>Befunde ({{ activeStep.findings?.length }})</v-subheader>
                  <v-list-item-group>
                    <v-list-item v-for="v in activeStep.findings" :key="v.number" inactive>
                      <v-list-item-icon>
                        <v-icon>mdi-clipboard-alert-outline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ v.number }} {{ v.text }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn icon x-small>
                          <v-icon size="24">mdi-delete</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn small color="success" outlined>
            <v-icon class="pr-2">mdi-clipboard-alert-outline</v-icon>
            Medizinischer Befund
          </v-btn>
          <v-btn small color="success" outlined @click="openMedicationDialog()">
            <v-icon class="pr-2">mdi-medication-outline</v-icon>
            Medikament
          </v-btn>

          <v-btn small color="success" outlined>
            <v-icon class="pr-2">mdi-check-all</v-icon>
            Behandlung abschließen
          </v-btn>
          <v-spacer/>
          <v-btn color="orange" small @click="removeStep(currentStep)">
            <v-icon left>mdi-minus</v-icon>
            Behandlungsschritt entfernen
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>

    <v-dialog v-model="dialogAddMedication" width="400px" persistent>
      <v-card>
        <v-card-title class="pt-2 pb-2 pl-3">
          <v-icon class="pr-2">mdi-medication-outline</v-icon>
          Medikament hinzufügen
        </v-card-title>
        <v-divider/>
        <v-card-text class="pb-1 pt-5">
          <v-autocomplete v-model="addMedicationName" :items="medication" solo dense outlined hint="Medikament"
                          persistent-hint/>
          <v-text-field v-model="addMedicationAmount" type="number" solo dense outlined hint="Anzahl" persistent-hint/>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn small color="success" @click="addMedication()">
            <v-icon left>mdi-pill-multiple</v-icon>
            Okay
          </v-btn>
          <v-spacer/>
          <v-btn small color="warning" @click="dialogAddMedication = false;">
            Abbrechen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAddFinding" width="400px">

    </v-dialog>
  </v-container>
</template>


<script>
import {v4 as uuidv4} from 'uuid';
import moment from "moment";

export default {
  data:     () => ({
    medicalRecords: [],

    dialogAddMedication: false,
    dialogAddFinding:    false,

    addMedicationName:   null,
    addMedicationAmount: 1,

    types: ['Transport', 'Behandlung', 'Operation', 'Nachkontrolle'],

    medication: [
      'Antibiotika',
      'Ibuprofen 600',
      'Ibuprofen 800',
      'Ibuprofen 1000',
      'Paracetamol',
      'Novalgin',
      'Kühlpack',
      'Voltaren Schmerzgel',
      'Tramadol',
      'Bepanthen',
      'Fentanyl',
      'Infusion',
      'Morphium',
      'Pantoprazol',
      'Imodium Akut',
      'Amlodipin',
    ],

    currentStep: 0,

    entry: {
      id:     null,
      record: null,
      steps:  [],
    },

    websocketSubscription: null,

    currentContent:    '',
    currentMedication: [],

    lastLocalModification: moment(),
  }),
  computed: {
    activeStep() {
      if (this.entry.steps.length === 0) return null;
      return this.entry.steps[this.currentStep];
    }
  },
  methods:  {

    async websocketReportAddStep(stepObj) {
      (await this.waitForWebsocket()).publish({
        destination: '/app/medic/medical-examination/' + this.entry.id, body: JSON.stringify({
          type: 'ADD_STEP',
          step: stepObj
        })
      })
    },

    async websocketReportRemoveStep(stepObj) {
      (await this.waitForWebsocket()).publish({
        destination: '/app/medic/medical-examination/' + this.entry.id, body: JSON.stringify({
          type: 'REMOVE_STEP',
          step: stepObj
        })
      });
    },

    async websocketReportUpdateStep(stepObj) {
      (await this.waitForWebsocket()).publish({
        destination: '/app/medic/medical-examination/' + this.entry.id, body: JSON.stringify({
          type: 'UPDATE_STEP',
          step: stepObj
        })
      });
    },

    async websocketSubscribe() {
      this.websocketSubscription = (await this.waitForWebsocket()).subscribe('/topic/medic/medical-examination/' + this.entry.id, msg => {
        let obj = JSON.parse(msg.body);

        if (obj.type === 'ADD_STEP') {
          this.entry.steps.push(obj.step);
        } else if (obj.type === 'REMOVE_STEP') {
          this.entry.steps.splice(this.getStepIndexOf(obj.step.id), 1);
          if (this.entry.steps.length > 0) this.currentStep = 0;
        } else if (obj.type === 'UPDATE_MEDICAL_RECORD') {
          this.entry.record = obj.medicalRecord;
        } else if (obj.type === 'UPDATE_STEP') {
          if (moment().add(5, 'seconds').isAfter(this.lastLocalModification)) {
            this.entry.steps.forEach(value => {
              if (value.id === obj.step.id) {
                if (this.activeStep.id === obj.step.id) {
                  this.currentContent    = value.content;
                  this.currentMedication = value.medication;
                }

                value.content    = obj.step.content;
                value.medication = obj.step.medication;
              }
            })
          }
        }
      });
    },

    getStepIndexOf(stepId) {
      let id = 0;
      for (let i = 0; i < this.entry.steps.length; i++) {
        if (this.entry.steps[i].id === stepId) id = i;
      }
      return id;
    },

    openMedicationDialog() {
      this.dialogAddMedication = true;
      this.addMedicationName   = null;
      this.addMedicationAmount = 1;
    },

    formatMedicalRecordName(item) {
      return item.name + ' ' + item.birthday;
    },

    fetchMasterDataRecords() {
      this.apiGetMedicalRecords().then(response => {
        this.medicalRecords = response.data;
      });
    },

    createStep(type) {
      let stepObj = {
        id:         uuidv4(),
        type:       type,
        user:       this.user,
        content:    '',
        medication: [],
        findings:   [],
      };

      this.websocketReportAddStep(stepObj);
      this.currentStepId = stepObj.id;
    },

    addMedication() {
      this.entry.steps[this.currentStep].medication.push(
          {name: this.addMedicationName, amount: this.addMedicationAmount}
      );
      this.dialogAddMedication = false;
    },
    removeMedication(index) {
      this.entry.steps[this.currentStep].medication.splice(index, 1);
    },

    removeStep(stepIndex) {
      this.websocketReportRemoveStep(this.entry.steps[stepIndex]);
    },
  },
  async mounted() {
    this.entry.id = this.$route.params.id;

    this.apiGetMedicalExamination(this.entry.id).then(r => {
      this.entry = r.data;
      this.websocketSubscribe();
    }).catch(() => {
      this.apiCreateMedicalExamination().then(r => {
        this.$router.push('/medical-examination/' + r.data.id);
      })
    })

    this.fetchMasterDataRecords();
  },
  watch: {
    'currentStep': {
      handler: function (newValue) {
        this.currentMedication = this.entry.steps[newValue].medication;
        this.currentContent    = this.entry.steps[newValue].content;
      },
    },
    'activeStep':  {
      handler: function () {
        if (this.activeStep.content !== this.currentContent) {
          this.websocketReportUpdateStep(this.activeStep);
          this.currentContent        = this.activeStep.content;
          this.lastLocalModification = moment();
        }
      },
      deep:    true,
    }
  },
  beforeDestroy() {
    this.websocketSubscription?.unsubscribe();
  }
}
</script>
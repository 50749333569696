<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="pb-2 pt-2">
        <v-icon left color="warning">mdi-account-tie-hat-outline</v-icon>
        <span v-if="course.id">Ausbildung bearbeiten</span>
        <span v-else>Ausbildung anlegen</span>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field prepend-inner-icon="mdi-form-textbox" placeholder="Grundwaffenausbildung"
                          hint="Name der Ausbildung" v-model="course.title" solo outlined dense persistent-hint/>
          </v-col>
          <v-col>
            <v-select prepend-inner-icon="mdi-account-tie-hat-outline" :items="employees" dense solo outlined
                      return-object item-text="displayName" v-model="course.trainers" multiple/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field prepend-inner-icon="mdi-form-textbox" placeholder="1. Grundausbildungen"
                          hint="Kategorie" v-model="course.category" solo outlined dense persistent-hint/>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-btn small color="primary lighten-2" @click="saveCourse">
          <v-icon left>mdi-content-save-outline</v-icon>
          Speichern
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data:    () => ({
    course: {
      id:          null,
      title:       '',
      description: '',
      category:    '',

      trainers:  [],
      applicant: [],

      accountType: null,
    },

    employees: [],
  }),
  methods: {
    saveCourse() {
      this.course.applicant = null;

      if (this.course.id) {
        this.apiUpdateCourseById(this.course.id, this.course).finally(() => this.$router.push('/courses'));
      } else {
        this.apiCreateCourse(this.course).finally(() => this.$router.push('/courses'));
      }
    },

    fetchRequiredData() {
      this.apiGetEmployees(false).then(response => {
        this.employees = response.data;
      })
    }
  },
  mounted() {
    this.course.accountType = this.user.accountType;

    this.fetchRequiredData();

    if (this.$route.params.id) {
      this.apiGetCourseById(this.$route.params.id).then(r => {
        this.course = r.data;
      }).catch(() => {
        this.$router.push('/courses')
      })
    }
  }
}
</script>
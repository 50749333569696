<!--suppress JSUnresolvedVariable -->
<template>
    <v-container fluid>
        <v-card>
            <v-card-title class="pt-2 pb-2">
                <v-icon left>mdi-wardrobe-outline</v-icon>
                Garderobe für Jobs (Manager)
            </v-card-title>
            <v-divider/>
            <v-card-text>
                
            </v-card-text>
        </v-card>
    </v-container>
</template>

<!--suppress ES6UnusedImports -->
<script>


export default {
    data: () => ({
        data: [],
    }),
    mounted() {
    },
    computed: {
    },
    methods:  {
    },
    watch:    {
    }
}
</script>
<!--suppress JSUnresolvedReference -->
<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" lg="6" xl="8">
                <v-card color="warning" dark tile elevation="10">
                    <v-card-title class="pt-2 pb-2">
                        <v-icon class="pr-2">mdi-alert-circle-outline</v-icon>
                        Einsatzzentrale
                    </v-card-title>
                </v-card>
                <v-card tile elevation="2">
                    <v-card-title class="pt-2 pb-1">
                        <v-icon class="pr-2" color="error">mdi-chat-alert-outline</v-icon>
                        Leitstellen Dispatches
                    </v-card-title>
                    <v-divider/>
                    <v-data-table hide-default-footer :items-per-page="-1" sort-desc sort-by="createdAt"
                                  :headers="[{text: 'Telefon-Nummer', value: 'phoneNumber', width: '160px'}, {text: 'Nachricht', value: 'message'}, {text: 'Erstellt am', value: 'createdAt'}, {text: 'Beamter', value: 'takenBy'}, {text: 'Aktionen', value: 'actions'}]"
                                  :items="dispatches">
                        <template v-slot:item.type="{item}">
                            <v-btn x-small v-if="item.type === 'OFFICIAL'" color="error" elevation="5" tile>HOCH
                            </v-btn>
                            <v-btn x-small v-else tile elevation="0" color="blue-grey">NIEDRIG</v-btn>
                        </template>
                        <template v-slot:item.createdAt="{item}">
                            <span style="font-family: monospace;">{{ item.createdAt | time }}</span>
                        </template>
                        <template v-slot:item.takenBy="{item}">
                            <user-avatar v-if="item.takenBy" :size="24" :user-obj="item.takenBy" cache class="mr-2"/>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-btn icon small color="primary" @click="dispatchAssign(item)" class="mr-2">
                                <v-icon>mdi-clipboard-text</v-icon>
                            </v-btn>
                            <v-btn icon small color="info" :disabled="!item.coordinate"
                                   @click="apiDispatchWaypoint(item)" class="mr-2">
                                <v-icon>mdi-google-maps</v-icon>
                            </v-btn>
                            <v-btn icon small color="grey" @click="dispatchDelete(item)">
                                <v-icon>mdi-delete-sweep-outline</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                    <div v-if="isMedic">
                        <v-divider/>
                        <v-card-title class="pt-1 pb-1">
                            <v-icon class="pr-2" color="error">mdi-clipboard-alert-outline</v-icon>
                            Schwer verletzte Personen
                        </v-card-title>
                        <v-divider/>
                        <v-data-table hide-default-footer :items-per-page="-1" sort-desc sort-by="createdAt"
                                      :headers="[{text: 'Telefon-Nummer', value: 'phoneNumber', width: '160px'}, {text: 'Kritikalität', value: 'type', width: '120px'}, {text: 'Erstellt am', value: 'createdAt'}, {text: 'Beamter', value: 'takenBy'}, {text: 'Aktionen', value: 'actions'}]"
                                      :items="medicDispatches">
                            <template v-slot:item.type="{item}">
                                <v-btn x-small v-if="item.type === 'OFFICIAL'" color="error" elevation="5" tile>HOCH
                                </v-btn>
                                <v-btn x-small v-else tile elevation="0" color="blue-grey">NIEDRIG</v-btn>
                            </template>
                            <template v-slot:item.createdAt="{item}">
                                <span style="font-family: monospace;">{{ item.createdAt | datetime }}</span>
                            </template>
                            <template v-slot:item.takenBy="{item}">
                                <user-avatar v-if="item.takenBy" :size="24" :user-obj="item.takenBy" cache class="mr-2"/>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-btn icon small color="primary" @click="medicDispatchAssign(item)" class="mr-2">
                                    <v-icon>mdi-clipboard-text</v-icon>
                                </v-btn>
                                <v-btn icon small color="info" :disabled="!item.coordinate"
                                       @click="apiMedicDispatchWaypoint(item)" class="mr-2">
                                    <v-icon>mdi-google-maps</v-icon>
                                </v-btn>
                                <v-btn icon color="grey" @click="medicDispatchDelete(item)">
                                    <v-icon>mdi-delete-sweep-outline</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </div>
                </v-card>
            </v-col>

            <v-col cols="12" lg="6" xl="4">
                <v-card>
                    <v-card-title class="pt-2 pb-2 pl-3">
                        <v-icon left>mdi-calendar</v-icon>
                        Dienstplan
                    </v-card-title>
                    <v-divider/>

                    <div v-for="(unitType, i) in rosterKeys" :key="i">
                        <v-card :color="rosterColor(unitType)" flat tile dark>
                            <v-card-title class="pt-2 pb-2">
                                <b>{{ accountTypeToLabel(unitType) }} - {{ roster[unitType].length }} im Dienst</b>
                            </v-card-title>
                            <v-divider/>

                            <v-expansion-panels dark flat accordion>
                                <v-expansion-panel v-for="(v, k) in roster[unitType]" :key="k" :disabled="!v.patrolUnit">
                                    <v-expansion-panel-header
                                        :expand-icon="v.patrolUnit ? 'mdi-chevron-up-box-outline' : ''"
                                        :color="rosterColor(v)">
                                        <div>
                                            <user-avatar :size="32" :user-obj="v.user" cache class="mr-3"/>
                                            <div class="pr-3 pl-3 d-inline">
                                                <b style="font-size: 17px;font-family: 'Montserrat', 'Nunito', sans-serif;color: white;">
                                                    {{ v.user.displayName }}
                                                </b>
                                            </div>
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content :color="rosterColor(v) + ' darken-3'" v-if="v.patrolUnit" class="pt-3">
                                        <div v-for="(partner, k) in v.patrolUnit.partners" :key="k">
                                            <b>Partner {{ k + 1 }}: </b>{{ partner.displayName }}<br/>
                                        </div>

                                        <div v-if="v.patrolUnit.partners.length === 0"><b>Beamter 2: </b><i>Niemand</i><br/>
                                        </div>

                                        <div v-if="v.patrolUnit.district">
                                            <b>Einteilung: </b>{{ v.patrolUnit.district }}<br/>
                                        </div>

                                        <div v-else><b>Einteilung: </b><i>Keine genaue Einteilung</i><br/></div>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-card>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Swal            from 'sweetalert2';
import UserAvatar      from "@/components/UserAvatar.vue";
import {ACCOUNT_TYPES} from "@/config";

export default {
    components: {UserAvatar},
    data:       () => ({
        roster: [],

        dispatches:      [],
        medicDispatches: [],

        interval: null,

        activeRosters: [0, 1, 2, 3],
    }),
    computed:   {
        isMedic() {
            return this.userHasFlag('MEDIC_POLICE', true) || this.userAccountType === 'MEDIC'
        },

        rosterKeys() {
            let keys = [];

            if (this.roster[this.userAccountType]) {
                keys.push(this.userAccountType);
            }

            for (let foreignAccountType in this.roster) {
                if (foreignAccountType !== this.userAccountType) {
                    keys.push(foreignAccountType);
                }
            }

            return keys;
        }
    },
    methods:    {
        accountTypeToLabel(accountType) {
            for (let obj of ACCOUNT_TYPES) {
                if (obj.accountType === accountType) return obj.label;
            }
            return accountType;
        },

        fetchRoster() {
            return this.apiGetRoster().then(r => {
                let data    = r.data;
                let grouped = {}

                for (let entry of data) {
                    grouped[entry.accountType] = grouped[entry.accountType] || [];
                    grouped[entry.accountType].push(entry);
                }

                this.roster = grouped;
            })
        },
        fetchDispatches() {
            if (this.isMedic) {
                console.log('Fetching all Dispatches (Medic+CurrentSociety)')
                Promise.allSettled([
                    this.apiGetAllDispatches(),
                    this.apiGetAllMedicDispatches()
                ]).then(r => {
                    this.dispatches      = r[0].value.data;
                    this.medicDispatches = r[1].value.data;
                })
            } else {
                return this.apiGetAllDispatches().then(r => {
                    this.dispatches = r.data;
                })
            }
        },

        rosterColor(roster) {
            let unitType   = roster?.patrolUnit?.unitType || roster?.user?.accountType;
            let onCallDuty = roster?.onCallDuty || false;

            if (typeof roster == 'string') {
                unitType = roster;
            }

            if (onCallDuty) return 'grey darken-3';

            if (unitType === this.userAccountType) {
                return 'primary';
            }

            if (unitType === 'MEDIC')
                return 'error darken-2'
            if (unitType === 'ZOLL')
                return 'blue darken-1'
            if (unitType === 'CORRECTIONAL')
                return 'blue darken-2'
            if (unitType === 'POLICE')
                return 'info'
            if (unitType === 'GOVERNMENT')
                return 'blue-grey'

            return 'primary';
        },


        medicDispatchDelete(dispatch) {
            this.apiDeleteMedicDispatch(dispatch).then(() => {
                this.fetchDispatches();
            })
        },

        medicDispatchAssign(dispatch) {
            Swal.fire({
                icon:             'question',
                html:             'Streife zuweisen',
                input:            'select',
                showCancelButton: true,
                showDenyButton:   true,
                denyButtonText:   'Abbrechen',
                cancelButtonText: 'Mich',

                inputOptions: new Promise(resolve => {
                    let options = {};
                    for (let rosterEntry of this.roster) {
                        if (rosterEntry.patrolUnit)
                            options[rosterEntry.user.id] = rosterEntry.patrolUnit.name + " (" + rosterEntry.user.displayName + ")"
                        else
                            options[rosterEntry.user.id] = rosterEntry.user.displayName;
                    }
                    resolve(options);
                })
            }).then(r => {
                if (r.isConfirmed) {
                    dispatch.takenBy = {id: r.value};
                    this.apiMedicDispatchAssignTo(dispatch, r.value).then(() => {
                        this.fetchDispatches();
                    })
                } else if (r.isDismissed && r.dismiss === Swal.DismissReason.cancel) {
                    dispatch.takenBy = this.user;
                    this.apiMedicDispatchAssignToMe(dispatch).then(() => {
                        this.fetchDispatches();
                    })
                }
            })
        },

        dispatchAssign(dispatch) {
            Swal.fire({
                icon:             'question',
                html:             'Streife zuweisen',
                input:            'select',
                showCancelButton: true,
                showDenyButton:   true,
                denyButtonText:   'Abbrechen',
                cancelButtonText: 'Mich',

                inputOptions: new Promise(resolve => {
                    let options = {};
                    for (let rosterEntry of this.roster) {
                        if (rosterEntry.patrolUnit)
                            options[rosterEntry.user.id] = rosterEntry.patrolUnit.name + " (" + rosterEntry.user.displayName + ")"
                        else
                            options[rosterEntry.user.id] = rosterEntry.user.displayName;
                    }
                    resolve(options);
                })
            }).then(r => {
                if (r.isConfirmed) {
                    dispatch.takenBy = {id: r.value};
                    this.apiUpdateDispatch(dispatch).then(() => {
                        this.fetchDispatches();
                    })
                } else if (r.isDismissed && r.dismiss === Swal.DismissReason.cancel) {
                    dispatch.takenBy = this.user;
                    this.apiUpdateDispatch(dispatch).then(() => {
                        this.fetchDispatches();
                    })
                }
            })
        },

        dispatchDelete(dispatch) {
            this.apiDeleteDispatch(dispatch).then(() => {
                this.fetchDispatches();
            })
        }
    },
    mounted() {
        this.fetchRoster();
        this.fetchDispatches();

        this.interval = setInterval(() => {
            this.fetchRoster();
            this.fetchDispatches();
        }, 2000)
    },
    destroyed() {
        console.log('Leaving ControlCenterView (destroying Interval)')
        clearInterval(this.interval);
    }
}
</script>
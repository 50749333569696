<template>
    <v-container fluid>
        <v-card elevation="4" outlined>

            <div v-if="user.orgAdmin || user.admin || user.accountType === 'FEDERAL_INTELLIGENCE_SERVICE'">
                <v-card-title class="pb-2 pt-2">
                    <v-icon left color="info">mdi-card-account-details-outline</v-icon>
                    Most Wanted

                    <v-btn x-small color="primary lighten-2" outlined class="ml-3" @click="openBNDInfo()">
                        <v-icon small class="pr-2">mdi-content-save</v-icon>
                        Bundesnachrichtendienst Informationsblatt
                    </v-btn>
                </v-card-title>
                <v-divider/>
                <v-data-table :headers="tblHeaderMostWanted" :items="mostWanted" multi-sort
                              :sort-by="['totalJailTime']" sort-desc dense hide-default-footer>
                    <template v-slot:item.actions="{ item }">
                        <v-btn icon color="primary lighten-1" @click="openRecord(item)"
                               v-if="user.trustLevel >= item.record.trustLevel">
                            <v-icon>mdi-eye-outline</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:item.record.birthday="{ item }">
                        {{ item.record.birthday | date }}
                    </template>
                    <template v-slot:item.totalFine="{ item }">
                        {{ item.totalFine | money }}
                    </template>
                </v-data-table>
                <v-divider/>
            </div>

            <v-card-title class="pb-2 pt-2">
                <v-icon left color="info">mdi-card-account-details-outline</v-icon>
                Zuletzt angelegte Akteneinträge
                <v-spacer/>
                <v-text-field dense v-model="search" append-icon="mdi-magnify" label="Suche" single-line hide-details/>
            </v-card-title>
            <v-divider/>
            <v-data-table :loading="loading" :headers="tblHeaders" :items="records" item-key="id" sort-by="createdAt"
                          sort-desc :search="search">
                <template v-slot:item.actions="{ item }">
                    <v-btn icon color="primary lighten-1" @click="previewRecordEntry(item)"
                           v-if="user.trustLevel >= item.trustLevel && (item.type !== 'INVESTIGATION' || userHasFlag('CRIMINAL_POLICE'))">
                        <v-icon>mdi-resize</v-icon>
                    </v-btn>
                    <v-btn icon color="primary lighten-1" @click="openRecord(item)" v-if="user.trustLevel >= item.trustLevel">
                        <v-icon>mdi-eye-outline</v-icon>
                    </v-btn>
                    <v-btn icon color="error" @click="deleteRecordEntry(item)"
                           v-if="user.orgAdmin || user.admin || user.flags.includes('DELETE_RECORD_ENTRY')">
                        <v-icon>mdi-delete-forever-outline</v-icon>
                    </v-btn>
                </template>
                <template v-slot:item.type="{ item }">
                    <v-btn x-small v-if="item.type === 'TICKET'" outlined color="orange lighten-2">Ordnungswidrigkeit</v-btn>
                    <v-btn x-small v-else-if="item.type === 'RECORD_CHANGE'" outlined color="blue lighten-2">Aktenänderung</v-btn>
                    <v-btn x-small v-else-if="item.type === 'JAIL'" outlined color="error lighten-1">Gefängnisbesuch</v-btn>
                    <v-btn x-small v-else-if="item.type === 'BAIL'" outlined color="error lighten-3">Bewährungsstrafe</v-btn>
                    <v-btn x-small v-else-if="item.type === 'NOTE'" outlined color="blue-grey lighten-2">Notiz</v-btn>
                    <v-btn x-small v-else-if="item.type === 'CRIMINAL_RECORD'" outlined color="error lighten-2">Straftat</v-btn>
                    <v-btn x-small v-else-if="item.type === 'INVESTIGATION'" outlined color="error lighten-2">Ermittlungen</v-btn>
                    <v-btn x-small v-else-if="item.type === 'HOSPITAL_NOTICE'" outlined color="primary">Krankenhausmeldung</v-btn>
                    <v-btn x-small v-else-if="item.type === 'GOVERNMENT_REGULATION_VIOLATION'" outlined color="yellow">Verstoß gesetzlicher Regulierungen</v-btn>
                    <v-btn x-small v-else outlined color="orange">{{ item.type }}</v-btn>
                    <!-- <v-btn x-small v-else color="success">Nicht Gesucht</v-btn> -->
                    <v-btn class="ml-2" v-if="previewFeatures && item.jailTime > 0" x-small color="info">{{ item.jailTime }} HE
                    </v-btn>

                    <v-icon v-if="item.enforced" right color="success">mdi-check-circle-outline</v-icon>

                    <v-icon v-if="item.wanted" color="yellow" right>mdi-star</v-icon>
                    <v-icon v-if="item.penaltyPoints > 0 && item.penaltyPoints <= 10" right color="blue-grey">
                        mdi-numeric-{{ item.penaltyPoints }}-circle-outline
                    </v-icon>
                </template>
                <template v-slot:item.createdAt="{ item }">
                    {{ item.createdAt | datetime }}
                </template>
                <template v-slot:item.user.displayName="{ item }">
          <span v-if="item.user">
            <user-avatar :size="24" :user-obj="item.user" cache class="mr-2"/>
            {{ item.user.displayName }}
          </span>
                    <span v-else>System</span>
                </template>
                <template v-slot:item.gender="{ item }">
                    <v-icon v-if="item.gender === 'FEMALE'" color="pink">mdi-gender-female</v-icon>
                    <v-icon v-if="item.gender === 'MALE'" color="primary lighten-2">mdi-gender-male</v-icon>
                    <v-icon v-if="item.gender === 'DIVERS'" color="primary lighten-2">mdi-gender-non-binary</v-icon>
                    <v-icon v-if="item.gender === 'GOLDFISH'" color="primary lighten-2">mdi-fishbowl-outline</v-icon>
                    <v-icon v-if="item.gender == null" color="warning darken-1">mdi-gender-male-female</v-icon>
                </template>
            </v-data-table>
            <v-divider/>
            <v-card-actions>
                <v-btn small color="success" elevation="3" to="/criminal-record/add">
                    <v-icon left small>mdi-plus</v-icon>
                    Akte Erstellen
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-bottom-sheet v-model="activeRecordSheet" inset>
            <v-card>
                <criminal-record-entry v-if="activeRecordEntry !== null" :entry="activeRecordEntry"
                                       :record="activeRecordEntry.record"/>
            </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet v-model="activeMostWantedSheet" inset>
            <v-card color="primary">
                <v-card-title class="pt-2 pb-2">
                    <v-icon class="pr-2">mdi-content-save</v-icon>
                    Informationsblatt
                </v-card-title>
                <v-divider/>
                <v-card-text class="pt-5">
                    <v-img :src="'https://gov-api-mw.alteravitarp.de/image?check=' + (new Date()).toDateString()"
                           class="rounded"/>
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-btn href="https://gov-api-mw.alteravitarp.de/image" target="_blank">
                        <v-icon>mdi-download</v-icon>
                        Download
                    </v-btn>
                    <v-spacer/>
                    <v-icon class="pr-2">mdi-information</v-icon>
                    Sollte kein Bild angezeigt werden, bitte einige Sekunden warten und danach erneut versuchen.
                </v-card-actions>
            </v-card>
        </v-bottom-sheet>
    </v-container>
</template>

<script>
import Swal                from 'sweetalert2';
import CriminalRecordEntry from "@/components/CriminalRecordEntry";
import UserAvatar          from "@/components/UserAvatar.vue";

export default {
    components: {UserAvatar, CriminalRecordEntry},
    data:       () => ({
        search:  '',
        loading: false,

        activeRecordSheet: false,
        activeRecordEntry: null,

        tblHeaders: [
            {text: 'Name des Bürger', value: 'record.name'},
            {text: 'Art des Eintrags', value: 'type'},
            {text: 'Beamter', value: 'user.displayName'},
            {text: 'Erstellt am', value: 'createdAt'},
            {text: 'Aktionen', value: 'actions', sortable: false}
        ],

        tblHeaderMostWanted: [
            {text: 'Name', value: 'record.name'},
            {text: 'Geburtsdatum', value: 'record.birthday'},
            {text: 'Ausstehende Haftstrafe', value: 'totalJailTime'},
            {text: 'Ausstehendes Bußgeld', value: 'totalFine'},
            {text: 'Aktionen', value: 'actions'},
        ],

        activeMostWantedSheet: false,

        records:    [],
        mostWanted: [],
    }),
    methods:    {
        openBNDInfo() {
            this.activeMostWantedSheet = true;
        },
        openRecord(recordEntry) {
            this.$router.push('/criminal-record/' + recordEntry.record.id);
        },
        previewRecordEntry(recordEntry) {
            this.activeRecordSheet = true
            this.activeRecordEntry = recordEntry
        },
        deleteRecordEntry(recordEntry) {
            Swal.fire({
                title:              'Bist du dir sicher?',
                html:               'Du kannst diese Aktion nicht rückgängig machen. Möchtest du den Eintrag aus der Akte von "<b>' + recordEntry.record.name + '</b>" wirklich löschen?',
                showDenyButton:     false,
                showCancelButton:   true,
                confirmButtonText:  `Löschen`,
                confirmButtonColor: '#d73939',
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    Swal.showLoading();
                    this.apiDeleteCriminalRecordEntry(recordEntry.record.id, recordEntry.id).then(() => {
                        Swal.hideLoading();
                        Swal.fire('Erfolgreich!', 'Der Eintrag wurde gelöscht.', 'success')
                        this.fetchRecords();
                    }).catch(err => {
                        Swal.hideLoading();
                        Swal.fire('Fehler', 'Der Eintrag konnte nicht gelöscht werden:<br>' + err.response.statusCode, 'error');
                    })
                }
            })
        },
        fetchRecords() {
            this.loading = true;

            if (this.user.orgAdmin || this.user.accountType === 'FEDERAL_INTELLIGENCE_SERVICE') {
                this.apiGetCriminalRecordsMostWanted().then(r => {
                    this.mostWanted = r.data;
                })
            }

            this.apiGetCriminalRecordEntriesLatest().then(r => {
                this.records = r.data
            }).finally(() => {
                this.loading = false;
            });
        }
    },
    mounted() {
        this.fetchRecords();
    }
}
</script>
<template>
    <v-container fluid fill-height>
        <v-layout flex align-center justify-center>
            <v-flex xs12 sm4 elevation-6>
                <v-toolbar dense class="primary" elevation="2" tile>
                    <v-toolbar-title class="white--text"><h4>Auf wiedersehen!</h4></v-toolbar-title>
                </v-toolbar>
                <v-card outlined elevation="4" tile>
                    <v-card-text>
                        Du wirst nun ausgeloggt! 💖
                    </v-card-text>
                    <v-card-actions>
                        <v-btn to="/login" small color="primary">
                            Zurück zum Login
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    mounted() {
        this.apiSignOut();
        this.ifStillOnLogout();
    },
    methods: {
        ifStillOnLogout() {
            setTimeout(() => {
                if (this.$route.name === 'Logout')
                    this.$router.push('/login');
            }, 4000)
        }
    }
}
</script>
import Vue                                      from 'vue';
import axios                                    from 'axios';
import moment                                   from "moment";
import {Organizations, UserFlagEnum, UserFlags} from "@/config";

let READY = false;

// load URL
axios.get('/config.json').then(r => {
  Vue.prototype.$SERVER_CONFIG = r.data || {};
  Vue.prototype.$SERVER_URL    = r.data.server_url || 'http://localhost:5100';
  console.log("Using Server: " + Vue.prototype.$SERVER_URL);
  READY = true;
});

const resolveIfReady = function (resolve, cb) {
  if (READY) {
    resolve(cb());
  } else {
    setTimeout(() => resolveIfReady(resolve, cb), 100);
  }
}

const waitForServer = function (cb) {
  return new Promise(function (resolve) {
    resolveIfReady(resolve, cb);
  });
};

export default {
  data:     () => ({
    TAX_TYPES: [
      {value: 0.0, label: '0% Ohne Umsatz- bzw. Mehrwehrsteuer (Reverse Charge?)'},
      {value: -1.19, label: '19% (incl.)'},
      {value: 1.19, label: '19% (excl.)'},
    ],
  }),
  filters:  {
    date(val) {
      console.log(typeof val, val);
      return val ? moment(val).format('DD.MM.YYYY') : 'Unbekannt';
    },
    datetime(val) {
      return val ? moment(val).format('DD.MM.YYYY HH:mm:ss') : 'Unbekannt';
    },
    time(val) {
      return val ? moment(val).format('HH:mm:ss') : 'Unbekannt';
    },
    money(val) {
      if (!val) return '';
      return new Intl.NumberFormat('de-DE', {
        style:    'currency',
        currency: 'EUR',
      }).format(val);
    }
  },
  computed: {
    isAuthenticated() {
      return this.$store.state.user != null;
    },
    user() {
      return this.$store.state.user;
    },
    userAccountType() {
      return this.$store.state.user?.accountType ?? this.$route.meta?.accountType ?? 'NONE';
    },
    legacyViews() {
      return this.$store.state.legacyViews || false;
    },
    previewFeatures() {
      return this.$store.state.previewFeatures || false;
    },

    grantableUserFlags() {
      let flags = [];

      UserFlags.forEach(flag => {
        if (((flag.requiresAdmin && (this.user.admin || this.user.orgAdmin)) || !flag.requiresAdmin) && (flag.accountTypes === undefined || flag.accountTypes.includes(this.userAccountType))) {
          flags.push(flag);
        }
      });

      return flags;
    },
    isCurrentAccountPolice() {
      return Organizations.EXECUTIVE.includes(this.userAccountType) || this.userHasFlag(UserFlagEnum.ORDER_POLICE, true) || this.userHasFlag(UserFlagEnum.MEDIC_POLICE, true);
    },
    requirePlayerSync() {
      if (this.$route.name === 'Logout') return false;
      if (this.user == null) return false;
      if (this.user.id === 'ce56b1db-566d-4349-bb48-da67079764a4') return false;
      if (this.user.hide) return false;

      return this.user && ['POLICE', 'MEDIC', 'ZOLL'].includes(this.user.accountType) && !this.user.playerIdentifier && new Date() > new Date(1674601200000);
    },
  },

  methods: {
    calculatePasswordComplexity(password) {
      const regexChecks = [
        new RegExp("[A-Z]"),
        new RegExp("[a-z]"),
        new RegExp("\\d"),
        new RegExp("[$&+,:;=?@#|'<>.^*()%!-]"),
        new RegExp("^.{8,}$"),
        new RegExp("^.{16,}$"),
      ];

      let passed = 0;

      for (const obj of regexChecks) {
        if (obj.test(password)) passed++;
      }

      return passed / regexChecks.length;
    },
    waitForWebsocket() {
      return new Promise((resolve) => {
        if (this.$root.websocketClient) {
          resolve(this.$root.websocketClient);
        } else {
          setInterval(() => {
            if (this.$root.websocketClient) {
              resolve(this.$root.websocketClient);
            }
          }, 250);
        }
      });
    },

    apiImageUrl(imageName, extension) {
      return this.$SERVER_URL + '/images/' + imageName + '.' + (extension || 'png');
    },

    apiAttachmentUrl(attachment) {
      return this.$SERVER_URL + '/api/shared/attachment/' + attachment.id;
    },

    apiGetAuditLog() {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/shared/audit-logs', {withCredentials: true}).catch(this.authErrorHandler));
    },

    apiOnlyOfficeUrl() {
      return this.$SERVER_URL + '/api/shared/onlyoffice';
    },

    apiGetOnlyOfficeDocumentUrl(id) {
      return waitForServer(() => this.$SERVER_URL + '/api/shared/onlyoffice/' + id);
    },

    apiGetInternalInfoDocumentUrlForPdf(id) {
      return waitForServer(() => this.$SERVER_URL + '/api/shared/internal-info/' + id + '/pdf');
    },

    apiGetOnlyOfficeEditorId(id) {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/shared/onlyoffice/' + id + '/editor-id', {withCredentials: true}).catch(this.authErrorHandler));
    },

    websocketUrl() {
      return waitForServer(() => this.$SERVER_URL + '/websocket');
    },

    apiDeleteAttachment(attachmentId) {
      return waitForServer(() => axios.delete(this.$SERVER_URL + '/api/shared/attachment/' + attachmentId, {withCredentials: true}).catch(this.authErrorHandler));
    },

    userHasFlag(flag, ignoreAdmin) {
      if (ignoreAdmin === true) return this.user.flags.includes(flag);
      return this.user.admin || this.user.orgAdmin || this.user.flags.includes(flag);
    },

    userHasAnyFlag(flags) {
      for (let flag of flags) {
        if (this.userHasFlag(flag)) return true;
      }
      return false;
    },

    userIsOfAnyAccountType(types) {
      for (let aType of types) {
        if (this.userAccountType === aType) return true;
      }
      return false;
    },

    userHasSecurityLevel(securityLevel) {
      return this.user.admin || this.user.orgAdmin || this.user.securityLevels.includes(securityLevel);
    },

    apiGetEmployeeRatingsData() {
      return waitForServer(() => {
        return Vue.prototype.$SERVER_CONFIG['employeeRatings'][this.userAccountType.toLowerCase()] || [];
      });
    },

    apiSignIn(username, password, playerIdentifier) {
      return axios.post(this.$SERVER_URL + '/api/authenticate', {
        username:         username,
        password:         password,
        playerIdentifier: playerIdentifier,
      }, {withCredentials: true})
    },

    apiSignOut() {
      this.$store.commit('update', {user: null})
      this.updateVuetifyTheme();

      return waitForServer(() => {
        return axios.delete(this.$SERVER_URL + '/api/authenticate', {withCredentials: true})
          .finally(() => this.$router.push('/'))
          .catch(this.authErrorHandler);
      })
    },

    apiSetMyPassword(currentPassword, newPassword1, newPassword2) {
      return axios.post(this.$SERVER_URL + '/api/security/@me/change-password', {
        currentPassword:  currentPassword,
        newPassword:      newPassword1,
        newPasswordCheck: newPassword2
      }, {withCredentials: true}).catch(this.authErrorHandler);
    },

    apiSetMyAvatar(avatar) {
      return axios.post(this.$SERVER_URL + '/api/security/@me/change-avatar', {
        image:    avatar,
        fileName: 'avatar.png'
      }, {withCredentials: true}).catch(this.authErrorHandler);
    },

    apiRequestSyncCode() {
      return waitForServer(() => axios.post(this.$SERVER_URL + '/api/security/@me/request-sync-code', null, {withCredentials: true}).catch(this.authErrorHandler));
    },

    apiGetMyUserInformation() {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/security/@me', {withCredentials: true}).catch(this.authErrorHandler));
    },

    authErrorHandler(err) {
      if (err.response.status === 401) {
        return this.$router.push('/logout');
      }
      throw err;
    },

    isTimeBasedDarkModeAvailable() {
      let isEnabled = this.$store.state.timeBasedDarkMode;
      let hours     = new Date().getHours();

      return isEnabled && (hours >= 19 || hours <= 7);
    },

    isDarkModeEnabled() {
      return this.isTimeBasedDarkModeAvailable() || this.$store.state.darkMode;
    },

    //region GOVERNMENT
    apiGovernmentGetAllWorkListItems() {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/government/work-lists', {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiGovernmentGetWorkListItemById(id) {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/government/work-list/' + id, {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiGovernmentCreateWorkListItem(item) {
      item.id = null;
      return waitForServer(() => axios.post(this.$SERVER_URL + '/api/government/work-lists', item, {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiGovernmentUpdateWorkListItem(id, item) {
      item.id = id;
      return waitForServer(() => axios.put(this.$SERVER_URL + '/api/government/work-list/' + id, item, {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiGovernmentUpdateWorkListItem_OnlyDescription(id, item) {
      item.id = id;
      return waitForServer(() => axios.put(this.$SERVER_URL + '/api/government/work-list/' + id + '/description', item, {withCredentials: true}).catch(this.authErrorHandler));
    },

    //endregion

    //region MEDIC
    apiGetMedicalRecords() {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/medic/medical-records', {withCredentials: true}).catch(this.authErrorHandler));
    },

    apiGetMedicalRecordEntriesLatest() {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/medic/medical-record-entries-latest', {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiGetMedicalRecordsByContentLike(param) {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/medic/medical-record-entries?searchQuery=' + encodeURIComponent(param), {withCredentials: true}).catch(this.authErrorHandler));
    },

    apiGetMedicalRecord(id) {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/medic/medical-record/' + id, {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiDeleteMedicalRecord(id) {
      return axios.delete(this.$SERVER_URL + '/api/medic/medical-record/' + id, {withCredentials: true}).catch(this.authErrorHandler);
    },
    apiCreateMedicalRecord(record) {
      return axios.post(this.$SERVER_URL + '/api/medic/medical-records', record, {withCredentials: true}).catch(this.authErrorHandler);
    },
    apiUpdateMedicalRecord(id, record) {
      return axios.put(this.$SERVER_URL + '/api/medic/medical-record/' + id, record, {withCredentials: true}).catch(this.authErrorHandler);
    },

    apiGetMedicalRecordEntries(id) {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/medic/medical-record/' + id + '/entries', {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiCreateMedicalRecordEntry(id, entry) {
      return axios.post(this.$SERVER_URL + '/api/medic/medical-record/' + id + '/entries', entry, {withCredentials: true}).catch(this.authErrorHandler);
    },
    apiDeleteMedicalRecordEntry(id, entryId) {
      return axios.delete(this.$SERVER_URL + '/api/medic/medical-record/' + id + '/entry/' + entryId, {withCredentials: true}).catch(this.authErrorHandler)
    },
    apiUpdateMedicalRecordEntry(recordId, entryId, entry) {
      return waitForServer(() => axios.patch(this.$SERVER_URL + '/api/medic/medical-record/' + recordId + '/entries/' + entryId, entry, {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiGetAllMedicalFindings() {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/shared/medical-findings', {withCredentials: true}).catch(this.authErrorHandler));
    },
    //endregion

    //region POLICE

    apiGetCriminalRecords() {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/police/criminal-records', {withCredentials: true}).catch(this.authErrorHandler));
    },

    apiGetCriminalRecordEntriesLatest() {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/police/criminal-record-entries-latest', {withCredentials: true}).catch(this.authErrorHandler));
    },

    apiGetCriminalRecordsMostWanted() {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/police/criminal-record-entries-top5to10', {withCredentials: true}).catch(this.authErrorHandler));
    },

    apiGetCriminalRecord(id) {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/police/criminal-record/' + id, {withCredentials: true}).catch(this.authErrorHandler));
    },

    apiDeleteCriminalRecord(id) {
      return axios.delete(this.$SERVER_URL + '/api/police/criminal-record/' + id, {withCredentials: true}).catch(this.authErrorHandler);
    },

    apiCreateCriminalRecord(record) {
      return axios.post(this.$SERVER_URL + '/api/police/criminal-records', record, {withCredentials: true}).catch(this.authErrorHandler);
    },

    apiUpdateCriminalRecord(id, record) {
      return axios.put(this.$SERVER_URL + '/api/police/criminal-record/' + id, record, {withCredentials: true}).catch(this.authErrorHandler);
    },

    apiGetCriminalRecordEntries(id) {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/police/criminal-record/' + id + '/entries', {withCredentials: true}).catch(this.authErrorHandler));
    },

    apiCreateCriminalRecordEntry(id, entry) {
      return axios.post(this.$SERVER_URL + '/api/police/criminal-record/' + id + '/entries', entry, {withCredentials: true}).catch(this.authErrorHandler);
    },

    apiUpdateCriminalRecordEntry(id, entryId, entry) {
      return axios.post(this.$SERVER_URL + '/api/police/criminal-record/' + id + '/entry/' + entryId, entry, {withCredentials: true}).catch(this.authErrorHandler);
    },

    apiDiscardCriminalRecordEntry(id, entryId, reason) {
      return axios.post(this.$SERVER_URL + '/api/police/criminal-record/' + id + '/entry/' + entryId + '/discard', {
        reason: reason
      }, {withCredentials: true}).catch(this.authErrorHandler)
    },

    apiEnforceCriminalRecordEntry(id, entryId) {
      return axios.post(this.$SERVER_URL + '/api/police/criminal-record/' + id + '/entry/' + entryId + '/enforce', {}, {withCredentials: true}).catch(this.authErrorHandler)
    },

    apiMarkWantedCriminalRecordEntry(id, entryId) {
      return axios.post(this.$SERVER_URL + '/api/police/criminal-record/' + id + '/entry/' + entryId + '/wanted', {}, {withCredentials: true}).catch(this.authErrorHandler)
    },

    apiFineToJailTimeCriminalRecordEntry(id, entryId) {
      return axios.post(this.$SERVER_URL + '/api/police/criminal-record/' + id + '/entry/' + entryId + '/fine', {}, {withCredentials: true}).catch(this.authErrorHandler)
    },

    apiJailTimeToBailMoneyCriminalRecordEntry(id, entryId) {
      return axios.post(this.$SERVER_URL + '/api/police/criminal-record/' + id + '/entry/' + entryId + '/bail', {}, {withCredentials: true}).catch(this.authErrorHandler)
    },

    apiDeleteCriminalRecordEntry(id, entryId) {
      return axios.delete(this.$SERVER_URL + '/api/police/criminal-record/' + id + '/entry/' + entryId, {withCredentials: true}).catch(this.authErrorHandler)
    },

    apiGetQuickRecords() {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/police/quick-records', {withCredentials: true}).catch(this.authErrorHandler));
    },

    apiCreateQuickRecord(record) {
      return axios.post(this.$SERVER_URL + '/api/police/quick-records', record, {withCredentials: true}).catch(this.authErrorHandler);
    },

    apiUpdateQuickRecord(recordId, record) {
      return axios.patch(this.$SERVER_URL + '/api/police/quick-record/' + recordId, record, {withCredentials: true}).catch(this.authErrorHandler);
    },

    apiDeleteQuickRecord(recordId) {
      return axios.delete(this.$SERVER_URL + '/api/police/quick-record/' + recordId, {withCredentials: true}).catch(this.authErrorHandler);
    },

    //endregion

    //region SHARED
    apiGetGlobalNotifications() {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/shared/notifications', {withCredentials: true})).catch(this.authErrorHandler);
    },

    apiGetInternalInfos() {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/shared/internal-infos', {withCredentials: true}).catch(this.authErrorHandler))
    },
    apiGetInternalInfo(id) {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/shared/internal-info/' + id, {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiCreateInternalInfoCopyFrom(id, entry) {
      return axios.post(this.$SERVER_URL + '/api/shared/internal-infos/@copy-from/' + id, entry, {
        timeout:         60000,
        withCredentials: true
      }).catch(this.authErrorHandler);
    },
    apiCreateInternalInfo(entry) {
      return axios.post(this.$SERVER_URL + '/api/shared/internal-infos', entry, {
        timeout:         60000,
        withCredentials: true
      }).catch(this.authErrorHandler);
    },
    apiUpdateInternalInfo(id, entry) {
      return axios.put(this.$SERVER_URL + '/api/shared/internal-info/' + id, entry, {withCredentials: true}).catch(this.authErrorHandler);
    },
    apiDeleteInternalInfo(id) {
      return axios.delete(this.$SERVER_URL + '/api/shared/internal-info/' + id, {withCredentials: true}).catch(this.authErrorHandler);
    },

    apiGetMissionReports() {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/shared/mission-reports', {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiGetMissionReport(id) {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/shared/mission-report/' + id, {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiCreateMissionReport(report) {
      return axios.post(this.$SERVER_URL + '/api/shared/mission-reports', report, {withCredentials: true}).catch(this.authErrorHandler);
    },
    apiUpdateMissionReport(id, report) {
      return axios.put(this.$SERVER_URL + '/api/shared/mission-report/' + id, report, {withCredentials: true}).catch(this.authErrorHandler);
    },

    apiGetMissionReportEmployees() {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/shared/mission-reports-employees', {withCredentials: true}).catch(this.authErrorHandler));
    },

    apiGetAllEmployeeActivitiesInRangeOf(from, to) {
      from = moment(from).set('hour', 0).set('minute', 0).set('second', 0);
      to   = moment(to).set('hour', 23).set('minute', 59).set('second', 59);

      return waitForServer(() => axios.get(this.$SERVER_URL + `/api/v2/user-activities?from=${encodeURIComponent(from.toISOString(true))}&to=${encodeURIComponent(to.toISOString(true))}`, {withCredentials: true}).catch(this.authErrorHandler))
    },

    apiGetEmployeeActivitiesInRangeOfFor(from, to, userId) {
      from = moment(from).set('hour', 0).set('minute', 0).set('second', 0);
      to   = moment(to).set('hour', 23).set('minute', 59).set('second', 59);

      return waitForServer(() => axios.get(this.$SERVER_URL + `/api/v2/user-activities/by-user/${userId}?from=${encodeURIComponent(from.toISOString(true))}&to=${encodeURIComponent(to.toISOString(true))}`, {withCredentials: true}).catch(this.authErrorHandler))
    },

    apiGetEmployeesPublic() {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/shared/employees-public', {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiGetEmployees(hidden) {
      if (hidden === undefined) hidden = true;
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/shared/employees?hidden=' + hidden, {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiGetEmployeesByAccountType(hidden, accountType) {
      if (hidden === undefined) hidden = true;
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/shared/employees?hidden=' + hidden + '&accountType=' + accountType, {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiGetEmployee(id) {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/shared/employee/' + id, {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiGetEmployeeChanges(id) {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/shared/employee/' + id + '/changes', {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiCreateEmployee(employee) {
      return axios.post(this.$SERVER_URL + '/api/shared/employees', employee, {withCredentials: true}).catch(this.authErrorHandler);
    },
    apiUpdateEmployee(id, employee) {
      return axios.put(this.$SERVER_URL + '/api/shared/employee/' + id, employee, {withCredentials: true}).catch(this.authErrorHandler);
    },
    apiUpdateEmployeeToggleLock(id) {
      return axios.put(this.$SERVER_URL + '/api/shared/employee/' + id + '/toggle-lock', null, {withCredentials: true}).catch(this.authErrorHandler);
    },

    apiGetAppointments() {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/shared/appointments', {withCredentials: true}).catch(this.authErrorHandler));
    },

    apiGetAppointmentById(id) {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/shared/appointment/' + id, {withCredentials: true}).catch(this.authErrorHandler));
    },

    apiCreateAppointment(appointment) {
      return axios.post(this.$SERVER_URL + '/api/shared/appointments', appointment, {withCredentials: true}).catch(this.authErrorHandler);
    },

    apiUpdateAppointment(id, appointment) {
      return axios.patch(this.$SERVER_URL + '/api/shared/appointment/' + id, appointment, {withCredentials: true}).catch(this.authErrorHandler);
    },

    apiDeleteAppointment(id) {
      return axios.delete(this.$SERVER_URL + '/api/shared/appointment/' + id, {withCredentials: true}).catch(this.authErrorHandler);
    },

    apiRegisterForAppointment(id) {
      return axios.post(this.$SERVER_URL + '/api/shared/appointment/' + id + '/register', {}, {withCredentials: true}).catch(this.authErrorHandler);
    },

    apiUnRegisterForAppointment(id) {
      return axios.post(this.$SERVER_URL + '/api/shared/appointment/' + id + '/unregister', {}, {withCredentials: true}).catch(this.authErrorHandler);
    },
    //endregion

    //region LETTERS

    apiGetUnreadMessageCount() {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/v3/letters/@info:unread', {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiGetAllMessages() {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/v3/letters', {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiGetMessage(id) {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/v3/letter/' + id, {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiCreateMessage(message) {
      return waitForServer(() => axios.post(this.$SERVER_URL + '/api/v3/letters', message, {withCredentials: true}).catch(this.authErrorHandler));
    },

    //endregion

    //region COMPANY
    apiGetAllCompanies() {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/v2/companies', {withCredentials: true}).catch(this.authErrorHandler));
    },

    apiGetCompanyById(id) {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/v2/company/' + id, {withCredentials: true}).catch(this.authErrorHandler));
    },

    apiCreateCompany(data) {
      return waitForServer(() => axios.post(this.$SERVER_URL + '/api/v2/companies', data, {withCredentials: true}).catch(this.authErrorHandler));
    },

    apiUpdateCompany(id, data) {
      return waitForServer(() => axios.patch(this.$SERVER_URL + '/api/v2/company/' + id, data, {withCredentials: true}).catch(this.authErrorHandler));
    },

    apiDeleteCompany(companyOrId) {
      if (typeof (companyOrId) == 'object') companyOrId = companyOrId.id;
      return waitForServer(() => axios.delete(this.$SERVER_URL + '/api/v2/company/' + companyOrId, {withCredentials: true}).catch(this.authErrorHandler));
    },

    apiCreateCompanyRecordEntry(companyOrId, entryData) {
      if (typeof (companyOrId) == 'object') companyOrId = companyOrId.id;
      return waitForServer(() => axios.post(this.$SERVER_URL + '/api/v2/company/' + companyOrId + '/entries', entryData, {withCredentials: true}).catch(this.authErrorHandler));
    },

    apiCompanyRemoveRepresentative(companyOrId, criminalOrCriminalId) {
      if (typeof (companyOrId) == 'object') companyOrId = companyOrId.id;
      if (typeof (criminalOrCriminalId) == 'object') criminalOrCriminalId = criminalOrCriminalId.id;
      return waitForServer(() => axios.delete(this.$SERVER_URL + '/api/v2/company/' + companyOrId + '/authorized-representative/' + criminalOrCriminalId, {withCredentials: true}).catch(this.authErrorHandler));
    },

    apiCompanyAddRepresentative(companyOrId, criminalOrCriminalId) {
      if (typeof (companyOrId) == 'object') companyOrId = companyOrId.id;
      if (typeof (criminalOrCriminalId) == 'object') criminalOrCriminalId = criminalOrCriminalId.id;
      return waitForServer(() => axios.post(this.$SERVER_URL + '/api/v2/company/' + companyOrId + '/authorized-representative/' + criminalOrCriminalId, null, {withCredentials: true}).catch(this.authErrorHandler));
    },

    apiGetTopCompanyRecordEntries() {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/v2/company-record-entries', {withCredentials: true}).catch(this.authErrorHandler));
    },

    apiDeleteCompanyRecordEntry(companyOrId, entryOrEntryId) {
      if (typeof (companyOrId) == 'object') companyOrId = companyOrId.id;
      if (typeof (entryOrEntryId) == 'object') entryOrEntryId = entryOrEntryId.id;

      return waitForServer(() => axios.delete(this.$SERVER_URL + '/api/v2/company/' + companyOrId + '/entries/' + entryOrEntryId, {withCredentials: true})).catch(this.authErrorHandler);
    },

    //endregion

    //region PATROL UNITS
    apiGetAllPatrolUnits() {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/shared/patrol-units', {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiCreatePatrolUnit(patrolUnit) {
      return axios.post(this.$SERVER_URL + '/api/shared/patrol-units', patrolUnit, {withCredentials: true}).catch(this.authErrorHandler);
    },
    apiUpdatePatrolUnit(id, patrolUnit) {
      return axios.put(this.$SERVER_URL + '/api/shared/patrol-unit/' + id, patrolUnit, {withCredentials: true}).catch(this.authErrorHandler);
    },
    apiDeletePatrolUnit(id) {
      return axios.delete(this.$SERVER_URL + '/api/shared/patrol-unit/' + id, {withCredentials: true}).catch(this.authErrorHandler);
    },
    //endregion

    //region COURSES
    apiGetAllCourses() {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/shared/courses', {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiGetCourseById(id) {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/shared/course/' + id, {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiCreateCourse(course) {
      return axios.post(this.$SERVER_URL + '/api/shared/courses', course, {withCredentials: true}).catch(this.authErrorHandler);
    },
    apiUpdateCourseById(id, course) {
      return axios.put(this.$SERVER_URL + '/api/shared/course/' + id, course, {withCredentials: true}).catch(this.authErrorHandler);
    },
    apiDeleteCourseById(id) {
      return axios.delete(this.$SERVER_URL + '/api/shared/course/' + id, {withCredentials: true}).catch(this.authErrorHandler);
    },
    apiRegisterForCourse(id) {
      return axios.post(this.$SERVER_URL + '/api/shared/course/' + id + '/toggle-interest', {}, {withCredentials: true}).catch(this.authErrorHandler);
    },
    //endregion

    //region TRAFFIC_CONTROL
    apiGetAllTrafficControls() {
      return axios.get(this.$SERVER_URL + '/api/police/traffic-controls', {withCredentials: true}).catch(this.authErrorHandler);
    },
    apiCreateTrafficControl(unit) {
      return axios.post(this.$SERVER_URL + '/api/police/traffic-controls', unit, {withCredentials: true}).catch(this.authErrorHandler);
    },
    apiDeleteTrafficControl(id) {
      return axios.delete(this.$SERVER_URL + '/api/police/traffic-control/' + id, {withCredentials: true}).catch(this.authErrorHandler);
    },
    //endregion

    //region CRIMINAL_FILES
    apiGetAllCriminalFiles() {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/police/criminal-files', {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiGetCriminalFile(recordId) {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/police/criminal-file/' + recordId, {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiDeleteCriminalFile(recordId) {
      return waitForServer(() => axios.delete(this.$SERVER_URL + '/api/police/criminal-file/' + recordId, {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiCreateCriminalFile(record) {
      return axios.post(this.$SERVER_URL + '/api/police/criminal-files', record, {withCredentials: true}).catch(this.authErrorHandler);
    },
    apiUpdateCriminalFile(recordId, record) {
      return axios.patch(this.$SERVER_URL + '/api/police/criminal-file/' + recordId, record, {withCredentials: true}).catch(this.authErrorHandler);
    },
    apiClaimCriminalFile(recordId) {
      return axios.patch(this.$SERVER_URL + '/api/police/criminal-file/' + recordId + '/claim', {}, {withCredentials: true}).catch(this.authErrorHandler);
    },
    apiCloseCriminalFile(recordId) {
      return axios.patch(this.$SERVER_URL + '/api/police/criminal-file/' + recordId + '/close', {}, {withCredentials: true}).catch(this.authErrorHandler);
    },

    //endregion

    //region MAP MARKERS
    apiGetAllMapMarkers() {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/shared/map-markers', {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiCreateMapMarker(mapMarker) {
      return axios.post(this.$SERVER_URL + '/api/shared/map-markers', mapMarker, {withCredentials: true}).catch(this.authErrorHandler);
    },
    apiUpdateMapMarker(id, mapMarker) {
      return axios.put(this.$SERVER_URL + '/api/shared/map-marker/' + id, mapMarker, {withCredentials: true}).catch(this.authErrorHandler);
    },
    apiDeleteMapMarker(id) {
      return axios.delete(this.$SERVER_URL + '/api/shared/map-marker/' + id, {withCredentials: true}).catch(this.authErrorHandler);
    },

    apiGetAllCrimeHeatmapMarkers(from, to) {
      return waitForServer(() => axios.get(this.$SERVER_URL + `/api/police/criminal-record-entry-heatmap?from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}`, {withCredentials: true}).catch(this.authErrorHandler));
    },

    apiGetAllMedicHeatmapMarkers(from, to) {
      return waitForServer(() => axios.get(this.$SERVER_URL + `/api/medic/medical-record-entry-heatmap?from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}`, {withCredentials: true}).catch(this.authErrorHandler));
    },
    //endregion

    //region ADMIN
    apiGetAllCreditInvoices() {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/admin/credit-invoices', {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiCreateCreditInvoice(invoiceInfo, fileContent) {
      return axios.post(this.$SERVER_URL + '/api/admin/credit-invoices', {
        invoice: invoiceInfo,
        file:    fileContent
      }, {withCredentials: true}).catch(this.authErrorHandler);
    },
    apiGetCreditInvoiceById(id) {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/admin/credit-invoice/' + id, {withCredentials: true}).catch(this.authErrorHandler));
    },

    //endregion

    //region Complaints

    apiGetAllComplaints() {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/v2/complaints', {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiGetComplaint(id) {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/v2/complaint/' + id, {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiCreateComplaint(complaint) {
      return axios.post(this.$SERVER_URL + '/api/v2/complaints', complaint, {withCredentials: true}).catch(this.authErrorHandler);
    },
    apiUpdateComplaint(id, complaint) {
      return axios.patch(this.$SERVER_URL + '/api/v2/complaint/' + id, complaint, {withCredentials: true}).catch(this.authErrorHandler);
    },
    apiDeleteComplaint(id) {
      return waitForServer(() => axios.delete(this.$SERVER_URL + '/api/v2/complaint/' + id, {withCredentials: true}).catch(this.authErrorHandler))
    },

    //endregion

    //region Fine Records

    apiGetAllFineRecords() {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/shared/fine-records', {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiResetFineRecordCache() {
      return axios.get(this.$SERVER_URL + '/api/shared/fine-records', {withCredentials: true}).catch(this.authErrorHandler);
    },

    //endregion

    //region Roster

    apiGetRoster() {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/shared/roster', {withCredentials: true}).catch(this.authErrorHandler));
    },

    //endregion

    //region Security Levels

    apiGetAllSecurityLevels() {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/shared/security-levels', {withCredentials: true}).catch(this.authErrorHandler));
    },

    //endregion

    //region Dispatches

    apiGetAllDispatches() {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/public/dispatches', {withCredentials: true}).catch(this.authErrorHandler));
    },

    apiDispatchWaypoint(dispatch) {
      return waitForServer(() => axios.post(this.$SERVER_URL + '/api/public/dispatch/' + dispatch.id + '/waypoint', null, {withCredentials: true}).catch(this.authErrorHandler));
    },

    apiDeleteDispatch(dispatch) {
      return waitForServer(() => axios.delete(this.$SERVER_URL + '/api/public/dispatch/' + dispatch.id, {withCredentials: true}).catch(this.authErrorHandler));
    },

    apiUpdateDispatch(dispatch) {
      return waitForServer(() => axios.put(this.$SERVER_URL + '/api/public/dispatch/' + dispatch.id, dispatch, {withCredentials: true}).catch(this.authErrorHandler));
    },

    //endregion

    //region DataLists

    apiGetAllDataLists() {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/v2/data-lists', {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiGetDataList(id) {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/v2/data-list/' + id, {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiCreateDataList(dataList) {
      return waitForServer(() => axios.post(this.$SERVER_URL + '/api/v2/data-lists', dataList, {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiCreateDataListRow(id, rowObj) {
      return waitForServer(() => axios.post(this.$SERVER_URL + '/api/v2/data-list/' + id + '/rows', rowObj, {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiUpdateDataListRow(id, rowId, rowObj) {
      return waitForServer(() => axios.patch(this.$SERVER_URL + '/api/v2/data-list/' + id + '/row/' + rowId, rowObj, {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiDeleteDataListRow(id, rowId) {
      return waitForServer(() => axios.delete(this.$SERVER_URL + '/api/v2/data-list/' + id + '/row/' + rowId, {withCredentials: true}).catch(this.authErrorHandler));
    },
    apiDeleteDataList(id) {
      return waitForServer(() => axios.delete(this.$SERVER_URL + '/api/v2/data-list/' + id, {withCredentials: true}).catch(this.authErrorHandler));
    },

    //endregion

    //region Medical Examination

    apiGetMedicalExamination(id) {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/medic/medical-examination/' + id, {withCredentials: true}).catch(this.authErrorHandler));
    },

    apiGetAllMedicalExaminations() {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/medic/medical-examinations', {withCredentials: true}).catch(this.authErrorHandler));
    },

    apiCreateMedicalExamination() {
      return waitForServer(() => axios.post(this.$SERVER_URL + '/api/medic/medical-examinations', {withCredentials: true}).catch(this.authErrorHandler));
    },

    apiDeleteMedicalExamination(objOrId) {
      let id = objOrId;
      if (typeof (objOrId) != 'string') id = objOrId.id;
      return waitForServer(() => axios.delete(this.$SERVER_URL + '/api/medic/medical-examination/' + id, {withCredentials: true}).catch(this.authErrorHandler));
    },

    apiGetAllMedicDispatches() {
      return waitForServer(() => axios.get(this.$SERVER_URL + '/api/public/medic-dispatches', {withCredentials: true}).catch(this.authErrorHandler));
    },

    apiMedicDispatchAssignToMe(dispatch) {
      return waitForServer(() => axios.post(this.$SERVER_URL + '/api/public/medic-dispatch/' + dispatch.id + '/assign-to-me', null, {withCredentials: true}).catch(this.authErrorHandler));
    },

    apiMedicDispatchAssignTo(dispatch, userId) {
      return waitForServer(() => axios.post(this.$SERVER_URL + '/api/public/medic-dispatch/' + dispatch.id + '/assign-to/' + userId, null, {withCredentials: true}).catch(this.authErrorHandler));
    },

    apiMedicDispatchWaypoint(dispatch) {
      return waitForServer(() => axios.post(this.$SERVER_URL + '/api/public/medic-dispatch/' + dispatch.id + '/waypoint', null, {withCredentials: true}).catch(this.authErrorHandler));
    },

    apiDeleteMedicDispatch(dispatch) {
      return waitForServer(() => axios.delete(this.$SERVER_URL + '/api/public/medic-dispatch/' + dispatch.id, {withCredentials: true}).catch(this.authErrorHandler));
    },

    //endregion

    apiUploadEvidence(image, filename, description, unrestricted) {
      if (unrestricted === undefined) unrestricted = false;

      return axios.post(this.$SERVER_URL + '/api/police/evidence', {
        image:           image,
        fileName:        filename,
        fileDescription: description,
        unrestricted:    unrestricted,
      }, {withCredentials: true}).catch(this.authErrorHandler);
    },

    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload  = () => resolve(reader.result.split("base64,")[1]);
        reader.onerror = error => reject(error);
      });
    },

    formatMoney(money) {
      return new Intl.NumberFormat('de-DE', {
        style:    'currency',
        currency: 'EUR',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      }).format(money);
    },

    //
    updateVuetifyTheme() {
      let accountType = this.$store.state.user?.accountType ?? this.$route.meta.accountType ?? 'UNKNOWN';

      if (this.$store.state.customColor) {
        this.$vuetify.theme.themes.light.primary = this.$store.state.customColor;
        this.$vuetify.theme.themes.dark.primary  = this.$store.state.customColor;
      } else if (accountType === 'MEDIC') {
        this.$vuetify.theme.themes.light.primary = '#e60005';
        this.$vuetify.theme.themes.dark.primary  = '#be0005';
      } else if (accountType === 'POLICE') {
        this.$vuetify.theme.themes.light.primary = '#0e518d';
        this.$vuetify.theme.themes.dark.primary  = '#0e518d';
      } else if (accountType === 'CORRECTIONAL') {
        this.$vuetify.theme.themes.light.primary = '#0e518d';
        this.$vuetify.theme.themes.dark.primary  = '#0e518d';
      } else if (accountType === 'ZOLL') {
        this.$vuetify.theme.themes.light.primary = '#0b3962';
        this.$vuetify.theme.themes.dark.primary  = '#0b3962';
      } else if (accountType === 'GOVERNMENT') {
        this.$vuetify.theme.themes.light.primary = '#4c4c4c';
        this.$vuetify.theme.themes.dark.primary  = '#373737';
      } else if (accountType === 'FEDERAL_INTELLIGENCE_SERVICE') {
        this.$vuetify.theme.themes.light.primary = '#4c4c4c';
        this.$vuetify.theme.themes.dark.primary  = '#373737';
      } else if (accountType === 'MECHANIC') {
        this.$vuetify.theme.themes.light.primary = '#F1C40F';
        this.$vuetify.theme.themes.dark.primary  = '#F39C12';
      } else if (accountType === 'ADMIN') {
        this.$vuetify.theme.themes.light.primary = '#43A047';
        this.$vuetify.theme.themes.dark.primary  = '#1B5E20';
      } else {
        this.$vuetify.theme.themes.light.primary = '#009688';
        this.$vuetify.theme.themes.dark.primary  = '#005b53';
      }

      this.$vuetify.theme.dark = this.$store.state.darkMode || this.isTimeBasedDarkModeAvailable();
    },
  }
}

<template>
  <v-container fluid>
    <v-card outlined elevation="4">
      <v-card-title class="pb-2 pt-2">
        <v-icon left color="info">mdi-file-document-multiple-outline</v-icon>
        Einsatzberichte
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-alert type="info" outlined elevation="6" border="left" dense>
          Einsatzberichte können von jedem Kollegen angelegt werden. Sie sind außerdem <b>geheim</b> zu halten.<br/>
          Du kannst hier nur Einsatzberichte sehen, auf die du auch Zugriff hast.<br/>
          Mit der Sicherheitsstufe <b>5</b> oder höher kannst du <b>alle</b> Einsatzberichte deines Departments sehen.
        </v-alert>
      </v-card-text>
      <v-divider/>

      <v-data-table :items="remote.reports.data" :headers="remote.reports.headers" sort-by="createdAt" sort-desc
                    :items-per-page="15" :loading="remote.reports.loading">
        <template v-slot:item.actions="{ item }">
          <v-btn icon color="primary" :to="'/report/' + item.id">
            <v-icon>mdi-eye-outline</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.summary="{ item }">
          <span v-if="item.userType === 'POLICE'" class="pr-1">👮</span>
          <span v-if="item.userType === 'MEDIC'" class="pr-1">👨‍⚕️</span>
          <span v-if="item.userType === 'GOVERNMENT'" class="pr-1">⚖️</span>
          <span>{{ item.summary }}</span>
        </template>
        <template v-slot:item.createdAt="{ item }">
          <span>{{ item.createdAt | formatDate }}</span>
        </template>
        <template v-slot:item.missionTime="{ item }">
          <span>{{ item.missionTime | formatDate }}</span>
        </template>
      </v-data-table>

      <v-divider/>
      <v-card-actions>
        <v-btn small color="primary" to="/reports/add">
          <v-icon left>mdi-file-plus-outline</v-icon>
          Einsatzbericht erstellen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";

export default {
  data: () => ({
    remote: {
      reports: {
        headers: [
          {text: 'Zusammenfassung / Titel', value: 'summary'},
          {text: 'Ersteller', value: 'createdBy.displayName'},
          {text: 'Erstelldatum', value: 'createdAt'},
          {text: 'Zeitpunkt des Vorfalls', value: 'missionTime'},
          {text: 'Aktionen', value: 'actions'}
        ],
        data: [],
        loading: false,
        error: null,
      }
    }
  }),
  filters: {
    formatDate(val) {
      if (!val) return 'Keine Zeitangabe';

      return moment(val).format('DD.MM.YYYY HH:ss');
    }
  },
  methods: {
    fetchMissionReports() {
      this.remote.reports.error = null;
      this.remote.reports.loading = true;

      this.apiGetMissionReports().then(r => {
        console.log(r.data);
        this.remote.reports.data = r.data;
      }).catch(err => {
        this.remote.reports.error = err;
      }).finally(() => {
        this.remote.reports.loading = false;
      })
    }
  },
  mounted() {
    this.fetchMissionReports();
  }
}
</script>
<template>
  <v-container fluid>
    <v-card elevation="4" outlined>
      <v-card-title class="pb-2 pt-2 pl-3">
        <v-icon left color="error">mdi-list-status</v-icon>
        Arbeitsvorrat
      </v-card-title>
      <v-divider/>
      <v-card-text v-if="false">
        <v-row>
          <v-col>
            <v-autocomplete solo dense outlined multiple v-model="filterTargetUser" item-text="displayName"
                            :items="employeeList" item-value="id"
                            hint="Bearbeiter" persistent-hint clearable>
              <template v-slot:selection="{item}">
                <v-btn x-small color="blue-grey" v-if="item.accountType === 'GOVERNMENT'" dark class="mr-2">
                  {{ item.displayName }}
                </v-btn>
                <v-btn x-small color="blue" v-else-if="item.accountType === 'POLICE'" dark class="mr-2">
                  {{ item.displayName }}
                </v-btn>
                <v-btn x-small color="error" v-else-if="item.accountType === 'MEDIC'" dark class="mr-2">
                  {{ item.displayName }}
                </v-btn>
                <v-btn x-small color="primary" v-else dark class="mr-2">{{ item.displayName }}</v-btn>
              </template>
              <template v-slot:item="{item}">
                {{ item.displayName }}
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider/>
      <v-data-table :items="workList" :headers="workListTableHeader" :sort-by="['priority', 'dueAt']" :sort-desc="[true, true]">
        <template v-slot:item.createdAt="{ item }">
          {{ item.createdAt | datetime }}
        </template>
        <template v-slot:item.status="{ item }">
          <v-btn x-small v-if="item.status === 'OPEN'" color="success">Offen</v-btn>
          <v-btn x-small v-else-if="item.status === 'CLOSED'" color="warning">Geschlossen</v-btn>
        </template>
        <template v-slot:item.assignee="{ item }">
          <v-btn x-small color="primary" dark class="mr-3" v-for="emp in item.assignee" :key="emp.id">
            {{ emp.displayName }}
          </v-btn>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn icon color="primary" @click="assignMe(item)" v-if="!isCurrentUserAssigned(item)">
            <v-icon>mdi-account-plus-outline</v-icon>
          </v-btn>
          <v-btn icon color="primary" @click="unAssignMe(item)" v-if="isCurrentUserAssigned(item)">
            <v-icon>mdi-account-minus-outline</v-icon>
          </v-btn>
          <v-btn icon color="error" @click="setStatusOfItem(item, 'CLOSED')"
                 v-if="item.status === 'OPEN'">
            <v-icon>mdi-check-all</v-icon>
          </v-btn>
          <v-btn icon color="warning" @click="setStatusOfItem(item, 'OPEN')"
                 v-if="item.status === 'CLOSED'">
            <v-icon>mdi-eye-lock-open-outline</v-icon>
          </v-btn>
          <v-btn icon color="success" @click="modifyItem(item)">
            <v-icon>mdi-pen</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn small color="success" @click="fetchWorkListItems">
          <v-icon left>mdi-refresh</v-icon>
          Neu laden
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data:    () => ({
    workList:            [],
    workListTableHeader: [
      {text: 'Wichtigkeit', value: 'priority', width: '120px'},
      {text: 'Title', value: 'title'},
      {text: 'Fälligkeit', value: 'dueAt'},
      {text: 'Bearbeiter', value: 'assignee'},
      {text: 'Status', value: 'status', width: '160px'},
      {text: 'Ersteller', value: 'createdBy.displayName'},
      {text: 'Aktionen', value: 'actions', width: '10%'},
    ],

    employeeList:     [],
    filterStatus:     ['OPEN'],
    filterTargetUser: [],
  }),
  methods: {
    modifyItem(item) {
      this.$router.push('/government/work-list/' + item.id);
    },

    fetchWorkListItems() {
      this.apiGovernmentGetAllWorkListItems().then(r => {
        this.workList = r.data;
      })
    },
    fetchEmployeeList() {
      this.apiGetMissionReportEmployees().then(r => {
        this.employeeList = r.data;
      })
    },

    isCurrentUserAssigned(item) {
      return item.assignee.filter(obj => obj.id === this.user.id).length > 0;
    },

    setStatusOfItem(item, status) {
      item.status = status;
      this.apiGovernmentUpdateWorkListItem(item.id, item).then(() => {
        this.fetchWorkListItems();
      })
    },
    assignMe(item) {
      if (!this.isCurrentUserAssigned(item)) {
        item.assignee.push(this.user);
        this.apiGovernmentUpdateWorkListItem(item.id, item).then(() => {
          this.fetchWorkListItems();
        })
      }
    },
    unAssignMe(item) {
      item.assignee = item.assignee.filter(u => u.id !== this.user.id);
      this.apiGovernmentUpdateWorkListItem(item.id, item).then(() => {
        this.fetchWorkListItems();
      })
    },
  },
  mounted() {
    this.fetchWorkListItems();
    this.fetchEmployeeList();
  }
}
</script>
<template>
  <v-container fluid>
    <v-card elevation="4" outlined>
      <v-card-title class="pb-2 pt-2">
        <v-icon left color="info">mdi-card-account-details-outline</v-icon>
        Aktenregister
        <v-spacer/>
        <v-text-field dense v-model="search" append-icon="mdi-magnify" label="Suche" single-line hide-details/>
      </v-card-title>

      <v-divider/>

      <v-data-table :loading="loading" :headers="tblHeaders" :items="records" item-key="id" sort-by="wanted" sort-desc
                    :search="search">
        <template v-slot:item.actions="{ item }">
          <v-btn icon color="primary" @click="openRecord(item)" v-if="user.trustLevel >= item.trustLevel">
            <v-icon>mdi-eye-outline</v-icon>
          </v-btn>
          <v-btn icon color="error" @click="deleteRecord(item)"
                 v-if="user.orgAdmin || user.admin || user.flags.includes('DELETE_RECORD')">
            <v-icon>mdi-delete-forever-outline</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.wanted="{ item }">
          <v-btn x-small v-if="item.wanted" color="error">GESUCHT</v-btn>
          <!-- <v-btn x-small v-else color="success">Nicht Gesucht</v-btn> -->
        </template>
        <template v-slot:item.createdAt="{ item }">
          {{ item.createdAt.substr(0, 10) }}
        </template>
        <template v-slot:item.organization="{ item }">
          <span v-if="item.organization">{{ item.organization }}</span>
          <i v-else><small>Keine</small></i>
        </template>
        <template v-slot:item.gender="{ item }">
          <v-icon v-if="item.gender === 'FEMALE'" color="pink">mdi-gender-female</v-icon>
          <v-icon v-if="item.gender === 'MALE'" color="primary lighten-2">mdi-gender-male</v-icon>
          <v-icon v-if="item.gender === 'DIVERS'" color="primary lighten-2">mdi-gender-non-binary</v-icon>
          <v-icon v-if="item.gender === 'GOLDFISH'" color="primary lighten-2">mdi-fishbowl-outline</v-icon>
          <v-icon v-if="item.gender == null" color="warning darken-1">mdi-gender-male-female</v-icon>
        </template>
      </v-data-table>
      <div v-if="!isCurrentAccountPolice">
        <v-divider/>
        <v-card-actions>
          <small>(Die Akten beinhalten keine Informationen die in einem aktuellem Strafprozess genutzt werden. Außerdem sind einträge älter 4 Wochen nur über die Bundespolizei einsehbar.)</small>
        </v-card-actions>
      </div>
      <div v-if="isCurrentAccountPolice">
        <v-divider/>
        <v-card-actions>
          <v-btn small color="primary" elevation="3" to="/criminal-record/add">
            <v-icon left small>mdi-plus</v-icon>
            Akte Erstellen
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import Swal from 'sweetalert2';

export default {
  data:    () => ({
    search:  '',
    loading: false,

    tblHeaders: [
      {text: 'Name des Bürger', value: 'name'},
      {text: 'Organisation', value: 'organization'},
      {text: 'Status', value: 'wanted'},
      {text: 'Geburtsdatum', value: 'birthday'},
      {text: 'Geschlecht', value: 'gender'},
      {text: 'Aktenkundig seit', value: 'createdAt'},
      {text: 'Aktionen', value: 'actions', sortable: false}
    ],
    records:    [],
  }),
  methods: {
    openRecord(record) {
      this.$router.push('/criminal-record/' + record.id);
    },
    deleteRecord(record) {
      Swal.fire({
        title:              'Bist du dir sicher?',
        html:               'Du kannst diese Aktion nicht rückgängig machen. Möchtest du den Eintrag "<b>' + record.name + '</b>" wirklich löschen?',
        showDenyButton:     false,
        showCancelButton:   true,
        confirmButtonText:  `Löschen`,
        confirmButtonColor: '#d73939',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          Swal.showLoading();
          this.apiDeleteCriminalRecord(record.id).then(() => {
            Swal.hideLoading();
            Swal.fire('Erfolgreich!', 'Der Eintrag wurde gelöscht.', 'success')
            this.fetchRecords();
          }).catch(err => {
            Swal.hideLoading();
            Swal.fire('Fehler', 'Der Eintrag konnte nicht gelöscht werden:<br>' + err.response.statusCode, 'error');
          })
        }
      })
    },
    fetchRecords() {
      this.loading = true;

      this.apiGetCriminalRecords().then(r => {
        let result = [];

        r.data.forEach(record => {
          // hide wanted status to non-police accounts
          if (record.wanted && !this.isCurrentAccountPolice) {
            record.wanted = false;
          }

          result.push(record);
        })

        this.records = result;
      }).finally(() => {
        this.loading = false;
      });
    }
  },
  mounted() {
    this.fetchRecords();
  }
}
</script>
<!--suppress JSUnresolvedVariable -->
<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="pt-2 pb-2">
        <v-icon left>mdi-hanger</v-icon>
        Kleidungstool
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-text-field prepend-icon="mdi-text" solo dense placeholder="Addon-Name" outlined v-model="addonName"/>
        <v-select :items="clothingTypes" v-model="clothing.type" prepend-icon="mdi-hanger" solo dense
                  outlined/>
        <v-select :items="['M', 'F']" v-model="clothing.sex" prepend-icon="mdi-gender-male-female" solo dense
                  outlined/>
        <v-text-field type="number" :value="0" v-model="clothing.id" solo dense outlined prepend-icon="mdi-counter"/>

        <v-file-input multiple label="Dateien Auswählen (eine *.ydd, maximal 26 *.ytd's)" solo dense outlined
                      v-model="clothing.files"/>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="save()" small color="info">Zwischenspeichern</v-btn>
        <v-btn @click="exportZip()" small color="success">Export</v-btn>
      </v-card-actions>
      <v-divider/>

      <v-simple-table>
        <thead>
        <tr>
          <th>Addon</th>
          <th>ID</th>
          <th>Type</th>
          <th>Files</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="v in data" :key="v._type + v._number + v._addonName">
          <td>{{ v._addonName }}</td>
          <td>{{ v._number }}</td>
          <td>{{ v._type }}</td>
          <td class="pt-2 pb-2">
            <li v-for="file in v._files" :key="file.name">
              {{ file._name }}
            </li>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
    </v-card>
  </v-container>
</template>

<!--suppress ES6UnusedImports -->
<script>

// noinspection SpellCheckingInspection
import JSZip     from "jszip";
import FileSaver from 'file-saver';


// noinspection SpellCheckingInspection
const ClothingType = {
  ACCS: 'ACCS',
  BERD: 'BERD',
  DECL: 'DECL',
  FEET: 'FEET',
  HAIR: 'HAIR',
  HAND: 'HAND',
  JBIB: 'JBIB',
  LOWR: 'LOWR',
  TASK: 'TASK',
  TEEF: 'TEEF',
  UPPR: 'UPPR'
}

const Sex = {
  M: 'M',
  F: 'F',
}

class ClothingFile {
  _name;
  _file;

  constructor(file) {
    this._name = file.name;
    this._file = file;
  }

  static isYddFile(file) {
    if (typeof (file) === 'undefined') return false;
    if (typeof (file) === 'string') return file.endsWith('.ydd');
    return ClothingFile.isYddFile(file._name);
  }

  static isYtdFile(file) {
    if (typeof (file) === 'undefined') return false;
    if (typeof (file) === 'string') return file.endsWith('.ytd');
    return ClothingFile.isYtdFile(file._name);
  }

  static getSexOfFileName(file) {
    if (typeof (file) === 'string') {
      if (file.indexOf('mp_m_') >= 0) return 'M';
      if (file.indexOf('mp_f_') >= 0) return 'F';
      return undefined;
    }
    return ClothingFile.getSexOfFileName(file._name);
  }
}

class Clothing {

  _addonName;
  _type;
  _number;
  _sex;
  _files = [];

  constructor(type, number, sex, addonName) {
    this._addonName = addonName;
    this._type      = type;
    this._number    = number;
    this._sex       = sex;
  }

  addFile(file) {
    this._files.push(new ClothingFile(file));
  }

  hasYddFile() {
    const ydd = this._files.find(f => ClothingFile.isYddFile(f));
    return ydd !== undefined && ydd !== null;
  }

  getAddonDirectory() {
    const sex = this._sex.toLowerCase()
    // noinspection SpellCheckingInspection
    return `mp_${sex}_freemode_01_mp_${sex}_${this._addonName}`;
  }

  formatFileName(file, index) {
    if (ClothingFile.isYddFile(file)) {
      // noinspection SpellCheckingInspection
      return `${this.getAddonDirectory()}^${this._type.toLowerCase()}_${this.formatClothNumber(this._number)}_u.ydd`;
    } else if (ClothingFile.isYtdFile(file)) {
      const letter = String.fromCharCode(index + 65).toLowerCase();
      // noinspection SpellCheckingInspection
      return `${this.getAddonDirectory()}^${this._type.toLowerCase()}_diff_${this.formatClothNumber(this._number)}_${letter}_uni.ytd`;
    }
  }

  formatClothNumber(number) {
    number = number * 1; // to number :avdummkopf:
    if (number < 10) return '00' + number;
    if (number < 100) return '0' + number;
    if (number < 1000) return number;
  }
}

export default {
  data: () => ({
    addonName: 'avrp2',

    clothing: {
      sex:   Sex.M,
      type:  ClothingType.JBIB,
      id:    0,
      files: [],
    },

    data: [],
  }),
  mounted() {
  },
  computed: {
    clothingTypes() {
      let types = [];
      for (let key in ClothingType) {
        types.push(key);
      }
      return types;
    }
  },
  methods:  {
    save() {
      const obj = new Clothing(this.clothing.type, this.clothing.id, this.clothing.sex, this.addonName);

      for (let file of this.clothing.files) {
        obj.addFile(file);
      }

      if (!obj.hasYddFile() && this.data.length > 0) {
        let lastObj = this.data[this.data.length - 1];
        for (let file of this.clothing.files) {
          lastObj.addFile(file);
        }
      } else {
        this.data.push(obj);
      }

      this.clothing.files = [];
      this.clothing.sex   = Sex.M;
      this.clothing.type  = ClothingType.JBIB;
    },

    exportZip() {
      const zip = new JSZip();

      for (let v of this.data) {
        let ytdCounter = 0;
        for (let file of v._files) {
          zip.file(`${v.getAddonDirectory()}/${v._type.toLowerCase()}/${v.formatFileName(file, ytdCounter)}`, file._file);
          if (ClothingFile.isYtdFile(file)) ytdCounter++;
        }
      }

      zip.generateAsync({type: 'blob'}).then((content) => {
        FileSaver.saveAs(content, `clothing.zip`);
      });
    }
  },
  watch:    {
    'clothing.files'(val) {
      if (val.length > 0) {
        let ydd = val.find(f => ClothingFile.isYddFile(f.name));

        if (ydd) {
          let sex = ClothingFile.getSexOfFileName(ydd.name);
          if (sex !== undefined) this.clothing.sex = sex;

          let type = this.clothingTypes.find(t => ydd.name.indexOf(t.toLowerCase()) > -1);
          if (type !== undefined) this.clothing.type = type;
        }
      }
    }
  }
}
</script>
<template>
  <v-container fluid>
    <v-card class="pb-0 mb-0" outlined elevation="4">
      <v-tabs height="40px" v-model="tab" color="primary lighten-2">
        <v-tab>
          Editor
        </v-tab>
        <v-tab>
          HTML
        </v-tab>
        <v-tab>
          Preview
        </v-tab>
      </v-tabs>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field prepend-icon="mdi-form-textbox" label="Zusammenfassung / Titel"
                          solo outlined
                          v-model="report.subject" dense></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field prepend-icon="mdi-form-textbox" label="Aktenzeichen"
                          solo outlined
                          v-model="report.number" dense></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider/>

      <v-row v-if="tab === 2">
        <v-col>
          <v-card-text class="text-box scrollbar-overflow-hidden ql-editor" v-html="report.content">
          </v-card-text>
        </v-col>
      </v-row>

      <v-card-text>
        <vue-editor v-model="report.content" v-if="tab === 0" style="height: 100%"
                    :customModules="editor.customModules"
                    :editorOptions="editor.settings"/>
        <v-textarea solo outlined rows="30" v-model="report.content" v-if="tab === 1" class="pa-4"></v-textarea>
      </v-card-text>

      <v-divider/>
      <v-card-actions>
        <v-btn small color="success" v-if="id" @click="saveEntry">
          <v-icon left>mdi-content-save-outline</v-icon>
          Speichern
        </v-btn>
        <v-btn small color="primary" v-else @click="createEntry">
          <v-icon left>mdi-content-save-outline</v-icon>
          Erstellen
        </v-btn>

        <v-btn small color="warning" @click="uploadFTP">
          <v-icon left>mdi-cloud-upload-outline</v-icon>
          Beweise Hochladen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import {VueEditor} from "vue2-editor";
import ImageResize from 'quill-image-resize-module';
import moment from 'moment';

export default {
  components: {VueEditor},
  data:       () => ({
    id: null,

    report: {
      subject: '',
      content: '',
      number:  '',
    },

    tab: 0,

    editor: {
      customModules: [
        {alias: 'imageResize', module: ImageResize},
      ],
      settings:      {
        theme:   'snow',
        modules: {
          table:       false,
          imageResize: {},
        },
      }
    }
  }),
  methods:    {
    uploadFTP() {
      var input    = document.createElement('input');
      input.type   = 'file';
      input.accept = '.png'

      input.onchange = e => {

        // getting a hold of the file reference
        let file = e.target.files[0];

        // setting up the reader
        let reader = new FileReader();
        reader.readAsDataURL(file); // this is reading as data url

        // here we tell the reader what to do when it's done reading...
        reader.onload = readerEvent => {
          let content     = readerEvent.target.result; // this is the content!
          let base64Image = content.substring("data:image/png;base64,".length);

          this.apiUploadEvidence(base64Image, "evidence.png").then(r => {
            this.report.content += '<img src="' + this.apiAttachmentUrl(r.data) + '"/>'
          })
        }

      }

      input.click();
    },
    fetchDocument(id) {
      this.apiGetCriminalFile(id).then(r => {
        this.report = r.data;
      })
    },

    saveEntry() {
      if (this.report.missionTime)
        this.report.missionTime = new Date(this.report.missionTime.getTime() - (this.report.missionTime.getTimezoneOffset() * 60000));

      this.apiUpdateCriminalFile(this.id, this.report).then(r => {
        this.$router.push('/criminal-file/' + r.data.id);
      })
    },
    createEntry() {
      if (this.$route.name === 'CriminalFilesCreateSecure' && this.userHasSecurityLevel('HIGH_CRIMINAL_OFFICER')) {
        this.report.requiredSecurityLevel = 'HIGH_CRIMINAL_OFFICER';
      }

      this.apiCreateCriminalFile(this.report).then(r => {
        this.$router.push('/criminal-file/' + r.data.id);
      })
    }
  },
  mounted() {
    this.id = this.$route.params.id || null;

    if (this.id) {
      this.fetchDocument(this.id);
    } else {
      this.report.number = 'K-' + moment().format('DDMMYYYY');

      if (this.$route.name === 'CriminalFilesCreateSecure') {
        this.report.number = 'KS-' + moment().format('DDMMYYYY');
      }
    }
  }
}
</script>
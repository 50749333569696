import { render, staticRenderFns } from "./MedicHeatmap.vue?vue&type=template&id=10d97801&"
import script from "./MedicHeatmap.vue?vue&type=script&lang=js&"
export * from "./MedicHeatmap.vue?vue&type=script&lang=js&"
import style0 from "./MedicHeatmap.vue?vue&type=style&index=0&id=10d97801&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
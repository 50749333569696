<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="pb-2 pt-2">
        <v-icon left>mdi-account-tie-hat-outline</v-icon>
        Ausbildungen
      </v-card-title>
      <v-divider/>
      <div v-for="category in categories" :key="category">
        <v-card-title class="pt-2 pb-2">
          <v-icon left>mdi-folder-open-outline</v-icon>
          {{ category }}
        </v-card-title>
        <v-divider/>
        <v-simple-table>
          <thead>
          <tr>
            <th width="20%">Ausbildung</th>
            <th width="60%">Ausbilder</th>
            <th width="5%">Interessenten</th>
            <th>Aktionen</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(v, k) in getCoursesForCategory(category)" :key="k">
            <td>{{ v.title }}</td>
            <td>{{ v.trainers | users_tostring }}</td>
            <td>{{ v.applicant.length }}</td>

            <td>
              <v-btn v-if="!isApplicant(v)" color="green" icon @click="toggleApplicant(v)">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-btn v-if="isApplicant(v)" color="red" icon @click="toggleApplicant(v)">
                <v-icon>mdi-minus</v-icon>
              </v-btn>

              <v-btn v-if="user.admin || user.orgAdmin || (user.flags.includes('TRAINER') && isTrainer(v))" color="blue" icon @click="patchCourseWithEmptyApplicants(v)">
                <v-icon>mdi-close</v-icon>
              </v-btn>

              <v-btn v-if="user.admin || user.orgAdmin || (user.flags.includes('TRAINER') && isTrainer(v))" icon
                     color="orange" @click="editCourse(v)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn v-if="user.admin || user.orgAdmin || (user.flags.includes('TRAINER') && isTrainer(v))" icon
                     color="red" @click="deleteCourse(v)">
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </td>
          </tr>
          </tbody>
        </v-simple-table>
      </div>
      <v-divider/>
      <v-card-actions>
        <v-btn small color="primary" to="/courses/add"
               v-if="user.flags.includes('TRAINER') || user.admin || user.orgAdmin">
          <v-icon left>mdi-file-plus-outline</v-icon>
          Ausbildung erstellen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data:     () => ({
    courses: [],
  }),
  computed: {
    categories() {
      let categories = []

      this.courses.forEach(course => {
        if (!categories.includes(course.category) && course.category) categories.push(course.category);
      })

      categories.sort((a, b) => a.localeCompare(b))

      return categories;
    }
  },
  filters:  {
    users_tostring(trainers) {
      let names = []
      trainers.forEach(trainer => {
        names.push(trainer.displayName)
      })
      return names.join(', ')
    },
  },
  methods:  {
    fetchCourses() {
      this.apiGetAllCourses().then(response => {
        this.courses = response.data;
      })
    },

    toggleApplicant(course) {
      this.apiRegisterForCourse(course.id).then(() => {
        this.fetchCourses();
      })
    },

    patchCourseWithEmptyApplicants(course) {
      course.applicant = [];
      this.apiUpdateCourseById(course.id, course).then(() => {
        this.fetchCourses();
      })
    },

    editCourse(course) {
      this.$router.push('/course/' + course.id);
    },

    deleteCourse(course) {
      this.apiDeleteCourseById(course.id);
    },

    isApplicant(course) {
      let isApplicant = false
      course.applicant.forEach(applicant => {
        if (applicant.id === this.user.id) isApplicant = true;
      })
      return isApplicant;
    },

    isTrainer(course) {
      let isTrainer = false
      course.trainers.forEach(trainer => {
        if (trainer.id === this.user.id) isTrainer = true;
      })
      return isTrainer;
    },

    getCoursesForCategory(category) {
      let courses = []
      this.courses.forEach(course => {
        if (course.category === category) courses.push(course);
      })
      return courses;
    }
  },
  mounted() {
    this.fetchCourses();
  }
}
</script>
<template>
  <v-container fluid>
    <v-card elevation="4" outlined>
      <v-card-title>
        <v-icon left color="error">mdi-hospital</v-icon>
        Patientenakten - Akte Anlegen
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-row>
          <v-col class="pt-4 pb-1">
            <v-text-field v-model="name" label="Name des Bürgers" dense solo outlined single-line counter
                          prepend-icon="mdi-account"/>
          </v-col>
          <v-col class="pt-4 pb-1">
            <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="birthday"
                    transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field dense solo outlined v-model="birthday" label="Geburtsdatum" prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="birthday" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(birthday)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-select dense solo outlined
                      :items="[{value: 'MALE', text: 'Männlich'}, {value: 'FEMALE', text: 'Weiblich'}]"
                      item-text="text" prepend-icon="mdi-gender-male-female"
                      item-value="value" v-model="gender"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field dense solo outlined v-model="bloodType" placeholder="Blutgruppe"
                          prepend-icon="mdi-blood-bag"/>
          </v-col>
          <v-col>
            <v-text-field dense solo outlined v-model="knownAllergies" placeholder="Bekannte Allergien"
                          prepend-icon="mdi-emoticon-sick-outline"/>
          </v-col>
          <v-col>
            <v-text-field dense solo outlined v-model="knownDiseases" placeholder="Bekannte Krankheiten"
                          prepend-icon="mdi-emoticon-sick-outline"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0 pb-1">
            <v-text-field solo outlined v-model="address" label="Anschrift (wenn bekannt)" dense
                          prepend-icon="mdi-home-group"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0 pb-1">
            <v-text-field solo outlined v-model="org" label="Organisation" dense prepend-icon="mdi-account-multiple"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0 pb-1">
            <v-text-field solo outlined v-model="phoneNumber" label="Telefonnummer" dense
                          prepend-icon="mdi-cellphone-basic"/>
          </v-col>
          <v-col class="pt-0 pb-1" cols="3">
            <v-checkbox dense v-model="privateInsurance" label="Privatversichert?" ripple/>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn small color="success" @click="addRecord">
          <v-icon left>mdi-check</v-icon>
          Akte Anlegen
        </v-btn>
        <v-spacer/>
        <v-btn small dark color="blue-grey" to="/medical-records">
          Abbrechen
        </v-btn>
      </v-card-actions>

      <v-divider v-if="error"/>
      <v-card-text v-if="error" class="pb-0">
        <v-alert type="error" dense outlined elevation="4" border="left">
          {{ error }}
        </v-alert>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data:    () => ({
    name:        '',
    birthday:    '1997-01-01',
    org:         '',
    address:     '',
    phoneNumber: '',
    gender:      'MALE',

    privateInsurance: false,

    trustLevel: 0,

    bloodType:      '',
    knownAllergies: '',
    knownDiseases:  '',
    allowedDrugs:   '',
    note:           '',

    error: null,

    menu: false,
  }),
  methods: {
    addRecord() {
      this.error = null;

      if (this.name.length < 3) {
        this.error = 'Name des Bürgers darf nicht weniger als 3 Zeichen enthalten.';
      } else if (!this.name.includes(' ')) {
        this.error = 'Name des Bürgers muss mindestens ein Leerzeichen enthalten.';
      } else {
        this.apiCreateMedicalRecord({
          name:             this.name,
          birthday:         this.birthday,
          gender:           this.gender,
          trustLevel:       this.trustLevel,
          bloodType:        this.bloodType,
          knownAllergies:   this.knownAllergies,
          knownDiseases:    this.knownDiseases,
          allowedDrugs:     this.allowedDrugs,
          privateInsurance: this.privateInsurance,
          note:             this.note,
          organization:     this.org,
          phoneNumber:      this.phoneNumber,
          address:          this.address,
        }).then(() => {
          this.$router.push('/medical-records');
        })
      }
    }
  }
}
</script>
<!--suppress JSUnresolvedVariable -->
<template>
  <v-container fluid>
    <v-card elevation="4" outlined>
      <v-card-title class="pb-2 pt-2">
        <v-icon left color="info">mdi-receipt-text-plus-outline</v-icon>
        Eingangsrechnungen
        <v-spacer/>
        <v-text-field dense v-model="search" append-icon="mdi-magnify" label="Suche" single-line hide-details/>
      </v-card-title>
      <v-divider/>
      <v-data-table :items="invoices" :search="search" :headers="invoiceTableHeaders" sort-by="billedAt" sort-desc
                    group-by="source">
        <template v-slot:item.createdAt="{ item }">
          {{ item.createdAt | datetime }}
        </template>
        <template v-slot:item.billedAt="{ item }">
          {{ item.billedAt | datetime }}
        </template>
        <template v-slot:item.amount="{ item }">
          <span v-if="item.tax > 0">
            {{ item.amount * item.tax | money }} ({{ Math.round((item.tax - 1) * 100) }} % incl.)
          </span>
          <span v-else-if="item.tax < 0">
            {{ item.amount / (item.tax * -1) | money }} ({{ Math.round((item.tax + 1) * -100) }} % excl.)
          </span>
          <span v-else>
            {{ item.amount | money }}
          </span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn icon color="primary" @click="openInvoice(item)">
            <v-icon>mdi-eye-outline</v-icon>
          </v-btn>
          <v-btn icon color="error" @click="deleteInvoice(item)"
                 v-if="user.orgAdmin || user.admin">
            <v-icon>mdi-delete-forever-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-divider/>
      <v-card-actions>
        <v-btn small color="success" @click="importInvoice()">
          <v-icon left>mdi-plus-circle-outline</v-icon>
          Rechnung verbuchen
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="createInvoiceDialog" width="700" persistent>
      <v-card>
        <v-card-title class="pb-2 pt-2 pl-3">
          <v-icon left>mdi-plus-circle-outline</v-icon>
          Rechnung verbuchen
        </v-card-title>
        <v-divider/>
        <v-card-text class="pt-4">
          <v-text-field v-model="createInvoice.source" solo dense outlined hint="Quelle" persistent-hint
                        prepend-inner-icon="mdi-web" placeholder="Tebex"/>
          <v-text-field v-model="createInvoice.description" solo dense outlined hint="Beschreibung" persistent-hint
                        prepend-inner-icon="mdi-comment-text-outline"
                        placeholder="Abrechnung von 01.01.2023 - 06.01.2023"/>
          <v-text-field v-model="createInvoice.amount" type="number" solo dense outlined hint="Rechnungsbetrag"
                        persistent-hint prepend-inner-icon="mdi-currency-eur" placeholder="200,00"/>
          <v-select v-model="createInvoice.tax" solo dense outlined persistent-hint :items="TAX_TYPES"
                    item-value="value" item-text="label" hint="Steuersatz"/>

          <v-file-input v-model="createInvoiceFile" solo dense outlined persistent-hint hint="Rechnungs-PDF"
                        prepend-icon="" prepend-inner-icon="mdi-file-pdf-box" show-size />

          {{ createInvoiceFile }}
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn small color="success" dark @click="publishInvoice()">
            Erstellen
          </v-btn>
          <v-spacer/>
          <v-btn small color="warning" dark @click="createInvoiceDialog = false">
            Abbrechen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Swal from 'sweetalert2';

export default {
  data: () => ({
    search: null,

    invoices: [],

    invoiceTableHeaders: [
      {value: 'source', text: 'Quelle'},
      {value: 'description', text: 'Beschreibung'},
      {value: 'billedAt', text: 'Buchungsdatum'},
      {value: 'amount', text: 'Betrag'},
      {value: 'createdBy.displayName', text: 'User'},
      {value: 'actions', text: 'Aktionen'}
    ],

    createInvoiceDialog: true,
    createInvoice: {
      source: null,
      description: null,
      amount: 0,
      tax: 0.0,
    },
    createInvoiceFile: null,
  }),
  methods: {
    openInvoice(invoice) {
      this.$router.push('/admin/credit-invoice/' + invoice.id);
    },
    deleteInvoice(invoice) {
      console.log(invoice.id + " deleted");
    },

    publishInvoice() {
      if (this.createInvoiceFile != null) {
        this.toBase64(this.createInvoiceFile).then(fileContent => {
          this.apiCreateCreditInvoice(this.createInvoice, fileContent).then(() => {
            this.createInvoiceDialog = false;
            Swal.fire('Rechnung erstellt');
          });
        });
      } else {
        this.apiCreateCreditInvoice(this.createInvoice, null).then(() => {
          this.createInvoiceDialog = false;
          Swal.fire('Rechnung erstellt');
        });
      }
      //
    },

    importInvoice() {
      this.createInvoiceDialog = true;
    },
  },
  mounted() {
    this.apiGetAllCreditInvoices().then(r => {
      this.invoices = r.data;
    })
  }
}
</script>
<template>
    <v-container fluid>
        <v-card class="mb-2">
            <v-card-title class="pt-2 pb-2">
                <v-icon left color="primary">mdi-database-search</v-icon>
                Datensätze durchsuchen
            </v-card-title>
            <v-divider/>
            <v-card-text class="pb-0">
                <v-text-field prepend-inner-icon="mdi-database-search" solo dense outlined placeholder="Mangelernährung" v-model="searchContent"
                              @keydown.enter="doSearchContent" @click:append-outer="doSearchContent" append-outer-icon="mdi-run" :loading="isSearchContent">

                </v-text-field>
            </v-card-text>
        </v-card>

        <v-card elevation="4" outlined>
            <v-card-title class="pt-2 pb-2">
                <v-icon left color="primary">mdi-card-account-details-outline</v-icon>
                <span v-if="currentSearchTerm">Alle Einträge welche <b>{{ currentSearchTerm }}</b> enthalten</span>
                <span v-else>Zuletzt angelegte Akteneinträge</span>
                <v-spacer/>
                <v-text-field dense v-model="search" append-icon="mdi-magnify" label="Suche" single-line hide-details/>
            </v-card-title>

            <v-divider/>

            <v-data-table :loading="loading" :headers="tblHeaders" :items="records" item-key="id" sort-by="createdAt" sort-desc
                          :search="search">
                <template v-slot:item.actions="{ item }">
                    <v-btn icon color="primary lighten-1" @click="previewRecordEntry(item)">
                        <v-icon>mdi-resize</v-icon>
                    </v-btn>
                    <v-btn icon color="primary lighten-1" @click="openRecord(item)">
                        <v-icon>mdi-eye-outline</v-icon>
                    </v-btn>
                    <v-btn icon color="error" @click="deleteRecordEntry(item)"
                           v-if="user.orgAdmin || user.admin || user.flags.includes('DELETE_RECORD_ENTRY')">
                        <v-icon>mdi-delete-forever-outline</v-icon>
                    </v-btn>
                </template>
                <template v-slot:item.type="{ item }">
                    <v-btn x-small v-if="item.entryType === 'FINDING'" outlined :color="entryEntryColor(item)">Medizinischer Befund</v-btn>
                    <v-btn x-small v-else-if="item.entryType === 'NOTE'" outlined :color="entryEntryColor(item)">Notiz</v-btn>
                    <v-btn x-small v-else-if="item.entryType === 'RECORD_CHANGE'" outlined :color="entryEntryColor(item)">Aktenänderung</v-btn>
                    <v-btn x-small v-else-if="item.entryType === 'OP'" outlined :color="entryEntryColor(item)">Operation</v-btn>
                    <v-btn x-small v-else-if="item.entryType === 'ACCIDENT'" outlined :color="entryEntryColor(item)">Unfall</v-btn>
                    <v-btn x-small v-else-if="item.entryType === 'REPORT'" outlined :color="entryEntryColor(item)">Meldung</v-btn>
                    <v-btn x-small v-else-if="item.entryType === 'NOTE'" outlined :color="entryEntryColor(item)">Anmerkung / Notiz</v-btn>
                    <v-btn x-small v-else-if="item.entryType === 'MALNUTRITION'" outlined :color="entryEntryColor(item)">Mangelernährung</v-btn>
                    <v-btn x-small v-else-if="item.entryType === 'MPE'" outlined :color="entryEntryColor(item)">MPU</v-btn>
                    <v-btn x-small v-else outlined color="orange">{{ item.entryType }}</v-btn>

                    <!-- <v-btn x-small v-else color="success">Nicht Gesucht</v-btn> -->
                </template>
                <template v-slot:item.createdAt="{ item }">
                    {{ item.createdAt.substr(0, 10) + ' ' + item.createdAt.substr(11, 8) }}
                </template>
                <template v-slot:item.gender="{ item }">
                    <v-icon v-if="item.gender === 'FEMALE'" color="pink">mdi-gender-female</v-icon>
                    <v-icon v-if="item.gender === 'MALE'" color="primary lighten-2">mdi-gender-male</v-icon>
                    <v-icon v-if="item.gender == null" color="warning darken-1">mdi-gender-male-female</v-icon>
                </template>

                <template v-slot:item.createdBy.displayName="{ item }">
                    <user-avatar :size="24" :user-obj="item.createdBy" cache class="mr-2"/>
                    <span>{{ item.createdBy.displayName }}</span>
                </template>
            </v-data-table>
            <v-divider/>
            <v-card-actions>
                <v-btn small color="success" elevation="3" to="/criminal-record/add">
                    <v-icon left small>mdi-plus</v-icon>
                    Akte Erstellen
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-bottom-sheet v-model="activeRecordSheet" inset>
            <v-card>
                <medical-record-entry v-if="activeRecordEntry !== null" :entry="activeRecordEntry"
                                      :record="activeRecordEntry.record"/>
            </v-card>
        </v-bottom-sheet>
    </v-container>
</template>

<script>
import Swal               from 'sweetalert2';
import MedicalRecordEntry from "@/components/MedicalRecordEntry";
import UserAvatar         from "@/components/UserAvatar.vue";

export default {
    components: {UserAvatar, MedicalRecordEntry},
    data:       () => ({
        searchContent:     '',
        isSearchContent:   false,
        currentSearchTerm: '',

        search:  '',
        loading: false,

        activeRecordSheet: false,
        activeRecordEntry: null,

        tblHeaders: [
            {text: 'Name des Bürger', value: 'record.name'},
            {text: 'Art des Eintrags', value: 'type'},
            {text: 'Beamter', value: 'createdBy.displayName'},
            {text: 'Erstellt am', value: 'createdAt'},
            {text: 'Aktionen', value: 'actions', sortable: false}
        ],
        records:    [],
    }),
    methods:    {
        doSearchContent() {
            if (this.searchContent?.length > 3) {
                this.loading         = true;
                this.isSearchContent = true;
                this.apiGetMedicalRecordsByContentLike(this.searchContent).then(r => {
                    this.records           = r.data;
                    this.currentSearchTerm = this.searchContent;
                }).finally(() => {
                    this.loading         = false;
                    this.isSearchContent = false;
                });
            } else {
                this.currentSearchTerm = '';
                this.fetchRecords();
            }
        },
        openRecord(recordEntry) {
            this.$router.push('/medical-record/' + recordEntry.record.id);
        },
        previewRecordEntry(recordEntry) {
            this.activeRecordSheet = true
            this.activeRecordEntry = recordEntry
        },
        entryEntryColor(entry) {
            if (entry.entryType === 'FINDING') return 'info';
            if (entry.entryType === 'REPORT') return 'info';
            if (entry.entryType === 'ACCIDENT') return 'warning';
            if (entry.entryType === 'OP') return 'error';
            if (entry.entryType === 'RECORD_CHANGE') return 'success';
            if (entry.entryType === 'NOTE') return 'grey';
            if (entry.entryType === 'MPE') return 'info lighten-3';
            if (entry.entryType === 'MALNUTRITION') return 'error lighten-3';

            return 'primary';
        },
        deleteRecordEntry(recordEntry) {
            Swal.fire({
                title:              'Bist du dir sicher?',
                html:               'Du kannst diese Aktion nicht rückgängig machen. Möchtest du den Eintrag aus der Akte von "<b>' + recordEntry.record.name + '</b>" wirklich löschen?',
                showDenyButton:     false,
                showCancelButton:   true,
                confirmButtonText:  `Löschen`,
                confirmButtonColor: '#d73939',
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    Swal.showLoading();
                    this.apiDeleteMedicalRecordEntry(recordEntry.record.id, recordEntry.id).then(() => {
                        Swal.hideLoading();
                        Swal.fire('Erfolgreich!', 'Der Eintrag wurde gelöscht.', 'success')
                        this.fetchRecords();
                    }).catch(err => {
                        Swal.hideLoading();
                        Swal.fire('Fehler', 'Der Eintrag konnte nicht gelöscht werden:<br>' + err.response.statusCode, 'error');
                    })
                }
            })
        },
        fetchRecords() {
            this.loading = true;

            this.apiGetMedicalRecordEntriesLatest().then(r => {
                this.records = r.data
            }).finally(() => {
                this.loading = false;
            });
        }
    },
    mounted() {
        this.fetchRecords();
    }
}
</script>
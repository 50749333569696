<template>
  <v-container fluid fill-height fill-width>
    <v-card fill-width width="100%" outlined elevation="4" v-if="report.createdBy">
      <v-card-title v-html="mdToHtml('*' + report.number + '* - ' + report.subject)" class="pb-2 pt-2">
      </v-card-title>
      <v-divider/>
      <v-row>
        <v-col>
          <v-card-text class="text-box scrollbar-overflow-hidden ql-editor scrollbox" v-html="report.content">
          </v-card-text>
        </v-col>
        <v-col cols="3">
          <v-card-text>
            <v-row>
              <v-col class="pb-0 pt-2">
                <v-text-field solo outlined disabled v-model="report.createdBy.displayName" dense
                              :prepend-icon="userTypeToIcon()"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0 pt-2">
                <v-text-field solo outlined disabled :value="formatDate(report.modifiedAt)" dense
                              prepend-icon="mdi-calendar-alert"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0 pt-2">
                <v-text-field solo outlined disabled :value="formatDate(report.createdAt)" dense
                              prepend-icon="mdi-calendar-edit"/>
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
      </v-row>
      <v-divider/>
      <v-card-actions>
        <v-btn small icon color="warning lighten-1" class="mr-1"
               :to="'/criminal-file/' + id + '/edit'">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>


<script>
import markdown from "markdown-it";
import moment from 'moment';

export default {
  components: {},
  data: () => ({
    id: null,

    report: {
      subject: '',
      content: '',
      createdBy: null,
    },

    tab: 0,
  }),
  methods: {
    mdToHtml(markdownText) {
      if (markdownText == null || markdownText.trim().length === 0) {
        return '<i>Keine Informationen angegeben.</i>'
      }

      return markdown({breaks: true, typographer: true, xhtmlOut: true}).render(markdownText);
    },
    fetchDocument(id) {
      this.apiGetCriminalFile(id).then(r => {
        this.report = r.data;
      })
    },
    formatDate(date) {
      if (date)
        return moment(date).format('DD.MM.YYYY HH:mm')
      return 'Keine Angabe';
    },
    userTypeToIcon() {
      if (this.userAccountType === 'POLICE') return 'mdi-police-badge-outline'
      if (this.userAccountType === 'MEDIC') return 'mdi-doctor'
      if (this.userAccountType === 'GOVERNMENT') return 'mdi-account-tie'
      return 'mdi-account';
    }
  },
  mounted() {
    this.id = this.$route.params.id || null;

    if (this.id) {
      this.fetchDocument(this.id);
    } else {
      this.$router.push('/criminal-files')
    }
  }
}
</script>
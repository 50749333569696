<template>
    <v-container fluid>
        <v-skeleton-loader type="card" v-if="!list">

        </v-skeleton-loader>
        <v-card v-if="list" elevation="6">
            <v-card-title class="pt-1 pb-1">
                <v-icon left color="primary">mdi-list-box-outline</v-icon>
                {{ list.label }}
                <v-spacer/>
                <v-text-field dense solo clearable v-model="search" prepend-inner-icon="mdi-magnify" label="Suche" single-line hide-details/>
            </v-card-title>
            <v-divider/>
            <v-data-table :items="dataTableItems" :headers="dataTableHeader" sort-by="createdAt" sort-desc :search="search" :custom-filter="customSearch">

                <template v-slot:item="item">
                    <tr :class="editRowId === item.item.id ? 'blue-grey white--text' : ''">
                        <td v-for="v in item.headers" :key="v.id">
                            <!-- DATETIME -->
                            <span v-if="v.dataType === 'DATETIME'">{{ item.item[v.value] | datetime }}</span>
                            <span v-else-if="v.dataType === 'DATE'">{{ item.item[v.value] | date }}</span>

                            <!-- MONEY -->
                            <span v-else-if="v.dataType === 'MONEY'">{{ item.item[v.value] | money }}</span>

                            <!-- BOOLEAN -->
                            <span v-else-if="v.dataType === 'BOOLEAN'">
                                <v-icon v-if="item.item[v.value]" color="success"
                                        @click="modifyRowColumn(list.id, item.item.id, v.value,false);">mdi-checkbox-marked-circle-outline</v-icon>
                                <v-icon v-else color="error" @click="modifyRowColumn(list.id, item.item.id, v.value, true);">mdi-circle-outline</v-icon>
                            </span>

                            <!-- CRIMINAL_RECORD -->
                            <span v-else-if="v.dataType === 'CRIMINAL_RECORD'">
                                <router-link v-if="item.item[v.value]" :to="'/criminal-record/' + item.item[v.value]">{{ getCriminalRecordDisplayName(item.item[v.value]) }}</router-link>
                                <span v-else>-</span>
                            </span>

                            <!-- MEDICAL_RECORD -->
                            <span v-else-if="v.dataType === 'MEDICAL_RECORD'">
                                <router-link v-if="item.item[v.value]" :to="'/medical-record/' + item.item[v.value]">{{ getMedicalRecordDisplayName(item.item[v.value]) }}</router-link>
                                <span v-else>-</span>
                            </span>

                            <!-- USER -->
                            <span v-else-if="v.dataType === 'USER'">
                                <router-link v-if="item.item[v.value]" :to="'/employee/' + item.item[v.value]">{{ getUserDisplayName(item.item[v.value]) }}</router-link>
                                <span v-else>-</span>
                            </span>

                            <!-- IMAGE -->
                            <span v-else-if="v.dataType === 'IMAGE'">
                                <v-img v-if="isImageValue(item.item[v.value])" :src="apiAttachmentUrl({id: item.item[v.value]})" height="64px" width="64px" @click="imagePopup(item.item[v.value])"/>
                                <span v-else>-</span>
                            </span>

                            <!-- ACTIONS -->
                            <span v-else-if="v.value === 'actions' && canWriteTable">
                                <v-btn icon color="orange" @click="editRow(item.item)">
                                    <v-icon>mdi-pen</v-icon>
                                </v-btn>
                                <v-btn icon color="error" class="ml-1" @click="deleteRow(item.item)">
                                    <v-icon>mdi-delete-sweep-outline</v-icon>
                                </v-btn>
                            </span>

                            <v-icon v-else-if="v.dataType === 'ICON'">{{ `mdi-${item.item[v.value]}` }}</v-icon>
                            <v-icon v-else-if="v.dataType === 'ICON_SELECT'">{{ `mdi-${item.item[v.value]}` }}</v-icon>

                            <!-- ANY OTHER DATA -->
                            <span v-else>{{ item.item[v.value] }}</span>
                        </td>
                    </tr>
                </template>
            </v-data-table>
            <div v-if="canWriteTable">
                <v-divider/>
                <v-card-title class="pt-1 pb-1" v-if="editRowId">
                    <v-icon left color="primary">mdi-database-edit-outline</v-icon>
                    Eintrag {{ editRowId }} bearbeiten
                    <v-spacer/>
                    <v-btn x-small color="orange" @click="clearInputFields">
                        Bearbeitung abbrechen
                    </v-btn>
                </v-card-title>
                <v-card-title class="pt-1 pb-1" v-else>
                    <v-icon left color="primary">mdi-database-plus-outline</v-icon>
                    Eintrag erfassen
                </v-card-title>
                <v-divider/>
                <v-card-text>
                    <v-row>
                        <v-col cols="3" v-for="col in list.columns" :key="col.id" class="pb-0 mb-0">
                            <v-text-field v-model="newRowValues[col.id]" v-if="col.dataType === 'STRING'" solo dense outlined :placeholder="col.label"/>
                            <v-text-field v-model="newRowValues[col.id]" v-if="col.dataType === 'ICON'" hint="https://pictogrammers.com/library/mdi/" persistent-hint solo dense outlined
                                          :placeholder="col.label"/>
                            <v-text-field type="number" v-model="newRowValues[col.id]" v-if="['MONEY', 'INTEGER'].includes(col.dataType)" solo dense outlined :placeholder="col.label"/>
                            <v-autocomplete :items="criminalRecords" item-text="name" item-value="id" v-model="newRowValues[col.id]" v-if="col.dataType === 'CRIMINAL_RECORD'" solo dense outlined
                                            :placeholder="col.label"/>
                            <v-autocomplete :items="medicalRecords" item-text="name" item-value="id" v-model="newRowValues[col.id]" v-if="col.dataType === 'MEDICAL_RECORD'" solo dense outlined
                                            :placeholder="col.label"/>
                            <v-autocomplete :items="users" item-text="displayName" item-value="id" v-model="newRowValues[col.id]" v-if="col.dataType === 'USER'" solo dense outlined
                                            :placeholder="col.label"/>

                            <v-checkbox v-model="newRowValues[col.id]" :label="col.label" v-if="col.dataType === 'BOOLEAN'" dense/>

                            <custom-date-picker :ref="'col_' + col.id" v-model="newRowValues[col.id]" :datetime="newRowValues[col.id]" v-if="col.dataType === 'DATE'" :label="col.label"
                                                :text-field-props="{solo: true, dense: true, outlined: true}"/>

                            <v-datetime-picker :ref="'col_' + col.id" v-model="newRowValues[col.id]" :datetime="newRowValues[col.id]" v-if="col.dataType === 'DATETIME'" :label="col.label"
                                               :text-field-props="{solo: true, dense: true, outlined: true}"/>

                            <v-select :items="col.defaults" v-model="newRowValues[col.id]" v-if="col.dataType === 'SELECT'" :label="col.label" solo dense outlined :placeholder="col.label"/>

                            <div>
                                <v-file-input v-model="newRowValues[col.id]" v-if="col.dataType === 'IMAGE' && !newRowValues[col.id]" :label="col.label" solo dense outlined :placeholder="col.label"/>

                                <v-img v-else-if="isImageValue(newRowValues[col.id])" :src="apiAttachmentUrl({id: newRowValues[col.id]})"/>
                            </div>

                            <v-select :items="col.defaults" v-model="newRowValues[col.id]" v-if="col.dataType === 'ICON_SELECT'" :label="col.label" solo dense outlined :placeholder="col.label">
                                <template v-slot:item="{ item  }">
                                    <v-icon left>{{ `mdi-${item}` }}</v-icon>
                                    {{ item }}
                                </template>
                                <template v-slot:selection="{ item  }">
                                    <v-icon left>{{ `mdi-${item}` }}</v-icon>
                                    {{ item }}
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-btn small color="primary" @click="postNewRow">
                        <v-icon left>mdi-database-plus-outline</v-icon>
                        Datensatz erfassen
                    </v-btn>
                </v-card-actions>

                <pre>{{ columnDataTypes }}</pre>

            </div>
        </v-card>

        <v-dialog width="800" v-model="imageDialog">
            <v-card>
                {{ imageDialogData }}
                <v-img v-if="isImageValue(imageDialogData)" :src="imageDialogData"/>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<!--suppress JSUnresolvedReference -->
<script>
import Swal             from "sweetalert2";
import CustomDatePicker from "@/components/CustomDatePicker.vue";
import moment           from "moment";

export default {
    components: {CustomDatePicker},
    data:       () => ({
        list: null,

        search: null,

        editRowId:    null,
        newRowValues: {},

        users:           [],
        criminalRecords: [],
        medicalRecords:  [],

        dataTableHeader: [],
        dataTableItems:  [],

        imageDialog:     false,
        imageDialogData: null,


        dataTypes:       [],
        columnDataTypes: {},
    }),
    computed:   {
        canWriteTable() {
            let user            = this.user;
            let writeUserFlag   = this.list.writeUserFlag;
            let writeTrustLevel = this.list.writeTrustLevel;

            if (user.admin || user.orgAdmin) return true;

            let isUserFlagAccessDenied  = writeUserFlag != null && !this.userHasFlag(writeUserFlag);
            let isUserTrustAccessDenied = writeTrustLevel != null && user.trustLevel < writeTrustLevel;

            return !isUserFlagAccessDenied && !isUserTrustAccessDenied;
        },
    },
    watch:      {
        newRowValues: {
            deep:    true,
            handler: function (newValue) {
                for (let colId in newValue) {
                    if (this.columnDataTypes[colId] === 'IMAGE' && newValue[colId] instanceof File) {
                        this.toBase64(newValue[colId]).then(data => {
                            this.apiUploadEvidence(data, 'datalist_entry.png', null, true).then(r => {
                                newValue[colId] = r.data.id;
                            }).catch(() => {
                                Swal.fire('', 'Fehler beim Hochladen vom Beweisfoto, versuch es erneut!', 'error');
                                this.isCreating = false;
                            })
                        }).catch(() => {
                            Swal.fire('', 'Fehler beim Hochladen vom Beweisfoto, versuch es erneut!', 'error');
                            this.isCreating = false;
                        });
                    }
                }
                console.log(newValue);
            }
        }
    },
    methods:    {
        imagePopup(imageData) {
            this.imageDialogData = this.apiAttachmentUrl({id: imageData});
            this.imageDialog     = true;
        },
        closeImagePopup() {
            this.imageDialog     = false;
            this.imageDialogData = null;
        },
        isImageValue(str) {
            if (str === undefined || str === null) return false;
            return str;
        },
        clearInputFields() {
            this.editRowId = null;
            for (let k in this.newRowValues) {
                this.newRowValues[k] = null;
            }

            for (let obj of this.list.columns) {
                if (obj.dataType === 'DATE' || obj.dataType === 'DATETIME') {
                    this.$refs['col_' + obj.id][0].clearHandler();
                }
            }
        },
        customSearch(value, search, item) {
            if (value === true && search === 'true') return true;
            if (value === false && search === 'false') return true;

            return Object.values(item).some(v => v && v.toString().toLowerCase().includes(search.toLowerCase()));
        },
        // eslint-disable-next-line no-unused-vars
        modifyRowColumn(listId, rowId, columnId, newValue) {
            let rowObj = {
                values: []
            };

            rowObj.values.push({
                columnId: columnId,
                value:    newValue
            })

            for (let obj of this.dataTableItems) {
                if (obj.id === rowId) {
                    obj[columnId] = newValue;
                }
            }

            this.apiUpdateDataListRow(listId, rowId, rowObj);
        },
        editRow(row) {
            this.clearInputFields();

            this.editRowId = row.id;

            for (let col of this.list.columns) {
                if ((col.dataType === 'DATETIME' || col.dataType === 'DATE') && row[col.id]) {
                    this.newRowValues[col.id] = moment(row[col.id]).toDate();
                } else if (col.dataType === 'IMAGE') {
                    // do nothing with image
                } else {
                    this.newRowValues[col.id] = row[col.id];
                }
            }
        },
        deleteRow(row) {
            Swal.fire({
                html:              'Möchtest du den Datensatz wirklich löschen?',
                title:             'Bist du sicher?',
                icon:              'warning',
                showCancelButton:  true,
                cancelButtonText:  'Abbrechen',
                confirmButtonText: 'Löschen'
            }).then(r => {
                if (r.isConfirmed) {
                    // reset modify form if row was deleted while editing
                    if (this.editRowId === row.id) {
                        this.clearInputFields();
                    }

                    this.apiDeleteDataListRow(this.list.id, row.id).then(r => {
                        this.toDataTable(r.data);
                    })
                }
            })
        },
        postNewRow() {
            let rowObj = {
                values: []
            };

            for (let colId in this.newRowValues) {
                rowObj.values.push({
                    columnId: colId,
                    value:    this.newRowValues[colId]
                })
            }

            if (this.editRowId) {
                this.apiUpdateDataListRow(this.list.id, this.editRowId, rowObj).then(r => {
                    this.clearInputFields();
                    this.toDataTable(r.data);
                })
            } else {
                this.apiCreateDataListRow(this.list.id, rowObj).then(r => {
                    this.clearInputFields();
                    this.toDataTable(r.data);
                })
            }
        },
        getUserDisplayName(recordId) {
            for (let obj of this.users) {
                if (obj.id === recordId) return obj.displayName;
            }
            return '-'
        },
        getMedicalRecordDisplayName(recordId) {
            for (let obj of this.medicalRecords) {
                if (obj.id === recordId) return obj.name;
            }
            return '-'
        },
        getCriminalRecordDisplayName(recordId) {
            for (let obj of this.criminalRecords) {
                if (obj.id === recordId) return obj.name;
            }
            return '-'
        },
        fetchUsers() {
            return this.apiGetEmployeesPublic(false).then(r => {
                this.users = r.data;
            })
        },
        fetchCriminalRecords() {
            return this.apiGetCriminalRecords(false).then(r => {
                this.criminalRecords = r.data;
            })
        },
        fetchMedicalRecords() {
            return this.apiGetMedicalRecords(false).then(r => {
                this.medicalRecords = r.data;
            })
        },

        toDataTable(list) {

            this.columnDataTypes = {};

            let dataTypes = [];
            let headers   = [];
            let rows      = [];

            let dataTypePerColumnId = {};

            for (let obj of list.columns) {
                headers.push({
                    value:    obj.id,
                    text:     obj.label,
                    dataType: obj.dataType,
                })

                this.columnDataTypes[obj.id] = obj.dataType;

                dataTypePerColumnId[obj.id] = obj.dataType;

                if (!dataTypes.includes(obj.dataType)) {
                    dataTypes.push(obj.dataType);
                }
            }

            headers.push({
                value:    'createdAt',
                text:     'Erfasst',
                dataType: 'DATETIME',
            })
            headers.push({
                value: 'actions',
                text:  'Aktionen',
            })

            let promises = [];

            if (dataTypes.includes('USER') && this.users.length === 0) {
                promises.push(this.fetchUsers());
            }
            if (dataTypes.includes('CRIMINAL_RECORD') && this.criminalRecords.length === 0) {
                promises.push(this.fetchCriminalRecords());
            }
            if (dataTypes.includes('MEDICAL_RECORD') && this.medicalRecords.length === 0) {
                promises.push(this.fetchMedicalRecords());
            }

            Promise.all(promises).finally(() => {

                for (let obj of (list.rows || [])) {
                    let row = {};

                    for (let data of obj.values) {
                        row[data.columnId] = data.value;
                    }

                    // fix to allow modified boolean in values if they are not set initially
                    for (let cId in dataTypePerColumnId) {
                        if (dataTypePerColumnId[cId] === 'BOOLEAN' && row[cId] === undefined) {
                            row[cId] = false;
                        }
                        if (dataTypePerColumnId[cId] === 'USER' && row[cId]) {
                            row[cId + '_search'] = this.getUserDisplayName(row[cId]);
                        }
                        if (dataTypePerColumnId[cId] === 'CRIMINAL_RECORD' && row[cId]) {
                            row[cId + '_search'] = this.getCriminalRecordDisplayName(row[cId]);
                        }
                        if (dataTypePerColumnId[cId] === 'MEDICAL_RECORD' && row[cId]) {
                            row[cId + '_search'] = this.getMedicalRecordDisplayName(row[cId]);
                        }
                    }

                    row['createdAt'] = obj.createdAt;
                    row['id']        = obj.id;

                    console.log(row);

                    rows.push(row);
                }

                this.list            = list;
                this.dataTableItems  = rows;
                this.dataTableHeader = headers;
                this.dataTypes       = dataTypes;
            })
        }
    },
    mounted() {
        let id = this.$route.params.id;

        this.apiGetDataList(id).then(r => {
            this.toDataTable(r.data);
        })
    }
}
</script>
<!--suppress JSUnresolvedVariable -->
<template>
    <v-container fluid>

        <div v-if="record">
            <v-card shaped elevation="12" :color="record.immunity ? 'primary darken-2' : 'primary'" dark
                    class="criminal-file-container">

                <div class="d-flex">
                    <div class="pa-3 hidden-sm-and-down">
                        <div class="criminal-file-image-background pa-3 mt-3 elevation-15">
                            <v-img :src="require('../../assets/paperclip.png')" class="criminal-file-image-paperclip"/>
                            <v-img
                                :src="record.mugshot === null ? require('../../assets/mugshot_' + record.gender + '.png') : apiAttachmentUrl(record.mugshot)"
                                height="320" width="284" class="rounded"/>
                        </div>
                    </div>
                    <div class="pa-3 hidden-md-and-up">
                        <div class="criminal-file-image-background pa-3 mt-3 elevation-15">
                            <v-img :src="require('../../assets/paperclip.png')" class="criminal-file-image-paperclip"/>
                            <v-img
                                :src="record.mugshot === null ? require('../../assets/prisoner.png') : apiAttachmentUrl(record.mugshot)"
                                height="256" width="196" class="rounded"/>
                        </div>
                    </div>

                    <div class="flex-fill pa-3">
                        <h2 class="criminal-file-header">P O L I Z E I - S T R A F A K T E</h2>

                        <div class="d-flex pb-4">
                            <div v-if="record.immunity">
                                <v-btn color="info" elevation="5" rounded small class="mr-3">
                                    <v-icon left>mdi-face-man-shimmer-outline</v-icon>
                                    <b>Die Person hat Immunität</b>
                                </v-btn>
                            </div>
                            <div v-if="record.blacklistedLawyer">
                                <v-btn color="black" elevation="5" rounded small class="mr-3">
                                    <v-icon left>mdi-briefcase-variant-off-outline</v-icon>
                                    <b>Die Person darf nicht als Anwalt agieren</b>
                                </v-btn>
                            </div>
                            <div v-if="isOfficialOrg(record.organization)">
                                <v-btn color="success" rounded small elevation="5" class="mr-3">
                                    <v-icon left>mdi-gavel</v-icon>
                                    <b>Staatsbedienstete:r.</b>
                                </v-btn>
                            </div>
                            <div v-if="record.wanted">
                                <v-btn color="error" small elevation="5" rounded border="left" class="mr-3">
                                    <v-icon left>mdi-handcuffs</v-icon>
                                    <b>Achtung! Die Person wird aktuell gesucht!</b>
                                </v-btn>
                            </div>
                            <div v-if="record.penaltyPoints >= 9">
                                <v-btn color="warning" elevation="5" small rounded>
                                    <v-icon left>mdi-alert-octagon-outline</v-icon>
                                    <b>Entzug der Fahrerlaubnis empfohlen.</b>
                                </v-btn>
                            </div>
                        </div>

                        <h4 class="pl-1 ml-1">Stammdaten</h4>
                        <div class="d-flex flex-wrap">
                            <div class="ma-1 pa-1">
                                <v-text-field color="info" hint="Vor- und Nachname" persistent-hint v-model="record.name"
                                              prepend-inner-icon="mdi-account" outlined dense/>
                            </div>
                            <div class="ma-1 pa-1" style="max-width: 200px">
                                <v-select v-model="record.gender" outlined dense color="info"
                                          :items="[{value: 'MALE', text: 'Männlich'}, {value: 'FEMALE', text: 'Weiblich'}, {value: 'DIVERS', text: 'Divers'}, {value: 'GOLDFISH', text: 'Goldfisch'}]"
                                          item-text="text" prepend-inner-icon="mdi-gender-male-female"
                                          hint="Geschlecht" persistent-hint
                                          item-value="value"/>
                            </div>
                            <div class="ma-1 pa-1">
                                <custom-date-picker v-model="record.birthday"
                                                    :datetime="record.birthday"
                                                    :text-field-props="{'color': 'info', 'outlined': true, 'dense': true, 'prependInnerIcon': 'mdi-calendar', 'hint': 'Geburtsdatum', 'persistentHint': true}"
                                                    :time-picker-props="{'format': '24hr'}"/>

                            </div>
                            <div class="ma-1 pa-1">
                                <v-text-field color="info" hint="Augenfarbe" persistent-hint v-model="record.eyeColor"
                                              prepend-inner-icon="mdi-eye-settings-outline" outlined dense/>
                            </div>
                            <div class="ma-1 pa-1">
                                <v-text-field color="info" hint="Haarfarbe" persistent-hint v-model="record.hairColor"
                                              prepend-inner-icon="mdi-palette" outlined dense/>
                            </div>
                            <div class="ma-1 pa-1">
                                <v-text-field color="info" hint="Hautfarbe" persistent-hint v-model="record.skinColor"
                                              prepend-inner-icon="mdi-palette" outlined dense/>
                            </div>
                        </div>

                        <h4 class="pl-1 ml-1">Kontaktinformationen</h4>
                        <div class="d-flex flex-wrap">
                            <div class="ma-1 pa-1">
                                <v-text-field color="info" hint="Wohnanschrift" persistent-hint v-model="record.address"
                                              prepend-inner-icon="mdi-map-outline" outlined dense/>
                            </div>
                            <div class="ma-1 pa-1" style="max-width: 200px">
                                <v-text-field color="info" hint="Telefonnummer" persistent-hint v-model="record.phoneNumber"
                                              prepend-inner-icon="mdi-card-account-phone-outline" outlined dense/>
                            </div>
                            <div class="ma-1 pa-1">
                                <v-text-field color="info" hint="Arbeitgeber oder Gruppierung" persistent-hint
                                              v-model="record.organization" prepend-inner-icon="mdi-briefcase-variant-outline" outlined
                                              dense/>
                            </div>
                        </div>

                        <h4 class="pl-1 ml-1">Sonstige Informationen</h4>
                        <div class="d-flex flex-wrap">
                            <div class="ma-1 pa-1">
                                <v-text-field type="number" color="info" hint="Strafpunkte nach StVO" persistent-hint
                                              v-model="record.penaltyPoints" prepend-inner-icon="mdi-car-info" outlined dense/>
                            </div>
                            <div class="ma-1 pa-1" style="max-width: 200px">
                                <v-datetime-picker v-model="record.workLicense"
                                                   :datetime="record.workLicense"
                                                   :text-field-props="{'color': 'info', 'outlined': true, 'dense': true, 'prependInnerIcon': 'mdi-account-hard-hat', 'hint': 'Arbeitslizenz', 'persistentHint': true}"
                                                   :time-picker-props="{'format': '24hr'}">
                                    <template v-slot:dateIcon>
                                        <v-icon>mdi-calendar</v-icon>
                                    </template>
                                    <template v-slot:timeIcon>
                                        <v-icon>mdi-clock</v-icon>
                                    </template>
                                </v-datetime-picker>

                            </div>
                            <div class="ma-1 pa-1">
                                <v-select multiple outlined dense v-model="record.licenses" :items="licenseTypes"
                                          append-icon="mdi-card-account-details-outline" hint="Führerscheine" persistent-hint
                                          item-text="name" item-value="type"/>
                            </div>
                            <div class="ma-1 pa-1">
                                <v-checkbox outlined dense v-model="record.immunity"
                                            :readonly="!(user.admin || user.orgAdmin || user.trustLevel >= 1000)"
                                            prepend-icon="mdi-shield-crown-outline" hint="Immunität" persistent-hint/>
                            </div>
                            <div class="ma-1 pa-1">
                                <v-checkbox outlined dense v-model="record.blacklistedLawyer"
                                            :readonly="!(user.admin || user.orgAdmin || user.trustLevel >= 1000)"
                                            prepend-icon="mdi-briefcase-variant-off-outline" color="red" hint="Anwaltssperre"
                                            persistent-hint/>
                            </div>
                        </div>
                    </div>
                </div>


                <v-card-actions>
                    <v-btn fab small color="primary" @click="saveRecord()">
                        <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-btn small color="success" @click="createExport()">
                        <v-icon left>mdi-clipboard-outline</v-icon>
                        Führungszeugnis
                    </v-btn>
                </v-card-actions>
            </v-card>

            <v-card elevation="4" class="mt-5" outlined>
                <v-card-title class="pb-2 pt-2">
                    <v-icon left color="info">mdi-card-account-details-outline</v-icon>
                    {{ record.name }} - Einträge
                    <v-spacer/>

                    <div v-if="availableEntryTypes.length > 0">
                        <v-btn small color="primary" @click="openCreateEntryDialog">
                            <v-icon left small>mdi-plus</v-icon>
                            Eintrag
                        </v-btn>
                        <v-btn small color="primary darken-1" @click="openCombineDialog"
                               v-if="combineRecordIds.length > 1">
                            <v-icon left small>mdi-call-merge</v-icon>
                            Kombinieren
                        </v-btn>
                    </div>
                </v-card-title>

                <v-divider/>
                <v-tabs v-if="hasAnyInvestigation && userHasFlag('CRIMINAL_POLICE')" v-model="recordTypes" class="pb-2">
                    <v-tab>Strafakte</v-tab>
                    <v-tab>Ermittlungen</v-tab>
                </v-tabs>
                <v-card-text class="pb-0">
                    <v-row v-for="entry in finalRecordEntries" :key="entry.id">
                        <v-col class="pl-1 pr-1 pb-0 pt-0">
                            <criminal-record-entry :selectable="!entry.enforced && !entry.discarded" v-on:selected="entrySelected"
                                                   v-on:updated="entryCreatedEvent" v-on:edit="openEditDialog" :record="record"
                                                   :entry="entry"/>
                        </v-col>
                    </v-row>
                    <v-row v-if="finalRecordEntries.length === 0">
                        <v-col>
                            <v-alert type="info" dense outlined>
                                Keine Einträge vorhanden.
                            </v-alert>
                        </v-col>
                    </v-row>
                </v-card-text>

                <div v-if="availableEntryTypes.length > 0">
                    <v-divider/>
                    <v-card-actions>
                        <v-btn small color="primary" @click="openCreateEntryDialog">
                            <v-icon left small>mdi-plus</v-icon>
                            Eintrag
                        </v-btn>
                        <v-btn small color="primary darken-1" @click="openCombineDialog"
                               v-if="combineRecordIds.length > 1">
                            <v-icon left small>mdi-call-merge</v-icon>
                            Kombinieren
                        </v-btn>
                    </v-card-actions>
                </div>
            </v-card>
        </div>

        <div v-else>
            <v-layout class="d-flex justify-center align-center">
                <v-progress-circular indeterminate size="128" color="primary"/>
            </v-layout>
        </div>

        <!-- Record Export Dialog -->
        <v-dialog v-model="exportTextDialog" width="800" persistent>
            <v-card outlined>
                <v-card-title class="pa-3 pt-2 pb-2">
                    <v-icon left>mdi-clipboard-text-outline</v-icon>
                    Führungszeugnis
                </v-card-title>
                <v-divider/>
                <v-card-text class="monospace pa-3">
                    <pre v-html="exportText"></pre>
                </v-card-text>
                <v-divider/>
                <v-card-actions class="pl-2">
                    <v-btn outlined small @click="exportTextDialog = null">
                        Schließen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Create Record Entry Dialog -->
        <v-dialog v-model="createRecordEntryDialog" width="800" persistent>
            <v-card outlined color="primary" dark>
                <v-card-title class="pt-2 pb-2 pl-3" v-if="createEntry.id">
                    <v-icon left>mdi-content-save-outline</v-icon>
                    Eintrag ändern ({{ createEntry.id }})
                </v-card-title>
                <v-card-title class="pt-2 pb-2 pl-3" v-else>
                    <v-icon left>mdi-plus</v-icon>
                    Eintrag anlegen
                </v-card-title>

                <div v-if="record && record.immunity">
                    <v-divider/>
                    <v-card-text class="pb-0 mb-0">
                        <v-alert type="info" elevation="12">
                            Für diese Person ist ein Immunitätsrecht ausgesprochen worden!
                        </v-alert>
                    </v-card-text>
                </div>
                <v-divider/>
                <v-card-text class="pa-3">
                    <!-- Entry Type -->
                    <v-select solo background-color="primary" outlined dense :items="availableEntryTypes" item-value="type"
                              item-text="text" v-model="createEntry.type" item-color="primary lighten-3"
                              color="primary lighten-3" label="Art" prepend-icon="mdi-clipboard-text"/>

                    <!-- Content -->
                    <v-textarea solo background-color="primary" outlined dense v-model="createEntry.content" label="Inhalt"
                                color="primary lighten-3" :readonly="combineRecords" append-outer-icon="mdi-calculator"
                                @click:append-outer="openHelpDialog()" prepend-icon="mdi-form-textbox" v-on:paste="doPasteEvent"
                                hint="Dieses Feld unterstützt Markdown" persistent-hint>
                    </v-textarea>

                    <!-- Comment -->
                    <v-textarea solo background-color="primary" outlined dense v-model="createEntry.comment" label="Kommentar"
                                color="primary lighten-3" v-if="combineRecords" v-on:paste="doPasteEvent"
                                prepend-icon="mdi-form-textbox" hint="Dieses Feld unterstützt Markdown" persistent-hint>
                    </v-textarea>

                    <!-- Context Info (Aussage eines Beamten) -->
                    <v-textarea solo background-color="primary" outlined dense v-model="createEntry.context"
                                label="Aussage eines Beamten"
                                color="primary lighten-3" v-on:paste="doPasteEvent"
                                prepend-icon="mdi-form-textbox" hint="Dieses Feld unterstützt Markdown" persistent-hint>
                    </v-textarea>

                    <!-- PLZ -->
                    <v-text-field type="number" clearable background-color="primary" outlined dense v-model="createEntry.zipCode"
                                  label="Ort des Verbrechen (PLZ)"
                                  color="primary lighten-3" prepend-icon="mdi-home-city"/>

                    <!-- Fine -->
                    <v-text-field solo background-color="primary" outlined dense type="number" label="Bußgeld"
                                  v-model="createEntry.fine" persistent-hint hint="Bußgeld in €"
                                  prepend-icon="mdi-cash-100" color="primary lighten-3"
                                  v-if="(['TICKET','CRIMINAL_RECORD', 'JAIL', 'GOVERNMENT_REGULATION_VIOLATION'].includes(createEntry.type))"/>

                    <!-- Jail-Time -->
                    <v-text-field solo background-color="primary" outlined dense type="number" label="Haftzeiteinheiten"
                                  v-model="createEntry.jailTime"
                                  prepend-icon="mdi-handcuffs" color="primary lighten-3"
                                  v-if="(['JAIL', 'GOVERNMENT_REGULATION_VIOLATION'].includes(createEntry.type))"/>

                    <!-- Penalty Points -->
                    <v-text-field solo background-color="primary" outlined dense type="number" label="Bußgeld"
                                  v-model="createEntry.penaltyPoints" persistent-hint hint="Punkte im Verkehrsregister"
                                  prepend-icon="mdi-alert-circle-outline" color="primary lighten-3"
                                  v-if="(['TICKET','CRIMINAL_RECORD', 'JAIL'].includes(createEntry.type)) && !combineRecords"/>

                    <v-text-field v-on:paste="doPasteEvent" v-model="createEntryEvidence" solo dense outlined
                                  background-color="primary" @click:clear="clearEvidence" clearable clear-icon="mdi-delete"
                                  prepend-icon="mdi-archive"
                                  color="white" hint="Beweisfoto" persistent-hint
                                  :readonly="createEntryEvidenceLock"
                                  placeholder="Beweismaterial aus dem Zwischenspeicher hier einfügen oder Bild-URL eingeben."/>

                    <v-checkbox background-color="primary" dense v-model="createEntry.wanted" prepend-icon="mdi-star"
                                v-if="!combineRecords && (createEntry.type !== 'INVESTIGATION')"
                                label="Fahndung ausschreiben" color="primary lighten-3">
                    </v-checkbox>
                </v-card-text>
                <div v-if="createEntryEvidences.length > 0">
                    <v-divider/>
                    <v-card-title class="pt-2 pb-2 pl-3">
                        <v-icon left>mdi-camera-wireless-outline</v-icon>
                        Beweise
                    </v-card-title>
                    <v-divider/>
                    <v-card-text>
                        <v-row>
                            <v-col cols="4" v-for="(ev, k) in createEntryEvidences" :key="k">
                                <div v-if="ev.type === 'BASE64_IMAGE'">
                                    <v-img @click="deleteEvidence(ev, k)" :height="256 / 16 * 9" :width="256" :src="'data:image/png;base64,' + ev.image"></v-img>
                                </div>
                                <div v-else-if="ev.type === 'URL'">
                                    <v-img @click="deleteEvidence(ev, k)" :height="256 / 16 * 9" :width="256" :src="ev.image"></v-img>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>

                </div>
                <v-divider/>
                <v-card-actions>
                    <v-btn small color="success" @click="postEntry" :loading="createEntryLock"
                           :disabled="(record && record.immunity && !user.orgAdmin && !user.admin)">
                        <v-icon left>mdi-content-save</v-icon>
                        Anlegen
                    </v-btn>
                    <v-spacer/>
                    <v-btn small color="error"
                           @click="abortPostEntry">
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="helpDialog" width="900" persistent>
            <v-card outlined>
                <v-card-title class="pt-2 pb-2 pl-3">
                    <v-icon left>mdi-calculator</v-icon>
                    Bußgeld-Rechner
                </v-card-title>
                <v-divider/>
                <v-card-text class="pt-5 pb-0">
                    <v-text-field dense solo outlined v-model="helpDialogSearch" prepend-inner-icon="mdi-help"/>
                </v-card-text>
                <v-divider/>
                <v-data-table :items-per-page="5" show-select item-key="id" :search="helpDialogSearch" :items="fineRecords"
                              :headers="fineRecordTable" v-model="helpDialogSelectedData">
                </v-data-table>
                <v-divider/>
                <v-card-title class="pt-2 pb-2 pl-3">
                    <v-icon left>mdi-calculator</v-icon>
                    Items
                </v-card-title>
                <v-divider/>
                <v-card-text class="pt-5">
                    <v-select :items="helpDialogIllegalItems" v-model="helpDialogIllegalItemsSelected" item-value="item" solo
                              dense outlined hint="Besitz illegaler Gegenstände" persistent-hint
                              return-object multiple prepend-inner-icon="mdi-bag-personal">
                        <template v-slot:selection="{ item }">
                            <div class="pr-4">
                                <img height="16px" :src='"https://fivem.r3ktm8.de/items/" + item.item + ".png"'/>
                                <span class="pl-3">{{ item.label }}</span>
                            </div>
                        </template>
                        <template v-slot:item="{ item }">
                            <img height="24px" :src='"https://fivem.r3ktm8.de/items/" + item.item + ".png"'/>
                            <span class="pl-3">{{ item.label }}</span>
                        </template>
                    </v-select>
                    <v-text-field type="number" v-model="helpDialogBlackMoney" prepend-inner-icon="mdi-currency-eur"
                                  placeholder="Schwarzgeld" hint="Besitz von Schwarzgeld (Menge in €)" persistent-hint solo dense
                                  outlined/>
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-btn small color="success" @click="calculateHelpDialog()">
                        Übernehmen
                    </v-btn>
                    <v-spacer/>
                    <v-btn small color="warning" @click="closeHelpDialog()">
                        Abbrechen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<style>

.criminal-file-header {
    font-family: "Rubik", "Nunito", sans-serif;
    font-size: 32px;
    font-weight: bolder;
}

.debug {
    background-color: #ff0000 !important;
}

.criminal-file-image-background {
    background-color: #838486;
    border-radius: 15px;
    transform: rotate(3deg);
}

.criminal-file-image-paperclip {
    width: 42px;
    height: 42px;
    position: absolute;
    top: -10px;
    right: 2px;
    z-index: 999;
    transform: rotate(55deg);
}

.criminal-file-col {
    width: 300px;
}

.criminal-file-container {
    border-bottom-left-radius: 370px !important;
}

.criminal-file-profile-info {
    font-size: 19px;
    font-family: "Rubik", "Nunito", sans-serif;
}

.criminal-file-profile-info span:first-child {
    font-size: 19px;
    font-family: "Rubik", "Nunito", sans-serif;
    width: 190px;
    font-weight: bolder !important;
}

.monospace {
    font-family: 'Nova Mono', Monocraft, monospace;
    font-weight: 800;
    font-size: 15px;
}

.monospace pre {
    white-space: pre-wrap; /* Since CSS 2.1 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; /* Internet Explorer 5.5+ */
}
</style>

<script>
import moment                        from "moment";
import markdown                      from "markdown-it";
import {CRIMINAL_RECORD_ENTRY_TYPES} from "@/plugins/config";
import {OfficialOrganizations}       from "@/config";
import CriminalRecordEntry           from "@/components/CriminalRecordEntry.vue";
import CustomDatePicker              from "@/components/CustomDatePicker.vue";

export default {
    components: {CustomDatePicker, CriminalRecordEntry},
    computed:   {
        recordBirthday() {
            return this.record.birthday ? moment(this.record.birthday).format('DD.MM.YYYY') : '';
        },
        availableEntryTypes() {
            let types = [];

            this.createEntryTypes.forEach(entryType => {
                if (
                    (this.user?.admin || entryType.requireFlags === undefined || this.userHasAnyFlag(entryType.requireFlags)) &&
                    (entryType.accountTypes === undefined || this.userIsOfAnyAccountType(entryType.accountTypes))
                ) {
                    types.push(entryType);
                }
            })

            return types;
        },
        hasAnyInvestigation() {
            let hasAny = false;
            if (this.isCurrentAccountPolice) {
                this.recordEntries.forEach(entry => {
                    if (entry.type === 'INVESTIGATION') hasAny = true;
                });
            }
            return hasAny;
        },
        finalRecordEntries() {
            let entries = [];

            if (this.recordTypes === 0 || !this.hasAnyInvestigation) {
                this.recordEntries.forEach(entry => {
                    if (entry.type !== 'INVESTIGATION') entries.push(entry);
                });
            } else if (this.recordTypes === 1) {
                this.recordEntries.forEach(entry => {
                    if (entry.type === 'INVESTIGATION') entries.push(entry);
                });
            }

            let result = [];

            if (this.isCurrentAccountPolice) {
                result = entries;
            } else {
                entries.forEach(e => {
                    if (e.trustLevel > 0) {
                        e.content   = '**_Dieser Eintrag kann auf Anfrage bei der Bundespolizei eingesehen werden_**.';
                        e.discarded = false;
                        e.enforced  = false;
                        e.locked    = true;
                    } else if (!this.hasAccessToType(e.type)) {
                        e.locked = true;
                    }

                    if (!e.wanted && !this.hasAccessToType(e.type))
                        result.push(e);
                    else if (this.hasAccessToType(e.type))
                        result.push(e);
                })
            }

            return result;
        }
    },
    data:       () => ({
        record:        null,
        recordEntries: [],
        fineRecords:   [],

        recordTypes: 0,

        tabIndex: 0,

        modifyRecordDialog: false,

        helpDialog:                     false,
        helpDialogSelectedData:         [],
        helpDialogSearch:               '',
        helpDialogBlackMoney:           0,
        helpDialogIllegalItems:         [
            {paragraph: '§2 Abs. 3c StGB', item: 'WEAPON_ASSAULTRIFLE', label: 'Kampfgewehr'},
            {paragraph: '§2 Abs. 3c StGB', item: 'WEAPON_ASSAULTRIFLE_MK2', label: 'Kampfgewehr Mk.2'},
            {paragraph: '§2 Abs. 3c StGB', item: 'WEAPON_MICROSMG', label: 'Mikro-SMG'},
            {paragraph: '§2 Abs. 3c StGB', item: 'WEAPON_ASSAULTSMG', label: 'Kampf-SMG'},
            {paragraph: '§2 Abs. 3c StGB', item: 'WEAPON_BULLPUPRIFLE', label: 'Bullpupgewehr'},
            {paragraph: '§2 Abs. 3c StGB', item: 'WEAPON_COMPACTRIFLE', label: 'Kurzes Kampfgewehr'},
            {paragraph: '§2 Abs. 3b StGB', item: 'WEAPON_PISTOL', label: 'Pistole'},
            {paragraph: '§2 Abs. 3b StGB', item: 'WEAPON_PISTOL50', label: 'Pistole .50'},
            {paragraph: '§2 Abs. 3b StGB', item: 'WEAPON_DOUBLEACTION', label: 'Double-Action Revolver'},
            {paragraph: '§2 Abs. 3b StGB', item: 'WEAPON_REVOLVER', label: 'Revolver'},
            {paragraph: '§1 Abs. 7 StGB', item: 'laptop_h', label: 'Hacker-Laptop'},
            {paragraph: '§1 Abs. 7 StGB', item: 'id_card', label: 'Kodierte Schlüsselkarte'},
            {paragraph: '§1 Abs. 7 StGB', item: 'secure_card', label: 'Sicherheitskarte'},
            {paragraph: '§1 Abs. 7 StGB', item: 'laptop_h', label: 'Hacker-Laptop'},
            {paragraph: '§1 Abs. 7 StGB', item: 'lockpick', label: 'Dietrich'},
            {paragraph: '§1 Abs. 7 StGB', item: 'fakeplate', label: 'Fake Kennzeichen'},
            {paragraph: '§1 Abs. 1c BtMG', item: 'cocaine', label: 'Kokain'},
            {paragraph: '§1 Abs. 1c BtMG', item: 'tilidin', label: 'Tilidin'},
            {paragraph: '§1 Abs. 1c BtMG', item: 'heroin', label: 'Heroin'},
            {paragraph: '§1 Abs. 1c BtMG', item: 'joint', label: 'Joint'},
            {paragraph: '§1 Abs. 1a BtMG', item: 'weed', label: 'Weed'},
            {paragraph: '§1 Abs. 1a BtMG', item: 'croton_oil', label: 'Croton-Öl'},
            {paragraph: '§1 Abs. 1a BtMG', item: 'opium_poppy', label: 'Schlafmohn'},
            {paragraph: '§1 Abs. 1a BtMG', item: 'coca_leafe', label: 'Kokain-Blätter'},
        ],
        helpDialogIllegalItemsSelected: [],

        fineRecordTable: [
            {text: 'Paragraph', value: 'paragraph'},
            {text: 'Text', value: 'description'},
            {text: 'Haftzeit', value: 'jailTime'},
            {text: 'Bußgeld', value: 'fine'},
        ],

        updatingRecord:        false,
        updatingRecordFailed:  false,
        updatingRecordSuccess: false,

        vehicleRecordDialog: false,

        createRecordEntryDialog: false,

        createEntryEvidence:     null,
        createEntryEvidenceLock: false,

        createEntryLock:      false,
        createEntry:          {
            type:            'TICKET',
            content:         '',
            fine:            0,
            jailTime:        10,
            penaltyPoints:   0,
            comment:         '',
            context:         '',
            combineEntryIds: [],
            zipCode:         null,
        },
        createEntryTypes:     CRIMINAL_RECORD_ENTRY_TYPES,
        createEntryEvidences: [],

        licenseTypes: [
            {name: 'Klasse A', type: 'A'},
            {name: 'Klasse B', type: 'B'},
            {name: 'Klasse CE', type: 'CE'},
            {name: 'Klasse D', type: 'D'},
            {name: 'Klasse PPL(A)', type: 'PPL_A'},
            {name: 'Klasse PPL(H)', type: 'PPL_H'},
            {name: 'SBF-See', type: 'SBF_SEE'}
        ],

        exportTextDialog: false,
        exportText:       'Test 123',

        combineRecords:   false,
        combineRecordIds: [],

        menu: false,
    }),
    methods:    {
        isOfficialOrg(org) {
            if (!org) return false;
            for (let officialOrganization of OfficialOrganizations) {
                if (org.indexOf(officialOrganization) >= 0) return true;
            }
            return false;
        },
        doPasteEvent(data) {
            let item = data.clipboardData.items[0];

            console.log(item);

            if (item.kind === 'file') {
                let file = item.getAsFile();

                this.toBase64(file).then(r => {
                    this.createEntryEvidences.push({
                        title: 'Beweisfoto',
                        type:  'BASE64_IMAGE',
                        image: r,
                    });
                });
            } else if (item.kind === 'string') {
                item.getAsString(s => {
                    this.createEntryEvidences.push({
                        title: 'Beweisfoto',
                        type:  'URL',
                        image: s,
                    })
                    this.createEntryEvidence = '';
                })
            }
        },
        deleteEvidence(evidence, index) {
            this.createEntryEvidences.splice(index, 1);
        },
        clearEvidence() {
            this.createEntryEvidence     = '';
            this.createEntryEvidenceLock = false;
        },
        mdToHtml(markdownText) {
            if (markdownText == null || markdownText.trim().length === 0) {
                return '<i>Keine Informationen angegeben.</i>'
            }

            return markdown({breaks: true}).render(markdownText);
        },
        hasAccessToType(typeName) {
            for (let typeInfo of this.availableEntryTypes) {
                if (typeInfo.type === typeName) return true;
            }
            return false;
        },
        formatMoney(money) {
            return new Intl.NumberFormat('de-DE', {
                style:    'currency',
                currency: 'EUR',
            }).format(money);
        },
        createExport() {
            let regex = /§.*/gm
            let text  = 'Ausgestellt von ' + this.user.displayName + '\n';
            text += 'Ausgestellt am ' + moment().format('DD.MM.Y') + '\n\n';
            text += 'Im Führungszeugnis der Person finden sich folgende Straftaten und Ordnungswidrigkeiten innerhalb der letzten 4 Wochen:\n'

            let countOfEntries = 0;

            this.recordEntries.forEach(entry => {
                if (entry.trustLevel === 0 && entry.enforced && moment(entry.enforcedAt).isAfter(moment().subtract(1, 'month'))) {
                    let matcher;
                    let firstMatch = true;

                    while ((matcher = regex.exec(entry.content)) !== null) {
                        if (matcher.index === regex.lastIndex) regex.lastIndex++;

                        if (firstMatch) {
                            text += '\nEinträge vom ' + moment(entry.createdAt).format('DD.MM.Y') + ' (durchgesetzt am ' + moment(entry.enforcedAt).format('DD.MM.Y') + ')\n(' + (entry.jailTime || 0) + ' HE und ' + this.formatMoney(entry.fine || 0) + ' Bußgeld): \n'
                            firstMatch = false;
                        }

                        matcher.forEach(m => {
                            text += '- ' + m + '\n';
                        });

                        countOfEntries++;
                    }
                }
            })

            if (countOfEntries === 0) {
                text += '\nKeine Einträge vorhanden.'
            }

            this.exportTextDialog = true;
            this.exportText       = text;
        },
        openHelpDialog() {
            this.helpDialog = true;
        },
        closeHelpDialog() {
            this.helpDialog                     = false;
            this.helpDialogSelectedData         = [];
            this.helpDialogIllegalItemsSelected = [];
            this.helpDialogBlackMoney           = 0;
        },
        calculateHelpDialog() {
            let text          = '';
            let fine          = 0;
            let jail          = 0;
            let penaltyPoints = 0;

            for (let selectedItem of this.helpDialogIllegalItemsSelected) {
                let entry = this.fineRecords.find(fr => fr.paragraph === selectedItem.paragraph);
                if (entry && this.helpDialogSelectedData.find(sd => sd.paragraph === selectedItem.paragraph) == null)
                    this.helpDialogSelectedData.push(entry);
            }

            if (this.helpDialogBlackMoney > 0) {
                let entry = this.fineRecords.find(fr => fr.paragraph === '§1 Abs. 6 StGB' && fr.description === 'Besitz von Schwarzgeld');
                if (entry && this.helpDialogSelectedData.find(fr => fr.paragraph === '§1 Abs. 6 StGB' && fr.description === 'Besitz von Schwarzgeld') == null) {
                    let copyOfEntry      = JSON.parse(JSON.stringify(entry));
                    copyOfEntry.jailTime = Math.min(Math.floor(this.helpDialogBlackMoney / 10000), 20)
                    copyOfEntry.description += ' (' + copyOfEntry.jailTime + ' HE)';
                    this.helpDialogSelectedData.push(copyOfEntry);
                }
            }

            let sortedData = this.helpDialogSelectedData.sort((a, b) => {
                let jailTimeA = ((a.jailTime || 0));
                let jailTimeB = ((b.jailTime || 0));

                return jailTimeB - jailTimeA;
            });

            let top3   = sortedData.slice(0, 3);
            let others = sortedData.slice(3, sortedData.length);

            console.log(top3);
            console.log(others);

            if (top3.length > 0) text += '**Gewertete Einträge**:\n';
            for (let item of top3) {
                text += '+ ' + item.paragraph + ' ' + item.description + '\n';
                fine += item.fine || 0;
                jail += item.jailTime || 0;
                penaltyPoints += item.penaltyPoints || 0;
            }
            if (others.length > 0) text += '\n**Nicht gewertete Einträge**:\n';
            for (let item of others) {
                text += '- ' + item.paragraph + ' ' + item.description + '\n';
            }

            if (this.helpDialogIllegalItemsSelected.length > 0 || this.helpDialogBlackMoney > 0) text += '\n**Beschlagnahmte Gegenstände**:\n'
            for (let selectedItem of this.helpDialogIllegalItemsSelected) {
                text += '- ' + selectedItem.label + '\n';
            }
            if (this.helpDialogBlackMoney > 0)
                text += '- Schwarzgeld x' + this.helpDialogBlackMoney + '\n';

            this.createEntry.content       = text;
            this.createEntry.jailTime      = jail;
            this.createEntry.fine          = fine;
            this.createEntry.penaltyPoints = penaltyPoints;

            if (jail > 0) this.createEntry.type = 'JAIL';

            this.closeHelpDialog();
        },
        openCreateEntryDialog() {
            this.createEntry.type          = this.availableEntryTypes[0].type;
            this.createEntry.fine          = 0;
            this.createEntry.jailTime      = 0;
            this.createEntry.penaltyPoints = 0;
            this.createEntry.zipCode       = null;
            this.createRecordEntryDialog   = true
        },
        openEditDialog(entry) {
            this.createRecordEntryDialog = true;
            this.createEntry             = JSON.parse(JSON.stringify(entry));
        },
        openCombineDialog() {
            this.createRecordEntryDialog = true;
            this.combineRecords          = true;

            let totalFine     = 0;
            let totalJailTime = 0;

            let originalContents = [];

            this.recordEntries.forEach(recordEntry => {
                if (this.combineRecordIds.indexOf(recordEntry.id) >= 0) {
                    totalFine += recordEntry.fine;
                    totalJailTime += recordEntry.jailTime;
                    originalContents.push(
                        'Eintrag vom **' + moment(recordEntry.createdAt).format('DD.MM.YYYY HH:mm:ss') + '**\n' +
                        'Erstellt von **' + recordEntry.user.displayName + '**\n' +
                        'Bußgeld **' + (recordEntry.fine || 0) + '€**, **' + (recordEntry.jailTime || 0) + ' Hafteinheiten** und **' + (recordEntry.penaltyPoints || 0) + ' Punkte**.\n' + '\n' +
                        recordEntry.content);
                }
            })

            this.createEntry.type = 'CRIMINAL_RECORD';
            if (totalJailTime > 0) {
                this.createEntry.type = 'JAIL';
            }

            this.createEntry.fine     = totalFine;
            this.createEntry.jailTime = totalJailTime;

            this.createEntry.content = 'Zusammenführung von ' + this.combineRecordIds.length + ' Einträgen.\n' +
                'Das Bußgeld beläuft sich auf ' + totalFine + '€ und eine Haftzeit von ' + totalJailTime + ' HE.\n{KOMMENTAR}\n\n' +
                'Die Strafen setzen sich aus folgenden Einträgen zusammen:\n━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━\n';

            this.createEntry.content += originalContents.join('\n\n━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━\n');
        },
        entrySelected(recordId, selected) {
            if (selected) {
                this.combineRecordIds.push(recordId);
            } else {
                this.combineRecordIds.splice(this.combineRecordIds.indexOf(recordId), 1);
            }
        },
        fetchRecord() {
            this.apiGetCriminalRecord(this.$route.params.id).then(r => {
                if (r.data.workLicense !== null) {
                    r.data.workLicense = new Date(r.data.workLicense)
                }

                this.record = r.data;
            }).catch(() => {
                this.$router.push('/criminal-records');
            })
        },
        fetchRecordEntries() {
            this.apiGetCriminalRecordEntries(this.$route.params.id).then(r => {
                this.recordEntries = r.data.reverse();
            })
        },
        fetchFineRecords() {
            this.apiGetAllFineRecords().then(r => {
                this.fineRecords = r.data;
            })
        },
        entryCreatedEvent() {
            this.fetchRecord();
            this.fetchRecordEntries();

            this.combineRecordIds.splice(0, 100);
            this.combineRecords = false;
        },
        saveRecord() {
            this.updatingRecord = true;

            this.apiUpdateCriminalRecord(this.$route.params.id, this.record).then(() => {
                this.updatingRecordSuccess = true;

                this.fetchRecordEntries();
            }).catch(() => {
                this.updatingRecordFailed = true;
            }).finally(() => {
                this.updatingRecord = false;
            });

            setTimeout(() => {
                this.updatingRecordSuccess = false;
                this.updatingRecordFailed  = false;
            }, 2000);
        },
        abortPostEntry() {
            this.clearEvidence();

            this.createEntry.id          = undefined;
            this.createRecordEntryDialog = false;
            this.combineRecords          = false;
        },
        async postEntry() {
            console.log('Creating new Entry ...');

            if (!['TICKET', 'CRIMINAL_RECORD', 'JAIL', 'GOVERNMENT_REGULATION_VIOLATION'].includes(this.createEntry.type)) {
                this.createEntry.fine = null;
            }
            if (!['JAIL', 'GOVERNMENT_REGULATION_VIOLATION'].includes(this.createEntry.type)) {
                this.createEntry.jailTime = null;
            }

            this.createEntryLock = true;

            if (this.createEntryEvidences.length > 0) {
                let evidenceUrlList = [];

                for (let ev of this.createEntryEvidences) {
                    if (ev.type === 'BASE64_IMAGE') {
                        console.log('Posting Evidence ...');
                        let response = await this.apiUploadEvidence(ev.image, 'evidence.png', null, true);
                        evidenceUrlList.push(`- [${ev.title}](${this.apiAttachmentUrl({id: response.data.id})})`);
                    } else if (ev.tpye === 'URL') {
                        evidenceUrlList.push(`- [${ev.title}](${ev.image})`);
                    }
                }

                console.log('Adjusting Text for Evidences ...');

                if (this.createEntry.content.includes('Beweise**:\n')) {
                    this.createEntry.content =
                        this.createEntry.content.replace('Beweise**:\n', `Beweise**\n${evidenceUrlList.join('\n')}\n`);
                } else {
                    this.createEntry.content += `\n\n**Beweise**:\n${evidenceUrlList.join('\n')}\n`;
                }

                this.createEntryEvidence     = null;
                this.createEntryEvidences    = [];
                this.createEntryEvidenceLock = false;
            }

            if (this.combineRecords) {
                this.createEntry.combineEntryIds = this.combineRecordIds;
                this.createEntry.content         = this.createEntry.content.replace("{KOMMENTAR}", this.createEntry.comment);
            }

            if (this.createEntry.context) {
                this.createEntry.content = this.createEntry.content += '\n**Aussage eines Beamten**:\n' + this.createEntry.context + '\n';
            }

            if (this.createEntry.id) {
                console.log('Updating record entry ...');
                this.apiUpdateCriminalRecordEntry(this.record.id, this.createEntry.id, this.createEntry).then(() => {
                    this.fetchRecordEntries();

                    this.createEntryLock           = false;
                    this.createRecordEntryDialog   = false;
                    this.createEntry.id            = undefined;
                    this.createEntry.content       = '';
                    this.createEntry.type          = 'TICKET';
                    this.createEntry.penaltyPoints = 0;
                    this.createEntry.fine          = 0;
                    this.createEntry.jailTime      = 0;
                    this.createEntry.wanted        = false;
                })
            } else {
                console.log('Posting record entry ...');
                this.apiCreateCriminalRecordEntry(this.$route.params.id, this.createEntry).then(() => {
                    this.fetchRecordEntries();

                    this.record.penaltyPoints = this.record.penaltyPoints + this.createEntry.penaltyPoints;

                    this.createEntryLock           = false;
                    this.createRecordEntryDialog   = false;
                    this.createEntry.content       = '';
                    this.createEntry.type          = 'TICKET';
                    this.createEntry.penaltyPoints = 0;
                    this.createEntry.fine          = 0;
                    this.createEntry.jailTime      = 0;
                    this.createEntry.wanted        = false;

                    this.combineRecords = false;
                    this.combineRecordIds.splice(0, 100);
                })
            }
        }
    },
    mounted() {
        this.fetchRecord();
        this.fetchRecordEntries();
        this.fetchFineRecords();
    },
    filters: {
        age(val) {
            return moment().diff(val, 'years')
        }
    },
    beforeRouteLeave(to, from, next) {
        this.clearEvidence();
        next();
    }
}
</script>
<template>
    <v-alert :outlined="!entry.wanted" elevation="6" dense border="left" class="pa-0" :color="entryEntryColor(entry)">
        <v-card-text
            :style="$vuetify.theme.dark || entry.wanted ? 'color: white !important;' : 'color: black !important;'">
            <v-row>
                <v-col class="pt-1 pb-0" cols="3">
                    <b>Ersteller:</b> <i v-if="entry.user && entry.user.locked">{{ entry.user.displayName }}</i><span
                    v-else-if="entry.user">{{ entry.user.displayName }}</span><span v-else>System</span><br/>
                    <b>Datum:</b> {{ entry.createdAt.substr(0, 10) + ' ' + entry.createdAt.substr(11, 8) }}

                    <div v-if="entry.fine">
                        <b>Bußgeld: </b> {{ formatMoney(entry.fine) }}<br>
                    </div>
                    <div v-if="entry.bailTime">
                        <b>Kaution: </b> {{ entry.bailTime }} Einheiten<br>
                    </div>
                    <div v-if="entry.jailTime">
                        <b>Haftzeit: </b> {{ entry.jailTime }} Einheiten<br>
                    </div>
                    <div v-if="entry.penaltyPoints">
                        <b>Punkte: </b> {{ entry.penaltyPoints }}<br>
                    </div>
                    <div v-if="entry.zipCode">
                        <b>Postleitzahl: </b> {{ entry.zipCode }}<br>
                    </div>

                    <div v-if="entry.discarded">
                        <br/>
                        <b>Verworfen von: </b> {{ entry.discardedBy?.displayName || 'System' }}<br>
                        <b>Verworfen am: </b> {{ entry.discardedAt.substring(0, 10) }}<br>
                    </div>
                    <div v-if="entry.enforced">
                        <br/>
                        <b>Durchgesetzt von: </b> {{ entry.enforcedBy.displayName }}<br>
                        <b>Durchgesetzt am: </b>
                        {{ entry.enforcedAt.substring(0, 10) + ' ' + entry.enforcedAt.substr(11, 8) }}<br>
                    </div>
                    <div v-if="entry.trustLevel > 0">
                        <b>Sicherheitsstufe: </b>
                        <v-icon small color="error lighten-1">mdi-file-lock-outline</v-icon>
                        {{ entry.trustLevel }}
                    </div>
                </v-col>
                <v-col class="pl-2 pt-1 pb-0">
                    <div v-html="mdToHtml(entry.content)"></div>
                    <div v-if="entry.discarded">
                        <b>Eintrag wurde verworfen. Begründung:</b>
                        <div v-html="mdToHtml(entry.discardedReason)"></div>
                    </div>
                    <div v-if="entry.enforced && entry.type === 'BAIL'">
                        <i>Die Strafe wurde nach §1 Abs. 8 StPO auf Bewährung ausgestellt.</i>
                    </div>
                </v-col>

                <div
                    v-if="selectable && !combineRestriction.includes(entry.type) && user.trustLevel >= entry.trustLevel"
                    style="position: absolute; right: -2px; top: -2px;">
                    <v-checkbox v-model="selected" :color="entry.wanted ? 'white' : 'primary'" dense></v-checkbox>
                </div>

                <v-col cols="3"
                       :class="(selectable && !combineRestriction.includes(entry.type) && user.trustLevel >= entry.trustLevel) ? 'text-right pt-1 pb-0 pr-7' : 'text-right pt-1 pb-0 pr-1'">
                    <div v-if="entry.type === 'TICKET'">Ordnungswidrigkeit</div>
                    <div v-if="entry.type === 'JAIL'">Gefängnisbesuch</div>
                    <div v-if="entry.type === 'RECORD_CHANGE'">Aktenänderung</div>
                    <div v-if="entry.type === 'CRIMINAL_RECORD'">Straftat</div>
                    <div v-if="entry.type === 'NOTE'">Anmerkung / Notiz</div>
                    <div v-if="entry.type === 'BAIL'">Bewährungsstrafe</div>
                    <div v-if="entry.type === 'INVESTIGATION'">Ermittlung</div>
                    <div v-if="entry.type === 'HOSPITAL_NOTICE'">Krankenhausmeldung</div>
                    <div v-if="entry.type === 'GOVERNMENT_REGULATION_VIOLATION'">Verstoß gesetzlicher Regulierungen</div>

                    <div v-if="entry.discarded"><b><i>Verworfen</i></b></div>
                    <div v-if="entry.enforced"><b><i>Vollstreckt</i></b></div>

                    <div style="position: absolute; bottom: 4px; right: 7px;"
                         v-if="user.trustLevel >= entry.trustLevel && !entry.locked">

                        <v-btn x-small icon color="warning" @click="openEditor()"
                               v-if="!entry.discarded && !entry.enforced && (user.admin || user.orgAdmin || user.id === entry.user?.id) && entry.trustLevel <= user.trustLevel && !lockRestriction.includes(entry.type)">
                            <v-icon small>mdi-pencil-outline</v-icon>
                        </v-btn>

                        <v-btn x-small icon color="green lighten-2"
                               v-if="(user.orgAdmin || user.admin || user.trustLevel >= 1000) && entry.trustLevel > 0 && !lockRestriction.includes(entry.type)"
                               @click="setTrustLevel(0)">
                            <v-icon small>mdi-file-lock-open-outline</v-icon>
                        </v-btn>
                        <v-btn x-small icon color="blue-grey lighten-4"
                               v-if="(user.orgAdmin || user.admin || user.trustLevel >= 1000) && entry.trustLevel === 0 && !lockRestriction.includes(entry.type)"
                               @click="setTrustLevel(1000)">
                            <v-icon small>mdi-file-lock-outline</v-icon>
                        </v-btn>

                        <!--
                        <v-btn x-small icon color="yellow" @click="fineToJailTime()"
                               v-if="!entry.discarded && !entry.enforced && entry.fine > 0">
                          <v-icon small>mdi-timelapse</v-icon>
                        </v-btn>
                        -->

                        <v-btn x-small icon color="yellow" @click="jailTimeToBailMoney()"
                               v-if="!entry.discarded && !entry.enforced && entry.jailTime > POLICE_BAIL_TIME_MAXIMUM()">
                            <v-icon small>mdi-account-cash-outline</v-icon>
                        </v-btn>
                        <v-btn x-small icon color="orange" @click="bailMoneyToJailTime()"
                               v-if="!entry.discarded && !entry.enforced && entry.bailTime > 0">
                            <v-icon small>mdi-account-cash-outline</v-icon>
                        </v-btn>

                        <v-btn x-small icon color="yellow" @click="wanted()"
                               v-if="!entry.wanted && !entry.discarded && !entry.enforced && !modifyRestriction.includes(entry.type)">
                            <v-icon small>mdi-star</v-icon>
                        </v-btn>

                        <v-btn x-small icon color="success" @click="enforce()"
                               v-if="!entry.discarded && !entry.enforced && !modifyRestriction.includes(entry.type)">
                            <v-icon small>mdi-check</v-icon>
                        </v-btn>

                        <v-btn x-small icon color="error" @click="discard()"
                               v-if="!entry.discarded && !entry.enforced && !modifyRestriction.includes(entry.type)">
                            <v-icon small>mdi-delete-outline</v-icon>
                        </v-btn>

                        <v-btn x-small icon color="error"
                               v-if="user.admin || user.orgAdmin || user.flags.includes('DELETE_RECORD_ENTRY')"
                               @click="deleteEntry()">
                            <v-icon>mdi-delete-forever-outline</v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
    </v-alert>
</template>

<style>
ul {
    padding-bottom: 20px !important;
    margin-top: -20px !important;
}

strong {
    padding-top: 20px !important;
}

ol {
    padding-bottom: 20px !important;
    margin-top: -20px !important;
}

p {
    margin: 0 !important;
    padding-bottom: 20px !important;
}
</style>

<script>
import markdown                   from "markdown-it";
import Swal                       from "sweetalert2";
import {POLICE_BAIL_TIME_MAXIMUM} from "@/plugins/config";

export default {
    props:   ['entry', 'record', 'selectable'],
    data:    () => ({
        selected: false,

        modifyRestriction:  ['RECORD_CHANGE', 'HOSPITAL_NOTICE', 'INVESTIGATION'],
        lockRestriction:    ['RECORD_CHANGE', 'HOSPITAL_NOTICE'],
        combineRestriction: ['RECORD_CHANGE', 'NOTE', 'HOSPITAL_NOTICE'],
    }),
    watch:   {
        selected: function (newVal) {
            this.$emit('selected', this.entry.id, newVal);
        }
    },
    methods: {
        POLICE_BAIL_TIME_MAXIMUM() {
            return POLICE_BAIL_TIME_MAXIMUM
        },
        mdToHtml(markdownText) {
            if (markdownText == null || markdownText.trim().length === 0) {
                return '<i>Keine Informationen angegeben.</i>'
            }

            return markdown({breaks: true}).render(markdownText);
        },
        setTrustLevel(trustLevel) {
            this.entry.trustLevel = trustLevel;
            this.apiUpdateCriminalRecordEntry(this.record.id, this.entry.id, this.entry);
        },
        openEditor() {
            this.$emit('edit', this.entry);
        },
        deleteEntry() {
            Swal.fire({
                title:             'Eintrag endgültig löschen?',
                showCancelButton:  true,
                showConfirmButton: true,
                confirmButtonText: 'Ja',
                cancelButtonText:  'Nein',
            }).then(r => {
                if (r.isConfirmed) {
                    this.apiDeleteCriminalRecordEntry(this.record.id, this.entry.id).then(() => this.$emit('updated'));
                }
            })
        },
        enforce() {
            let allowProbation = this.entry.jailTime <= POLICE_BAIL_TIME_MAXIMUM && this.entry.jailTime > 0;
            Swal.fire({
                title:              'Eintrag durchgesetzt?',
                icon:               'question',
                html:               '<small>Bitte die neuen Gesetzesänderungen §1 Abs. 7ff StPO beachten.</small>',
                showCancelButton:   true,
                showConfirmButton:  true,
                showDenyButton:     allowProbation,
                confirmButtonText:  this.entry.jailTime > 0 ? 'Inhaftiert' : 'Durchgesetzt',
                cancelButtonText:   'Abbrechen',
                denyButtonText:     'Bewährung',
                denyButtonColor:    '#388E3C',
                confirmButtonColor: '#F44336',
                cancelButtonColor:  '#616161',
            }).then(r => {
                if (r.isConfirmed) {
                    this.apiEnforceCriminalRecordEntry(this.record.id, this.entry.id).then(() => this.$emit('updated'));
                } else if (r.isDenied) {
                    this.entry.type = 'BAIL'
                    this.apiUpdateCriminalRecordEntry(this.record.id, this.entry.id, this.entry).then(() => {
                        this.apiEnforceCriminalRecordEntry(this.record.id, this.entry.id).then(() => this.$emit('updated'));
                    })
                }
            })
        },
        wanted() {
            Swal.fire({
                title:             'Eintrag zur Fahndung ausschreiben?',
                showCancelButton:  true,
                showConfirmButton: true,
                confirmButtonText: 'Ja',
                cancelButtonText:  'Nein',
            }).then(r => {
                if (r.isConfirmed) {
                    this.apiMarkWantedCriminalRecordEntry(this.record.id, this.entry.id).then(() => this.$emit('updated'));
                }
            })
        },
        fineToJailTime() {
            Swal.fire({
                title:             'Möchtest du wirklich ' + this.formatMoney(this.entry.fine) + ' Bußgeld in ' + (this.entry.fine / 1000 * 2) + ' HE umrechnen lassen?',
                showCancelButton:  true,
                showConfirmButton: true,
                confirmButtonText: 'Ja',
                cancelButtonText:  'Nein',
            }).then(r => {
                if (r.isConfirmed) {
                    this.apiFineToJailTimeCriminalRecordEntry(this.record.id, this.entry.id).then(() => this.$emit('updated'));
                }
            })
        },
        jailTimeToBailMoney() {
            Swal.fire({
                title:             'Möchtest du wirklich ' + (this.entry.jailTime - POLICE_BAIL_TIME_MAXIMUM) + ' Hafteinheiten in ' + this.formatMoney((this.entry.jailTime - POLICE_BAIL_TIME_MAXIMUM) * 3000) + ' Kautionsleistung umrechnen lassen?',
                showCancelButton:  true,
                showConfirmButton: true,
                confirmButtonText: 'Ja',
                cancelButtonText:  'Nein',
            }).then(r => {
                if (r.isConfirmed) {
                    this.apiJailTimeToBailMoneyCriminalRecordEntry(this.record.id, this.entry.id).then(() => this.$emit('updated'));
                }
            })
        },
        bailMoneyToJailTime() {
            Swal.fire({
                title:             'Mit dieser Aktion werden die Einheiten auf Kautionsleistung wieder als Hafteinheiten angerechnet.',
                showCancelButton:  true,
                showConfirmButton: true,
                confirmButtonText: 'Ja',
                cancelButtonText:  'Nein',
            }).then(r => {
                if (r.isConfirmed) {
                    this.apiJailTimeToBailMoneyCriminalRecordEntry(this.record.id, this.entry.id).then(() => this.$emit('updated'));
                }
            })
        },
        discard() {
            Swal.fire({
                title:             'Eintrag verwerfen',
                input:             'text',
                inputLabel:        'Begründung',
                showCancelButton:  true,
                showConfirmButton: true,
                confirmButtonText: 'Verwerfen',
                denyButtonText:    'Abbrechen',
            }).then(r => {
                if (r.isConfirmed && r.value && r.value.length > 3) {
                    this.apiDiscardCriminalRecordEntry(this.record.id, this.entry.id, r.value).then(() => {
                        this.$emit('updated');
                    });
                } else {
                    Swal.fire('Oho!', 'Deine Begründung reicht nicht aus', 'error');
                }
            })
        },
        entryEntryColor(entry) {
            if (entry.discarded) return 'blue-grey';
            if (entry.wanted && entry.type === 'BAIL') return 'warning darken-3';
            if (entry.wanted) return 'error darken-3';

            if (entry.type === 'TICKET') return 'info';
            if (entry.type === 'JAIL') return 'error';
            if (entry.type === 'BAIL') return 'error lighten-2';
            if (entry.type === 'CRIMINAL_RECORD') return 'warning';
            if (entry.type === 'RECORD_CHANGE') return 'success';
            if (entry.type === 'NOTE') return 'grey';
            if (entry.type === 'INVESTIGATION') return 'yellow darken-2';
            if (entry.type === 'GOVERNMENT_REGULATION_VIOLATION') return 'yellow';

            return 'primary';
        },
        formatMoney(money) {
            return new Intl.NumberFormat('de-DE', {
                style:    'currency',
                currency: 'EUR',

                // These options are needed to round to whole numbers if that's what you want.
                //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
            }).format(money);
        }
    }
}
</script>
<template>
  <v-container fluid>
    <v-card :loading="fetching" elevation="4" outlined>
      <v-card-title class="pb-2 pt-2 pl-3">
        <v-icon left>mdi-camera-wireless-outline</v-icon>
        Blitzer-Tool
      </v-card-title>
      <v-divider/>
      <!-- Blitzer-Infos -->
      <v-card-title class="pa-1 pl-3">
        <v-icon left>mdi-cog-outline</v-icon>
        Einstellungen
      </v-card-title>
      <v-divider/>
      <v-card-text class="pb-0">
        <v-text-field v-model="entry.location" solo dense outlined hint="Standort des Blitzers" persistent-hint
                      prepend-inner-icon="mdi-earth"/>
        <v-row>
          <v-col>
            <v-text-field v-model="entry.allowedSpeed" type="number" solo dense outlined hint="Erlaubte Geschwindigkeit"
                          persistent-hint prepend-inner-icon="mdi-speedometer-slow"/>
          </v-col>
          <v-col :cols="4">
            <v-text-field v-model="entry.tolerance" type="number" solo dense outlined hint="Toleranz"
                          persistent-hint prepend-inner-icon="mdi-car-speed-limiter"/>
          </v-col>
          <v-col :cols="2">
            <v-checkbox v-model="entry.inCity" label="Innerorts" dense/>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider/>
      <!-- Criminal Record -->
      <v-card-title class="pa-1 pl-3">
        <v-icon left>mdi-account</v-icon>
        Stammdaten (<small><i>Bestehende auswählen oder neue erstellen</i></small>)
      </v-card-title>
      <v-divider/>
      <v-card-text class="pb-0 mb-0">
        <v-row>
          <v-col :cols="4">
            <v-autocomplete :items="criminalRecords" return-object :item-text="formatCriminalName"
                            v-model="entry.criminal"
                            label="Existierender Stammdatensatz" solo outlined clearable
                            prepend-inner-icon="mdi-account" dense></v-autocomplete>
          </v-col>
          <v-col>
            <v-row>
              <v-col>
                <v-text-field v-model="entry.newRecord.name" label="Name des Bürgers" dense single-line outlined
                              prepend-inner-icon="mdi-account" :disabled="entry.criminal != null" clearable/>
              </v-col>
              <v-col>
                <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                        :return-value.sync="entry.newRecord.birthday"
                        transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field dense v-model="entry.newRecord.birthday" label="Geburtsdatum"
                                  prepend-inner-icon="mdi-calendar" readonly outlined :disabled="entry.criminal != null"
                                  v-bind="attrs" v-on="on" clearable></v-text-field>
                  </template>
                  <v-date-picker v-model="entry.newRecord.birthday" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn outlined color="blue-grey" @click="menu = false">
                      Cancel
                    </v-btn>
                    <v-btn outlined color="success" @click="$refs.menu.save(entry.newRecord.birthday)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col :cols="2">
                <v-select dense outlined v-model="entry.newRecord.gender"
                          :items="genders" item-value="type" :disabled="entry.criminal != null">
                  <template v-slot:selection="{item}">
                    <v-icon left>{{ item.icon }}</v-icon>
                  </template>
                  <template v-slot:item="{item}">
                    <v-icon left>{{ item.icon }}</v-icon>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider/>
      <!-- Blitzer-Infos -->
      <v-card-title class="pa-1 pl-3">
        <v-icon left>mdi-file-alert-outline</v-icon>
        Messbericht
      </v-card-title>
      <v-divider/>
      <v-card-text class="pb-3">
        <v-row>
          <v-col cols="8">
            <v-text-field v-model="entry.drivenSpeed" solo dense outlined hint="Gemessene Geschwindigkeit im Km/h"
                          persistent-hint prepend-inner-icon="mdi-speedometer"/>
            <v-text-field v-on:paste="doPasteEvent" solo dense outlined v-model="entry.imageUrl"
                          placeholder="Beweismaterial aus dem Zwischenspeicher hier einfügen oder Bild-URL eingeben."/>

            <v-divider/>
            <div class="pt-3" v-html="mdToHtml(entryText)"></div>
          </v-col>
          <v-col cols="4">
            <v-img :src="'data:image/png;base64,' + entry.image" contain max-height="512px"
                   class="rounded-lg elevation-4 mx-auto transition-swing secondary">
              <v-btn x-small @click="entry.image = null;" style="position: absolute; top: 5px; right: 5px;" fab
                     color="red" dark>
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </v-img>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-btn small color="warning" @click="createRecord">
          <v-icon left>mdi-plus</v-icon>
          Akteneintrag anlegen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
import markdown from "markdown-it";
import Swal from 'sweetalert2';

export default {
  data: () => ({
    fetching: false,

    genders: [{type: 'MALE', icon: 'mdi-gender-male'}, {type: 'FEMALE', icon: 'mdi-gender-female'}],

    criminalRecords: [],
    fineRecords: [],

    entry: {
      criminal: null,
      location: 'Autobahn Abschnitt A ➣ B',
      allowedSpeed: 160,
      tolerance: 9,
      inCity: false,

      drivenSpeed: 0,
      image: null,
      imageId: null,
      imageUrl: null,

      newRecord: {
        name: '',
        birthday: '',
        gender: 'MALE',
      }
    },

    lawKey: '§5 Abs. 5 StVO',

    isCreating: false,

    // refs
    menu: null,
  }),
  filters: {
    datetime(data) {
      return moment(data).format("DD.MM.YYYY HH:mm");
    },
    date(data) {
      return moment(data).format("DD.MM.YYYY");
    }
  },
  computed: {
    overspeed() {
      return this.entry.drivenSpeed - this.entry.allowedSpeed - this.entry.tolerance;
    },
    brokenLaw() {
      let speed = this.entry.drivenSpeed - this.entry.allowedSpeed - this.entry.tolerance;

      let result = null

      let regex1 = /^(\d+)\s*-\s*(\d+)/gm;
      let regex2 = /^über\s+(\d+)/gm;
      let regex3 = /^(\d+)\+.*/gm;

      for (let fineRecord of this.fineRecords) {
        let cityCheck = (this.entry.inCity && fineRecord.description.includes('innerorts')) || (!this.entry.inCity && fineRecord.description.includes('außerorts'))
        if (fineRecord.paragraph === this.lawKey && cityCheck) {
          let m;
          while ((m = regex1.exec(fineRecord.description)) !== null) {
            if (m.index === regex1.lastIndex) regex1.lastIndex++;

            let from = m[1] * 1;
            let to = m[2] * 1;

            if (speed >= from && speed <= to) {
              result = fineRecord;
              break;
            }
          }
        }
      }

      if (result) return result;
      for (let fineRecord of this.fineRecords) {
        let cityCheck = (this.entry.inCity && fineRecord.description.includes('innerorts')) || (!this.entry.inCity && fineRecord.description.includes('außerorts'))
        if (fineRecord.paragraph === this.lawKey && cityCheck) {
          let m;

          while ((m = regex3.exec(fineRecord.description)) !== null) {
            if (m.index === regex3.lastIndex) regex3.lastIndex++;
            console.log(fineRecord);
            let from = m[1] * 1;
            if ((this.entry.drivenSpeed) >= from) {
              result = fineRecord;
              break;
            }
          }
        }
      }

      if (result) return result;
      for (let fineRecord of this.fineRecords) {
        let cityCheck = (this.entry.inCity && fineRecord.description.includes('innerorts')) || (!this.entry.inCity && fineRecord.description.includes('außerorts'))
        if (fineRecord.paragraph === this.lawKey && cityCheck) {
          let m;

          while ((m = regex2.exec(fineRecord.description)) !== null) {
            if (m.index === regex2.lastIndex) regex2.lastIndex++;
            let from = m[1] * 1;
            if (speed >= from) {
              result = fineRecord;
              break;
            }
          }
        }
      }

      return result;
    },
    entryText() {
      if (this.overspeed <= 0) return '';
      if (!this.brokenLaw) return '';

      let law = this.brokenLaw;

      let text = '**Gewertete Einträge:**\n';
      text += '- ' + law.paragraph + ' ' + law.description + ' ....\n';

      text += '\n**Geschwindigkeitskontrolle:**\n'
      text += '_Standort_: `' + this.entry.location + '`\n';
      text += '_Geschwindigkeit_: `' + this.entry.allowedSpeed + ' Km/h`\n';
      text += '_Toleranz_: `' + this.entry.tolerance + ' Km/h`\n';
      text += '_Gemessene Geschwindigkeit:_ `' + this.entry.drivenSpeed + ' Km/h` (`' + this.overspeed + ' Km/h` zu schnell)\n';

      if (this.entry.image || this.entry.imageUrl) {
        text += '\n**Beweise:**\n';
      }

      if (this.entry.image)
        text += '- [Beweisfoto](' + (this.apiAttachmentUrl({id: this.entry.imageId || '1234567'})) + ')\n';
      if (this.entry.imageUrl)
        text += '- [Beweisfoto (Extern)](' + this.entry.imageUrl + ')\n';

      return text;
    },
  },
  methods: {
    mdToHtml(markdownText) {
      if (markdownText == null || markdownText.trim().length === 0) {
        return '<i>Keine Informationen angegeben.</i>'
      }

      return markdown({breaks: true}).render(markdownText);
    },
    doPasteEvent(data) {
      let item = data.clipboardData.items[0];
      if (item.kind === 'file') {
        let file = item.getAsFile();

        this.toBase64(file).then(r => {
          this.entry.image = r;

          console.log(this.entry.image);
        });
      }
    },
    formatCriminalName(item) {
      return item.name + ' ' + item.birthday;
    },

    fetchMasterDataRecords() {
      this.apiGetCriminalRecords().then(response => {
        this.criminalRecords = response.data;
      });
      this.apiGetAllFineRecords().then(response => {
        this.fineRecords = response.data;
      })
    },

    createRecordStep3() {
      let law = this.brokenLaw;
      this.apiCreateCriminalRecordEntry(this.entry.criminal.id, {
        type: 'TICKET',
        content: this.entryText,
        wanted: true,
        fine: law.fine,
        jailTime: law.jailTime,
        penaltyPoints: law.penaltyPoints,
      }).then(() => {
        Swal.fire('', 'Akteneintrag angelegt.', 'success');

        this.entry.criminal = null;
        this.entry.drivenSpeed = 0;
        this.entry.image = null;
        this.entry.imageId = null;
        this.entry.imageUrl = null;
      })
    },
    createRecordStep2() {
      if (this.entry.image) {
        this.apiUploadEvidence(this.entry.image, 'blitzerfoto.png', null, true).then(r => {
          let data = r.data;
          this.entry.imageId = data.id;

          this.createRecordStep3();
        }).catch(() => {
          Swal.fire('', 'Fehler beim Hochladen vom Beweisfoto, versuch es erneut!', 'error');
          this.isCreating = false;
        })
      } else {
        this.createRecordStep3();
      }
    },

    createRecord() {
      if (this.overspeed <= 5) {
        Swal.fire('', 'Die Person ist nach deinen Angaben nicht zu schnell gefahren, Akteneintrag nicht notwendig.', 'error');
        return;
      }

      if (!this.entry.image && !this.entry.imageUrl) {
        Swal.fire('', 'Du musst Beweismaterial angeben!', 'error');
        return;
      }

      if (this.entry.criminal == null && this.entry.newRecord.name && this.entry.newRecord.birthday) {
        this.isCreating = true;

        this.apiCreateCriminalRecord(this.entry.newRecord).then(r => {
          this.entry.criminal = r.data;
          this.entry.newRecord.name = null;
          this.entry.newRecord.birthday = null;
          this.entry.newRecord.gender = 'MALE';
          this.createRecordStep2();
          this.fetchMasterDataRecords();
        })
      } else if (this.entry.criminal) {
        this.createRecordStep2();
      } else {
        Swal.fire('', 'Du musst eine Akte auswählen oder neue Informationen angeben!', 'error');
      }
    }
  },
  mounted() {
    this.fetchMasterDataRecords();
  }
}
</script>
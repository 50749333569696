<template>
  <v-container>
    <v-alert type="error" elevation="6" outlined border="left">
      <b>Hinweis</b>: Du musst deinen Character mit deinem CopNET-Account verknüpfen.
    </v-alert>
    <v-card>
      <v-card-title class="pt-2 pb-2 pl-3">
        <v-icon color="error" left>mdi-shield-outline</v-icon>
        Charakter verknüpfen
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-progress-circular v-if="syncCode == null" indeterminate/>
        <v-alert v-else type="info" outlined border="bottom" elevation="6">
          Führe mit deinem Charakter im Spiel den folgenden Befehl aus: <tt>/copnet_link {{ syncCode }}</tt>
        </v-alert>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-btn color="primary" @click="fetchUser" :loading="checking">
          Überprüfen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    syncCode: null,

    checking: false,
  }),
  methods: {
    fetchSyncCode() {
      this.apiRequestSyncCode().then(r => {
        this.syncCode = r.data.syncCode;
      })
    },
    fetchUser() {
      this.checking = true;
      this.apiGetMyUserInformation().then(r => {
        this.$store.commit('update', {user: r.data});

        setTimeout(() => {
          this.checking = false;
        }, 1000)
      })
    }
  },
  mounted() {
    this.fetchSyncCode();
  }
}
</script>